<template>
  <el-form-item
    class="render-input-box"
    :label="itemData.label"
    :prop="itemData.code"
    :rules="[
      {
        required: itemData.options.required,
        validator: validator,
        trigger: ['blur', 'change']
      }
    ]"
  >
    <template v-if="$attrs.editable"> </template>
    <template v-else>
      <span>{{
        Array.isArray(data) ? data.map(v => v.label).join(",") : data.name
      }}</span>
    </template>
  </el-form-item>
</template>

<script>
export default {
  name: "RenderFacility",
  props: {
    value: [String, Array, Object],
    itemData: Object
  },
  data() {
    return {
      data: "",
      options: []
    };
  },
  watch: {
    data: {
      handler: function(val) {
        this.$emit("input", val);
      },
      deep: true
    }
  },
  created() {
    if (this.value) {
      this.data =
        Object.prototype.toString.call(this.value) === "[object String]"
          ? JSON.parse(this.value)
          : this.value;
    }
    if (this.itemData.options.isCustomOptions) {
      this.options = this.itemData.options.options;
    } else {
      this.options = [];
    }
  },
  methods: {
    validator(rule, value, callback) {
      if (this.itemData.options) {
        if (this.itemData.options.required && !this.data) {
          callback(new Error(`${this.itemData.label}不能为空`));
        }
      }
      callback();
    }
  }
};
</script>

<style scoped lang="scss">
.render-input-box {
  position: relative;
  .el-select {
    width: 100%;
  }
}
</style>
