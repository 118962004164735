import Vue from "vue";
import store from "@/store/index";
import InputDialog from "./InputDialog";

const component = Vue.extend(InputDialog);

let instance;

function init() {
  if (!instance) {
    instance = new component({
      store,
      el: document.createElement("div")
    });
    document.body.appendChild(instance.$el);
  }
}

export default {
  show({
    title = "标题",
    message = "",
    hasInput = true,
    isRequired = true,
    successCb,
    failCb,
    hasCheckPerson,
    ticketId,
    department,
    isPersonByTab = true,
    choosePersonLabel = "验收",
    personByTabTitle = "验收人(多选)",
    personByTabMultiple = true,
    showRecentUsePerson = false,
    recentType = "",
    maxlength = 500,
    otherPerson = [],
    otherPersonLabel = "",
    isPowerCut = false,
    powerCutConfig = {},
    defaultCheckPerson = [],
    personType = "",
    t1 = "",
    t2 = "",
    isYanShouHeGe = false,
    ticketEvaluate,
    excludePersonIds,
    pauseTime
  } = {}) {
    init();
    if (instance.visible) return;
    const params = {
      title,
      message,
      hasInput,
      isRequired,
      successCb,
      failCb,
      hasCheckPerson,
      ticketId,
      department,
      isPersonByTab,
      choosePersonLabel,
      personByTabTitle,
      personByTabMultiple,
      showRecentUsePerson,
      recentType,
      maxlength,
      otherPerson,
      otherPersonLabel,
      isPowerCut,
      powerCutConfig,
      defaultCheckPerson,
      personType,
      t1,
      t2,
      isYanShouHeGe,
      ticketEvaluate,
      excludePersonIds,
      pauseTime
    };
    return instance.show(params);
  },
  close() {
    if (!instance || !instance.visible) return;
    return instance.close();
  },
  removeLoading() {
    if (!instance || !instance.visible) return;
    return instance.removeLoading();
  }
};
