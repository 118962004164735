<template>
  <div>
    <van-field
      :value="dataValue"
      class="render-input-box"
      clickable
      readonly
      :required="itemData.options.required"
      :placeholder="$attrs.editable ? '请选择' : ''"
      :label="itemData.label + ':'"
      :rules="[
        {
          validator: validator
        }
      ]"
      @click="onClick"
    >
    </van-field>
    <select-popup
      :choose-value="dataValue"
      :show-filter="false"
      :data="popupData"
      title="选择"
      :visible="popupVisible"
      :popup-style="{
        height: '50vh'
      }"
      @close="onPopupClose"
      @confirm="onPopupConfirm"
    />
  </div>
</template>

<script>
import SelectPopup from "@/components/SelectPopup";
export default {
  name: "RenderSelect",
  components: {
    SelectPopup
  },
  props: {
    value: [String, Array],
    itemData: Object
  },
  computed: {
    popupData() {
      return this.options.map(opt => ({
        label: opt,
        value: opt
      }));
    }
  },
  data() {
    return {
      data: "",
      options: [],
      dataValue: "",
      popupVisible: false,
      errorMessage: ""
    };
  },
  watch: {
    data: {
      handler: function(val) {
        this.$emit("input", val);
        this.dataValue = Array.isArray(val) ? val.join() : val;
        this.errorMessage = "";
      },
      deep: true
    }
  },
  created() {
    this.data = this.value;
    if (this.itemData.options.isCustomOptions) {
      this.options = this.itemData.options.options;
    } else {
      this.options = [];
    }
  },
  methods: {
    onClick() {
      if (this.$attrs.editable) {
        this.popupVisible = true;
      }
    },
    onPopupClose() {
      this.popupVisible = false;
    },
    onPopupConfirm(row) {
      this.popupVisible = false;
      this.data = row.value;
    },
    validator(value) {
      if (this.itemData.options) {
        if (
          this.itemData.options.required &&
          (!this.data || !this.data.length)
        ) {
          this.errorMessage = `${this.itemData.label}不能为空`;
          return false;
        }
      }
      this.errorMessage = "";
      return true;
    }
  }
};
</script>

<style scoped lang="scss">
.render-input-box {
  position: relative;
}
</style>
