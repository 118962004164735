<template>
  <van-popup
    v-model="visible"
    :close-on-click-overlay="false"
    position="bottom"
    :style="popupStyle"
    get-container="body"
    @open="onOpen"
    @close="close"
    @click-overlay="clickOverlay"
  >
    <section class="main">
      <div class="header block-space-left-right">
        <h3 class="popup-title">{{ title }}</h3>
        <van-search
          v-model="filterStr"
          autofocus
          placeholder="请输入"
          class="popup-search"
          @input="filter"
        >
          <template #left-icon>
            <van-icon
              name="sousuo"
              class-prefix="iconfont"
              class="search-icon"
            />
          </template>
        </van-search>
      </div>
      <div class="content">
        <van-radio-group v-show="filterData.length" v-model="value">
          <van-cell-group>
            <van-cell
              v-for="(item, idx) in filterData"
              :key="idx"
              :title="item[labelKey]"
              clickable
              @click.prevent="handlerClick(item)"
            >
              <template #right-icon>
                <van-radio :name="item[valueKey]" />
              </template>
            </van-cell>
          </van-cell-group>
        </van-radio-group>
        <p v-show="!filterData.length" align="center" class="no-data">
          暂无数据
        </p>
      </div>
      <div class="footer block-space-left-right">
        <van-button
          plain
          class="auto-input__button--cancel"
          @click.prevent="close(undefined)"
          >取消</van-button
        >
        <van-button
          type="info"
          :disabled="!value"
          @click.prevent="onConfirm(value)"
          >确定</van-button
        >
      </div>
    </section>
  </van-popup>
</template>

<script>
import {
  Popup,
  Radio,
  RadioGroup,
  CellGroup,
  Cell,
  Search,
  Button
} from "vant";
import { generatorComponents } from "@/utils";
const components = [Popup, Radio, RadioGroup, CellGroup, Cell, Search, Button];
export default {
  name: "AutoInput",
  components: { ...generatorComponents(components) },
  props: {
    choosedVal: [String || Number],
    title: {
      type: String,
      default: "所属企业"
    },
    // 选中的值的属性名，必传
    valueKey: {
      type: String,
      default: "value"
    },
    // 在页面要展示的选项属性名，必传
    labelKey: {
      type: String,
      default: "label"
    },
    // popupStyle: {
    //   type: Object,
    //   default: () => ({ height: '75%' }),
    // },
    visible: {
      type: Boolean,
      default: false
    },
    data: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      popupStyle: {
        height: "75%"
      },
      value: "",
      filterStr: "",
      filterData: [],
      choosedRow: null
    };
  },
  methods: {
    onOpen() {
      this.filterStr = "";
      this.filterData = [...this.data];
      this.value = this.choosedVal;
    },
    filter(queryString) {
      const data = [...this.data];
      this.filterData = queryString
        ? data.filter(this.createStateFilter(queryString))
        : data;
    },
    createStateFilter(queryString) {
      return state => {
        return (
          state.label.toLowerCase().indexOf(queryString.toLowerCase()) !== -1
        );
      };
    },
    handlerClick(row) {
      this.value = row.value;
      this.choosedRow = row;
    },
    close(val) {
      this.$emit("close", val ? this.choosedRow : undefined);
    },
    clickOverlay() {
      this.$emit("close", undefined);
    },
    onConfirm() {
      this.$emit("confirm", this.choosedRow);
      this.$emit("close", undefined);
    }
  }
};
</script>

<style lang="scss" scoped>
.van-hairline--top-bottom::after,
.van-hairline-unset--top-bottom::after {
  border: none;
}
.popup-title {
  font-weight: bold;
  font-size: 17px;
  margin-top: 3vh;
  padding-bottom: 3vh;
  // border-bottom: 1px solid #F1F1F1;
}
.popup-search {
  margin-bottom: 2vh;
  height: 4.6vh;
}
.van-radio-group {
  // padding: 0 20px;
  margin: 0 9vw;
}
.content {
  height: 45vh;
  overflow-y: auto;
  .no-data {
    padding-top: 80px;
    color: #aeb3c0;
    font-size: 14px;
  }
}
.footer {
  margin-top: 20px;
  display: flex;
  justify-content: space-around;
  .van-button {
    width: 148px;
    // border-radius: $--button-border-radius;
  }
  .auto-input__button--cancel {
    color: #1b0f49;
    background-color: $--button-color-cancel;
    // border-color: $--button-border-color-cancel;
    font-size: 14px;
    box-shadow: $--button-border-color-cancel-shadow;
  }
  .van-button--info {
    font-size: 14px;
    border: none;
    background-color: $--button-color-save;
    border-color: $--button-color-save;
    box-shadow: $--button-color-save-shadow;
    color: #fff;
  }
}
</style>
