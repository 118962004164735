<template>
  <div class="view-user">
    <transition name="route-switch" mode="out-in">
      <router-view />
    </transition>
  </div>
</template>

<script>
export default {
  name: "Layout",
  data() {
    return {};
  }
};
</script>
<style>
.view-user {
  height: 100%;
  overflow: hidden;
}
</style>
