<template>
  <div class="FileUploader" :class="{ unableUpload }">
    <van-uploader
      ref="uploader"
      v-model="fileList"
      class="FileUploader__feature"
      multiple
      result-type="file"
      :before-read="onBeforeRead"
      :after-read="onAfterReadNew"
      :accept="accept"
      :deletable="!unableUpload"
      :max-count="maxCount"
      :max-size="maxSize"
      v-on="$listeners"
      @delete="handlerDelete"
    >
      <template #preview-cover="{ file,url,status, index }">
        <slot
          name="preview-cover"
          :file="file"
          :url="url"
          :status="status"
          :index="index"
        >
          <!--这里做自定义预览-->
          <div
            class="preview-cover"
            :class="getFileType(file.name)"
            @click.stop="handlerPreview(file, url)"
          >
            <!--图片-->
            <van-image
              v-if="getFileType(file.name) == 'image'"
              class="image"
              fit="cover"
              :src="url"
            >
              <template v-slot:loading>
                <van-loading type="spinner" size="20" />
              </template>
            </van-image>
            <!--视频-->
            <div v-else-if="getFileType(file.name) == 'video'" class="video">
              <van-image class="video-image" fit="cover" :src="videoImage[url]">
                <template v-slot:loading>
                  <van-loading type="spinner" size="20" />
                </template>
              </van-image>
              <van-icon
                v-if="status == ''"
                name="play-circle"
                class="video-icon"
              />
            </div>
            <!--音频-->
            <div v-else-if="getFileType(file.name) == 'audio'" class="audio">
              <van-icon v-if="status == ''" name="music" class="audio-icon" />
            </div>
            <!--其他-->
            <div v-else class="other">
              <van-icon
                v-if="status == ''"
                :name="previewIcon(getFileType(file.name))"
              />
              <!--            <div class="name van-ellipsis">{{file.name}}</div>-->
              <div class="name van-ellipsis">{{ getFileType(file.name) }}</div>
            </div>

            <div class="label van-ellipsis">
              {{ file.name }}
            </div>
          </div>
        </slot>
      </template>

      <slot :unable-upload="unableUpload">
        <div v-show="!unableUpload" class="upload-btn">
          <van-icon :name="uploadIcon" class="icon" />
          <div v-if="uploadTextShow" class="text">{{ uploadTextShow }}</div>
        </div>
      </slot>
    </van-uploader>
  </div>
</template>

<script>
import { Toast } from "vant";
import axios from "@/utils/axios";
import {
  FILE_ACCEPT_TYPES,
  getFileType,
  getFileUrl,
  getVideoImageBase64
} from "./util";
import VideoPreview from "@/components/fileuploader/VideoPreview";
import AudioPreview from "@/components/fileuploader/AudioPreview";
import { ImagePreview } from "vant";
import { imageCompression } from "@/utils/file";

export default {
  name: "FileUploader",
  components: {},
  model: {
    prop: "value",
    event: "change"
  },
  props: {
    value: {
      type: [Array, Object, String],
      default: null
    },
    maxSize: { type: [Number, String], default: 1024 * 1024 * 100 }, //文件大小限制，单位为 byte
    maxCount: { type: Number, default: 10 }, //maxCount=1时，value是一个对象
    unableUpload: { type: Boolean, default: false }, //不能点击上传，不可点击状态传：true
    /**
     * 文件类型参数，和app约定只用FILE_ACCEPT_TYPES中的参数
     */
    accept: { type: String, default: FILE_ACCEPT_TYPES.IMAGE },
    uploadText: { type: String, default: "" } //上传按钮的文字
  },
  data() {
    return {
      fileList: [],
      getFileType,
      videoImage: {}
    };
  },
  computed: {
    uploadIcon() {
      let { accept, uploadText } = this;
      if (uploadText) return "plus";

      if (accept == FILE_ACCEPT_TYPES.IMAGE) {
        return "photograph";
      } else if (accept == FILE_ACCEPT_TYPES.IMAGE_AND_AUDIO) {
        return "photograph";
      } else if (accept == FILE_ACCEPT_TYPES.AUDIO) {
        return "audio";
      } else if (accept == FILE_ACCEPT_TYPES.VIDEO) {
        return "video";
      } else if (accept == FILE_ACCEPT_TYPES.FILE) {
        return "plus";
      } else if (accept == FILE_ACCEPT_TYPES.ONLY_PHOTO) {
        return "photograph";
      } else if (accept == FILE_ACCEPT_TYPES.ONLY_VIDEO) {
        return "video";
      } else if (accept == FILE_ACCEPT_TYPES.ONLY_PHOTO_AND_VIDEO) {
        return "photograph";
      }
      return "plus";
    },

    uploadTextShow() {
      let { uploadText, accept } = this;
      if (uploadText) return uploadText;

      if (accept == FILE_ACCEPT_TYPES.IMAGE) {
        return "添加照片";
      } else if (accept == FILE_ACCEPT_TYPES.IMAGE_AND_AUDIO) {
        return "照片或视频";
      } else if (accept == FILE_ACCEPT_TYPES.AUDIO) {
        return "添加音频";
      } else if (accept == FILE_ACCEPT_TYPES.VIDEO) {
        return "添加视频";
      } else if (accept == FILE_ACCEPT_TYPES.FILE) {
        return "添加文件";
      } else if (accept == FILE_ACCEPT_TYPES.ONLY_PHOTO) {
        return "拍照";
      } else if (accept == FILE_ACCEPT_TYPES.ONLY_VIDEO) {
        return "录像";
      } else if (accept == FILE_ACCEPT_TYPES.ONLY_PHOTO_AND_VIDEO) {
        return "拍照或录像";
      }
      return "";
    }
  },
  watch: {
    value: {
      immediate: true,
      handler(val, oldVal) {
        this.initFileList(val);
      }
    }
  },
  methods: {
    // 手动触发选择文件
    chooseFile() {
      this.$refs.uploader?.chooseFile();
    },
    initFileList(value) {
      let valueArr = [];
      if (Array.isArray(value)) {
        valueArr = value;
      }
      if (Object.prototype.toString.call(value) === "[object Object]") {
        valueArr = [value];
      }

      //判断value和fileList是否相等
      if (this.equal(valueArr, this.fileList)) return;

      this.fileList = valueArr.map(item => {
        let fileListItem = { ...item };
        fileListItem.url = getFileUrl(item.id);
        fileListItem.file = { name: item.name };
        fileListItem.status = "";
        fileListItem.message = "";

        this.updateVideoImage(fileListItem);
        return fileListItem;
      });
    },

    equal(valueArr, fileList) {
      if (valueArr.length != fileList.length) {
        return false;
      }
      return !valueArr.some((item, index) => item.id != fileList[index].id);
    },

    updateVideoImage(fileItem) {
      if (
        "video" != getFileType(fileItem.name) ||
        this.videoImage[fileItem.url]
      )
        return;
      getVideoImageBase64(fileItem.url)
        .then(res => {
          this.$set(this.videoImage, fileItem.url, res);
        })
        .catch(error => {
          console.log(error);
        });
    },
    updateValue(fileList) {
      console.log("updateValue---", fileList);

      if (!Array.isArray(fileList)) return;

      let value = [];
      if (fileList.length > 0) {
        value = fileList.map(item => {
          return {
            id: item.id,
            name: item.name,
            null: item.null,
            size: item.size,
            uploadTime: item.uploadTime,
            url: item.url
          };
        });
      }
      this.$emit("change", this.maxCount == 1 ? value[0] : value);
    },
    previewIcon(fileType) {
      let icons = {
        video: "video-o",
        audio: "music-o"
      };
      return icons[fileType] || "description";
    },
    handlerPreview(file, url) {
      console.log("handlerPreview", file, url);
      let fileType = getFileType(file.name);
      if ("image" == fileType) {
        ImagePreview([url]);
      } else if ("video" == fileType) {
        VideoPreview.show(url);
        return;
      } else if ("audio" == fileType) {
        AudioPreview.show(url);
        return;
      } else {
        if (window.android) {
          // window.android.downLoadFile("http://192.168.8.22:8023/print/work_tickets?specialTicketId=1&specialType=206318905658966016", "a.docx");
          window.androidH5.downLoadFile(url, file.name);
        } else {
          this.$toast.fail("此文件格式暂不支持预览");
        }
        // window.open("https://view.officeapps.live.com/op/view.aspx?src=" + url)
      }
    },

    onBeforeRead(file) {
      file = Array.isArray(file) ? file : [file];
      const maxCount = this.maxCount;
      const isLt10 = file.length <= maxCount && this.fileList.length < maxCount;

      const isValidity = file.every(f => {
        let type = getFileType(f.name);
        return type && type != "other";
      });

      const isOverSize = file.every(f => f.size / this.maxSize > 1);
      const maxSize = Number(this.maxSize) / 1024 / 1024;
      if (!isLt10) Toast(`附件最多上传${maxCount}个`);
      if (isOverSize) Toast(`文件不能超过${maxSize}MB`);
      if (!isValidity) Toast("附件格式不支持");
      return isLt10 && isValidity && !isOverSize;
    },
    handlerDelete(file) {
      console.log("handlerDelete--------", file);
      file = Array.isArray(file) ? file : [file];
      this.updateValue(this.fileList);
    },
    onAfterReadNew(file) {
      console.log("onAfterReadNew--------", file);
      file = Array.isArray(file) ? file : [file];
      file.map(item => {
        item.status = "uploading";
        item.message = "上传中...";

        this.uploadFile(item)
          .then(res => {
            let res0 = res[0];
            item.status = "";
            item.message = "";

            item.url = getFileUrl(res0.id);
            item.name = res0.name;
            item.id = res0.id;
            item.size = res0.size;
            item.uploadTime = res0.uploadTime;
            this.updateValue(this.fileList);
            this.updateVideoImage(item);
          })
          .catch(error => {
            item.status = "failed";
            item.message = "上传失败";
          });
      });
    },
    uploadFile({ file }) {
      const filePath = window.globalConfig.VUE_APP_FILE_URL + "/file/upload";
      if (file.type.includes("image")) {
        const size = file.size / 1024;
        let pq = Number(window.globalConfig.VUE_APP_PICTURE_QUALITY);
        // pq为1则未设置/设置错误或设置为原图
        pq = isNaN(pq) ? 1 : pq || 1;
        if (size > 500 && pq !== 1) {
          return imageCompression(file, pq);
        }
      }
      const fd = new FormData();
      fd.append("files", file);
      return axios.post(filePath, fd);
    }
  }
};
</script>

<style lang="scss">
.FileUploader {
  width: 100%;

  &.unableUpload {
    .van-uploader__upload,
    .van-uploader__input-wrapper {
      display: none;
    }
  }

  .upload-btn {
    position: relative;
    width: 80px; /*no*/
    height: 80px; /*no*/
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #f7f8fa;
    color: #b8bcc6;

    &:active {
      background-color: #f2f3f5;
    }

    .icon {
      font-size: 20px;
    }

    .text {
      font-size: 12px;
      line-height: 1;
      margin-top: 5px;
    }
  }

  .preview-cover {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: #f7f8fa;
    color: #646566;
    font-size: 22px;

    .image {
      width: 100%;
      height: 100%;
    }

    .video {
      position: relative;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .video-image {
        position: absolute;
        width: 100%;
        height: 100%;
      }

      .video-icon {
        font-size: 32px;
        background-color: #ffffff;
        border-radius: 50%;
        color: rgba(0, 0, 0, 0.7);
      }
    }

    .audio {
      position: relative;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-color: rgba(0, 0, 0, 0.3);

      .audio-icon {
        font-size: 32px;
        background-color: #ffffff;
        border-radius: 50%;
        color: rgba(0, 0, 0, 0.7);
      }
    }

    .other {
      position: relative;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    .name {
      width: 100%;
      font-size: 10px;
      line-height: 14px;
      padding: 5px 5px 0px 5px;
      box-sizing: border-box;
      text-align: center;
    }

    .label {
      position: absolute;
      bottom: 0;
      box-sizing: border-box;
      width: 100%;
      color: #fff;
      font-size: 12px;
      line-height: 20px;
      height: 20px;
      text-align: center;
      background: rgba(0, 0, 0, 0.3);
      z-index: 10;
      padding: 0px 3px;
    }
  }
}
</style>
