<template>
  <div class="WorkticketSafedDsclosureView">
    <van-skeleton title avatar :row="3" :loading="!list">
      <WorkticketSafetyItem
        v-for="(item, index) in list"
        :key="index"
        :value="item"
        :ticket-id="ticketId"
        :ticket-status="ticketStatus"
        :safe-inventory-confirm-person-ids="safeInventoryConfirmPersonIds"
        :bottom-border="!bottomBorderBlackList.includes(item.personType)"
        :origin-status="originStatus"
        @signature-success="getWorker()"
      >
        <span v-if="otherTypeHasPersonIdx === index" slot="otherPersonTitle"
          >其他人员</span
        >
      </WorkticketSafetyItem>

      <van-cell title="现场照片">
        <template #label>
          <FileUploader
            v-model="workSite"
            :unable-upload="
              WORKTICKET_STATUS.Safety_disclosureing != ticketStatus
            "
            :accept="FILE_ACCEPT_TYPES.IMAGE_AND_AUDIO"
          />
        </template>
      </van-cell>
    </van-skeleton>
  </div>
</template>

<script>
import { uniqBy, cloneDeep } from "lodash";
import WorkticketSafetyItem from "@/components/workticket/WorkticketSafetyItem";
import { getWorkSafeWork, getWorkWorkSite } from "@/api/workticket";
import FileUploader from "@/components/fileuploader/FileUploader";
import { WORKTICKET_STATUS } from "@/views/workticket/utils/constant";
import { FILE_ACCEPT_TYPES } from "@/components/fileuploader/util";

export default {
  name: "WorkticketSafedDsclosureView",
  components: { FileUploader, WorkticketSafetyItem },
  props: {
    ticketId: { type: String, default: "" }, //作业id
    ticketStatus: { type: [String, Number], default: "" }, //作业状态
    isAllSign: { type: Boolean, default: false }, // 签名状态
    affectFlag: { type: Boolean, default: true }, // 是否需要受影响相关方
    originStatus: { type: Number },
    isEquipmentSafety: { type: Boolean, default: false } // 是否是设备检修安全作业
  },
  data() {
    return {
      list: null,
      workSite: null,
      WORKTICKET_STATUS,
      FILE_ACCEPT_TYPES,
      // 最后一个保留border
      bottomBorderBlackList: ["10", "20", "21", "22", "23", "13", "11", "12"],
      otherTypeHasPersonIdx: -1,
      safeInventoryConfirmPersonIds: []
    };
  },
  computed: {},
  watch: {
    list: {
      immediate: true,
      deep: true,
      handler(val) {
        if (!val || val.length == 0) {
          this.$emit("update:isAllSign", true);
          return;
        }
        let hasNoSign = val.some(item => {
          if (!item.personType) return false;
          return (item.workerList || []).some(item1 => {
            return (item1.workerList || []).some(
              item2 => item2.current && !item2.fileId
            );
          });
        });
        this.$emit("update:isAllSign", !hasNoSign);
      }
    }
  },
  created: function() {},
  mounted: function() {
    this.init();
  },
  methods: {
    init() {
      this.getWorker();

      getWorkWorkSite(this.ticketId).then(res => {
        this.workSite = res;
      });
    },
    getWorker() {
      getWorkSafeWork(this.ticketId).then(res => {
        // 吊装指挥、吊装人员、司索人放入作业人内
        const hoistingPersonBySpecial = [];
        // let data = res.filter(item => !["11", "12"].includes(item.personType));
        const workerData = res.find(item => item.personType === "3");
        res.forEach(item => {
          if (
            ["11", "12", "1201"].includes(item.personType) &&
            item.workerList
          ) {
            hoistingPersonBySpecial.push(...(cloneDeep(item.workerList) || []));
          }
        });
        let worker = hoistingPersonBySpecial.reduce((acc, cur) => {
          const matchData = acc.find(item => item.specialId === cur.specialId);
          if (matchData) {
            matchData.workerList.push(...(cur.workerList || []));
          } else {
            acc.push(cur);
          }
          return acc;
        }, []);
        worker = worker.map(item => {
          return {
            ...item,
            workerList: uniqBy(item.workerList, "personId")
          };
        });
        workerData.workerList.push(...worker);
        const specialWorkerIds = workerData.workerList.reduce((acc, cur) => {
          const ids = (cur.workerList || []).map(i => i.personId);
          acc.push(...ids);
          return acc;
        }, []);
        const nomarlWorkerIds = res
          .find(item => item.personType === "15")
          .workerList.reduce((acc, cur) => {
            const ids = (cur.workerList || []).map(i => i.personId);
            acc.push(...ids);
            return acc;
          }, []);
        this.safeInventoryConfirmPersonIds = [
          ...specialWorkerIds,
          ...nomarlWorkerIds
        ];
        // 安全交底额外选的人19并入4内
        // const sw = res.find(item => item.personType === "4");
        // const f = res.find(item => item.personType === "19"); // 安全交底人
        // const sc = (f?.workerList || []).reduce((acc, cur) => {
        //   acc.push(...(cur?.workerList || []));
        //   return acc;
        // }, []);
        // sw.workerList.push(...sc);
        // res = res.filter(i => i.personType !== "19");
        let data = res;
        if (window.globalConfig.VUE_APP_NAME === "RT") {
          // 安全教育实施人包含：属地部门20、属地21、工作负责人22
          const safeDucationPersonBySpecial = [];
          data = res.filter(item => !["21", "22"].includes(item.personType));
          const territorialDepartment = data.find(
            item => item.personType === "20"
          );
          res.forEach(item => {
            if (["21", "22"].includes(item.personType) && item.workerList) {
              safeDucationPersonBySpecial.push(...(item.workerList || []));
            }
          });
          const safeDucationPerson = safeDucationPersonBySpecial.reduce(
            (acc, cur) => {
              const matchData = acc.find(
                item => item.specialId === cur.specialId
              );
              if (matchData) {
                matchData.workerList.push(...(cur.workerList || []));
              } else {
                acc.push(cur);
              }
              return acc;
            },
            []
          );
          territorialDepartment.workerList?.[0]?.workerList?.push(
            ...(safeDucationPerson?.[0]?.workerList || [])
          );
        } else {
          data = res.filter(
            item => !["20", "21", "22", "23"].includes(item.personType)
          );
          if (this.isZx) {
            // 中新化工隐藏“一般作业人员”
            data = data.filter(item => item.personType !== "15");
          }
        }
        // const safetyWorkerData = data.find(item => item.personType === "4");
        // if (safetyWorkerData?.otherWorkerList.length) {
        //   safetyWorkerData.workerList = safetyWorkerData.otherWorkerList;
        // }
        const otherType = [
          "10",
          "20",
          "21",
          "22",
          "23",
          "13",
          "11",
          "12",
          "1201"
        ];
        this.otherTypeHasPersonIdx = data.findIndex(
          i => otherType.includes(i.personType) && i.workerList?.length
        );
        if (!this.affectFlag) {
          data = data.filter(item => item.personType !== "7");
        }
        if (!this.isEquipmentSafety) {
          // 过滤工艺安全交底人、检修安全交底人
          data = data.filter(item => !["38", "39"].includes(item.personType));
        }
        this.list = data;
      });
    },
    onImageChange(val) {
      this.workSite = val;
    },
    getFiles() {
      return this.workSite;
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.WorkticketSafedDsclosureView {
  .content-box {
    padding: 16px;

    .title {
      color: $--color-black-title;
      font-size: 16px;
      margin-bottom: 10px;
    }
  }

  ::v-deep.van-cell__title {
    font-size: 16px;
  }
}
</style>
