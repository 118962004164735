<template>
  <van-field
    class="CheckboxEditFieldWorkHazard"
    error-message-align="right"
    :value="validatorValue"
    :rules="[
      { required: true, message: '请选择' + title, trigger: 'onChange' }
    ]"
    v-bind="$attrs"
  >
    <template #input>
      <div class="content-box">
        <div class="title">{{ title }}</div>
        <Tag
          v-for="(item, index) in options"
          v-show="editing || isChecked(item)"
          :key="index"
          :type="isChecked(item) ? tagType : 'white'"
          @click.native="clickItem(index, item)"
        >
          {{ item[optionLabelKey] }}
        </Tag>
        <Tag v-show="extra && !editing" :type="tagType">{{ extra }}</Tag>
        <slot></slot>
      </div>
    </template>
  </van-field>
</template>

<script>
import Tag from "@/components/Tag";
import EditFieldMixin from "@/components/edit/mixins/EditFieldMixin";
import {
  getCheckedArrayValues,
  isChecked
} from "@/components/edit/utils/options";

export default {
  name: "CheckboxEditFieldWorkHazard", //危害辨识checkbox
  components: { Tag },
  mixins: [EditFieldMixin],
  props: {
    tagType: {
      type: String,
      default: "yellow"
    },
    extra: null,
    optionLabelKey: { type: String, default: "label" },
    optionValueKey: { type: String, default: "value" }
  },
  data() {
    return {
      workHazardDesc: "",
      fieldValue: " " //van-field有value字段才会有校验功能
    };
  },
  computed: {
    //van-field恶心的bug：必须给value设置响应式数据才有正确的校验功能，value只能是String，Number,
    // 需要升级vant-ui版本
    validatorValue() {
      return this.extra || (this.value && this.value.length > 0) ? "1111" : "";
    }
  },
  watch: {},
  created: function() {},
  mounted: function() {},
  methods: {
    validator(val) {
      console.log("validator----");
      return this.extra || (this.value && this.value.length > 0);
    },
    clickItem(index, item) {
      if (!this.editing) return;
      let { value, optionValueKey } = this;
      let checkedCopy = getCheckedArrayValues({
        checked: value,
        value: item[optionValueKey],
        multiple: true,
        inverse: true
      });
      this.$emit("change", checkedCopy);
    },
    isChecked(item) {
      return isChecked({
        checked: this.value,
        value: item[this.optionValueKey],
        optionValueKey: this.optionValueKey
      });
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.CheckboxEditFieldWorkHazard {
  padding-bottom: 15px;

  .content-box {
    width: 100%;
  }

  .title {
    color: $--color-black-title;
    margin-bottom: 8px;
  }

  .tags-box {
    padding: 0px 16px;
  }

  &::v-deep {
    .Tag {
      width: auto;
      padding-left: 15px;
      padding-right: 15px;
    }
  }
}
</style>
