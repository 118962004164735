<template>
  <div class="render-group">
    <render-title :item-data="itemData"></render-title>
    <template v-if="$attrs.editable">
      <div class="group-box">
        <div
          v-for="(item, index) in data"
          :key="index"
          :class="{ hasLabel: index === data.findIndex(v => v) }"
        >
          <div
            v-if="item"
            class="group-item"
            style="margin-bottom: 15px;"
            :style="`min-width: ${item.length * 150 + 140}px;`"
          >
            <template v-if="Array.isArray(item)">
              <div
                v-for="(groupItem, groupIdx) in item"
                :key="groupIdx"
                style="flex:1;min-width: 150px; "
                :style="
                  `padding-right: ${
                    groupIdx === item.length - 1 ? '0' : '20px'
                  };`
                "
              >
                <component
                  :is="getFormItem(groupItem.name)['renderCom']"
                  :key="groupItem.name"
                  v-model="groupItem.value"
                  v-bind="$attrs"
                  :item-data="getFormItem(groupItem.name)"
                  :editable="true"
                ></component>
              </div>
            </template>
            <template v-else>
              <div
                v-for="(value, key, idx) in item"
                :key="idx"
                class="group-item-box"
              >
                <component
                  :is="getFormItem(key)['renderCom']"
                  :key="item.name"
                  v-model="item[key]"
                  v-bind="$attrs"
                  :item-data="getFormItem(key)"
                  :editable="true"
                ></component>
              </div>
            </template>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="group-box">
        <div
          v-for="(group, groupIdx) in data"
          :key="groupIdx"
          style="display: flex;justify-content: flex-start;"
          :class="{ hasLabel: groupIdx === 0 }"
        >
          <div
            v-for="(groupItem, gidx) in group"
            :key="gidx"
            style="flex: 1;min-width: 150px;"
            class="group-item-detail"
          >
            <p v-if="groupIdx === 0" class="group-item_title">
              {{ groupItem.label }}
            </p>
            <p v-if="groupItem.type === 'check'" class="group-item_content">
              <van-checkbox
                v-model="groupItem.checked"
                shape="square"
                checked-color="#8c8f97"
              ></van-checkbox>
            </p>
            <p v-else class="group-item_content">{{ getData(groupItem) }}</p>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { multipleImportVue } from "../util/index";

// 引入所有需要的动态组件
const requireComponent = require.context(
  "../render", //组件所在目录的相对路径
  true, //是否查询其子目录
  /\w+\.vue$/ //匹配基础组件文件名的正则表达式
);
let comObj = multipleImportVue(requireComponent);

export default {
  name: "RenderGroup",
  components: {
    ...comObj
  },
  props: {
    value: [String, Array],
    itemData: Object
  },
  data() {
    return {
      data: [],
      formList: JSON.parse(JSON.stringify(this.itemData.children)),
      groupBackup: {}
    };
  },
  computed: {
    hasAddIcon() {
      return this.itemData.options?.addition;
    },
    renderData() {
      return Array.isArray(this.value) ? this.value : JSON.parse(this.value);
    }
  },
  watch: {
    data: {
      handler: function(val) {
        this.$parent.validateField(this.itemData.code);

        val = val
          .filter(v => v)
          .map(v => {
            if (Array.isArray(v)) {
              let obj = {};
              v.map(k => {
                if (this.getFormItem(k.name).type === "facility") {
                  obj[k.name] = JSON.stringify(k.value);
                } else {
                  obj[k.name] =
                    this.getFormItem(k.name).type === "input"
                      ? k.value
                      : k.value;
                }
              });
              return obj;
            } else {
              return v;
            }
          });
        if (this.$attrs.editable) {
          this.$emit("input", val);
        }
      },
      deep: true
    }
  },
  created() {
    this.data = JSON.parse(JSON.stringify(this.renderData));
    if (this.renderData.length) {
      this.groupBackup = JSON.parse(JSON.stringify(this.renderData[0]));
    }
  },
  methods: {
    isDisabled() {
      let res = false;
      this.itemData.children
        .filter(v => v)
        .map(v => {
          this.data
            .filter(k => k)
            .map(k => {
              if (
                v.type === "input" &&
                k[v.code] &&
                k[v.code].some(j => j === "")
              ) {
                res = true;
              }
              if (
                v.type === "select" &&
                v.options.isMultiple &&
                !k[v.code].length
              ) {
                res = true;
              }
              if (
                v.type === "select" &&
                !v.options.isMultiple &&
                k[v.code] === ""
              ) {
                res = true;
              }
              if (k[v.code] === "") {
                res = true;
              }
            });
        });
      return res;
    },
    validator(rule, value, callback) {
      let children = this.itemData.children;
      if (this.itemData.options.required) {
        this.data.map(v => {
          let code = Object.keys(v).find(k =>
            Array.isArray(v[k]) ? !v[k][0] : !v[k]
          );
          if (code) {
            let label = children.find(j => j.code === code).label;
            callback(new Error(`${label}不能为空`));
          }
        });
      }
      callback();
    },
    getFormItem(key) {
      let obj =
        this.formList.find(v => v.code === key || v.code === key.name) || {};
      if (this?.itemData?.options[obj.type]) {
        obj.options.disabled = true;
      }
      return obj || { options: {} };
    },
    getData(item) {
      let businessCom = [
        "user",
        "position",
        "department",
        "facility",
        "indicator",
        "jsa",
        "material",
        "product",
        "intermediate",
        "feedstock"
      ];
      if (!businessCom.includes(item.type)) {
        return item.value;
      }
      let arr = this.getFormItem(item).options?.isMultiple
        ? item.value
        : [item.value];

      if (item.type === "department") {
        return this.$attrs[`dept-data`]
          ?.filter(s => arr.includes(s.value))
          .map(s => s.label)
          .join(",");
      }
      if (item.type === "facility") {
        if (Array.isArray(JSON.parse(item.value))) {
          return this.$attrs[`${item.type}-data`]
            ?.filter(s => arr.includes(s.value))
            .map(s => s.label)
            .join(",");
        } else {
          return this.$attrs[`facility-data`]
            ?.filter(s =>
              arr
                .map(v => JSON.parse(v))
                .map(v => v.id)
                .includes(s.value)
            )
            .map(s => s.label)
            .join(",");
        }
      }
      return this.$attrs[`${item.type}-data`]
        ?.filter(s => arr.includes(s.value))
        .map(s => s.label)
        .join(",");
    }
  }
};
</script>

<style lang="scss" scoped>
.render-group-item {
  position: relative;
  margin-bottom: 10px;
  .add-button {
    position: absolute;
    top: -10px;
    right: 10px;
    font-size: 24px;
    z-index: 100;
  }
}
.group-box {
  position: relative;
  overflow: hidden;
  overflow-x: auto;
  margin-left: 16px;
  margin-bottom: 4px;
}
.group-item {
  position: relative;
  display: flex;
  justify-content: space-between;
  margin-bottom: 14px;
  padding-right: 40px;
  .el-button {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 24px;
    z-index: 10;
  }
  ::v-deep.group-item-box {
    flex: 1;
    &:not(:first-of-type) {
      margin: 0 0 0 10px;
    }
  }
  .el-form-item__label {
    display: block !important;
  }
  .el-form-item__content {
    margin-left: 0 !important;
    margin-bottom: 14px;
  }
  ::v-deep.render-input-box {
    position: relative;
    padding-right: 0 !important;
    margin-bottom: 0;
    ::v-deep.input-item {
      margin-bottom: 0 !important;
    }
  }
  ::v-deep .el-form-item__label {
    display: none;
    text-align: left;
    float: none;
  }
  ::v-deep .el-form-item__content {
    width: 100% !important;
    margin-left: 0 !important;
  }
}
.group-item:last-of-type {
  margin-bottom: 0;
  ::v-deep .el-form-item__label {
    display: none;
  }
}
.hasLabel {
  .group-item ::v-deep .el-form-item__label {
    display: block !important;
  }
  .group-item_content {
    min-height: 40px;
    height: calc(100% - 34px) !important;
  }
}
::v-deep .el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 100% !important;
}

.group-item_title {
  box-sizing: border-box;
  padding: 9px 14px;
  //border: 1px solid rgba(192, 196, 204, 0.7);
  background: #eceef2;
  font-size: 14px;
  font-family: PingFangSC, PingFangSC-Regular, serif;
  font-weight: 400;
  text-align: center;
  color: #8c8f97;
  line-height: 14px;
}
.group-item_content {
  box-sizing: border-box;
  white-space: pre-line;
  padding: 9px 14px;
  border: 1px solid #eceef2;
  border-top: 0;
  background: #fff;
  font-size: 14px;
  font-family: PingFangSC, PingFangSC-Regular, serif;
  font-weight: 400;
  text-align: center;
  color: #3b4664;
  line-height: 20px;
  height: 100%;
  min-height: 40px;
  max-height: 200px;
  overflow-y: auto;
}

.group-item-detail + .group-item-detail {
  .group-item_title,
  .group-item_content {
    border-left: 0;
  }
}
</style>
