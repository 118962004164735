<template>
  <div
    class="Tag"
    :style="{
      ...styleObj[type],
      borderColor: border ? styleObj[type].color : ''
    }"
  >
    <slot></slot>
    <van-icon
      v-if="tick"
      class="tick"
      class-prefix="iconfont"
      name="icoqianming1"
    />
  </div>
</template>

<script>
export default {
  name: "Tag",
  components: {},
  props: {
    type: {
      type: String,
      default: "blue"
    },
    tick: {
      //是否显示勾号√
      type: Boolean,
      default: false
    },
    border: { type: Boolean, default: true } //是否显示边框
  },
  data() {
    return {
      styleObj: {
        gray: {
          color: "#8C8F97",
          background: "#e1e3e8"
        },
        blue: {
          color: "#1676FF",
          background: "#dcebff"
        },
        green: {
          color: "#2eb752",
          background: "#eaf7ed"
        },
        yellow: {
          color: "#F3961C",
          background: "#FFEED8"
        },
        white: {
          color: "#8C8F97",
          background: "#fff"
        },
        purple: {
          color: "#98adcf",
          background: "#f4f6fa"
        }
      }
    };
  },
  created: function() {},
  mounted: function() {},
  methods: {}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.Tag {
  display: inline-block;
  // min-width: 70px;
  width: 88px;
  // height: fit-content;
  // height: 32px;
  border-radius: 16px;
  // padding: 5px 12px;
  padding: 6px 4px 5px;
  margin: 0px 8px 8px 0px;
  border: transparent solid 1px;
  box-sizing: border-box;
  text-align: center;
  font-size: 14px;
  line-height: 20px;
  vertical-align: top;
  .tick {
    // margin-left: 4px;
    font-size: 18px;
    line-height: 20px;
    vertical-align: bottom;
  }
}
</style>
