<template>
  <van-dialog
    v-model="visible"
    class="InputDialog"
    :class="{ loading }"
    :title="title"
    show-cancel-button
    :before-close="beforeClose"
    :disabled="loading"
  >
    <div v-for="(item, k) of list" :key="k">
      <van-field
        v-if="item.hasInput"
        v-model.trim="item.message"
        class="remark-box"
        rows="2"
        :autosize="{ maxHeight: 250 }"
        type="textarea"
        :maxlength="item.maxlength"
        :placeholder="placeholder"
        show-word-limit
        :error="item.isRequired && !item.message"
        :disabled="loading"
      />
      <div
        v-if="projectName === 'RT' && item.otherPerson.length"
        class="person-signature-box"
      >
        <div style="line-height: 44px;">{{ item.otherPersonLabel }}人：</div>
        <div class="tag-box">
          <Tag
            v-for="p in item.otherPerson"
            :key="p.personId"
            :type="p.fileId ? 'green' : 'white'"
            :tick="!!p.fileId"
            @click.native="
              handleClickTag(
                p,
                p.fileId,
                p.personId,
                p.personType,
                ticketId,
                p.specialId
              )
            "
          >
            {{ p.personName }}
          </Tag>
        </div>
      </div>
      <div v-if="item.hasCheckPerson" class="person-signature-box">
        <div>
          {{ item.choosePersonLabel }}人：
          <van-button
            class="btn-select-person"
            round
            @click="choosePerson(item, k)"
            >选择人员</van-button
          >
        </div>
        <div class="tag-box">
          <Tag
            v-for="p in item.checkPersonList"
            :key="p.personId"
            :type="p.fileId ? 'green' : 'white'"
            :tick="!!p.fileId"
            @click.native="
              handleClickTag(
                p,
                p.fileId,
                p.personId,
                p.personType,
                ticketId,
                p.specialId,
                item.choosePersonLabel
              )
            "
          >
            {{ p.personName }}
          </Tag>
        </div>
      </div>
    </div>

    <WorkticketPersonPicker
      ref="personPicker"
      v-model="list[curConfigIndex].checkPersonList"
      :title="list[curConfigIndex].startWorkSpaceWorker ? '作业人' : '验收人'"
      :multiple="true"
      :is-check-person="true"
      :department="list[curConfigIndex].department"
      :ticket-id="ticketId"
      :special-id="list[curConfigIndex].specialId"
      :start-work-space-worker="list[curConfigIndex].startWorkSpaceWorker"
      @change="checkPersonChange"
    />
    <select-person-by-tabs
      :key="list[curConfigIndex].recentType"
      :title="list[curConfigIndex].personByTabTitle"
      :visible="SPBTPicker"
      :show-department-name="false"
      :show-phone-number="true"
      position="bottom"
      :include-children="includeChildrenSwitch"
      :multiple="list[curConfigIndex].personByTabMultiple"
      :is-department-auth="false"
      :default-department="list[curConfigIndex].department"
      :recent-type="list[curConfigIndex].recentType"
      :show-recent-use-person="list[curConfigIndex].showRecentUsePerson"
      :default-tab-name="list[curConfigIndex].showRecentUsePerson ? 2 : 0"
      @close="SPBTPicker = false"
      @confirm="onPersonConfirm"
    />
    <select-person
      title="停电人(单选)"
      :visible="powerPersonPincker"
      :choose-value="list[curConfigIndex].checkPersonList"
      :show-department-name="false"
      :show-phone-number="true"
      position="bottom"
      :include-children="true"
      :multiple="false"
      :license-types="list[curConfigIndex].powerCutConfig.licenseTypes"
      department-disabled-key="--"
      @close="powerPersonPincker = false"
      @confirm="powerPersonConfirm"
    />
  </van-dialog>
</template>

<script>
import Tag from "@/components/Tag";
import esign from "@/components/esign";
import WorkticketPersonPicker from "@/components/picker/WorkticketPersonPicker";
import { fileUrl } from "@/utils/constant";
import { addWorkSignature } from "@/api/workticket";
import SelectPersonByTabs from "@/components/SelectPersonByTabs";

export default {
  name: "InputDialogMultiplePerson",
  components: {
    Tag,
    WorkticketPersonPicker,
    SelectPersonByTabs
  },
  props: {},
  data() {
    return {
      projectName: window.globalConfig.VUE_APP_NAME,
      // 公用
      title: "标题",
      placeholder: "请输入",
      loading: false, //loading状态下，对话框不可点击
      visible: false,
      doneFn: null,
      successCb: null,
      failCb: null,
      ticketId: "",
      SPBTPicker: false,
      powerPersonPincker: false,

      // 私有
      // resolve: null,
      // reject: null,
      list: [
        {
          message: "",
          // messageError: false,
          hasInput: true, //是否显示input输入框
          isRequired: true, // 是否必填
          checkPersonList: [],
          hasCheckPerson: false,
          department: "",
          isPersonByTab: true,
          choosePersonLabel: "",
          personByTabTitle: "",
          personByTabMultiple: true,
          recentType: "",
          showRecentUsePerson: false,
          maxlength: 500,
          otherPerson: [],
          otherPersonLabel: "",
          isPowerCut: false,
          powerCutConfig: {
            licenseTypes: ""
          },
          defaultCheckPerson: [],
          personType: "",
          specialId: "",
          specialJobType: "",
          startWorkSpaceWorker: false
        }
      ],
      curConfigIndex: 0
    };
  },
  computed: {
    //提交按钮判断是否可点击
    // submitBtnDisabled() {
    //   const personList = this.checkPersonList;
    //   const isSg = personList.some(item => !item.fileId);
    //   return personList.length ? isSg : true;
    // },
    // 其他人是否签名
    // otherSubmitBtnDisabled() {
    //   const personList = this.otherPerson;
    //   const isSg = personList.some(item => !item.fileId);
    //   return personList.length ? isSg : true;
    // },
    includeChildrenSwitch() {
      const inCh = ["验票", "验收", "关闭", "批准"];
      return inCh.some(
        i => i === this.list[this.curConfigIndex].choosePersonLabel
      );
    }
  },
  watch: {},
  methods: {
    powerPersonConfirm(ids, list) {
      const data = list ? (Array.isArray(list) ? list : [list]) : [];
      if (!data.length) {
        this.list[this.curConfigIndex].checkPersonList = [];
        return;
      }
      const checkPersonList = [];
      data.forEach(item => {
        const find = this.list[this.curConfigIndex].checkPersonList.find(
          f => f.personId === item.value
        );
        let obj = { ...find, personType: "26" };
        if (!find) {
          obj = {
            fileId: "",
            personType: "26",
            personId: item.value,
            personName: item.label,
            id: item.value,
            name: item.label,
            ticketId: this.ticketId,
            specialId: "",
            specialJobType: this.list[this.curConfigIndex].specialJobType,
            type: item._mechanismType || item.personType || "0"
          };
        }
        checkPersonList.push(obj);
      });
      this.list[this.curConfigIndex].checkPersonList = checkPersonList;
    },
    checkPersonChange(val) {
      const data = val ? (Array.isArray(val) ? val : [val]) : [];
      if (!data.length) {
        this.list[this.curConfigIndex].checkPersonList = [];
        return;
      }
      const checkPersonList = [];
      data.forEach(item => {
        let obj = null;
        if (this.list[this.curConfigIndex].startWorkSpaceWorker) {
          obj = {
            fileId: "",
            personType: item.personType,
            personId: item.value,
            personName: item.label,
            ticketId: this.ticketId,
            specialId: "",
            specialJobType: this.list[this.curConfigIndex].specialJobType
          };
        } else {
          const find = this.list[this.curConfigIndex].checkPersonList.find(
            f => f.personId === item.value
          );
          obj = { ...find, personType: "8" };
          if (!find) {
            obj = {
              fileId: "",
              personType: "8",
              personId: item.value,
              personName: item.label,
              ticketId: this.ticketId,
              specialId: "",
              specialJobType: this.list[this.curConfigIndex].specialJobType
            };
          }
        }
        checkPersonList.push(obj);
      });
      this.list[this.curConfigIndex].checkPersonList = checkPersonList;
    },
    onPersonConfirm(ids, list) {
      const data = list || [];
      if (!data.length) {
        this.list[this.curConfigIndex].checkPersonList = [];
        return;
      }
      const checkPersonList = [];
      data.forEach(item => {
        const find = this.list[this.curConfigIndex].checkPersonList.find(
          f => f.personId === item.value
        );
        let obj = { ...find, personType: "14" };
        if (!find) {
          obj = {
            ...item,
            fileId: "",
            personType: this.list[this.curConfigIndex].personType
              ? this.list[this.curConfigIndex].personType
              : this.list[this.curConfigIndex].choosePersonLabel === "验收"
              ? "8"
              : "14",
            personId: item.value,
            personName: item.label,
            ticketId: this.ticketId,
            specialId: "",
            specialJobType: this.list[this.curConfigIndex].specialJobType
          };
        }
        checkPersonList.push(obj);
      });
      this.list[this.curConfigIndex].checkPersonList = checkPersonList;
    },
    handleClickTag(
      item,
      fileId,
      personId,
      personType,
      ticketId,
      specialId,
      choosePersonLabel
    ) {
      if (fileId) {
        //已上传过，直接预览
        esign.showImage(fileUrl + fileId);
        return;
      }
      esign.show(imageRes => {
        fileId = imageRes.id;
        // 待作业-开始作业-进入人签名，不调addWorkSignature接口（from 杨飞）
        if (choosePersonLabel === "进入") {
          item.fileId = fileId;
          return;
        }
        addWorkSignature({
          fileId,
          personId,
          personType,
          ticketId,
          specialId
        })
          .then(res => {
            item.fileId = fileId;
            this.$toast.success("提交成功");
          })
          .catch(error => {
            this.$toast.fail("签名失败，请重试");
          });
      });
    },
    show({
      title = "标题",
      successCb,
      failCb,
      ticketId,

      list = [
        {
          message: "",
          hasInput: true,
          isRequired: true,
          hasCheckPerson: false,
          department: "",
          isPersonByTab: true,
          choosePersonLabel: "验收",
          personByTabTitle: "验收人(多选)",
          personByTabMultiple: true,
          showRecentUsePerson: false,
          recentType: "",
          maxlength: 500,
          otherPerson: [],
          otherPersonLabel: "",
          isPowerCut: false,
          powerCutConfig: {},
          defaultCheckPerson: [],
          personType: "",
          specialId: "",
          specialJobType: "",
          startWorkSpaceWorker: false
        }
      ]
    }) {
      const defaultConf = {
        message: "",
        hasInput: true,
        isRequired: true,
        hasCheckPerson: false,
        department: "",
        isPersonByTab: true,
        choosePersonLabel: "验收",
        personByTabTitle: "验收人(多选)",
        personByTabMultiple: true,
        showRecentUsePerson: false,
        recentType: "",
        maxlength: 500,
        otherPerson: [],
        otherPersonLabel: "",
        isPowerCut: false,
        powerCutConfig: {
          licenseTypes: ""
        },
        defaultCheckPerson: [],
        personType: "",
        specialId: "",
        specialJobType: "",
        startWorkSpaceWorker: false
        // 额外
        // messageError: false
      };
      // 私有
      this.list = list.map(i => {
        return {
          ...defaultConf,
          ...i,
          checkPersonList: [...i.defaultCheckPerson],
          powerCutConfig: { ...defaultConf.powerCutConfig, ...i.powerCutConfig }
        };
      });
      // 共有
      this.title = title;
      this.successCb = successCb;
      this.failCb = failCb;
      this.ticketId = ticketId;
      this.loading = false;
      this.visible = true;
    },
    close() {
      this.visible = false;
      this.doneFn && this.doneFn();
      this.doneFn = null;
    },
    //移除loading状态，一般接口异步加载失败时候调用后，dialog可点击
    removeLoading() {
      this.loading = false;
      this.doneFn && this.doneFn(false);
      this.doneFn = null;
    },
    choosePerson(conf, idx) {
      this.curConfigIndex = idx;
      if (conf.isPowerCut) {
        this.powerPersonPincker = true;
        return;
      }
      if (conf.isPersonByTab) {
        this.SPBTPicker = true;
        return;
      }
      this.$refs.personPicker.show();
    },
    beforeClose(action, done) {
      if (action == "cancel") {
        done();
        this.failCb && this.failCb();
        return;
      }

      if (action === "confirm") {
        // let {
        //   message,
        //   hasInput,
        //   hasCheckPerson,
        //   checkPersonList,
        //   otherPerson
        // } = this;
        // if (
        //   (!message || message.trim().length == 0) &&
        //   hasInput &&
        //   this.isRequired
        // ) {
        //   //没有输入内容
        //   this.messageError = true;
        //   done(false);
        //   return;
        // }

        // if (
        //   this.projectName === "RT" &&
        //   otherPerson.length &&
        //   this.otherSubmitBtnDisabled
        // ) {
        //   this.$toast.fail(`${this.otherPersonLabel}人签字未完成！`);
        //   done(false);
        //   return;
        // }

        // if (hasCheckPerson && this.submitBtnDisabled) {
        //   this.$toast.fail(`${this.choosePersonLabel}人签字未完成！`);
        //   done(false);
        //   return;
        // }
        const isRt = this.projectName === "RT";
        for (let i = 0; i < this.list.length; i++) {
          const conf = this.list[i];
          if (conf.hasInput && conf.isRequired && !conf.message) {
            done(false);
            return;
          }
          if (isRt && conf.otherPerson.some(item => !item.fileId)) {
            this.$toast.fail(`${conf.otherPersonLabel}人签字未完成！`);
            done(false);
            return;
          }
          if (
            conf.hasCheckPerson &&
            (!conf.checkPersonList.length ||
              conf.checkPersonList.some(item => !item.fileId))
          ) {
            this.$toast.fail(`${conf.choosePersonLabel}人签字未完成！`);
            done(false);
            return;
          }
        }

        //有输入内容
        //回调方法中需要手动调用show()方法关闭
        this.doneFn = done;
        this.loading = true;
        // const params = checkPersonList.map(item => {
        //   return {
        //     ...item,
        //     value: item.personId,
        //     label: item.personName
        //   };
        // });
        const params = this.list.reduce((acc, cur) => {
          const p = cur.checkPersonList.map(item => {
            return {
              ...item,
              value: item.personId,
              label: item.personName
            };
          });
          acc.push(...p);
          return acc;
        }, []);
        this.successCb && this.successCb(123, params);
      }
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.InputDialog {
  &.loading {
    //禁止取消按钮点击
    ::v-deep .van-dialog__cancel {
      pointer-events: none;
      cursor: default;
      opacity: 0.6;
    }
  }
  .remark-box {
    // border-bottom: 1px solid #e1e3e8;
    padding: 8px 20px 16px;
    ::v-deep {
      .van-cell__value {
        border: 1px solid #e1e3e8;
        padding: 8px 10px 0;
        .van-field__word-limit {
          padding: 0 0 3px;
        }
      }
    }
  }
  .remark-box::after {
    display: none;
  }
  .person-signature-box {
    padding: 16px 20px 20px;
    .btn-select-person {
      display: inline-block;
      width: 86px;
      height: 32px;
      line-height: 30px;
      font-size: 14px;
      font-weight: 400;
      text-align: center;
      background-color: #1676ff;
      color: #ffffff;
      padding: 0;
      margin: 0 0 16px;
    }
    // .tag-box {
    //   margin: 16px 0 0;
    // }
  }
}
</style>
