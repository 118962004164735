var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('van-dialog',{staticClass:"InputDialog",class:{ loading: _vm.loading },attrs:{"title":_vm.title,"show-cancel-button":"","before-close":_vm.beforeClose,"disabled":_vm.loading},model:{value:(_vm.visible),callback:function ($$v) {_vm.visible=$$v},expression:"visible"}},[_vm._l((_vm.list),function(item,k){return _c('div',{key:k},[(item.hasInput)?_c('van-field',{staticClass:"remark-box",attrs:{"rows":"2","autosize":{ maxHeight: 250 },"type":"textarea","maxlength":item.maxlength,"placeholder":_vm.placeholder,"show-word-limit":"","error":item.isRequired && !item.message,"disabled":_vm.loading},model:{value:(item.message),callback:function ($$v) {_vm.$set(item, "message", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"item.message"}}):_vm._e(),(_vm.projectName === 'RT' && item.otherPerson.length)?_c('div',{staticClass:"person-signature-box"},[_c('div',{staticStyle:{"line-height":"44px"}},[_vm._v(_vm._s(item.otherPersonLabel)+"人：")]),_c('div',{staticClass:"tag-box"},_vm._l((item.otherPerson),function(p){return _c('Tag',{key:p.personId,attrs:{"type":p.fileId ? 'green' : 'white',"tick":!!p.fileId},nativeOn:{"click":function($event){return _vm.handleClickTag(
              p,
              p.fileId,
              p.personId,
              p.personType,
              _vm.ticketId,
              p.specialId
            )}}},[_vm._v(" "+_vm._s(p.personName)+" ")])}),1)]):_vm._e(),(item.hasCheckPerson)?_c('div',{staticClass:"person-signature-box"},[_c('div',[_vm._v(" "+_vm._s(item.choosePersonLabel)+"人： "),_c('van-button',{staticClass:"btn-select-person",attrs:{"round":""},on:{"click":function($event){return _vm.choosePerson(item, k)}}},[_vm._v("选择人员")])],1),_c('div',{staticClass:"tag-box"},_vm._l((item.checkPersonList),function(p){return _c('Tag',{key:p.personId,attrs:{"type":p.fileId ? 'green' : 'white',"tick":!!p.fileId},nativeOn:{"click":function($event){return _vm.handleClickTag(
              p,
              p.fileId,
              p.personId,
              p.personType,
              _vm.ticketId,
              p.specialId,
              item.choosePersonLabel
            )}}},[_vm._v(" "+_vm._s(p.personName)+" ")])}),1)]):_vm._e()],1)}),_c('WorkticketPersonPicker',{ref:"personPicker",attrs:{"title":_vm.list[_vm.curConfigIndex].startWorkSpaceWorker ? '作业人' : '验收人',"multiple":true,"is-check-person":true,"department":_vm.list[_vm.curConfigIndex].department,"ticket-id":_vm.ticketId,"special-id":_vm.list[_vm.curConfigIndex].specialId,"start-work-space-worker":_vm.list[_vm.curConfigIndex].startWorkSpaceWorker},on:{"change":_vm.checkPersonChange},model:{value:(_vm.list[_vm.curConfigIndex].checkPersonList),callback:function ($$v) {_vm.$set(_vm.list[_vm.curConfigIndex], "checkPersonList", $$v)},expression:"list[curConfigIndex].checkPersonList"}}),_c('select-person-by-tabs',{key:_vm.list[_vm.curConfigIndex].recentType,attrs:{"title":_vm.list[_vm.curConfigIndex].personByTabTitle,"visible":_vm.SPBTPicker,"show-department-name":false,"show-phone-number":true,"position":"bottom","include-children":_vm.includeChildrenSwitch,"multiple":_vm.list[_vm.curConfigIndex].personByTabMultiple,"is-department-auth":false,"default-department":_vm.list[_vm.curConfigIndex].department,"recent-type":_vm.list[_vm.curConfigIndex].recentType,"show-recent-use-person":_vm.list[_vm.curConfigIndex].showRecentUsePerson,"default-tab-name":_vm.list[_vm.curConfigIndex].showRecentUsePerson ? 2 : 0},on:{"close":function($event){_vm.SPBTPicker = false},"confirm":_vm.onPersonConfirm}}),_c('select-person',{attrs:{"title":"停电人(单选)","visible":_vm.powerPersonPincker,"choose-value":_vm.list[_vm.curConfigIndex].checkPersonList,"show-department-name":false,"show-phone-number":true,"position":"bottom","include-children":true,"multiple":false,"license-types":_vm.list[_vm.curConfigIndex].powerCutConfig.licenseTypes,"department-disabled-key":"--"},on:{"close":function($event){_vm.powerPersonPincker = false},"confirm":_vm.powerPersonConfirm}})],2)}
var staticRenderFns = []

export { render, staticRenderFns }