<template>
  <div class="EditButton">
    <button
      v-show="!editing"
      class="edit-btn btn-reset"
      @click.stop="edit"
    ></button>
    <button v-show="editing" class="btn-work-edit cancel" @click.stop="cancel">
      取消
    </button>
    <button v-show="editing" class="btn-work-edit" @click.stop="save">
      保存
    </button>
  </div>
</template>

<script>
export default {
  name: "EditButton",
  components: {},
  model: {
    prop: "editing",
    event: "change"
  },
  props: {
    editing: { type: Boolean, default: true },
    form: { type: Object, default: () => ({}) } //sync,一组可编辑的表单值对象
  },
  data() {
    return {
      copyForm: null
    };
  },
  watch: {
    form: {
      immediate: true,
      handler(newValue, oldValue) {
        //备份数据
        this.copyForm = JSON.parse(JSON.stringify(this.form));
      }
    }
  },
  created: function() {},
  mounted: function() {},
  methods: {
    edit() {
      //备份数据
      this.copyForm = JSON.parse(JSON.stringify(this.form));

      this.$emit("change", true);
      this.$emit("edit");
    },
    cancel(e) {
      //还原数据
      this.$emit("update:form", JSON.parse(JSON.stringify(this.copyForm)));

      this.$emit("change", false);
      this.$emit("cancel", e);
    },
    save(e) {
      // this.$emit("change", false);
      this.$emit("save", e);
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.EditButton {
  display: inline-block;
  .edit-btn {
    display: inline-block;
    width: 24px;
    height: 24px;
    background-image: url("../../assets/images/ic-edit.png");
    background-repeat: no-repeat;
    background-size: cover;
    vertical-align: middle;
  }
}
</style>
