export default {
  /**
   * 获取 32 位的 uuid
   * @returns {string}
   */
  getGuid() {
    /**
     * @return {string}
     */
    function S4() {
      return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
    }
    return `${S4()}${S4()}-${S4()}-${S4()}-${S4()}-${S4()}${S4()}${S4()}`;
  },
  getUidCode() {
    function S4() {
      return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
    }
    return S4() + S4();
  }
};

// 左侧表单组件配置项
export const config = [
  {
    name: "基础控件",
    component: [
      {
        type: "input",
        name: "文本输入",
        component: "formInput",
        renderCom: "renderInput",
        icon: "wenbenshurusvg"
      },
      {
        type: "textarea",
        name: "文本域",
        component: "formTextarea",
        renderCom: "renderTextarea",
        icon: "wenbenyu"
      },
      {
        type: "ckeditor",
        name: "富文本",
        component: "formCkEditor",
        renderCom: "renderCkEditor",
        icon: "fubenwen"
      },
      {
        type: "number",
        name: "数字输入",
        component: "formNumber",
        renderCom: "renderNumber",
        icon: "shuzishuru"
      },
      {
        type: "radio",
        name: "单选框",
        component: "formRadio",
        renderCom: "renderRadio",
        icon: "danxuankuang"
      },
      {
        type: "checkbox",
        name: "复选框",
        component: "formCheckbox",
        renderCom: "renderCheckbox",
        icon: "fuxuankuang"
      },
      {
        type: "select",
        name: "下拉选择",
        component: "formSelect",
        renderCom: "renderSelect",
        icon: "xialaxuanze"
      },
      {
        type: "date",
        name: "日期选择",
        component: "formDatePicker",
        renderCom: "renderDatePicker",
        icon: "riqixuanze"
      },
      {
        type: "time",
        name: "时间选择",
        component: "formTimePicker",
        renderCom: "renderTimePicker",
        icon: "shijianxuanze"
      },
      {
        type: "dateTime",
        name: "日期时间",
        component: "formDateTimePicker",
        renderCom: "renderDateTimePicker",
        icon: "riqishijian"
      },
      {
        type: "phone",
        name: "手机号",
        component: "formPhone",
        renderCom: "renderPhone",
        icon: "shoujihao"
      },
      {
        type: "docUpload",
        name: "附件上传",
        component: "formDocUpload",
        renderCom: "renderDocUpload",
        icon: "fujianshangchuan"
      },
      {
        type: "cascade",
        name: "级联选择",
        component: "formCascade",
        renderCom: "renderCascade",
        icon: "jilianxuanze"
      },
      {
        type: "group",
        name: "简单表格",
        component: "formGroup",
        renderCom: "renderGroup",
        icon: "jiandanbiaoge"
      }
    ]
  },
  {
    name: "数据控件",
    component: [
      {
        type: "user",
        name: "选择人员",
        isDesign: true,
        component: "formSearchUser",
        renderCom: "renderSearchUser",
        icon: "xuanzerenyuan"
      },
      {
        type: "position",
        name: "选择岗位",
        isDesign: true,
        component: "formSelectPosition",
        renderCom: "renderSelectPosition",
        icon: "xuanzegangwei"
      },
      {
        type: "department",
        name: "选择部门",
        isDesign: true,
        component: "formCascaderDepartment",
        renderCom: "renderCascaderDepartment",
        icon: "xuanzebumen"
      },
      {
        type: "facility",
        name: "选择设施",
        isDesign: true,
        component: "formFacility",
        renderCom: "renderFacility",
        icon: "xuanzesheshi"
      },
      {
        type: "jsa",
        name: "选择JSA",
        isDesign: true,
        component: "formSelectJSA",
        renderCom: "renderSelectJSA",
        icon: "JSA"
      },
      {
        type: "indicator",
        name: "选择传感器",
        isDesign: true,
        component: "formSelectIndicator",
        renderCom: "renderSelectIndicator",
        icon: "chuanganqi"
      },
      {
        type: "material",
        name: "选择物料",
        isDesign: true,
        component: "formSelectMaterial",
        renderCom: "renderSelectMaterial",
        icon: "xuanzewuliao"
      },
      {
        type: "product",
        name: "选择产品",
        isDesign: true,
        component: "formSelectProduct",
        renderCom: "renderSelectProduct",
        icon: "xuanzechanpin"
      },
      {
        type: "intermediate",
        name: "选择中间品",
        isDesign: true,
        component: "formSelectIntermediate",
        renderCom: "renderSelectIntermediate",
        icon: "xuanzezhongjianpin"
      },
      {
        type: "feedstock",
        name: "选择原料",
        isDesign: true,
        component: "formSelectFeedstock",
        renderCom: "renderSelectFeedstock",
        icon: "xuanzeyuanliao"
      }
    ]
  },
  {
    name: "布局控件",
    icon: "xuanzesheshi",
    component: [
      {
        type: "title",
        name: "小节标题",
        component: "formTitle",
        renderCom: "renderTitle",
        icon: "xiaojiebiaoti"
      },
      {
        type: "line",
        name: "分割线",
        component: "formLine",
        renderCom: "renderLine",
        icon: "fengexian"
      },
      {
        type: "area",
        name: "内容占位",
        component: "formArea",
        renderCom: "renderArea",
        icon: "neirongzhanwei"
      }
    ]
  }
];

export const multipleImportVue = requireComponent => {
  var comObj = {};
  requireComponent.keys().forEach(fileName => {
    // 获取文件名
    var names = fileName
      .split("/")
      .pop()
      .replace(/\.\w+$/, "");
    // 获取组件配置
    const componentConfig = requireComponent(fileName);
    // 若该组件是通过"export default"导出的，优先使用".default"，否则退回到使用模块的根
    comObj[names] = componentConfig.default || componentConfig;
  });
  return comObj;
};

/**
 * 判断是不是 json
 * @param str
 * @returns {boolean}
 */
export const isJSON = str => {
  if (typeof str == "string") {
    try {
      const obj = JSON.parse(str);
      return !!(typeof obj == "object" && obj);
    } catch (e) {
      return false;
    }
  }
};
