<!--从联锁管理拷贝过来 在工单和保养等级会使用-->
<!--将上传文件进行了封装 以列表形式展示文件(fileuploader 以图片形式展示)-->
<template>
  <van-field
    class="switching-uploader switching-uploader-box"
    :class="{ 'is-empty': !value || !value.length }"
  >
    <template #label>
      <van-field
        class="switching-uploader-reference"
        :label="label"
        :placeholder="unableUpload ? '' : '请选择'"
        input-align="right"
        :is-link="!unableUpload"
        readonly
        :clickable="!unableUpload"
        @click="onClick"
      ></van-field>
    </template>

    <template #input>
      <FileUploader
        ref="uploader"
        :value="value"
        :unable-upload="unableUpload"
        :max-count="maxCount"
        :max-size="1024 * 1024 * 5"
        v-bind="$attrs"
        v-on="$listeners"
      >
        <div class="reference-div" />

        <template #preview-cover="{ file, status, index, url }">
          <div class="switching-file-item" @click="onPreview(file, url)">
            <van-icon
              class="switching-file-icon"
              name="wenjian"
              class-prefix="iconfont"
            />

            <span
              class="switching-file-text"
              :class="{ 'is-error': status === 'failed' }"
              >{{ file.name }}</span
            >

            <img
              v-if="status === 'uploading'"
              src="@/assets/images/loading.png"
              class="switching-file-icon is-loading"
            />
            <van-icon
              v-else-if="status === 'failed'"
              class="switching-file-icon is-error"
              name="guanbi"
              class-prefix="iconfont"
              @click.prevent.stop="handlerDelete(file, index)"
            />
            <van-icon
              v-else-if="!unableUpload"
              class="switching-file-icon"
              name="guanbi"
              class-prefix="iconfont"
              @click.prevent.stop="handlerDelete(file, index)"
            />
          </div>
        </template>
      </FileUploader>
    </template>

    <div v-if="tip" slot="extra" class="switching-file-tip">
      {{ tip }}
    </div>
  </van-field>
</template>

<script>
import FileUploader from "@/components/fileuploader/FileUploader";

export default {
  components: {
    FileUploader
  },
  model: {
    prop: "value",
    event: "change"
  },
  props: {
    tip: {
      type: String,
      default: ""
    },
    label: {
      type: String,
      default: "上传附件"
    },
    value: {
      type: [Array, Object, String],
      default: null
    },
    unableUpload: { type: Boolean, default: false }, //不能点击上传，不可点击状态传：true
    maxCount: {
      type: Number,
      default: 5
    },
    maxSize: {
      type: Number,
      default: 1024 * 1024 * 5
    }
  },
  methods: {
    onClick() {
      if (this.unableUpload) return;
      this.$refs.uploader?.chooseFile();
    },
    onPreview(file, url) {
      this.$refs.uploader?.handlerPreview(file, url);
    },
    handlerDelete(file, index) {
      const fileList = this.value.slice(0);
      fileList.splice(index, 1);
      this.$emit("change", fileList);
      this.$refs.uploader?.fileList.splice(index, 1);
      this.$refs.uploader?.handlerDelete(file);
    }
  }
};
</script>

<style lang="scss" scoped>
.switching-file-tip {
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #c0c4cc;
  padding: 0 16px;
}

.switching-uploader-box.van-field {
  display: block;
  padding-top: 0;
  padding-left: 0;
  padding-right: 0;

  &.is-empty {
    padding-bottom: 0;
  }

  ::v-deep {
    & > .van-field__label {
      display: block;
      width: 100%;
    }

    & > .van-field__value {
      .van-field__control--custom {
        min-height: 0px;
        align-items: flex-start;
      }
    }
  }
}

.switching-uploader {
  .switching-file-item {
    height: 30px;
    box-sizing: border-box;
    padding: 5px 16px;
    width: 100%;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .switching-file-icon {
    color: #aeb3c0;
    font-size: 16px;

    &.is-error {
      color: #f46b6b;
    }

    &.is-loading {
      animation: van-rotate 2s linear infinite;
      width: 16px;
      height: 16px;
    }
  }

  .switching-file-text {
    padding: 0 5px;
    flex-grow: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #1676ff;
    line-height: 20px;

    &.is-error {
      color: #f46b6b;
    }
  }

  ::v-deep {
    .van-uploader {
      width: 100%;
    }

    .FileUploader__feature {
      display: block;
    }

    .van-uploader__wrapper {
      width: 100%;
      display: block;
    }

    .van-uploader__file {
      display: block;
      width: 100%;
      height: 100%;
      overflow: hidden;
    }

    .van-uploader__preview {
      height: 30px;
      box-sizing: border-box;
      width: 100%;
      margin: 0;
      overflow: hidden;

      .van-uploader__mask {
        display: none;
      }
    }

    .van-uploader__preview-image {
      width: 100%;
      height: 100%;

      > .van-image__img {
        opacity: 0;
      }
    }

    .van-uploader__preview-delete {
      display: none;
    }
  }
}
</style>
