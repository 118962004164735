<template>
  <div
    class="LoadView"
    :style="{
      position: status !== LOAD_STATUS.SUCCESS ? 'absolute' : 'static'
    }"
  >
    <van-skeleton
      class="skeleton"
      title
      :row="3"
      :loading="status == LOAD_STATUS.LOADING"
    />
    <slot v-if="status == LOAD_STATUS.SUCCESS"></slot>
    <van-empty
      v-if="status == LOAD_STATUS.ERROR"
      image="error"
      :description="errorText"
    >
      <van-button type="default" icon="replay" @click="handleRetry">
        重试
      </van-button>
    </van-empty>
    <van-empty v-if="status == LOAD_STATUS.EMPTY" :description="emptyText" />
  </div>
</template>

<script>
import { LOAD_STATUS } from "@/components/loadview/utils";

export default {
  name: "LoadView",
  components: {},
  model: {
    prop: "status",
    event: "change"
  },
  props: {
    status: {
      type: String,
      default: LOAD_STATUS.LOADING
    },
    errorText: {
      type: String,
      default: "加载失败，点击重试"
    },
    emptyText: {
      type: String,
      default: "暂无数据"
    }
  },
  data() {
    return {
      LOAD_STATUS
    };
  },
  created: function() {},
  mounted: function() {},
  methods: {
    handleRetry() {
      this.$emit("change", LOAD_STATUS.LOADING);
      this.$emit("retry");
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.LoadView {
  .skeleton {
    margin-top: 20px;
  }

  ::v-deep .van-button {
    height: 40px;
    border-radius: 3px;
  }
}
</style>
