<template>
  <van-empty v-bind="$attrs" :image="image" v-on="$listeners">
    <div><slot></slot></div>
    <div v-if="$slots.image" slot="image">
      <slot name="image"></slot>
    </div>
    <div v-if="$slots.description" slot="description">
      <slot name="description"></slot>
    </div>
  </van-empty>
</template>

<script>
import { Empty } from "vant";
import error from "@/assets/images/empty/error.png";
import network from "@/assets/images/empty/network.svg";
import search from "@/assets/images/empty/search.png";
import defaultImg from "@/assets/images/empty/default.png";
export default {
  name: "VanEmpty",
  components: { [Empty.name]: Empty },
  data() {
    return {
      imageMap: { default: defaultImg, error, network, search }
    };
  },
  computed: {
    image() {
      const img = this.$attrs.image || "default";
      if (this.imageMap[img]) {
        return this.imageMap[img];
      }
      return img;
    }
  }
};
</script>
