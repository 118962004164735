<template>
  <div class="datetime">
    <div class="dateinput">
      <div class="left-input">
        <!-- <van-icon name="notes-o" class="datetime-sign" /> -->
        <van-icon
          name="shijianxuanze"
          class-prefix="iconfont"
          class="datetime-sign"
        />
        <div
          class="input-style"
          @click="handlerInputClick($event, 'start', datetime.start)"
        >
          <span v-show="datetime.start">{{ datetime.start }}</span>
          <span v-show="!datetime.start" class="disabled-color">{{
            startText
          }}</span>
        </div>
        <van-icon
          v-if="timeClose"
          v-show="datetime.start"
          slot="button"
          name="close"
          class="clear-btn"
          @click.stop="handleClearClick($event, 'start')"
        />
      </div>
      <span class="date-split-text">至</span>
      <div class="right-input">
        <div
          class="input-style"
          @click="handlerInputClick($event, 'end', datetime.end)"
        >
          <span v-show="datetime.end">{{ datetime.end }}</span>
          <span v-show="!datetime.end" class="disabled-color">{{
            endText
          }}</span>
        </div>
        <van-icon
          v-if="timeClose"
          v-show="datetime.end"
          slot="button"
          name="close"
          class="clear-btn"
          @click.stop="handleClearClick($event, 'end')"
        />
      </div>
    </div>
    <van-popup
      v-model="datetimeVisible"
      position="bottom"
      get-container="#app"
      :style="{ height: '40%' }"
    >
      <van-datetime-picker
        v-model="currentTime"
        v-bind="$attrs"
        @confirm="handleConfirm"
        @cancel="datetimeVisible = false"
      />
    </van-popup>
  </div>
</template>

<script>
import { generatorComponents, parseTime } from "@/utils";
import { DatetimePicker, Field, Popup, Icon, Toast } from "vant";
const components = [DatetimePicker, Field, Popup, Icon, Toast];
export default {
  name: "DateTime",
  components: { ...generatorComponents(components) },
  props: {
    // showTime: {
    //   type: Object,
    //   default: () => ({
    //     start: "",
    //     end: ""
    //   })
    // },
    value: {
      type: Object,
      default: () => ({
        start: "",
        end: ""
      })
    },
    format: {
      type: String,
      // {y}-{m}-{d} {h}:{i}:{s}
      default: "{y}-{m}-{d}"
    },
    timeClose: {
      type: Boolean,
      default: true
    },
    startText: {
      type: String,
      default: "开始时间"
    },
    endText: {
      type: String,
      default: "结束时间"
    }
  },
  data() {
    return {
      datetimeVisible: false,
      datetime: {
        start: "",
        end: ""
      },
      currentTime: new Date(),
      type: ""
    };
  },
  watch: {
    value: {
      handler(val) {
        if (val.start && val.end) {
          this.datetime = { ...this.value };
        }
        if (!val.start && !val.end) {
          this.datetime.start = "";
          this.datetime.end = "";
        }
      }
    }
  },
  created() {
    // 支持 v-model
    this.datetime = { ...this.value };
  },
  methods: {
    handleConfirm(val) {
      this.datetimeVisible = false;
      this.datetime[this.type] = parseTime(val, this.format);
      if (this.datetime.start && this.datetime.end) {
        if (
          new Date(this.datetime.start).getTime() >
          new Date(this.datetime.end).getTime()
        ) {
          this.datetime[this.type] = "";
          return Toast("结束日期不能小于开始日期");
        }
      }
      this.$emit("input", this.datetime);
    },
    handlerInputClick(ev, type, time) {
      this.datetimeVisible = true;
      this.type = type;
      if (time) {
        this.currentTime = new Date(time);
      }
    },
    handleClearClick(ev, type) {
      this.type = type;
      this.datetime[this.type] = "";
      this.$emit("input", this.datetime);
    }
  }
};
</script>

<style lang="scss" scoped>
.van-icon-notes-o::before {
  // 处理图标偏上的问题
  transform: translateY(-1px);
}
.datetime {
  margin: 12px 16px;
  border: 1px solid #e1e3e8;
  border-radius: 2px;
  height: 36px;
  display: flex;
  align-items: center;
  .dateinput {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: inherit;
    line-height: 36px;
    flex: 1;
    padding: 0 8px;
    box-sizing: border-box;
  }
  .left-input,
  .right-input {
    width: 53%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    // height: 4vh;
    .clear-btn {
      position: relative;
      vertical-align: middle;
      z-index: 999;
      color: #606266;
      opacity: 0.3;
    }
  }
  .right-input {
    width: 48%;
  }
  .date-split-text {
    margin: 0 1.5vw 0 2vw;
    font-size: 12px;
    color: #606266;
  }
  .datetime-sign {
    vertical-align: middle;
    font-size: 16px;
    color: #aeb3c0;
  }
  .input-style {
    font-size: 13px;
    margin-left: 5px;
    outline: none;
    border: none;
    width: 80%;
    color: #606266;
    padding: 0;
  }
  .right-input .input-style {
    width: 90%;
  }
}
</style>
