<template>
  <van-field
    class="render-input-box"
    :label="itemData.label + ':'"
    :prop="itemData.code"
    :required="itemData.options.required"
    :error-message="errorMessage"
    :rules="[
      {
        validator: validator
      }
    ]"
  >
    <template #input>
      <FileUploader
        v-model="data"
        :max-count="5"
        :unable-upload="!$attrs.editable"
        :accept="FILE_ACCEPT_TYPES.FILE"
      />
    </template>
  </van-field>
</template>

<script>
window.globalConfig = window.globalConfig || process.env;
import FileUploader from "@/components/fileuploader/FileUploader";
import { FILE_ACCEPT_TYPES } from "@/components/fileuploader/util";

export default {
  name: "RenderDocUpload",
  components: {
    FileUploader
  },
  props: {
    value: [String, Array],
    itemData: Object,
    size: {
      type: Number,
      default: 50
    },
    unit: {
      type: String,
      default: "MB"
    }
  },
  data() {
    return {
      FILE_ACCEPT_TYPES,
      dialogVisible: false,
      data: [],
      errorMessage: ""
    };
  },
  watch: {
    data: {
      handler: function(val) {
        val = this.formatFileList(val);
        this.$parent.validateField(this.itemData.code);
        this.$emit("input", val);
      },
      deep: true
    }
  },
  created() {
    this.data = this.value;
  },
  methods: {
    formatFileList(fileList = []) {
      const fileUrl = `${window.globalConfig.VUE_APP_FILE_URL}/file/download?downloadFlag=false&fileName=`;
      return fileList
        .filter(v => v)
        .map(item => {
          if (item.id) {
            return { ...item, url: fileUrl + item.id };
          } else if (item.response && item.response.data) {
            const file = item.response.data[0];
            return { ...file, url: fileUrl + file.id };
          }
        });
    },
    validator(value) {
      if (this.itemData.options) {
        if (this.itemData.options.required && !this.data.length) {
          this.errorMessage = `${this.itemData.label}不能为空`;
          return false;
        }
      }
      this.errorMessage = "";
      return true;
    }
  }
};
</script>

<style lang="scss">
.file-list {
  cursor: pointer;
  width: fit-content;
  line-height: 18px;
  font-size: 14px;
  color: #1676ff;
}
</style>
