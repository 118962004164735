<template>
  <van-popup
    v-model="visible"
    :close-on-click-overlay="false"
    :style="treeStyle"
    get-container="body"
    position="bottom"
    round
    v-bind="$attrs"
    @click-overlay="onClose"
    @close="onClose"
    @open="onOpen"
    v-on="$listeners"
  >
    <section class="department-container">
      <div class="header">
        <h3 class="popup-title">{{ title }}</h3>
        <van-icon
          name="danchuangguanbi"
          class-prefix="iconfont"
          class="close-icon"
          @click.native="onClose"
        />
      </div>
      <div class="content">
        <van-search
          v-model="filterString"
          placeholder="请输入关键词"
          @search="onFilter"
          @focus="onSearchFocus"
          @blur="onSearchBlur"
        >
          <template #left-icon>
            <van-icon
              name="sousuo"
              class-prefix="iconfont"
              class="search-icon"
            />
          </template>
        </van-search>
        <div
          :class="{
            'org-tree-container': true,
            'container-height--nto-btn': !featureButtonVisible
          }"
        >
          <tree-node
            :node="treeData"
            :label-key="labelKey"
            :value-key="valueKey"
            :multiple="multiple"
            :checked-value="checkedValue"
            :disabled-key="disabledKey"
            disabled-toast="没有对应部门的数据权限"
            @change="onTreeChange"
            @reset-tree="resetTreeChecked"
          />
        </div>
      </div>
      <div class="footer">
        <div v-show="featureButtonVisible" class="btn-group">
          <van-button :disabled="false" @click.prevent="onReset"
            >重置
          </van-button>
          <van-button size="large" type="info" @click.prevent="onConfirm"
            >确定
          </van-button>
        </div>
      </div>
    </section>
  </van-popup>
</template>

<script>
import { treeForEach } from "@/utils";
import { getSelectTreeDept } from "@/api/authorization";
import { mapState } from "vuex";
import TreeNode from "./tree-node";

export default {
  name: "DepartmentTree",
  components: { TreeNode },
  model: {
    prop: "value", // 绑定的值事件过父组件传递
    event: "update" // 自定义时间名
  },
  props: {
    multiple: {
      type: Boolean,
      default: false
    },
    value: [Array, String, Number],
    treeStyle: {
      type: Object,
      default: () => ({ height: "80vh" })
    },
    title: {
      type: String,
      default: "所属部门"
    },
    visible: {
      type: Boolean,
      default: false
    },
    valueKey: {
      type: String,
      default: "id"
    },
    labelKey: {
      type: String,
      default: "label"
    },
    disabledKey: {
      type: String,
      default: "disabled"
    }
  },
  data() {
    return {
      treeData: [],
      treeMap: {},
      filterString: "",
      // org-tree 组件接收的值
      checkedValue: [],
      featureButtonVisible: true
    };
  },
  computed: {
    ...mapState({
      userInfo: state => state.login.userInfo
    })
  },
  methods: {
    onTreeChange(list, currentNode) {
      if (this.multiple) {
        this.checkedValue = list;
        this.$emit("change", list, currentNode);
      } else {
        this.checkedValue = [currentNode];
      }
      // this.$emit("change", this.multiple ? list : currentNode, currentNode);
    },
    processTreeData() {
      // 遍历树
      treeForEach(this.treeData, node => {
        const mapKey = node[this.valueKey];
        // 设置过滤响应式
        this.$set(node, "filtered", true);
        // 设置选中响应式
        this.$set(node, "checked", false);
        // 生成 treeMap
        this.treeMap[mapKey] = node;
      });
    },
    processCheckedTreeData() {
      if (!this.value || !this.value.length) {
        return;
      }
      let selectedIds;
      if (this.multiple) {
        selectedIds = [...this.value];
      } else {
        selectedIds = [this.value];
      }
      const valueKey = this.valueKey;
      // 为树设置选中
      // treeForEach(this.treeData, node => {
      //   if (selectedIds.includes(node[valueKey])) {
      //     this.$set(node, "checked", true);
      //   }
      // });
      Object.values(this.treeMap).forEach(node => {
        if (selectedIds.includes(node[valueKey])) {
          this.$set(node, "checked", true);
        }
      });
      // 设置 org-tree 组件接收的值
      selectedIds.forEach(id => {
        this.checkedValue.push(this.treeMap[id]);
      });
    },
    async getDepartmentTreeData() {
      try {
        this.treeData = await getSelectTreeDept(this.userInfo.orgCode);
        this.processTreeData();
        this.processCheckedTreeData();
      } catch (e) {
        console.log("getDepartmentTreeData -> e", e);
      }
    },
    resetFilterTree() {
      Object.values(this.treeMap).forEach(node => {
        this.$set(node, "filtered", false);
      });
    },
    // 过滤树，原则上是设置 filtered 为 true
    filterTree(filterStr) {
      this.resetFilterTree();
      const labelKey = this.labelKey;
      Object.values(this.treeMap).forEach(node => {
        if (node[labelKey].includes(filterStr)) {
          this.$set(node, "filtered", true);
        }
      });
    },
    resetTreeChecked() {
      // treeForEach(this.treeData, node => {
      //   this.$set(node, "checked", false);
      // });
      Object.values(this.treeMap).forEach(node => {
        this.$set(node, "checked", false);
      });
    },
    onFilter() {
      this.filterTree(this.filterString);
    },
    onSearchFocus() {
      this.featureButtonVisible = false;
    },
    onSearchBlur() {
      this.featureButtonVisible = true;
    },
    onOpen() {
      if (this.treeData.length) {
        this.processCheckedTreeData();
      } else {
        this.getDepartmentTreeData();
      }
    },
    onConfirm() {
      const checkedIds = this.checkedValue.map(x => x[this.valueKey]);
      const len = checkedIds.length;
      const singleId = checkedIds[len - 1];
      if (this.multiple) {
        this.$emit("update", checkedIds || []);
        this.$emit("confirm", [...this.checkedValue], checkedIds);
      } else {
        this.$emit("update", singleId || "");
        this.$emit(
          "confirm",
          this.checkedValue[len - 1] || { label: "", value: "" },
          singleId
        );
      }
      this.onClose();
    },
    resetTreeStatus() {
      this.filterString = "";
      this.checkedValue = [];
      this.resetTreeChecked();
    },
    onClose() {
      this.resetTreeStatus();
      this.$emit("close");
    },
    onReset() {
      this.resetTreeStatus();
      this.$emit("reset");
    }
  }
};
</script>

<style lang="scss" scoped>
input.van-field__control {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.van-search {
  padding-left: 0;
  padding-right: 0;
}

.van-hairline--top-bottom::after,
.van-hairline-unset--top-bottom::after {
  border: none;
}

.department-container {
  position: relative;
  height: 100%;
}

.header {
  text-align: center;
  position: relative;
  /*border-bottom: 1px solid #f1f1f1;*/
  .close-icon {
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
    color: #9496a3;
    z-index: 1;
    font-size: 18px;
  }

  .popup-title {
    font-weight: bold;
    font-size: 16px;
    padding: 20px 0 16px;
    color: #1b1b4e;
  }
}

.content {
  position: absolute;
  top: 30px;
  bottom: 0;
  left: 20px;
  right: 20px;
  padding-top: 16px;

  .org-tree-container {
    position: absolute;
    top: 76px;
    bottom: 72px;
    left: 0;
    right: 0;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  }

  .container-height--nto-btn {
    bottom: 0;
  }
}

.footer {
  position: fixed;
  left: 20px;
  right: 20px;
  bottom: 20px;

  .btn-group {
    display: flex;
    justify-content: space-between;
  }

  .van-button {
    width: 148px;
    border-radius: $--button-height;
  }

  .cascade-tree__footer-button--confirm {
    background-color: $--button-color-save;
    border-color: $--button-color-save;
    box-shadow: $--button-color-save-shadow;
  }
}
</style>
