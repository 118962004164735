<template>
  <div>
    <van-field
      clickable
      readonly
      :value="dataValue"
      class="render-input-box"
      :required="itemData.options.required"
      :placeholder="$attrs.editable ? '请选择人员' : ''"
      :label="itemData.label + ':'"
      :rules="[
        {
          validator: validator
        }
      ]"
      @click="onClick"
    >
    </van-field>
    <!--  人员选择  -->
    <select-person
      title="请选择人员"
      :visible="selectPersonVisible"
      :show-department-name="false"
      :show-phone-number="true"
      :multiple="itemData.options.isMultiple"
      department-disabled-key="--"
      position="bottom"
      :choose-value="chooseValue"
      :exclude-person-ids="excludePersonIds"
      @close="selectPersonVisible = false"
      @confirm="onPersonConfirm"
    />
  </div>
</template>

<script>
import SelectPerson from "@/components/SelectPerson";
import { mapState } from "vuex";

export default {
  name: "RenderSearchUser",
  components: {
    SelectPerson
  },
  props: {
    value: [String, Array],
    itemData: Object,
    userData: Array
  },
  data() {
    return {
      data: "",
      dataValue: "",
      options: [],
      selectPersonVisible: false,
      chooseValue: [],
      excludePersonIds: [],
      errorMessage: ""
    };
  },
  computed: {
    ...mapState({
      userInfo: state => state.login.userInfo
    })
  },
  watch: {
    data: {
      handler: function(val) {
        this.$emit("input", val);
        this.dataValue = this.getData(val);
      },
      deep: true
    },
    userData: {
      handler(val) {
        if (val?.length) {
          this.dataValue = this.getData();
        }
      }
    }
  },
  created() {
    this.data = this.itemData.options.isMultiple
      ? Array.isArray(this.value)
        ? this.value
        : this.value && this.value.split(",")
      : this.value;
    if (this.itemData.options.isCustomOptions) {
      this.options = this.itemData.options.options;
    } else {
      this.options = [];
    }
  },
  methods: {
    onClick() {
      if (this.$attrs.editable) {
        this.selectPersonVisible = true;
      }
    },
    onPersonConfirm(id, list) {
      if (list?.length) {
        this.data = list.map(item => item.id).join();
      } else {
        this.data = "";
      }
      this.chooseValue = (list || []).map(item => {
        return {
          value: item.id,
          label: item.name
        };
      });
    },
    validator(value) {
      if (this.itemData.options) {
        if (
          this.itemData.options.required &&
          (!this.data || !this.data.length)
        ) {
          this.errorMessage = `${this.itemData.label}不能为空`;
          return false;
        }
      }
      this.errorMessage = "";
      return true;
    },
    getData(value = this.value) {
      let arr = this.itemData.options.isMultiple ? value : [value];
      return this.userData
        ?.filter(s => arr.includes(s.value))
        .map(s => s.label)
        .join(",");
    }
  }
};
</script>

<style scoped lang="scss">
.render-input-box {
  position: relative;
}
</style>
