<template>
  <van-collapse-item
    class="WorkticketAttachmentView"
    :title="title"
    :name="name"
    :border="false"
    :is-link="false"
  >
    <template #value>
      <EditButton
        v-if="ticketStatus == WORKTICKET_STATUS.Working && userEditAuthority"
        v-model="editing"
        :form.sync="form"
        @save="handerSave"
        @cancel="handleCancel"
        @change="btnChange"
      />
    </template>

    <div>
      <van-skeleton
        v-if="editable"
        title
        :row="3"
        :loading="!form"
        validate-trigger="onSubmit"
      >
        <van-form ref="form" :scroll-to-error="true">
          <van-field
            v-model="form.record"
            rows="3"
            autosize
            type="textarea"
            maxlength="500"
            placeholder="点击输入多行文本"
            show-word-limit
            :rules="[{ required: true, message: '请输入作业记录内容' }]"
          />
          <van-field>
            <template #input>
              <FileUploader
                v-model="form.files"
                :accept="FILE_ACCEPT_TYPES.IMAGE_AND_AUDIO"
              />
            </template>
          </van-field>
        </van-form>
      </van-skeleton>
      <div v-else>
        <div v-for="(item, idx) of value" :key="idx" class="record-box">
          <div class="inner-box">
            <p class="remark">{{ `${idx + 1}、${item.record}` }}</p>
            <!-- <FileUploader
              v-if="item.file"
              v-model="item.file"
              :unable-upload="true"
              :accept="FILE_ACCEPT_TYPES.IMAGE_AND_AUDIO"
            /> -->
            <ul class="ul-box">
              <li v-for="file of item.files" :key="file.id" class="img-li">
                <van-image
                  width="104"
                  height="80"
                  lazy-load
                  :src="`${fileUrl}${file.id}`"
                  @click="ImagePreview([`${fileUrl}${file.id}`])"
                />
              </li>
            </ul>
            <p class="oper">{{ `${item.uuserName} - ${item.utime}` }}</p>
          </div>
        </div>
      </div>
    </div>
  </van-collapse-item>
</template>

<script>
import FileUploader from "@/components/fileuploader/FileUploader";
import { getWorkAttachment, updateWorkAttachment } from "@/api/workticket";
import EditButton from "@/components/edit/EditButton";
import { WORKTICKET_STATUS } from "@/views/workticket/utils/constant";
import { FILE_ACCEPT_TYPES } from "@/components/fileuploader/util";
import { fileUrl } from "@/utils/constant";
import { Lazyload, ImagePreview } from "vant";

export default {
  name: "WorkticketAttachmentView",
  components: { EditButton, FileUploader },
  props: {
    title: { type: String, default: "" },
    name: { type: String, default: "" },
    ticketId: { type: String, default: "" }, //作业id
    ticketStatus: { type: [String, Number], default: "" }, //作业状态
    userEditAuthority: { type: Boolean }, // 编辑权限
    editable: { type: Boolean } // 是否编辑状态
  },
  data() {
    return {
      editing: false,
      value: [],
      WORKTICKET_STATUS,
      FILE_ACCEPT_TYPES,
      fileUrl,
      form: {
        record: "",
        files: []
      }
    };
  },
  watch: {
    ticketId: {
      immediate: true,
      handler(newValue, oldValue) {
        if (this.ticketId) {
          this.getData();
        }
      }
    }
    // ticketStatus: {
    //   handler(newValue, oldValue) {
    //     this.editing = WORKTICKET_STATUS.Working == newValue;
    //     this.btnChange(this.editing);
    //   }
    // }
  },
  created: function() {},
  mounted: function() {
    // if (WORKTICKET_STATUS.Working == this.ticketStatus) {
    //   this.editing = true;
    // }
  },
  methods: {
    ImagePreview,
    getData() {
      getWorkAttachment(this.ticketId).then(res => {
        this.value = res;
      });
    },
    handleCancel(e) {
      this.$emit("cancel", e);
    },
    handerSave(e) {
      this.$emit("save", e);
      this.$refs.form.validate().then(() => {
        this.$emit("update:editable", false);
        updateWorkAttachment(this.ticketId, this.form.record, this.form.files)
          .then(res => {
            this.getData();
            this.editing = false;
            this.$toast.success("操作成功");
          })
          .catch(error => {
            this.$toast.fail("请求失败，请重试");
          });
      });
    },
    btnChange(editable) {
      if (editable) {
        this.form = {
          record: "",
          files: []
        };
      }
      this.$emit("update:editable", editable);
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.WorkticketAttachmentView {
  position: relative;
  border-bottom: #eceef2 solid 8px;
  .record-box {
    padding: 12px 15px 0 17px;
    .inner-box {
      padding: 0 0 12px 0;
      border-bottom: 1px solid #e1e3e8;
      .remark {
        font-size: 14px;
        font-weight: 400;
        color: #2e2e4d;
        line-height: 21px;
        margin: 0 0 8px;
      }
      .oper {
        font-size: 12px;
        font-weight: 400;
        color: #8c8f97;
        line-height: 17px;
      }
      .ul-box {
        .img-li {
          display: inline-block;
          margin: 15px 0 0 15px;
        }
        .img-li:nth-child(3n + 1) {
          margin: 15px 0 0 0;
        }
      }
    }
  }
}
</style>
