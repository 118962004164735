import { getSelectFacilities } from "../api/datasource";
import { getSelectJsas, selectPositions } from "../api/datasource";
import { getSelectSensorNum } from "../api/datasource";
import { getSelectUserDept, getUsers } from "../api/datasource";
import {
  getMaterials,
  getMedias,
  getProducts,
  getRaws
} from "../api/datasource";
import { isJSON } from "@/components/designForm/util";

export default {
  data() {
    return {
      department: [],
      positions: [],
      users: [],
      indicators: [],
      jsas: [],
      facilities: [],
      feedstocks: [],
      intermediates: [],
      products: [],
      materials: []
    };
  },
  watch: {
    renderList: {
      handler: function(val) {
        val.map(v => {
          if (v.type === "title") {
            this.renderFormData[v.code] = v.label || "";
          } else if (v.type === "group") {
            let obj = {};
            v.children.map(k => {
              obj[k.code] = k.value;
            });
            this.renderFormData[v.code] = [obj];
          } else if (["docUpload", "imageUpload"].includes(v.type)) {
            this.renderFormData[v.code] = [];
          } else if (["input", "textarea"].includes(v.type)) {
            this.renderFormData[v.code] = [""];
          } else if (["docUpload", "imageUpload"].includes(v.type)) {
            this.renderFormData[v.code] = [];
          } else if (["input", "textarea"].includes(v.type)) {
            this.renderFormData[v.code] = [""];
          } else if (
            [
              "ckeditor",
              "number",
              "user",
              "position",
              "department",
              "facility",
              "indicator",
              "jsa",
              "material",
              "product",
              "intermediate",
              "feedstock",
              "check"
            ].includes(v.type)
          ) {
            this.renderFormData[v.code] = v.value || "";
          } else {
            this.renderFormData[v.code] = v.value || [];
          }
        });
      },
      deep: true
    },
    approveRenderList: {
      handler: function(val) {
        val.map(v => {
          if (v.type === "title") {
            this.approveRenderFormData[v.code] = v.label || "";
          } else if (v.type === "group") {
            let obj = {};
            v.children.map(k => {
              obj[k.code] = k.value;
            });
            this.approveRenderFormData[v.code] = [obj];
          } else if (["docUpload", "imageUpload"].includes(v.type)) {
            this.approveRenderFormData[v.code] = [];
          } else if (["input", "textarea"].includes(v.type)) {
            this.approveRenderFormData[v.code] = [""];
          } else if (["docUpload", "imageUpload"].includes(v.type)) {
            this.approveRenderFormData[v.code] = [];
          } else if (["input", "textarea"].includes(v.type)) {
            this.approveRenderFormData[v.code] = [""];
          } else if (
            [
              "ckeditor",
              "number",
              "user",
              "position",
              "department",
              "facility",
              "indicator",
              "jsa",
              "material",
              "product",
              "intermediate",
              "feedstock",
              "check"
            ].includes(v.type)
          ) {
            this.approveRenderFormData[v.code] = v.value || "";
          } else {
            this.approveRenderFormData[v.code] = v.value || [];
          }
        });
      },
      deep: true
    }
  },
  created() {
    this.getSelectUserDept();
    this.getUsers();
    this.getSelectPositions();
    this.getSelectSensor();
    this.getSelectJsa();
    this.getSelectFacilities();
    this.getMedias();
    this.getRaws();
    this.getMaterials();
    this.getProducts();
  },
  methods: {
    // 获取产品
    getProducts(data = {}) {
      let params = Object.assign({}, { orgCode: this.userInfo.orgCode }, data);
      getProducts(params)
        .then(res => {
          if (res) {
            this.products = res.list.map(v => {
              return {
                value: v.id,
                label: `${v.materialName}(${v.departmentName})`
              };
            });
          }
        })
        .catch(_ => {});
    },
    // 获取物料
    getMaterials(data = {}) {
      let params = Object.assign({}, { orgCode: this.userInfo.orgCode }, data);
      getMaterials(params)
        .then(res => {
          if (res) {
            this.materials = res.list.map(v => {
              return {
                value: v.id,
                label: `${v.name}`
              };
            });
          }
        })
        .catch(_ => {});
    },
    // 获取原料
    getRaws(data = {}) {
      let params = Object.assign({}, { orgCode: this.userInfo.orgCode }, data);
      getRaws(params)
        .then(res => {
          if (res) {
            this.feedstocks = res.list.map(v => {
              return {
                value: v.id,
                label: `${v.materialName}(${v.departmentName})`
              };
            });
          }
        })
        .catch(_ => {});
    },
    // 获取中间品
    getMedias(data = {}) {
      let params = Object.assign({}, { orgCode: this.userInfo.orgCode }, data);
      getMedias(params)
        .then(res => {
          if (res) {
            this.intermediates = res.list.map(v => {
              return {
                value: v.id,
                label: `${v.materialName}(${v.departmentName})`
              };
            });
          }
        })
        .catch(_ => {});
    },
    // 获取设施
    async getSelectFacilities() {
      let params = { orgCode: this.userInfo.orgCode };
      let res = await getSelectFacilities(params).catch(_ => {});
      this.facilities = res;
    },
    // 获取 jsa
    getSelectJsa() {
      let params = { orgCode: this.userInfo.orgCode };
      getSelectJsas(params)
        .then(res => {
          if (res) {
            this.jsas = res;
          }
        })
        .catch(_ => {});
    },
    // 获取传感器
    getSelectSensor() {
      getSelectSensorNum({ orgCode: this.userInfo.orgCode })
        .then(res => {
          if (res) {
            this.indicators = res.map(v => {
              return {
                value: v.id,
                label: v.num
              };
            });
          }
        })
        .catch(_ => {});
    },
    // 获取岗位
    getSelectPositions() {
      selectPositions({ orgCode: this.userInfo.orgCode })
        .then(res => {
          this.positions = res;
        })
        .catch(_ => {});
    },
    // 获取人员
    getUsers() {
      getUsers({ orgCode: this.userInfo.orgCode })
        .then(res => {
          this.users = res.filter(v => !v.disabled);
        })
        .catch(_ => {});
    },
    // 获取部门
    getSelectUserDept() {
      getSelectUserDept({ orgCode: this.userInfo.orgCode })
        .then(res => {
          if (res) {
            this.department = res;
          }
        })
        .catch(_ => {});
    },
    initRenderFormData(formList, layoutValues = {}) {
      let renderFormData = {};
      formList.map(v => {
        if (v.type === "title") {
          renderFormData[v.code] = v.label || "";
        } else if (v.type === "group") {
          let obj = {};
          v.children.map(k => {
            if (Array.isArray(k.value)) {
              if (k.value[0]) {
                obj[k.code] =
                  k.type === "input"
                    ? Array.isArray(k.value)
                      ? k.value
                      : [k.value]
                    : k.value;
              } else {
                obj[k.code] = { label: k.label, value: "", type: k.type };
              }
            } else {
              if (k.value) {
                obj[k.code] =
                  k.type === "input"
                    ? Array.isArray(k.value)
                      ? k.value
                      : [k.value]
                    : k.value;
              } else {
                obj[k.code] = { label: k.label, value: "", type: k.type };
              }
            }
          });
          renderFormData[v.code] = [obj];
        } else if (["docUpload", "imageUpload"].includes(v.type)) {
          renderFormData[v.code] = [];
        } else if (["input", "textarea"].includes(v.type)) {
          renderFormData[v.code] = [""];
        } else if (
          [
            "ckeditor",
            "number",
            "user",
            "position",
            "department",
            "facility",
            "indicator",
            "jsa",
            "phone",
            "material",
            "product",
            "intermediate",
            "feedstock"
          ].includes(v.type)
        ) {
          renderFormData[v.code] = v.value || "";
        } else if (["area"].includes(v.type)) {
          let change = {
            changeId: this.id
          };
          renderFormData[v.code] = JSON.stringify({ ...v, ...change });
        } else {
          renderFormData[v.code] = v.value || [];
        }
      });
      renderFormData = Object.assign({}, renderFormData, layoutValues);
      return renderFormData;
    },
    formatRecordsValue(records, values = {}) {
      records.map(v => {
        if (v.value) {
          let arr = JSON.parse(v.value);
          let obj = {};
          arr.map(k => {
            if (k.type === "docUpload") {
              obj[k.name] = JSON.parse(k.value);
            } else if (
              [
                "group",
                "date",
                "dateTime",
                "textarea",
                "input",
                "ckeditor"
              ].includes(k.type)
            ) {
              obj[k.name] = k.value || "";
            } else {
              if (
                k.value &&
                typeof k.value === "string" &&
                k.value.indexOf(",") > 0 &&
                !isJSON(k.value)
              ) {
                obj[k.name] = k.value.split(",") || [];
              } else {
                obj[k.name] = k.value || "";
              }
            }
          });
          values = { ...values, ...obj };
        }
      });
      return values;
    },
    formatRenderFormData(arr) {
      let fn = function(array) {
        return array.map(v => {
          v.value = "";
          if (v.children && v.children.length) {
            v.value = fn(v.children);
          }
          return {
            label: v.label,
            type: v.type,
            name: v.code,
            value: v.value
          };
        });
      };
      return fn(arr);
    }
  }
};
