<template>
  <div>
    <van-cell class="CheckboxEditCell" :title="title">
      <template #label>
        <van-checkbox-group v-show="editing" v-model="vModelCom">
          <van-checkbox
            v-for="(item, index) in options"
            :key="index"
            :name="item"
            shape="square"
            icon-size="1"
          >
            {{ item }}
          </van-checkbox>
        </van-checkbox-group>
        <!-- 强制措施-编辑状态 -->
        <ul
          v-show="forceMeasureList.length && editing"
          class="coercive-measures-editing"
        >
          <li v-for="(item, index) in forceMeasureList" :key="index">
            {{ item.name }}
          </li>
        </ul>
        <div v-show="!editing">
          <div
            v-for="(item, index) in [...value, ...(forceMeasureList || [])]"
            :key="index"
            class="meature-person"
          >
            <span class="dec">{{ index + 1 }}、{{ item.name }}</span>
            <!-- <span
              v-if="item.operatorName"
              class="meature-person-name"
              @click="previewSign(item)"
              >{{ item.operatorName }}
              <van-icon name="success" />
            </span> -->
            <Tag
              v-if="item.operatorName"
              :type="tagTypeSwitch(item)"
              :tick="!!item.fileId"
              @click.native="previewSign(item.fileId)"
            >
              {{ item.operatorName }}
            </Tag>
            <div
              v-if="(item.pictureFileIdList || []).length"
              class="upload-image-box"
              @click="uploadPopClick(item)"
            >
              <div class="content">
                <van-icon name="photograph" size="16" color="#1678FF" />
                <span class="num">
                  {{ (item.pictureFileIdList || []).length }}
                </span>
              </div>
            </div>
          </div>
        </div>
        <slot></slot>
      </template>
      <template v-if="options.length && editing" #default>
        <button class="btn-all" @click="allChoose">
          全选
        </button>
      </template>
    </van-cell>
    <cameraUpload
      :visible="cameraUploadVb"
      position="bottom"
      mode-type="1"
      :files="rowFiles"
      @close="cameraUploadVb = false"
    />
  </div>
</template>

<script>
import EditFieldMixin from "@/components/edit/mixins/EditFieldMixin";
import { filterValue, isChecked } from "@/components/edit/utils/options";
import { fileUrl } from "@/utils/constant";
import esign from "../esign";
import Tag from "@/components/Tag";
import cameraUpload from "@/views/workticket/components/cameraUpload.vue";

export default {
  name: "CheckboxEditCell",
  mixins: [EditFieldMixin],
  components: {
    Tag,
    cameraUpload
  },
  props: {
    tagType: {
      type: String,
      default: "blue"
    },
    multiple: { type: Boolean, default: true },
    optionLabelKey: { type: String, default: "" },
    optionValueKey: { type: String, default: "" },
    vModel: { type: Array, default: () => [] },
    forceMeasureList: { type: Array, default: () => [] }
  },
  data() {
    return {
      cameraUploadVb: false,
      rowFiles: []
    };
  },
  computed: {
    vModelCom: {
      get() {
        return this.vModel;
      },
      set(v) {
        this.$emit("update:vModel", v);
      }
    }
  },
  watch: {
    options(val) {
      if (val) {
        const v = filterValue({
          value: this.value,
          options: this.options,
          optionValueKey: this.optionValueKey
        });
        this.$emit("change", v);
      }
    }
  },
  created: function() {},
  mounted: function() {},
  methods: {
    // getFileUrl(id, downloadFlag = true) {
    //   return `${window.globalConfig.VUE_APP_FILE_URL}/file/download?downloadFlag=${downloadFlag}&fileName=${id}`;
    // },
    uploadPopClick(rowData) {
      this.rowData = rowData;
      this.rowFiles = (rowData.pictureFileIdList || []).map(i => ({
        // url: this.getFileUrl(i),
        id: i,
        name: `${i}.jpg`
      }));
      this.cameraUploadVb = true;
    },
    // 预览签名
    previewSign(fileId) {
      // confirmTime: null
      // fileId: null
      // id: "228645875784613893"
      // name: "111222"
      // operator: null
      // operatorName: null
      // type: 0
      if (!fileId) return;
      esign.showImage(fileUrl + fileId);
    },
    isItemChecked(item) {
      return isChecked({
        checked: this.value,
        value: item,
        optionValueKey: null
      });
    },
    allChoose() {
      this.vModelCom = [...this.options];
    },
    tagTypeSwitch(item) {
      // if (!item.involved) {
      //   return item.fileId ? "green" : "white";
      // }
      // return "purple";
      if (item.fileId) {
        return item.involved ? "purple" : "green";
      }
      return "white";
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.CheckboxEditCell {
  padding-bottom: 15px;
  .meature-person {
    // height: 30px;
    // line-height: 30px;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 0 16px 8px 0;
    .dec {
      font-size: 14px;
      line-height: 20px;
      padding: 0 8px 0 0;
      white-space: break-spaces;
      word-break: break-all;
      max-width: 68%;
    }
    .meature-person-name {
      position: relative;
      display: inline-block;
      height: 32px;
      max-width: 120px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      line-height: 32px;
      border-radius: 16px;
      color: #64ae08;
      border: 1px solid #75c70e;
      background-color: #dcffdb;
      padding: 0 30px 0 10px;
      box-sizing: border-box;
      .van-icon {
        position: absolute;
        right: 10px;
        top: 0;
        line-height: 32px;
        margin-left: 6px;
        font-size: 18px;
        vertical-align: top;
      }
    }
    .upload-image-box {
      position: absolute;
      width: 60px;
      height: 30px;
      color: #1678ff;
      font-size: 12px;
      background-color: #eceef2;
      border-radius: 0 0 30px 30px;
      top: 0;
      right: -46px;
      .content {
        display: flex;
        flex-direction: column;
        margin: 2px 0 0 12px;
        .num {
          margin: 0 0 0 5px;
          line-height: 9px;
        }
      }
    }
  }
  .tags-box {
    padding: 0px 16px;
  }

  ::v-deep .van-checkbox__icon {
    height: auto;

    .van-icon {
      font-size: 12px;
      vertical-align: center;
    }
  }
  .btn-all {
    width: 72px;
    height: 32px;
    color: #fff;
    background: #1676ff;
    border-radius: 16px;
    border: none;
  }
  .van-cell__title {
    // width: 265px;
    // flex: initial !important;
    white-space: initial !important;
  }
  .van-cell__value {
    // width: 78px;
    flex: initial !important;
  }
  .coercive-measures {
    li {
      line-height: 1.2;
      margin-bottom: 8px;
      font-size: 14px;
    }
  }
  .coercive-measures-editing {
    li {
      line-height: 1.2;
      margin-top: 8px;
    }
  }
}
</style>
