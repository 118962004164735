<template>
  <div>
    <van-field
      class="render-input-box"
      :value="data"
      readonly
      :label="itemData.label + ':'"
      :required="itemData.options.required"
      :placeholder="$attrs.editable ? '请选择' : ''"
      :rules="[
        {
          validator: validator
        }
      ]"
      @click="onClick"
    >
    </van-field>
    <van-popup v-model="showPicker" position="bottom">
      <van-datetime-picker
        v-model="datetime"
        type="date"
        @confirm="onConfirm"
        @cancel="showPicker = false"
      />
    </van-popup>
  </div>
</template>

<script>
import dayjs from "dayjs";

export default {
  name: "RenderDatePicker",
  props: {
    value: [String, Array],
    itemData: Object
  },
  data() {
    return {
      data: "",
      errorMessage: "",
      showPicker: false,
      datetime: new Date()
    };
  },
  watch: {
    data: {
      handler: function(val) {
        this.$emit("input", val);
      },
      deep: true
    }
  },
  methods: {
    onClick() {
      if (this.$attrs.editable) {
        this.showPicker = true;
      }
    },
    onConfirm(value) {
      if (Array.isArray(value)) {
        this.data = value.map(v => dayjs(v).format("YYYY-MM-DD"));
      }
      this.data = dayjs(value).format("YYYY-MM-DD");
      this.showPicker = false;
    },
    validator(value) {
      if (this.itemData.options) {
        if (this.itemData.options.required && !this.data.length) {
          this.errorMessage = `${this.itemData.label}不能为空`;
          return false;
        }
      }
      this.errorMessage = "";
      return true;
    }
  },
  mounted() {
    if (Array.isArray(this.value)) {
      if (this.itemData.options.isRange) {
        this.data = this.value.length ? this.value : null;
      } else {
        this.data = "";
      }
    } else {
      if (this.$attrs.editable) {
        this.data = this.itemData.options.isRange
          ? this.value.split(",")
          : this.value || null;
      } else {
        this.data = this.value || null;
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>
