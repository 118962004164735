<template>
  <div class="RadioPicker">
    <van-popup v-model="showPopup" position="bottom" closeable round>
      <div class="title">{{ title }}</div>
      <ul class="checkbox-group">
        <li
          v-for="(item, index) in options"
          :key="index"
          class="item"
          :class="{ active: isItemChecked(item) }"
          @click="clickItem(index, item)"
        >
          <span>{{ item[optionLabelKey] }}</span>
          <van-icon v-show="isItemChecked(item)" class="icon" name="success" />
        </li>
      </ul>
    </van-popup>
  </div>
</template>

<script>
import { getCheckedValues } from "@/components/edit/utils/options";

export default {
  name: "RadioPicker",
  components: {},
  model: {
    prop: "value",
    event: "change"
  },
  props: {
    title: { type: String, default: "标题" },
    value: { type: [String, Number], default: null },
    label: { type: String, default: "" }, //sync,选择项的label
    options: { type: Array, default: () => [] },
    optionLabelKey: { type: String, default: "label" },
    optionValueKey: { type: String, default: "value" }
  },
  data() {
    return {
      showPopup: false
    };
  },
  watch: {
    value: {
      immediate: true,
      handler(val) {
        this.$emit("update:label", this.getLabel());
      }
    },
    options() {
      this.$emit("update:label", this.getLabel());
    }
  },
  created: function() {},
  mounted: function() {},
  methods: {
    show() {
      this.showPopup = true;
    },
    isItemChecked(item) {
      let { value, optionValueKey } = this;
      return value === item[optionValueKey];
    },
    clickItem(index, item) {
      this.showPopup = false;

      this.$emit(
        "change",
        getCheckedValues({
          checked: this.value,
          value: item[this.optionValueKey],
          multiple: false
        })
      );
    },
    getLabel() {
      let { value, options, optionValueKey, optionLabelKey } = this;
      let item = options.find(ite => ite[optionValueKey] == value);
      return item ? item[optionLabelKey] : null;
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.RadioPicker {
  .title {
    font-size: 16px;
    text-align: center;
    color: $--color-black-title;
    line-height: 22px;
    padding: 15px 0px 10px 0px;
    font-weight: 500;
    border-bottom: $--color-gray-item-block solid 1px;
  }

  .checkbox-group {
    min-height: 200px;
    .item {
      position: relative;
      height: 38px;
      line-height: 38px;
      font-size: 14px;
      text-align: left;
      color: $--color-black-list-page;
      padding: 0px 17px;

      &.active {
        color: $--color-primary;
      }

      .icon {
        position: absolute;
        right: 17px;
        top: 50%;
        transform: translateY(-50%);
        font-size: 18px;
      }
    }
  }
}
</style>
