<template>
  <div>
    <van-collapse-item
      class="WorkticketTypeDetailItem"
      :class="{ editing, 'edit-button-editing': editing }"
      :title="title"
      :name="name"
      :border="false"
      :is-link="false"
      title-class="ticket-title"
      value-class="ticket-value"
    >
      <template #title>
        <span class="ticket-name" :class="{ 'export-w': exportSTSwitch }">{{
          title
        }}</span>
        <i
          v-if="exportSTSwitch"
          class="iconfont iconfont-daochu daochu"
          @click.stop="exportSpecialTickets(value)"
        ></i>
      </template>
      <template #value>
        <div v-if="!isWorkInspect" class="btn-box">
          <EditButton
            v-if="showEditBtn"
            v-model="editing"
            :form.sync="value"
            @edit="handlerEdit"
            @save="handSave"
            @cancel="handlerCancel"
            @change="btnChange"
          ></EditButton>
          <!-- <button
            v-if="showReferralBtn"
            class="btn-work-edit"
            @click.stop="chickReferral"
          >
            转审
          </button> -->
          <button
            v-if="showGasCheckBtn"
            class="btn-work-edit"
            @click.stop="clickGasCheck"
          >
            检测
          </button>

          <span
            v-if="showGasHistoryBtn"
            class="gas-history-btn"
            :style="{
              color: originalData.gasMonitorResult ? '#64ae08' : '#f55f5f'
            }"
            @click="gasHistoryClick"
            >检测记录</span
          >

          <button
            v-if="showShenPiBtn"
            class="btn-work-edit shenpi"
            @click.stop="clickShenPi"
          >
            审批
          </button>
          <button
            v-if="discrepancyRecordBtn"
            class="btn-work-edit"
            @click.stop="discrepancyRecordClick"
          >
            进出记录
          </button>
        </div>
      </template>
      <van-form ref="form" class="form-box">
        <van-skeleton title :row="3" :loading="!value">
          <template v-if="value">
            <van-field
              class="custom-input-person"
              readonly
              :clickable="false"
              :is-link="false"
              name="leader"
              label="作业类别"
              v-bind="fieldProps"
            >
              <template #input>
                <p class="desc">
                  {{ title }}
                </p>
              </template>
            </van-field>
            <van-field
              class="custom-input-person"
              readonly
              :clickable="editingWithStatus19"
              :is-link="editingWithStatus19"
              :value="getPersonLabel(value.leader)"
              name="leader"
              label="作业负责人"
              placeholder="请选择人员"
              v-bind="fieldProps"
              :rules="[
                {
                  required: true,
                  message: '请选择作业负责人',
                  trigger: 'onChange'
                }
              ]"
              @click="editingWithStatus19 && $refs.personPicker1.show()"
            >
              <template #input>
                <p v-if="value.leader">
                  <span class="person-box">{{ value.leader.label }}</span>
                </p>
                <p v-if="value.replaceLeaderList">
                  <span
                    v-for="item of value.replaceLeaderList"
                    :key="item.id"
                    class="person-box replace-color"
                  >
                    {{ item.workerName }}
                  </span>
                </p>
              </template>
            </van-field>
            <WorkticketPersonPicker
              ref="personPicker1"
              v-model="value.leader"
              title="作业负责人"
              :work-type-id="id"
              :contractor-status="value.contractorStatus"
              :dept-code="value.deptCode"
              @change="leaderChange"
            />
            <!-- <select-person
              title="作业负责人(单选)"
              :visible="leaderPicker"
              :showDepartmentName="false"
              :showPhoneNumber="true"
              position="bottom"
              :multiple="false"
              :isDepartmentAuth="false"
              :showDepartmentSearch="false"
              :defaultDepartment="department"
              :license-types="licenseTypesSwitch(1)"
              @close="leaderPicker = false"
              @confirm="(ids, list) => onPersonConfirm(ids, list, 'leader')"
              :chooseValue="[value.leader]"
            /> -->
            <van-field
              class="custom-input-person"
              readonly
              :disabled="specialJobType === WORKTICKET_TYPE.hoisting"
              :is-link="editingWithStatus19"
              :clickable="editingWithStatus19"
              autosize
              :value="getPersonLabel(value.workerList)"
              name="workerList"
              label="特种作业人员"
              placeholder="请选择人员"
              v-bind="fieldProps"
              :rules="[
                {
                  required:
                    originalData.licenseType !== null &&
                    originalData.licenseType.length > 0,
                  message: '请选择特种作业人',
                  trigger: 'onChange'
                }
              ]"
              @click="editingWithStatus19 && workerClick()"
            >
              <template #input>
                <p v-if="value.workerList">
                  <span
                    v-for="item of value.workerList"
                    :key="item.value"
                    class="person-box"
                  >
                    {{ item.label }}
                  </span>
                </p>
                <p v-if="value.replaceWorkerList">
                  <span
                    v-for="item of value.replaceWorkerList"
                    :key="item.id"
                    class="person-box replace-color"
                  >
                    {{ item.workerName }}
                  </span>
                </p>
              </template>
            </van-field>
            <!-- <WorkticketPersonPicker
              ref="personPicker2"
              v-model="value.workerList"
              title="特种作业人员"
              :work-type-id="id"
              :multiple="true"
              :contractor-status="value.contractorStatus"
              :dept-code="value.deptCode"
              :is-worker="true"
              :show-license-tag="true"
              :special-id="value.id"
            /> -->
            <select-person-by-tabs
              title="特种作业人员(多选)"
              :visible="workerPickerVisible"
              :show-department-name="false"
              :show-phone-number="true"
              position="bottom"
              :include-children="true"
              :multiple="true"
              :is-department-auth="false"
              :default-department="department"
              :default-contractor="
                originalData.contractorStatus ? originalData.deptCode : ''
              "
              :choose-value="value.workerList"
              :license-types="
                licenseTypeFormatter(
                  originalData.licenseType && originalData.licenseType[0]
                )
              "
              :contractor-license-types="
                licenseTypeFormatter(
                  originalData.licenseType && originalData.licenseType[0]
                )
              "
              work-type-id=""
              recent-type="3"
              contractor-worker-type="3"
              :show-recent-use-person="true"
              :default-tab-name="2"
              @close="workerPickerVisible = false"
              @confirm="(ids, list) => onPersonConfirm(ids, list, 'workerList')"
            />
            <van-field
              v-if="!(isZx && specialJobType === '8')"
              class="custom-input-person"
              readonly
              :is-link="editingWithStatus19"
              :clickable="editingWithStatus19"
              autosize
              :value="getPersonLabel(value.generalWorkerList)"
              name="generalWorkerList"
              label="一般作业人员"
              placeholder="请选择人员"
              v-bind="fieldProps"
              :rules="[
                {
                  required: !originalData.licenseType,
                  message: '请选择一般作业人',
                  trigger: 'onChange'
                }
              ]"
              @click="editingWithStatus19 && normalWorkerClick()"
            >
              <template #input>
                <p v-if="value.generalWorkerList">
                  <span
                    v-for="item of value.generalWorkerList"
                    :key="item.value"
                    class="person-box"
                  >
                    {{ item.label }}
                  </span>
                </p>
                <p v-if="value.replaceGeneralWorkerList">
                  <span
                    v-for="item of value.replaceGeneralWorkerList"
                    :key="item.id"
                    class="person-box replace-color"
                  >
                    {{ item.workerName }}
                  </span>
                </p>
              </template>
            </van-field>
            <!-- <WorkticketPersonPicker
              ref="normalPersonPicker"
              v-model="value.generalWorkerList"
              title="一般作业人员"
              :work-type-id="id"
              :multiple="true"
              :contractor-status="value.contractorStatus"
              :dept-code="value.deptCode"
              :is-worker="true"
              :show-license-tag="true"
            /> -->
            <select-person-by-tabs
              title="一般作业人员(多选)"
              :visible="normalPersonPickerVisible"
              :show-department-name="false"
              :show-phone-number="true"
              position="bottom"
              :include-children="true"
              :multiple="true"
              :is-department-auth="false"
              :default-department="department"
              :default-contractor="
                originalData.contractorStatus ? originalData.deptCode : ''
              "
              :choose-value="value.generalWorkerList"
              license-types=""
              work-type-id=""
              recent-type="15"
              contractor-worker-type="15"
              :show-recent-use-person="true"
              :default-tab-name="2"
              @close="normalPersonPickerVisible = false"
              @confirm="
                (ids, list) => onPersonConfirm(ids, list, 'generalWorkerList')
              "
            />
            <van-field
              class="custom-input-person"
              readonly
              :clickable="editingWithStatus19"
              :is-link="editingWithStatus19"
              autosize
              :value="guarderLabel"
              name="guarderList"
              label="监护人"
              placeholder="请选择人员"
              v-bind="fieldProps"
              :rules="[
                {
                  required: true,
                  message: '请选择监护人',
                  trigger: 'onChange'
                }
              ]"
              @click="editingWithStatus19 && showSelectGuarder()"
            >
              <template #input>
                <p v-if="value.guarderList">
                  <span
                    v-for="item of value.guarderList"
                    :key="item.value"
                    class="person-box"
                  >
                    {{ item.label }}
                  </span>
                </p>
                <p v-if="value.replaceGuarderList">
                  <span
                    v-for="item of value.replaceGuarderList"
                    :key="item.id"
                    class="person-box replace-color"
                  >
                    {{ item.workerName }}
                  </span>
                </p>
              </template>
            </van-field>
            <select-person-by-tabs
              title="监护人(多选)"
              :visible="showGuarderPicker"
              :show-department-name="false"
              :show-phone-number="true"
              position="bottom"
              :include-children="true"
              :multiple="true"
              :is-department-auth="false"
              :default-department="department"
              :default-contractor="
                originalData.contractorStatus ? originalData.deptCode : ''
              "
              :choose-value="value.guarderList"
              :license-types="licenseTypesSwitch(2)"
              :work-type-id="id"
              :show-recent-use-person="true"
              :default-tab-name="2"
              recent-type="6"
              @close="showGuarderPicker = false"
              @confirm="
                (ids, list) => onPersonConfirm(ids, list, 'guarderList')
              "
            />
            <!-- 视频采集 -->
            <van-field
              v-if="
                +value.videoCaptureFlag === 1 ||
                  (value.workVideoRelation && value.workVideoRelation.videoId)
              "
              class="custom-input-person"
              readonly
              :clickable="editing"
              :is-link="editing"
              name="leader"
              label="视频采集"
              v-bind="fieldProps"
              @click.native="onVideoClick(value.workVideoRelation)"
            >
              <template #input>
                <p
                  :class="[
                    !editing
                      ? workInfo.videoTags
                        ? 'color-primary-important desc'
                        : 'desc'
                      : 'person-box'
                  ]"
                >
                  {{
                    value.workVideoRelation && value.workVideoRelation.videoName
                  }}
                </p>
              </template>
            </van-field>
            <template v-if="specialJobType === WORKTICKET_TYPE.temporary_power">
              <van-field
                class="custom-input-person"
                readonly
                :clickable="editing"
                :is-link="editing"
                autosize
                :value="getPersonLabel(value.powerWorkerList)"
                name="powerWorkerList"
                label="用电人"
                v-bind="fieldProps"
                :rules="[
                  {
                    required: true,
                    message: '请选择用电人',
                    trigger: 'onChange'
                  }
                ]"
                @click="powerWorkerListClick()"
              >
                <template #input>
                  <p v-if="value.powerWorkerList">
                    <span class="person-box">{{
                      getPersonLabel(value.powerWorkerList)
                    }}</span>
                  </p>
                  <p v-if="value.replacePowerWorkerList">
                    <span class="person-box replace-color">
                      {{
                        value.replacePowerWorkerList
                          .map(item => item.workerName)
                          .toString()
                      }}
                    </span>
                  </p>
                </template>
              </van-field>
              <select-person-by-tabs
                title="用电人(多选)"
                :visible="powerWorkerListVisible"
                :show-department-name="false"
                :show-phone-number="true"
                position="bottom"
                :include-children="true"
                :multiple="true"
                :is-department-auth="false"
                :default-department="department"
                :default-contractor="
                  originalData.contractorStatus ? originalData.deptCode : ''
                "
                :choose-value="value.powerWorkerList"
                :license-types="licenseTypesSwitch(10)"
                recent-type="10"
                contractor-worker-type="10"
                :work-type-id="id"
                :show-recent-use-person="true"
                :default-tab-name="2"
                @close="powerWorkerListVisible = false"
                @confirm="
                  (ids, list) => onPersonConfirm(ids, list, 'powerWorkerList')
                "
              />
              <van-field
                class="custom-input-person"
                readonly
                :clickable="editing"
                :is-link="editing"
                autosize
                :value="getPersonLabel(value.powerWorkerLeaderList)"
                name="powerWorkerLeaderList"
                label="用电单位负责人"
                placeholder=""
                v-bind="fieldProps"
                :rules="[
                  {
                    required: true,
                    message: '请选择用电单位负责人',
                    trigger: 'onChange'
                  }
                ]"
                @click="powerWorkerLeaderListClick()"
              >
                <template #input>
                  <p v-if="value.powerWorkerLeaderList">
                    <span class="person-box">{{
                      getPersonLabel(value.powerWorkerLeaderList)
                    }}</span>
                  </p>
                </template>
              </van-field>
              <select-person-by-tabs
                title="用电单位负责人(单选)"
                :visible="powerWorkerLeaderListVisible"
                :show-department-name="false"
                :show-phone-number="true"
                position="bottom"
                :include-children="true"
                :multiple="false"
                :is-department-auth="false"
                :default-department="department"
                :default-contractor="
                  originalData.contractorStatus ? originalData.deptCode : ''
                "
                :choose-value="value.powerWorkerLeaderList"
                contractor-worker-type=""
                :show-recent-use-person="true"
                :default-tab-name="2"
                recent-type="2601"
                @close="powerWorkerLeaderListVisible = false"
                @confirm="
                  (ids, list) =>
                    onPersonConfirm(ids, list, 'powerWorkerLeaderList')
                "
              />
              <van-field
                class="custom-input-person"
                :value="getWorkSiteTypeName(value.workSiteType)"
                label="作业地点类型"
                placeholder=""
                :clickable="editing"
                :is-link="editing"
                readonly
                v-bind="fieldProps"
                :rules="[
                  {
                    required: true,
                    message: '请选择作业地点类型',
                    trigger: 'onChange'
                  }
                ]"
                @click="editing && (workSiteTypeVisible = true)"
              >
                <template #input>
                  <p v-if="value.workSiteType">
                    <span class="person-box">{{
                      getWorkSiteTypeName(value.workSiteType)
                    }}</span>
                  </p>
                </template>
              </van-field>
              <select-popup
                v-model="value.workSiteType"
                title="作业地点类型"
                :visible="workSiteTypeVisible"
                :data="workSiteTypes"
                :popup-style="{ height: '45vh' }"
                @close="workSiteTypeVisible = false"
                @confirm="workSiteTypeConfirm"
              />
            </template>
            <template
              v-if="
                value.gasMonitor === 1 &&
                  (specialJobType === WORKTICKET_TYPE.temporary_power
                    ? ['1001', '2001', '3001'].includes(value.workSiteType)
                    : true)
              "
            >
              <van-field
                class="custom-input-person"
                :value="getPersonLabel(value.analyst)"
                :label="TEXT_WORK_ANALYST"
                :clickable="editing"
                :is-link="editing"
                readonly
                v-bind="fieldProps"
                :rules="[
                  {
                    required: true,
                    message: `请选择${TEXT_WORK_ANALYST}`,
                    trigger: 'onChange'
                  }
                ]"
                @click="editing && (showAnalystPicker = true)"
              >
                <template #input>
                  <p v-if="value.analyst">
                    <span class="person-box">{{ value.analyst.label }}</span>
                  </p>
                  <p v-if="value.replaceAnalystList">
                    <span
                      v-for="item of value.replaceAnalystList"
                      :key="item.id"
                      class="person-box replace-color"
                    >
                      {{ item.workerName }}
                    </span>
                  </p>
                </template>
              </van-field>
              <select-person
                :title="`${TEXT_WORK_ANALYST}(单选)`"
                :visible="showAnalystPicker"
                :show-department-name="false"
                :show-phone-number="true"
                position="bottom"
                :include-children="true"
                :multiple="false"
                :is-department-auth="false"
                :default-department="department"
                :license-types="licenseTypesSwitch(5)"
                :choose-value="[value.analyst]"
                @close="showAnalystPicker = false"
                @confirm="(ids, list) => onPersonConfirm(ids, list, 'analyst')"
              />
            </template>

            <!--动火作业:需要气体检测、检测分析人、动火方式-->
            <template v-if="WORKTICKET_TYPE.fire == specialJobType">
              <CheckboxEditField
                v-model="value.fireWayList"
                title="动火方式"
                :multiple="true"
                :editing="editing"
                :options="fireWayOptionts"
                option-value-key="id"
                option-label-key="name"
              >
                <template slot="tagContent" slot-scope="scope">
                  <div class="pp-tag-content">
                    <span>{{ scope.item.name }}</span>
                    <van-icon
                      v-if="scope.item.delete"
                      class="pp-option-close"
                      name="clear"
                      @click.stop="
                        dictionaryDelete(scope.item.id, specialJobType)
                      "
                    />
                  </div>
                </template>
                <Tag
                  v-if="editing"
                  type="white"
                  @click.native="dictionaryAdd(specialJobType)"
                >
                  <van-icon name="plus" />
                </Tag>
              </CheckboxEditField>
            </template>

            <!--受限空间:	需要气体检测
                       检测分析人
                       原有介质名称
                       受限空间作业类型	受限空间类型枚举
           -->
            <template v-if="WORKTICKET_TYPE.space == specialJobType">
              <van-field
                v-model="value.spaceName"
                label="受限空间名称"
                :readonly="!editing"
                maxlength="32"
                v-bind="fieldPropsInput"
                :placeholder="editing ? '请输入' : ''"
                :rules="[
                  {
                    required: true,
                    message: ' ',
                    trigger: 'onChange'
                  }
                ]"
              />
              <van-field
                v-model="value.mediumName"
                label="原有介质名称"
                :readonly="!editing"
                maxlength="100"
                v-bind="fieldPropsInput"
                :placeholder="editing ? '请输入' : ''"
                :rules="[
                  {
                    required: true,
                    message: ' ',
                    trigger: 'onChange'
                  }
                ]"
              />

              <CheckboxEditField
                v-model="value.spaceTypeList"
                title="受限空间作业类型"
                :multiple="true"
                :editing="editing"
                :options="workSpaceTypeOptionts"
                option-value-key="id"
                option-label-key="name"
              >
                <template slot="tagContent" slot-scope="scope">
                  <div class="pp-tag-content">
                    <span>{{ scope.item.name }}</span>
                    <van-icon
                      v-if="scope.item.delete"
                      class="pp-option-close"
                      name="clear"
                      @click.stop="
                        dictionaryDelete(scope.item.id, specialJobType)
                      "
                    />
                  </div>
                </template>
                <Tag
                  v-if="editing"
                  type="white"
                  @click.native="dictionaryAdd(specialJobType)"
                >
                  <van-icon name="plus" />
                </Tag>
              </CheckboxEditField>
            </template>

            <!--
              盲板抽堵:	设备管道名称
                      介质
                      温度
                      压力
                      盲板材质
                      盲板规格
                      盲板编号
                      管线打开（盲板）位置示意图
            -->
            <template v-if="WORKTICKET_TYPE.blind_disk == specialJobType">
              <!-- <van-field
                class="custom-input-person"
                :value="getPersonLabel(value.productionUnitCommander)"
                name="productionUnitCommander"
                :label="TEXT_PRODUCTION_UNIT_WORK_COMMANDER"
                placeholder="请选择人员"
                :clickable="editing"
                :is-link="editing"
                readonly
                v-bind="fieldProps"
                :rules="[
                  {
                    required: true,
                    message: `请选择${TEXT_PRODUCTION_UNIT_WORK_COMMANDER}`,
                    trigger: 'onChange'
                  }
                ]"
                @click="editing && (showPUCPicker = true)"
              >
                <template #input>
                  <p v-if="value.productionUnitCommander">
                    <span class="person-box">{{
                      value.productionUnitCommander.label
                    }}</span>
                  </p>
                  <p v-if="value.replaceProductionUnitCommander">
                    <span
                      v-for="item of value.replaceProductionUnitCommander"
                      :key="item.id"
                      class="person-box replace-color"
                    >
                      {{ item.workerName }}
                    </span>
                  </p>
                </template>
              </van-field>
              <select-person
                :title="`${TEXT_PRODUCTION_UNIT_WORK_COMMANDER}(单选)`"
                :visible="showPUCPicker"
                :show-department-name="false"
                :show-phone-number="true"
                position="bottom"
                :include-children="true"
                :multiple="false"
                :is-department-auth="false"
                :default-department="department"
                :license-types="licenseTypesSwitch(13)"
                :choose-value="[value.productionUnitCommander]"
                @close="showPUCPicker = false"
                @confirm="
                  (ids, list) =>
                    onPersonConfirm(ids, list, 'productionUnitCommander')
                "
              /> -->
              <!-- <RadioEditField
                v-model="value.type"
                name="type"
                label="盲板作业类型"
                :editing="editing"
                :options="[
                  { label: '抽', value: '1' },
                  { label: '堵', value: '0' }
                ]"
                v-bind="fieldProps"
                :rules="[
                  {
                    required: false,
                    message: ' ',
                    trigger: 'onChange'
                  }
                ]"
              /> -->
              <van-field
                v-model="value.pipelineName"
                name="pipelineName"
                label="设备管道名称"
                :readonly="!editing"
                v-bind="fieldPropsInput"
                maxlength="100"
                :placeholder="editing ? '请输入' : ''"
              />

              <van-field
                v-model="value.mediumName"
                name="mediumName"
                label="介质"
                :readonly="!editing"
                v-bind="fieldPropsInput"
                maxlength="100"
                :placeholder="editing ? '请输入' : ''"
                :rules="[
                  {
                    required: false,
                    message: ' ',
                    trigger: 'onChange'
                  }
                ]"
              />

              <van-field
                v-model="value.temperature"
                name="temperature"
                label="温度°C"
                :readonly="!editing"
                maxlength="32"
                :placeholder="editing ? '请输入' : ''"
                v-bind="fieldPropsInput"
                :rules="[
                  {
                    required: false,
                    message: ' ',
                    trigger: 'onChange'
                  }
                ]"
              />

              <van-field
                v-model="value.pressure"
                name="pressure"
                label="压力"
                :readonly="!editing"
                maxlength="32"
                :placeholder="editing ? '请输入' : ''"
                v-bind="fieldPropsInput"
                :rules="[
                  {
                    required: false,
                    message: ' ',
                    trigger: 'onChange'
                  }
                ]"
              />

              <RadioEditField
                v-model="value.pressureUnit"
                name="pressureUnit"
                label="压力单位"
                v-bind="fieldProps"
                :editing="editing"
                :options="[
                  { label: 'Mpa', value: 'Mpa' },
                  { label: 'Kpa', value: 'Kpa' }
                ]"
              />

              <CheckboxEditField
                v-model="value.type"
                title="盲板作业类型"
                :multiple="false"
                :editing="editing"
                :options="[
                  { label: '抽', value: '1' },
                  { label: '堵', value: '0' }
                ]"
              />
              <van-field
                v-model="value.num"
                name="num"
                label="盲板编号"
                :readonly="!editing"
                :placeholder="editing ? '请输入' : ''"
                v-bind="fieldPropsInput"
                maxlength="32"
                :rules="[
                  {
                    required: false,
                    message: ' ',
                    trigger: 'onChange'
                  }
                ]"
              />
              <van-field
                v-model="value.texture"
                name="texture"
                label="盲板材质"
                :readonly="!editing"
                :placeholder="editing ? '请输入' : ''"
                v-bind="fieldPropsInput"
                maxlength="100"
                :rules="[
                  {
                    required: false,
                    message: ' ',
                    trigger: 'onChange'
                  }
                ]"
              />
              <van-field
                v-model="value.specification"
                name="specification"
                label="盲板规格"
                :readonly="!editing"
                :placeholder="editing ? '请输入' : ''"
                v-bind="fieldPropsInput"
                maxlength="100"
                :rules="[
                  {
                    required: false,
                    message: ' ',
                    trigger: 'onChange'
                  }
                ]"
              />

              <van-field
                label="管线打开(盲板)位置示意图"
                :readonly="!editing"
                v-bind="fieldProps"
                :rules="[
                  {
                    required: false,
                    message: '请上传示意图',
                    trigger: 'onChange'
                  }
                ]"
              >
                <template #input>
                  <FileUploader
                    v-model="value.attachmentList"
                    :max-count="1"
                    :unable-upload="!editing"
                    :accept="FILE_ACCEPT_TYPES.FILE"
                  />
                </template>
              </van-field>
            </template>

            <!--高处作业:	作业高度	-->
            <template v-if="WORKTICKET_TYPE.elevation == specialJobType">
              <van-field
                v-model="value.workElevation"
                class="work-elevation"
                name="workElevation"
                label="作业高度(m)"
                :readonly="!editing"
                maxlength="32"
                v-bind="fieldPropsInput"
                :placeholder="editing ? '请输入' : ''"
                :rules="[
                  {
                    required: true,
                    message: ' ',
                    trigger: 'onChange'
                  },
                  {
                    validator: workElevationValidator,
                    message: '请输入2-999.99区间的数字',
                    trigger: 'onChange'
                  }
                ]"
                @input="workElevationInput"
              />
              <!-- <RadioEditField
                v-model="value.workElevation"
                name="workElevation"
                label="作业高度"
                v-bind="fieldProps"
                :editing="editing"
                :options="[
                  { label: '2m≤h≤5m', value: '2m≤h≤5m' },
                  { label: '5m<h≤15m', value: '5m<h≤15m' },
                  { label: '15m<h≤30m', value: '15m<h≤30m' },
                  { label: 'h>30m', value: 'h>30m' }
                ]"
                :rules="[
                  {
                    required: true,
                    message: '请选择',
                    trigger: 'onChange'
                  }
                ]"
              /> -->
              <van-field
                class="custom-input-person wem"
                readonly
                :clickable="false"
                autosize
                :value="value.elevationLevelType"
                name="elevationLevelType"
                label="作业等级"
                placeholder="请选择人员"
                v-bind="fieldProps"
                :rules="[
                  {
                    required: true,
                    message: '请选择作业等级',
                    trigger: 'onChange'
                  },
                  {
                    validator: workElevationLevelValidator,
                    message: `和作业配置中级别（${originalData.elevationLevel}级）不符`,
                    trigger: 'onChange'
                  }
                ]"
              >
                <template #label>
                  <p>
                    作业等级<van-icon
                      class="wem-icon"
                      name="warning-o"
                      size="16"
                      @click="workLevelDescVisib = true"
                    />
                  </p>
                </template>
                <template #input>
                  <template v-if="editing">
                    <van-radio-group
                      v-model="value.elevationLevelType"
                      direction="horizontal"
                      @change="n => workElevationInput(value.workElevation)"
                    >
                      <van-radio name="1">A类分级法</van-radio>
                      <van-radio name="2">B类分级法</van-radio>
                    </van-radio-group>
                  </template>
                  <template v-else>
                    <p v-if="value.elevationLevelType">
                      {{
                        `${
                          value.elevationLevelType === "1" ? "A" : "B"
                        }类分级法`
                      }}
                    </p>
                  </template>
                  <p>{{ workElevationLevelText }}</p>
                </template>
              </van-field>
              <van-popup
                v-model="workLevelDescVisib"
                closeable
                position="bottom"
                :style="{ height: '100%' }"
                get-container="#app"
              >
                <div style="padding: 16px;">
                  <h2 style="padding: 16px; text-align: center;">作业等级</h2>
                  <table class="work-level-desc-table">
                    <thead>
                      <tr class="tit">
                        <td rowspan="2">分类法</td>
                        <td colspan="4">高处作业高度/m</td>
                      </tr>
                      <tr>
                        <!-- &gt; -->
                        <td>2≤h≤5</td>
                        <td>5&lt;h≤15</td>
                        <td>15&lt;h≤30</td>
                        <td>h>30m</td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>A</td>
                        <td>Ⅰ</td>
                        <td>Ⅱ</td>
                        <td>Ⅲ</td>
                        <td>Ⅳ</td>
                      </tr>
                      <tr>
                        <td>B</td>
                        <td>Ⅱ</td>
                        <td>Ⅲ</td>
                        <td>Ⅳ</td>
                        <td>Ⅳ</td>
                      </tr>
                    </tbody>
                  </table>
                  <div style="margin: 16px 0 10px; line-height: 20px;">
                    <p
                      style="margin: 0 0 8px; text-indent: 2em; font-weight: bold;"
                    >
                      不存在以下列出的任一种客观危险因素的高处作业按A类法分级，存在以下列出的一种或一种以上客观危险因素的高处作业按B类法分级。
                    </p>
                    <p>a）阵风风力五级（风速8.0m/s）以上；</p>
                    <p>
                      b）GB/T 4200规定的Ⅱ级或Ⅱ级以上的高温作业；
                    </p>
                    <p>c）平均气温等于或低于5°C的作业环境；</p>
                    <p>d）接触冷水温度等于或低于12°C的作业；</p>
                    <p>e）作业场地有冰、雪、霜、油、水等易滑物；</p>
                    <p>f）作业场所光线不足或能见度差；</p>
                    <p>
                      g）作业活动范围与危险电压带电体距离小于下表的规定：
                    </p>
                  </div>
                  <table class="work-level-desc-table">
                    <thead>
                      <tr class="tit">
                        <td colspan="2">
                          表1 作业活动范围与危险电压带电体的距离
                        </td>
                      </tr>
                      <tr class="tit">
                        <td>危险电压带电体的电压等级/KV</td>
                        <td>距离/m</td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>≤10</td>
                        <td>1.7</td>
                      </tr>
                      <tr>
                        <td>35</td>
                        <td>2.0</td>
                      </tr>
                      <tr>
                        <td>63~110</td>
                        <td>2.5</td>
                      </tr>
                      <tr>
                        <td>220</td>
                        <td>4.0</td>
                      </tr>
                      <tr>
                        <td>330</td>
                        <td>5.0</td>
                      </tr>
                      <tr>
                        <td>500</td>
                        <td>6.0</td>
                      </tr>
                    </tbody>
                  </table>
                  <div style="margin: 16px 0 10px; line-height: 20px;">
                    <p>
                      h）摆动，立足处不是平面或只有很小的平面，即任一边小于500mm的矩形平面，直径小于500mm的圆形平面或具有类似尺寸的其他形状的平面，致使作业者无法维持正常姿势；
                    </p>
                    <p>
                      i）GB 3869规定的Ⅲ级或Ⅲ级以上的体力劳动强度；
                    </p>
                    <p>
                      j）存在有毒气体或空气中含氧量低于19.5%的作业环境；
                    </p>
                    <p>
                      k）可能会引起各种灾害事故的作业环境和抢救突然发生的各种灾害事故。
                    </p>
                  </div>
                </div>
              </van-popup>
            </template>

            <!--吊装作业:	 吊物内容
                         吊装级别	下拉选择：一级、二级、三级
                         吊物质量（t）
                         吊具名称
           -->
            <template v-if="WORKTICKET_TYPE.hoisting == specialJobType">
              <RadioEditField
                v-model="value.hoistingMethod"
                name="hoistingMethod"
                label="吊装方式"
                v-bind="fieldProps"
                :editing="editing"
                :options="hoistingMethodSltData"
                :rules="[
                  {
                    required: true,
                    message: '请选择',
                    trigger: 'onChange'
                  }
                ]"
                @change="hoistingMethodChange"
              />
              <van-field
                v-show="value.hoistingMethod !== '0'"
                class="custom-input-person"
                readonly
                :is-link="editing"
                :clickable="editing"
                autosize
                :value="hoistingGetPersonLabel(value.hoistingCommandList)"
                name="hoistingCommandList"
                label="指挥人员"
                placeholder=""
                v-bind="fieldProps"
                :rules="[
                  {
                    required: value.hoistingMethod === '1',
                    message: '请选择指挥人员',
                    trigger: 'onChange'
                  }
                ]"
                @click="editing && (hoistingCommandListVisible = true)"
              >
                <template #input>
                  <p v-if="value.hoistingCommandList">
                    <span
                      v-for="item of value.hoistingCommandList"
                      :key="item.value"
                      class="person-box"
                    >
                      {{ item.label }}
                    </span>
                  </p>
                  <p v-if="value.replaceHoistingCommandList">
                    <span
                      v-for="item of value.replaceHoistingCommandList"
                      :key="item.id"
                      class="person-box replace-color"
                    >
                      {{ item.workerName }}
                    </span>
                  </p>
                </template>
              </van-field>
              <!-- <WorkticketPersonPicker
                ref="personPicker3"
                v-model="value.hoistingCommandList"
                title="指挥人员"
                :multiple="true"
                :work-type-id="id"
                :contractor-status="value.contractorStatus"
                :dept-code="value.deptCode"
                :special-id="value.id"
                :is-hoisting="true"
                :show-license-tag="true"
                :license-types="licenseTypesSwitch(11)"
                @change="hositingPersonConcat"
              /> -->
              <select-person-by-tabs
                title="指挥人员(多选)"
                :visible="hoistingCommandListVisible"
                :show-department-name="false"
                :show-phone-number="true"
                position="bottom"
                :include-children="true"
                :multiple="true"
                :is-department-auth="false"
                :default-department="department"
                :default-contractor="
                  originalData.contractorStatus ? originalData.deptCode : ''
                "
                :choose-value="value.hoistingCommandList"
                :license-types="licenseTypesSwitch(11)"
                recent-type="11"
                contractor-worker-type="11"
                :work-type-id="id"
                :show-recent-use-person="true"
                :default-tab-name="2"
                @close="hoistingCommandListVisible = false"
                @confirm="
                  (ids, list) =>
                    onPersonConfirm(ids, list, 'hoistingCommandList')
                "
              />
              <van-field
                v-show="value.hoistingMethod !== '0'"
                class="custom-input-person"
                readonly
                :is-link="editing"
                :clickable="editing"
                autosize
                :value="hoistingGetPersonLabel(value.hoistingPersonList)"
                name="hoistingPersonList"
                label="吊装作业人"
                placeholder=""
                v-bind="fieldProps"
                :rules="[
                  {
                    required: value.hoistingMethod === '1',
                    message: '请选择吊装作业人',
                    trigger: 'onChange'
                  }
                ]"
                @click="editing && (hoistingPersonListVisible = true)"
              >
                <template #input>
                  <p v-if="value.hoistingPersonList">
                    <span
                      v-for="item of value.hoistingPersonList"
                      :key="item.value"
                      class="person-box"
                    >
                      {{ item.label }}
                    </span>
                  </p>
                  <p v-if="value.replaceHoistingPersonList">
                    <span
                      v-for="item of value.replaceHoistingPersonList"
                      :key="item.id"
                      class="person-box replace-color"
                    >
                      {{ item.workerName }}
                    </span>
                  </p>
                </template>
              </van-field>
              <!-- <WorkticketPersonPicker
                ref="personPicker4"
                v-model="value.hoistingPersonList"
                title="吊装作业人"
                :multiple="true"
                :work-type-id="id"
                :contractor-status="value.contractorStatus"
                :dept-code="value.deptCode"
                :special-id="value.id"
                :is-hoisting="true"
                :show-license-tag="true"
                :license-types="licenseTypesSwitch(12)"
                @change="hositingPersonConcat"
              /> -->
              <select-person-by-tabs
                title="吊装作业人(多选)"
                :visible="hoistingPersonListVisible"
                :show-department-name="false"
                :show-phone-number="true"
                position="bottom"
                :include-children="true"
                :multiple="true"
                :is-department-auth="false"
                :default-department="department"
                :default-contractor="
                  originalData.contractorStatus ? originalData.deptCode : ''
                "
                :choose-value="value.hoistingPersonList"
                :license-types="licenseTypesSwitch(12)"
                recent-type="12"
                contractor-worker-type="12"
                :work-type-id="id"
                :show-recent-use-person="true"
                :default-tab-name="2"
                @close="hoistingPersonListVisible = false"
                @confirm="
                  (ids, list) =>
                    onPersonConfirm(ids, list, 'hoistingPersonList')
                "
              />
              <van-field
                v-show="value.hoistingMethod !== '0'"
                class="custom-input-person"
                readonly
                :clickable="editing"
                :is-link="editing"
                autosize
                :value="getPersonLabel(value.riggerPersonList)"
                name="riggerPersonList"
                label="司索人"
                placeholder="请选择人员"
                v-bind="fieldProps"
                :rules="[
                  {
                    required: value.hoistingMethod === '1',
                    message: '请选择司索人',
                    trigger: 'onChange'
                  }
                ]"
                @click="editing && (riggerPersonListVisible = true)"
              >
                <template #input>
                  <p v-if="value.riggerPersonList">
                    <span
                      v-for="item of value.riggerPersonList"
                      :key="item.value"
                      class="person-box"
                    >
                      {{ item.label }}
                    </span>
                  </p>
                  <p v-if="value.replaceRiggerPersonList">
                    <span
                      v-for="item of value.replaceRiggerPersonList"
                      :key="item.id"
                      class="person-box replace-color"
                    >
                      {{ item.workerName }}
                    </span>
                  </p>
                </template>
              </van-field>
              <select-person-by-tabs
                title="司索人(多选)"
                :visible="riggerPersonListVisible"
                :show-department-name="false"
                :show-phone-number="true"
                position="bottom"
                :include-children="true"
                :multiple="true"
                :is-department-auth="false"
                :default-department="department"
                :default-contractor="
                  originalData.contractorStatus ? originalData.deptCode : ''
                "
                :choose-value="value.riggerPersonList"
                :license-types="licenseTypesSwitch(1201)"
                recent-type="1201"
                contractor-worker-type="1201"
                :work-type-id="id"
                :show-recent-use-person="true"
                :default-tab-name="2"
                @close="riggerPersonListVisible = false"
                @confirm="
                  (ids, list) => onPersonConfirm(ids, list, 'riggerPersonList')
                "
              />
              <van-field
                v-model="value.toolName"
                name="toolName"
                label="吊具名称"
                :readonly="!editing"
                v-bind="fieldPropsInput"
                maxlength="100"
                :placeholder="editing ? '请输入' : ''"
                :rules="[
                  {
                    required: false,
                    message: ' ',
                    trigger: 'onChange'
                  }
                ]"
              />
              <van-field
                v-model="value.hoistingContent"
                name="hoistingContent"
                label="吊物内容"
                :readonly="!editing"
                v-bind="fieldPropsInput"
                maxlength="100"
                :placeholder="editing ? '请输入' : ''"
                :rules="[
                  {
                    required: true,
                    message: ' ',
                    trigger: 'onChange'
                  }
                ]"
              />
              <van-field
                v-if="isRH"
                v-model="value.ratedCapacity"
                name="ratedCapacity"
                class="input"
                label="吊具额定吨位（t）"
                :readonly="!editing"
                v-bind="fieldPropsInput"
                maxlength="32"
                :placeholder="editing ? '请输入' : ''"
                :rules="[
                  {
                    required: false,
                    message: ' ',
                    trigger: 'onChange'
                  }
                ]"
              />
              <van-field
                v-model="value.quality"
                name="quality"
                class="input"
                label="吊物质量（t）"
                :readonly="!editing"
                v-bind="fieldPropsInput"
                maxlength="32"
                :placeholder="editing ? '请输入' : ''"
                :rules="[
                  {
                    required: false,
                    message: ' ',
                    trigger: 'onChange'
                  }
                ]"
              />
              <RadioEditField
                v-model="value.level"
                class="hoisting-level-field"
                name="level"
                label="吊装级别"
                :editing="editing"
                :disabled="editing && value.levelEditStatus === 0"
                :options="hoistingLevelOptions"
                v-bind="fieldProps"
                :rules="[
                  {
                    required: true,
                    message: '请选择',
                    trigger: 'onChange'
                  }
                ]"
              />
            </template>

            <!--临时用电:	电源接入点及许可用电功率
                          工作电压
                         用电设备名称及额定功率
            -->
            <template v-if="WORKTICKET_TYPE.temporary_power == specialJobType">
              <van-field
                v-model="value.apn"
                name="apn"
                label="电源接入点及许可用电功率"
                :readonly="!editing"
                v-bind="fieldPropsInput"
                :placeholder="editing ? '请输入' : ''"
                maxlength="100"
                :rules="[
                  {
                    required: false,
                    message: ' ',
                    trigger: 'onChange'
                  }
                ]"
              />

              <van-field
                v-model="value.voltage"
                name="voltage"
                label="工作电压(V)"
                :readonly="!editing"
                v-bind="fieldPropsInput"
                :placeholder="editing ? '请输入' : ''"
                maxlength="32"
                :rules="[
                  {
                    required: false,
                    message: ' ',
                    trigger: 'onChange'
                  }
                ]"
              />

              <van-field
                v-model="value.devicePower"
                name="devicePower"
                label="用电设备名称及额定功率"
                :readonly="!editing"
                :placeholder="editing ? '请输入' : ''"
                v-bind="fieldPropsInput"
                maxlength="32"
                :rules="[
                  {
                    required: false,
                    message: ' ',
                    trigger: 'onChange'
                  }
                ]"
              />

              <!-- <template v-if="projectName === 'RT'">
                <CheckboxEditField
                  v-model="value.specialRequirement"
                  title="特殊要求"
                  :multiple="true"
                  :editing="editing"
                  :options="specialRequirementCheckboxData"
                  option-value-key="id"
                  option-label-key="name"
                />
                <p class="safe-education">实施安全教育人</p>
                <van-field
                  class="custom-input-person safe-education-field"
                  readonly
                  :clickable="editing"
                  :is-link="editing"
                  autosize
                  :value="getPersonLabel(value.deptSafetyEducationList)"
                  name="deptSafetyEducationList"
                  label="属地部门"
                  :placeholder="editing ? '请选择人员' : ''"
                  v-bind="fieldProps"
                  @click="TDWorkerClick()"
                >
                  <template #input>
                    <p v-if="value.deptSafetyEducationList.length">
                      <span class="person-box">{{
                        value.deptSafetyEducationList[0].label
                      }}</span>
                    </p>
                  </template>
                </van-field>
                <select-person
                  title="属地部门(单选)"
                  :visible="TDSelectPersonVisible"
                  :choose-value="value.deptSafetyEducationList"
                  :show-department-name="false"
                  :show-phone-number="true"
                  position="bottom"
                  :multiple="false"
                  department-disabled-key="--"
                  @close="TDSelectPersonVisible = false"
                  @confirm="TDPersonConfirm"
                />
                <van-field
                  class="custom-input-person safe-education-field"
                  readonly
                  :clickable="editing"
                  :is-link="editing"
                  autosize
                  :value="getPersonLabel(value.safetyEducationList)"
                  name="safetyEducationList"
                  label="属地"
                  :placeholder="editing ? '请选择人员' : ''"
                  v-bind="fieldProps"
                  @click="TWorkerClick()"
                >
                  <template #input>
                    <p v-if="value.safetyEducationList.length">
                      <span class="person-box">{{
                        value.safetyEducationList[0].label
                      }}</span>
                    </p>
                  </template>
                </van-field>
                <select-person
                  title="属地(单选)"
                  :visible="TSelectPersonVisible"
                  :choose-value="value.safetyEducationList"
                  :show-department-name="false"
                  :show-phone-number="true"
                  position="bottom"
                  :multiple="false"
                  department-disabled-key="--"
                  @close="TSelectPersonVisible = false"
                  @confirm="TPersonConfirm"
                />
                <van-field
                  class="custom-input-person"
                  readonly
                  :clickable="editing"
                  :is-link="editing"
                  autosize
                  :value="getPersonLabel(value.leaderSafetyEducationList)"
                  name="leaderSafetyEducationList"
                  label="工作负责人"
                  :placeholder="editing ? '请选择人员' : ''"
                  v-bind="fieldProps"
                  @click="editing && showWorkManagerSelectGuarder()"
                >
                  <template #input>
                    <p v-if="value.leaderSafetyEducationList.length">
                      <span class="person-box">{{
                        value.leaderSafetyEducationList[0].label
                      }}</span>
                    </p>
                  </template>
                </van-field>
                <select-person-by-tabs
                  title="工作负责人(单选)"
                  :visible="showWorkManagerPicker"
                  :show-department-name="false"
                  :show-phone-number="true"
                  position="bottom"
                  :multiple="false"
                  :is-department-auth="false"
                  :choose-value="value.leaderSafetyEducationList"
                  @close="showWorkManagerPicker = false"
                  @confirm="
                    (ids, list) =>
                      onPersonConfirm(ids, list, 'leaderSafetyEducationList')
                  "
                />
                <van-field
                  class="custom-input-person"
                  readonly
                  :clickable="editing"
                  :is-link="editing"
                  autosize
                  :value="getPersonLabel(value.contactsList)"
                  name="contactsList"
                  label="联系人"
                  :placeholder="editing ? '请选择人员' : ''"
                  v-bind="fieldProps"
                  @click="editing && showContactSelectGuarder()"
                >
                  <template #input>
                    <p v-if="value.contactsList.length">
                      <span class="person-box">{{
                        value.contactsList[0].label
                      }}</span>
                      <span v-if="value.contactsList[0].workMobile"
                        >({{ value.contactsList[0].workMobile }})</span
                      >
                    </p>
                  </template>
                </van-field>
                <select-person-by-tabs
                  title="联系人(单选)"
                  :visible="showContactPicker"
                  :show-department-name="false"
                  :show-phone-number="true"
                  position="bottom"
                  :multiple="false"
                  :is-department-auth="false"
                  :choose-value="value.contactsList"
                  @close="showContactPicker = false"
                  @confirm="
                    (ids, list) => onPersonConfirm(ids, list, 'contactsList')
                  "
                />
              </template> -->
            </template>

            <template v-if="WORKTICKET_TYPE.ground == specialJobType">
              <!--动土作业:	作业范围、内容、方式
	           -->

              <van-field
                v-model="value.groundScope"
                name="devicePower"
                label="作业范围"
                :readonly="!editing"
                v-bind="fieldPropsInput"
                maxlength="500"
                :placeholder="editing ? '请输入' : ''"
                :rules="[
                  {
                    required: false,
                    message: ' ',
                    trigger: 'onChange'
                  }
                ]"
              />

              <van-field
                v-model="value.content"
                name="content"
                label="作业内容"
                :readonly="!editing"
                v-bind="fieldPropsInput"
                maxlength="500"
                :placeholder="editing ? '请输入' : ''"
                :rules="[
                  {
                    required: false,
                    message: ' ',
                    trigger: 'onChange'
                  }
                ]"
              />

              <RadioEditField
                v-model="value.groundWay"
                name="groundWay"
                label="作业方式"
                :editing="editing"
                :options="workTypeSltData"
                v-bind="fieldProps"
                :rules="[
                  {
                    required: true,
                    message: '请选择',
                    trigger: 'onChange'
                  }
                ]"
              />
              <van-field
                label="作业简图"
                :readonly="!editing"
                v-bind="fieldProps"
                :rules="[
                  {
                    required: false,
                    message: '请上传附件',
                    trigger: 'onChange'
                  }
                ]"
              >
                <template #input>
                  <FileUploader
                    v-model="value.attachmentList"
                    :max-count="1"
                    :unable-upload="!editing"
                    :accept="FILE_ACCEPT_TYPES.FILE"
                  />
                </template>
              </van-field>
            </template>

            <template v-if="WORKTICKET_TYPE.breaker == specialJobType">
              <!--断路作业:	断路原因
	                        断路示意图及相关说明	附件
	           -->
              <van-field
                v-if="isBy"
                v-model="value.involveOrg"
                name="involveOrg"
                label="涉及相关单位"
                :readonly="!editing"
                v-bind="fieldPropsInput"
                maxlength="100"
                :placeholder="editing ? '请输入' : ''"
                :rules="[
                  {
                    required: false,
                    message: ' ',
                    trigger: 'onChange'
                  }
                ]"
              />
              <van-field
                v-model="value.cause"
                name="cause"
                label="断路原因"
                :readonly="!editing"
                v-bind="fieldPropsInput"
                maxlength="500"
                :placeholder="editing ? '请输入' : ''"
                :rules="[
                  {
                    required: false,
                    message: ' ',
                    trigger: 'onChange'
                  }
                ]"
              />

              <van-field
                label="断路示意图及相关说明"
                :readonly="!editing"
                v-bind="fieldProps"
                :rules="[
                  {
                    required: false,
                    message: '请上传附件',
                    trigger: 'onChange'
                  }
                ]"
              >
                <template #input>
                  <FileUploader
                    v-model="value.attachmentList"
                    :max-count="1"
                    :unable-upload="!editing"
                    :accept="FILE_ACCEPT_TYPES.FILE"
                  />
                </template>
              </van-field>
            </template>

            <!--
              管线打开:	设备管道名称
                      介质
                      温度
                      压力
                      管线打开位置示意图
            -->
            <template v-if="WORKTICKET_TYPE.pipeline == specialJobType">
              <van-field
                v-model="value.pipelineName"
                name="pipelineName"
                label="设备管道名称"
                :readonly="!editing"
                v-bind="fieldPropsInput"
                maxlength="100"
                :placeholder="editing ? '请输入' : ''"
              />

              <van-field
                v-model="value.mediumName"
                name="mediumName"
                label="介质"
                :readonly="!editing"
                v-bind="fieldPropsInput"
                maxlength="100"
                :placeholder="editing ? '请输入' : ''"
                :rules="[
                  {
                    required: false,
                    message: ' ',
                    trigger: 'onChange'
                  }
                ]"
              />

              <van-field
                v-model="value.temperature"
                name="temperature"
                label="温度°C"
                :readonly="!editing"
                maxlength="32"
                :placeholder="editing ? '请输入' : ''"
                v-bind="fieldPropsInput"
                :rules="[
                  {
                    required: projectName !== 'RT',
                    message: ' ',
                    trigger: 'onSubmit'
                  }
                ]"
              />

              <van-field
                v-model="value.pressure"
                name="pressure"
                label="压力"
                :readonly="!editing"
                maxlength="32"
                :placeholder="editing ? '请输入' : ''"
                v-bind="fieldPropsInput"
                :rules="[
                  {
                    required: projectName !== 'RT',
                    message: ' ',
                    trigger: 'onSubmit'
                  }
                ]"
              />

              <RadioEditField
                v-model="value.pressureUnit"
                name="pressureUnit"
                label="压力单位"
                v-bind="fieldProps"
                :editing="editing"
                :options="[
                  { label: 'Mpa', value: 'Mpa' },
                  { label: 'Kpa', value: 'Kpa' }
                ]"
              />

              <van-field
                label="管线打开位置示意图"
                :readonly="!editing"
                v-bind="fieldProps"
                :rules="[
                  {
                    required: false,
                    message: '请上传示意图',
                    trigger: 'onChange'
                  }
                ]"
              >
                <template #input>
                  <FileUploader
                    v-model="value.attachmentList"
                    :max-count="1"
                    :unable-upload="!editing"
                    :accept="FILE_ACCEPT_TYPES.FILE"
                  />
                </template>
              </van-field>
            </template>

            <!--
              一般检修:	作业类型：专项、其他
            -->
            <template v-if="WORKTICKET_TYPE.overhaul == specialJobType">
              <CheckboxEditField
                v-model="value.typeSpecial"
                title="作业类型(专项)"
                :multiple="true"
                :editing="editing"
                :options="overhaulSpecialOptions"
                :rules="[{ required: false }]"
              />
              <CheckboxEditField
                v-model="value.typeOther"
                title="作业类型(其他)"
                :multiple="true"
                :editing="editing"
                :options="overhaulOtherOptions"
                :rules="[{ required: false }]"
                @change="typeOtherChange"
              >
                <template slot="tagContent" slot-scope="scope">
                  <span
                    v-if="!editing && scope.item.value === '160' && value.other"
                    >{{ `${scope.item.label}-${value.other}` }}</span
                  >
                </template>
                <InputEditCell
                  v-if="editing && value.typeOther.includes('160')"
                  v-model.trim="value.other"
                  :editing="editing"
                  placeholder="其他"
                  maxlength="50"
                  :rules="[{ required: true }]"
                />
              </CheckboxEditField>
            </template>

            <template v-if="WORKTICKET_TYPE.repair == specialJobType">
              <van-field
                v-model="value.content"
                name="content"
                label="检修内容与要求"
                :readonly="!editing"
                v-bind="fieldPropsInput"
                maxlength="50"
                :placeholder="editing ? '请输入' : ''"
                :rules="[
                  {
                    required: true,
                    message: ' ',
                    trigger: 'onChange'
                  }
                ]"
              />
              <van-field
                v-model="value.deviceBit"
                name="deviceBit"
                label="设备/位号"
                :readonly="!editing"
                v-bind="fieldPropsInput"
                maxlength="50"
                :placeholder="editing ? '请输入' : ''"
                :rules="[
                  {
                    required: true,
                    message: ' ',
                    trigger: 'onChange'
                  }
                ]"
              />
              <van-field
                v-model="value.medium"
                name="medium"
                label="介质"
                :readonly="!editing"
                v-bind="fieldPropsInput"
                maxlength="32"
                :placeholder="editing ? '请输入' : ''"
                :rules="[
                  {
                    required: true,
                    message: ' ',
                    trigger: 'onChange'
                  }
                ]"
              />
              <van-field
                v-model="value.pressure"
                name="pressure"
                label="压力"
                :readonly="!editing"
                v-bind="fieldPropsInput"
                maxlength="32"
                :placeholder="editing ? '请输入' : ''"
                :rules="[
                  {
                    required: false,
                    message: ' ',
                    trigger: 'onChange'
                  }
                ]"
              />
              <van-field
                v-model="value.temperature"
                name="temperature"
                label="温度"
                :readonly="!editing"
                v-bind="fieldPropsInput"
                maxlength="32"
                :placeholder="editing ? '请输入' : ''"
                :rules="[
                  {
                    required: false,
                    message: ' ',
                    trigger: 'onChange'
                  }
                ]"
              />
            </template>

            <CheckboxEditFieldWorkHazard
              v-model="value.workHazardIdList"
              title="危害辨识"
              :extra="value.extendHazard"
              :editing="editing"
              :options="workHazardOptionts"
            >
              <InputEditCell
                v-show="editing"
                v-model="value.extendHazard"
                :editing="editing"
                placeholder="其他危害"
              />
            </CheckboxEditFieldWorkHazard>

            <CheckboxEditCell
              v-model="value.safetyMeasureListObjList"
              :v-model.sync="value.safetyMeasureListStrs"
              :editing="editing"
              :options="safetyMeasureListOptions"
              :force-measure-list="value.forceMeasureList"
              title="安全措施"
            >
            </CheckboxEditCell>

            <div class="extend-safety-measure">
              <div class="extend-safety-measure-title">补充措施</div>
              <div
                v-for="(item, index) in value.extendSafetyMeasure"
                :key="item.id"
                class="extend-safety-item"
              >
                <InputEditCell
                  v-show="editing"
                  v-model="item.name"
                  :editing="editing"
                  placeholder="补充措施"
                  maxlength="500"
                  :rules="[
                    {
                      required: extendSafetyMeasureRequired
                    }
                  ]"
                />
                <div v-if="!editing" class="safety-item">
                  <span class="dec">{{ item.name }}</span>
                  <!-- <span
                    v-if="item.operatorName"
                    class="safety-item-name"
                    @click="previewSign(item.fileId)"
                    >{{ item.operatorName }}<van-icon name="success"
                  /></span> -->
                  <Tag
                    v-if="item.operatorName"
                    :type="tagTypeSwitch(item)"
                    :tick="!!item.fileId"
                    @click.native="previewSign(item.fileId)"
                  >
                    {{ item.operatorName }}
                  </Tag>
                  <div
                    v-if="(item.pictureFileIdList || []).length"
                    class="upload-image-box"
                    @click="uploadPopClick(item)"
                  >
                    <div class="content">
                      <van-icon name="photograph" size="16" color="#1678FF" />
                      <span class="num">
                        {{ (item.pictureFileIdList || []).length }}
                      </span>
                    </div>
                  </div>
                </div>
                <span
                  v-if="editing && index !== 0"
                  class="remove-safety"
                  @click="removeSafety(item, index)"
                >
                  移除
                </span>
              </div>
              <div v-if="editing" class="add-extend-safety-box">
                <div class="add-extend-safety" @click="addSafety">
                  <van-icon name="plus" />添加补充措施
                </div>
              </div>
            </div>

            <van-cell
              v-if="value.uuserName && value.utime"
              :value="`（${value.uuserName} - ${value.utime}）`"
            />
          </template>
        </van-skeleton>
      </van-form>
    </van-collapse-item>
    <cameraUpload
      :visible="cameraUploadVb"
      position="bottom"
      mode-type="1"
      :files="rowFiles"
      @close="cameraUploadVb = false"
    />
    <select-camera-popup
      :visible="selectCameraPopupVisible"
      :video-id="selectPopupInfo.videoId"
      :video-type="selectPopupInfo.videoType"
      :video-name="selectPopupInfo.videoName"
      :poi-id="workInfo.facilityId"
      @close="selectCameraPopupVisible = false"
      @confirm="onSelectCameraConfirm"
    />
    <live-streaming-dialog
      v-model="playerDialogInfo.liveVisible"
      :video-id="playerDialogInfo.videoId"
      :video-name="playerDialogInfo.videoName"
      :video-type="playerDialogInfo.videoType"
      @close="playerDialogInfo.liveVisible = false"
    />
    <playback-popup
      :visible="playerDialogInfo.playbackVisible"
      :video-id="playerDialogInfo.videoId"
      :video-name="playerDialogInfo.videoName"
      :video-type="playerDialogInfo.videoType"
      :real-start="workInfo.realStart"
      :real-end="workInfo.realEnd"
      @close="playerDialogInfo.playbackVisible = false"
    />
  </div>
</template>

<script>
import InputEditCell from "@/components/edit/InputEditCell";
import {
  checkedGasExpires,
  getWorkHazardSafetyMeasure,
  getOverhaulSpecial,
  getOverhaulOther,
  getWorkticketTypeDetail,
  updateSpecialWorks,
  getSpecialRequirement,
  getElevationLevel,
  getElevationRange,
  getHoistingLevel,
  getHoistingMethodSltData,
  getCoerciveMeasures,
  getWorkSiteTypes,
  getFireWay,
  addFireWay,
  getSpaceType,
  addSpaceType,
  dictionaryDelete,
  getCurrentWorkNode,
  getWorkHazard_equipmentSafety
} from "@/api/workticket";
import EditButton from "@/components/edit/EditButton";
import RadioEditField from "@/components/edit/RadioEditField";
import {
  WORKTICKET_TYPE,
  WORKTICKET_STATUS,
  SPECIAL_TICKETS_EXPORT_WHITE_LIST
} from "@/views/workticket/utils/constant";
import CheckboxEditField from "@/components/edit/CheckboxEditField";
import CheckboxEditFieldWorkHazard from "@/components/edit/CheckboxEditFieldWorkHazard";
import FileUploader from "@/components/fileuploader/FileUploader";
import esign from "@/components/esign";
import { FILE_ACCEPT_TYPES } from "@/components/fileuploader/util";
import { mapState } from "vuex";
import CheckboxEditCell from "@/components/edit/CheckboxEditCell";
import WorkticketPersonPicker from "@/components/picker/WorkticketPersonPicker";
import { cloneDeep, isNil, uniqBy } from "lodash";
import SelectPerson from "@/components/SelectPerson";
import SelectPersonByTabs from "@/components/SelectPersonByTabs";
import { fileUrl } from "@/utils/constant";
import Tag from "@/components/Tag";
import user from "@/utils/login";
import { workIsInFenceByPoiInfo } from "@/api/psm";
import { Dialog, Toast } from "vant";
import cameraUpload from "@/views/workticket/components/cameraUpload.vue";
import LiveStreamingDialog from "@/views/workticket/components/LiveStreamingDialog";
import SelectCameraPopup from "@/views/workticket/components/SelectCameraPopup";
import PlaybackPopup from "@/views/workticket/components/PlaybackPopup";
import SelectPopup from "@/components/SelectPopup";
import InputDialog from "@/components/dialog/InputDialog";

export default {
  name: "WorkticketTypeDetailItem",
  components: {
    PlaybackPopup,
    SelectCameraPopup,
    LiveStreamingDialog,
    SelectPerson,
    SelectPersonByTabs,
    WorkticketPersonPicker,
    CheckboxEditCell,
    FileUploader,
    CheckboxEditFieldWorkHazard,
    CheckboxEditField,
    RadioEditField,
    EditButton,
    InputEditCell,
    Tag,
    cameraUpload,
    SelectPopup
  },
  model: {
    prop: "value",
    event: ["change", "editable-change"]
  },
  props: {
    id: { type: [String, Number], default: "" },
    title: { type: String, default: "" },
    name: { type: String, default: "" },
    typeName: { type: String, default: "" },
    workName: { type: String, default: "" },
    ticketId: { type: String, default: "" }, //作业id
    ticketStatus: { type: [String, Number], default: "" }, //作业状态
    specialId: { type: String, default: "" }, //特殊作业specialId
    specialJobType: { type: String, default: "" }, //特殊作业类型specialJobType
    gasMonitor: { type: [String, Number], default: "" }, //是否有气体检测，1=有气体检测
    enableApprove: { type: [String, Number], default: "" }, //是否有能审批，1=能
    approveType: null,
    gasMonitorWorker: { type: [String, Number], default: "" }, //是否当前气体检测人员
    department: { type: String, default: "" }, //基本信息里面的所属部门id
    userEditAuthority: { type: Boolean }, // 编辑权限
    proposer: { type: String, default: "" }, // 当前申请人id
    originalData: {
      type: Object,
      default: () => ({})
    },
    workJsaInfo: {
      type: Object
    },
    workInfo: Object,
    isWorkInspect: { type: Boolean, default: false },
    workTypeIndex: { type: Number, default: 0 }
  },
  inject: ["reload"],
  data() {
    return {
      projectName: window.globalConfig.VUE_APP_NAME,
      leaderPicker: false,
      showAnalystPicker: false,
      powerWorkerListVisible: false,
      powerWorkerLeaderListVisible: false,
      workSiteTypeVisible: false,
      editing: false,
      value: null,
      WORKTICKET_TYPE,
      WORKTICKET_STATUS,
      SPECIAL_TICKETS_EXPORT_WHITE_LIST,
      fieldProps: {
        border: true,
        placeholder: "请输入",
        clearable: true,
        "input-align": "right",
        "error-message-align": "right"
      },
      fieldPropsInput: {
        class: "input",
        border: true,
        placeholder: "请输入",
        clearable: true,
        "input-align": "right",
        "error-message-align": "right",
        rules: [{ required: true, message: " ", trigger: "onBlur" }] //message不能是空字符串，必须有值，空格字符串也行
      },
      FILE_ACCEPT_TYPES,
      workHazardOptionts: [],
      safetyMeasureListOptionsMap: {},
      safetyMeasureListOptions: [],
      isShowAllDesc: false,
      showGuarderPicker: false,
      hoistingCommandListVisible: false,
      hoistingPersonListVisible: false,
      riggerPersonListVisible: false,
      showPUCPicker: false,
      overhaulSpecialOptions: [],
      overhaulOtherOptions: [],
      // specialRequirementCheckboxData: [],
      // TDSelectPersonVisible: false,
      // TSelectPersonVisible: false,
      // showWorkManagerPicker: false,
      // showContactPicker: false,
      workElevationLevelSltData: {
        1: [
          // {
          //   value: "1",
          //   label: "Ⅰ（2m≤h≤5m）"
          // },
          // {
          //   value: "2",
          //   label: "Ⅱ（5m<h≤15m）"
          // },
          // {
          //   value: "3",
          //   label: "Ⅲ（15m<h≤30m）"
          // },
          // {
          //   value: "4",
          //   label: "Ⅳ（h>30m）"
          // }
        ],
        2: [
          // {
          //   value: "2",
          //   label: "Ⅱ（2m≤h≤5m）"
          // },
          // {
          //   value: "3",
          //   label: "Ⅲ（5m<h≤15m）"
          // },
          // {
          //   value: "4",
          //   label: "Ⅳ（15m<h≤30m）"
          // },
          // {
          //   value: "5", // 这个值在需求内也是Ⅳ级
          //   label: "Ⅳ（h>30m）"
          // }
        ]
      },
      workElevationLevelText: "",
      workLevelDescVisib: false,
      hoistingLevelOptions: [],
      hoistingMethodSltData: [],
      cameraUploadVb: false,
      rowFiles: [],
      selectCameraPopupVisible: false,
      selectPopupInfo: { videoId: "", videoType: "", videoName: "" },
      playerDialogInfo: {
        liveVisible: false,
        playbackVisible: false,
        videoId: "",
        videoName: "",
        videoType: ""
      },
      workSiteTypes: [],
      fireWayOptionts: [],
      workSpaceTypeOptionts: [],
      dictionaryMap: {
        [WORKTICKET_TYPE.fire]: {
          title: "添加动火方式",
          formAttr: "fireWayList",
          listName: "fireWayOptionts",
          addApi: addFireWay
        },
        [WORKTICKET_TYPE.space]: {
          title: "添加受限空间作业类型",
          formAttr: "spaceTypeList",
          listName: "workSpaceTypeOptionts",
          addApi: addSpaceType
        }
      },
      isRH: window.globalConfig.VUE_APP_WORK_RH === "RH",
      workTypeSltData: [
        {
          label: "人工开挖",
          value: "1"
        },
        {
          label: "机械开挖",
          value: "2"
        },
        {
          label: "其他",
          value: "3"
        }
      ],
      extendSafetyMeasureRequired:
        window.globalConfig.VUE_APP_WORK_EXTEND_SAFETY_MEASURE_REQUIRED ===
        "true",
      normalPersonPickerVisible: false,
      workerPickerVisible: false,
      codeMap_equipmentSafety: {
        "16": "1001",
        "19": "1002"
      }
    };
  },
  computed: {
    exportSTSwitch() {
      // const exportWorkSpecialJobTypeWhitelist = ["0", "1", "2", "3", "6", "7"];
      // const isTicket = exportWorkSpecialJobTypeWhitelist.includes(
      //   this.specialJobType
      // );
      const isStatus = SPECIAL_TICKETS_EXPORT_WHITE_LIST.includes(
        this.ticketStatus
      );
      // return isTicket && isStatus;
      return isStatus;
    },
    guarderLabel() {
      // if (this.specialJobType === this.WORKTICKET_TYPE.temporary_power) {
      //   return this.value.guarderList.map(a => a.label).join(" ");
      // }
      return this.getPersonLabel(this.value.guarderList);
    },
    // guarder() {
    //   const guarderText = "监护人";
    //   const guarderObj = {
    //     [this.WORKTICKET_TYPE.temporary_power]: "用电人"
    //   };
    //   return guarderObj[this.specialJobType] || guarderText;
    // },
    // fireWayOptionts() {
    //   return this.$store.state.workticket.fireWayOptionts;
    // },
    // workSpaceTypeOptionts() {
    //   return this.$store.state.workticket.workSpaceTypeOptionts;
    // },
    showGasCheckBtn() {
      //只有[待气体检测/待安全交底/审批中/作业中/暂停/待作业]的检测人能够新建，其他情况只能查看
      const statusArr = [
        WORKTICKET_STATUS.Gas_monitor,
        WORKTICKET_STATUS.Safety_disclosure,
        WORKTICKET_STATUS.Safety_disclosureing,
        WORKTICKET_STATUS.Approving,
        WORKTICKET_STATUS.Working,
        WORKTICKET_STATUS.PendingJob,
        WORKTICKET_STATUS.Paused,
        WORKTICKET_STATUS.ApprovingPassOrBack
      ];
      if (this.gasMonitor == 1) {
        if (this.gasMonitorWorker == 1) {
          return statusArr.includes(this.ticketStatus);
        }
        return false;
      }
      return false;
    },
    showGasHistoryBtn() {
      //只有[待气体检测/待安全交底/审批中/作业中/暂停/待作业]的检测人能够新建，其他情况只能查看
      const statusArr = [
        WORKTICKET_STATUS.Gas_monitor,
        WORKTICKET_STATUS.Safety_disclosure,
        WORKTICKET_STATUS.Safety_disclosureing,
        WORKTICKET_STATUS.Approving,
        WORKTICKET_STATUS.Working,
        WORKTICKET_STATUS.PendingJob,
        WORKTICKET_STATUS.Paused,
        WORKTICKET_STATUS.ApprovingPassOrBack
      ];
      if (this.gasMonitor == 1 && this.originalData.gasMonitorResult !== null) {
        if (this.ticketStatus >= WORKTICKET_STATUS.Gas_monitor) {
          if (this.gasMonitorWorker != 1) return true;
          return !statusArr.includes(this.ticketStatus);
        }
        return false;
      }
      return false;
      // return (
      //   this.gasMonitor == 1 &&
      //   this.gasMonitorWorker != 1 &&
      //   this.ticketStatus >= WORKTICKET_STATUS.Gas_monitor &&
      //   this.originalData.gasMonitorResult !== null
      // );
    },
    showEditBtn() {
      const arr = [
        WORKTICKET_STATUS.Analysing,
        WORKTICKET_STATUS.Safety_disclosure,
        WORKTICKET_STATUS.Craft_analysing,
        WORKTICKET_STATUS.Overhaul_analysing
      ];
      let index = arr.includes(this.ticketStatus);
      return index && this.userEditAuthority;
    },

    showShenPiBtn() {
      const arr = [
        WORKTICKET_STATUS.Approving,
        WORKTICKET_STATUS.ApprovingPassOrBack
      ];

      return (
        arr.includes(this.ticketStatus) &&
        this.enableApprove == 1 &&
        !this.originalData.approvedFlag
      );
    },

    // 是否显示转审按钮
    showReferralBtn() {
      // 审批中 并且 申请人是当前登录人
      return (
        WORKTICKET_STATUS.Approving == this.ticketStatus &&
        this.userInfo.id === this.proposer
      );
    },
    discrepancyRecordBtn() {
      const arr = [
        WORKTICKET_STATUS.Working,
        WORKTICKET_STATUS.Delivering,
        WORKTICKET_STATUS.Qualified,
        WORKTICKET_STATUS.Unqualified,
        WORKTICKET_STATUS.Paused,
        WORKTICKET_STATUS.Close
      ];
      return this.specialJobType === "2" && arr.includes(this.ticketStatus);
    },

    ...mapState({
      userInfo: state => state.login.userInfo
    }),
    editingWithStatus19() {
      return this.editing && this.ticketStatus !== 19;
    }
  },
  watch: {
    "value.workHazardIdList": {
      immediate: false,
      handler(val) {
        if (!val || val.length == 0 || this.workHazardOptionts.length == 0) {
          this.safetyMeasureListOptions = [];
          return;
        }

        let list = [];
        this.workHazardOptionts.map(item => {
          if (val.some(v => v == item.value)) {
            // list = list.concat(item.list);
            (item.list || []).forEach(text => {
              if (!list.includes(text)) {
                list.push(text);
              }
            });
          }
        });
        this.safetyMeasureListOptions = list;
      }
    }
  },
  created() {
    // this.$on("activeChange", activeNames => {
    //   if (activeNames.indexOf(this.name) != -1) {
    //     this.$off("activeChange");
    //     //第一次展开加载数据
    //     const { ticketId, specialJobType, value } = this;
    //     if (ticketId && specialJobType && !value) {
    //       this.loadData();
    //     }
    //   }
    // });
    const { ticketId, specialJobType, value } = this;
    if (ticketId && specialJobType && !value) {
      this.loadData();
    }
    if (this.isRH) {
      this.workTypeSltData.splice(2, 0, {
        label: "机械结合人力挖掘",
        value: "4"
      });
    }
  },
  mounted() {},
  methods: {
    onVideoClick(videoInfo) {
      const fn = this.editing ? this.onVideoChoose : this.onPlayerVideo;
      fn.call(this, videoInfo);
    },
    onVideoChoose(videoInfo) {
      if (String(this.value.videoCaptureFlag) === "0") return;
      this.selectCameraPopupVisible = true;
      this.selectPopupInfo.videoId = videoInfo.videoId;
      this.selectPopupInfo.videoType = videoInfo.videoType;
    },
    onPlayerVideo(videoInfo) {
      // 视频采集类型：1-直播  2-回放 null 未配置|未开始
      const videoTags = this.workInfo.videoTags;
      // const videoTags = 2;
      if (videoTags == null) return Toast("不在直播或回放时间");
      this.playerDialogInfo.videoId = videoInfo.videoId;
      this.playerDialogInfo.videoName = videoInfo.videoName;
      this.playerDialogInfo.videoType = videoInfo.videoType;
      if (+videoTags === 1) {
        this.playerDialogInfo.playbackVisible = false;
        this.playerDialogInfo.liveVisible = true;
      }
      if (+videoTags === 2) {
        this.playerDialogInfo.liveVisible = false;
        this.playerDialogInfo.playbackVisible = true;
      }
    },
    onSelectCameraConfirm(checked) {
      this.value.workVideoRelation.videoId = checked?.id ?? "";
      this.value.workVideoRelation.videoName = checked?.videoName ?? "";
      this.value.workVideoRelation.videoType = checked?.activeName ?? "";
    },
    discrepancyRecordClick() {
      const find = this.value.guarderList.find(
        i => i.value === this.userInfo.id
      );
      this.$router.push({
        name: "discrepancyRecord",
        params: {
          match:
            find && WORKTICKET_STATUS.Working === this.ticketStatus ? "1" : "0",
          ticketId: this.ticketId,
          specialTicketId: this.specialId
        }
      });
    },
    // getFileUrl(id, downloadFlag = true) {
    //   return `${window.globalConfig.VUE_APP_FILE_URL}/file/download?downloadFlag=${downloadFlag}&fileName=${id}`;
    // },
    uploadPopClick(rowData) {
      this.rowData = rowData;
      this.rowFiles = (rowData.pictureFileIdList || []).map(i => ({
        // url: this.getFileUrl(i),
        id: i,
        name: `${i}.jpg`
      }));
      this.cameraUploadVb = true;
    },
    typeOtherChange(val, idx) {
      if (!val.includes("160")) {
        this.value.other = "";
      }
    },
    leaderChange(v) {
      // if (this.projectName === "RT" && this.specialJobType === "8") {
      //   const ids = v?.value ? [v.value] : [];
      //   const list = (v?.value ? [v] : []).map(i => {
      //     return {
      //       ...i,
      //       workMobile: i.mobile,
      //       _mechanismType: i.personType
      //     };
      //   });
      //   this.onPersonConfirm(ids, list, "leaderSafetyEducationList");
      // }
      this.value.leader = {
        value: v.personId,
        label: v.personName,
        type: v.personType
      };
    },
    workElevationLevelValidator() {
      if (!this.originalData.elevationLevel) return true;
      const level = this.value.elevationLevelId.split("-")[0];
      return this.originalData.elevationLevel === level;
    },
    workElevationLevelSwitch() {
      const arr =
        this.workElevationLevelSltData[this.value.elevationLevelType] || [];
      const level = this.value.elevationLevelId;
      this.workElevationLevelText =
        arr.find(i => i.value === level)?.label || "";
    },
    workElevationInput(val) {
      val = Number(val);
      if (val < 2 || isNaN(val)) {
        this.value.elevationLevelId = "";
        return;
      }
      let level = "";
      let label = "";
      if (val >= 2 && val <= 5) {
        level = 1;
        label = 1;
      } else if (val <= 15) {
        level = 2;
        label = 2;
      } else if (val <= 30) {
        level = 3;
        label = 3;
      } else if (val > 30) {
        level = 4;
        label = 4;
      }
      if (this.value.elevationLevelType === "2") {
        level++;
      }
      if (level > 4) {
        level = 4;
      }
      this.value.elevationLevelId = `${level}-${label}`;
      this.workElevationLevelSwitch();
    },
    workElevationValidator(value, rule) {
      const val = Number(value);
      if (isNaN(val)) return false;
      const numArr = value.split(".");
      if (numArr?.[0]?.length > 3 || numArr?.[1]?.length > 2) return false;
      const integer = Number(numArr[0]) || 0;
      const decimal = Number(numArr[1]) || 0;
      return integer >= 2 && integer <= 999 && decimal <= 99;
    },
    licenseTypesSwitch(workerType) {
      if (!this.originalData.workTypeLicenseList) return "";
      const find = (this.originalData.workTypeLicenseList || []).find(t => {
        return Number(t.workerType) === Number(workerType);
      });

      if (find) {
        return find.licenseTypeList
          .map(i => {
            const t = i.replace(/,/g, "-");
            return t;
          })
          .toString();
      }
      return "";
    },
    powerWorkerListClick() {
      if (this.editing) {
        this.powerWorkerListVisible = true;
      }
    },
    powerWorkerLeaderListClick() {
      if (this.editing) {
        this.powerWorkerLeaderListVisible = true;
      }
    },
    // TDWorkerClick() {
    //   if (this.editing) {
    //     this.TDSelectPersonVisible = true;
    //   }
    // },
    // TWorkerClick() {
    //   if (this.editing) {
    //     this.TSelectPersonVisible = true;
    //   }
    // },
    previewSign(fileId) {
      if (!fileId) return;
      esign.showImage(`${fileUrl}${fileId}`);
    },
    removeSafety(item, index) {
      this.value.extendSafetyMeasure.splice(index, 1);
    },
    addSafety() {
      if (!this.value.extendSafetyMeasure) {
        this.value.extendSafetyMeasure = [];
      }
      const l = this.value.extendSafetyMeasure.length;
      const last = this.value.extendSafetyMeasure[l - 1];
      if (last && !last.name) {
        this.$toast("请输入补充措施之后再添加");
        return;
      }
      this.value.extendSafetyMeasure.push({
        name: ""
      });
    },
    exportSpecialTickets(SpecialTicketsData) {
      const psmPath = window.globalConfig.VUE_APP_API_PSM_PATH;
      const token = user.getToken();
      const url = `${psmPath}/preview?id=${this.specialId}&type=${this.id}&token=${token}`;
      window.open(url, "_blank");
      // window.location.href = url;

      // if (!window?.android?.downLoadFile) {
      //   this.$toast.fail("此版本不支持导出，请更新后使用");
      //   return;
      // }
      // const fileName = `${this.workName}-${this.typeName}.docx`;
      // const psmPath = window.globalConfig.VUE_APP_API_PSM_PATH;
      // const url = `${psmPath}/print/work_tickets?specialTicketId=${this.specialId}&specialType=${this.id}`;
      // window.android.downLoadFile(url, fileName);
    },
    workSiteTypeConfirm(item) {
      this.value.workSiteType = item.value;
    },
    // TDPersonConfirm(ids, list) {
    //   this.value.deptSafetyEducationList = list.map(i => {
    //     return {
    //       label: i.label || "",
    //       value: i.value || "",
    //       name: i.label || "",
    //       id: i.value || "",
    //       type: "0"
    //     };
    //   });
    // },
    // TPersonConfirm(ids, list) {
    //   this.value.safetyEducationList = list.map(i => {
    //     return {
    //       label: i.label || "",
    //       value: i.value || "",
    //       name: i.label || "",
    //       id: i.value || "",
    //       type: "0"
    //     };
    //   });
    // },
    onPersonConfirm(ids, list, type) {
      // 教育安全的三个人和联系人是单选，但数据保存需要数组，不写入alone内
      const alone = ["leader", "analyst", "productionUnitCommander"];
      const concatList = [
        "hoistingCommandList",
        "hoistingPersonList",
        "riggerPersonList"
      ];
      const p = list.map(item => {
        return {
          ...item,
          name: item.label,
          id: item.value,
          fileId: "",
          type: isNil(item.type) ? item._mechanismType : item.type,
          workMobile: item.workMobile
        };
      });
      if (alone.includes(type)) {
        if (p.length) {
          this.value[type] = p[0];
        } else {
          this.value[type] = {
            id: "",
            name: "",
            fileId: "",
            // 私有
            value: "",
            label: "",
            workMobile: ""
          };
        }
        return;
      }
      if (concatList.includes(type)) {
        this.$nextTick(() => {
          this.hositingPersonConcat();
        });
      }

      this.value[type] = p;
    },
    // 显示选择人员组件
    showSelectGuarder() {
      // 如果是临时作业类型
      // if (this.specialJobType === this.WORKTICKET_TYPE.temporary_power) {
      //   // 选择带部门的组件
      //   this.powerWorkerListVisible = true;
      //   return;
      // }
      // this.$refs.personPicker3.show();
      this.showGuarderPicker = true;
    },
    // showWorkManagerSelectGuarder() {
    //   this.showWorkManagerPicker = true;
    // },
    // showContactSelectGuarder() {
    //   this.showContactPicker = true;
    // },
    loadData() {
      const { specialId, specialJobType } = this;
      getWorkticketTypeDetail(specialJobType, specialId).then(async res => {
        this.value = this.dataSwitch(res);
        if (WORKTICKET_TYPE.elevation === specialJobType) {
          await this.methodLevelSltDataSwitch();

          this.workElevationLevelSwitch();
          // this.workElevationInput(this.value.workElevation);
        }
      });
      if (WORKTICKET_TYPE["equipment-safety"] === specialJobType) {
        const params = {
          workTypeId: this.id
        };
        getWorkHazard_equipmentSafety(params).then(res => {
          this.workHazardOptionts = res
            .filter(
              item =>
                item.riskTypeCode ===
                this.codeMap_equipmentSafety[this.ticketStatus]
            )
            .map(item => {
              item.label = item.name;
              item.value = item.id;
              return item;
            });
        });
      } else {
        getWorkHazardSafetyMeasure(this.id).then(list => {
          this.workHazardOptionts = list;
        });
      }
      getOverhaulSpecial().then(res => {
        this.overhaulSpecialOptions = res;
      });
      getOverhaulOther().then(res => {
        this.overhaulOtherOptions = res;
      });
      // if (this.projectName === "RT") {
      //   getSpecialRequirement().then(res => {
      //     this.specialRequirementCheckboxData = res;
      //   });
      // }
      getHoistingLevel().then(res => {
        this.hoistingLevelOptions = res.map(i => {
          return {
            ...i,
            value: i.id,
            label: i.name
          };
        });
      });
      getHoistingMethodSltData().then(res => {
        this.hoistingMethodSltData = res;
      });
      if (WORKTICKET_TYPE.fire == specialJobType) {
        // this.$store.dispatch("getFireWayOptions");
        getFireWay().then(res => {
          this.fireWayOptionts = res;
        });
      }
      if (WORKTICKET_TYPE.space == specialJobType) {
        // this.$store.dispatch("getWorkSpaceTypeOptionts");
        getSpaceType().then(res => {
          this.workSpaceTypeOptionts = res;
        });
      }
      if (specialJobType === WORKTICKET_TYPE.temporary_power) {
        getWorkSiteTypes().then(res => {
          this.workSiteTypes = res;
        });
      }
    },
    dataSwitch(data) {
      if (
        !data.utime &&
        this.workJsaInfo?.workTypeList &&
        this.workJsaInfo?.safetyAnalysis.includes("1")
      ) {
        const personKeySwitch = p => {
          return {
            ...p,
            workerId: p.id,
            workerName: p.name
          };
        };
        const findType = this.workJsaInfo.workTypeList.find(
          i =>
            `${i.workTypeId}-${i.deptCode}` ===
            `${data.workTypeId}-${data.deptCode}`
        );
        let sjp = findType.workerList; // 默认为转换后的对象
        if (Array.isArray(findType.workerList)) {
          // 如果是数组则是未转换的，则进行转换
          const wl = findType.workerList || [];
          sjp = {
            leader: wl.filter(i => i.personType === "1")?.[0] || {},
            analyst: wl.filter(i => i.personType === "5")?.[0] || {},
            productionUnitCommander:
              wl.filter(i => i.personType === "13")?.[0] || {},
            workerList: wl.filter(i => i.personType === "3"),
            ordinaryWorkerList: wl.filter(i => i.personType === "15"),
            guarderList: wl.filter(i => i.personType === "2"),
            hoistingCommandList: wl.filter(i => i.personType === "11"),
            hoistingPersonList: wl.filter(i => i.personType === "12")
          };
        }
        sjp.leader = sjp.leader?.id
          ? {
              ...sjp.leader,
              workerId: sjp.leader.id,
              workerName: sjp.leader.name
            }
          : null;
        sjp.analyst = sjp.analyst?.id
          ? {
              ...sjp.analyst,
              workerId: sjp.analyst.id,
              workerName: sjp.analyst.name
            }
          : null;
        sjp.productionUnitCommander = sjp.productionUnitCommander?.id
          ? {
              ...sjp.productionUnitCommander,
              workerId: sjp.productionUnitCommander.id,
              workerName: sjp.productionUnitCommander.name
            }
          : null;
        sjp.workerList = sjp.workerList.map(personKeySwitch);
        sjp.generalWorkerList = (sjp.ordinaryWorkerList || []).map(
          personKeySwitch
        );
        sjp.guarderList = sjp.guarderList.map(personKeySwitch);
        sjp.hoistingCommandList = sjp.hoistingCommandList.map(personKeySwitch);
        sjp.hoistingPersonList = sjp.hoistingPersonList.map(personKeySwitch);
        data = {
          ...data,
          ...sjp
        };
      }

      data.analyst = {
        value: data.analyst ? data.analyst.workerId || "" : "",
        label: data.analyst ? data.analyst.workerName || "" : "",
        type: data.analyst ? data.analyst.orgType || data.analyst.type : ""
      };
      data.leader = {
        value: data.leader ? data.leader.workerId || "" : "",
        label: data.leader ? data.leader.workerName || "" : "",
        type: data.leader ? data.leader.orgType || data.leader.type : ""
      };
      // data.powerWorker = {
      //   value: data.powerWorker ? data.powerWorker.workerId || "" : "",
      //   label: data.powerWorker ? data.powerWorker.workerName || "" : "",
      //   type: data.powerWorker
      //     ? data.powerWorker.orgType || data.powerWorker.type
      //     : ""
      // };
      data.powerWorkerList = (data.powerWorkerList || []).map(item => {
        return {
          value: item.workerId || "",
          label: item.workerName || "",
          id: item.workerId || "",
          type: item.orgType || item.type
        };
      });
      data.powerWorkerLeaderList = (data.powerWorkerLeaderList || []).map(
        item => {
          return {
            value: item.workerId || "",
            label: item.workerName || "",
            id: item.workerId || "",
            type: item.orgType || item.type
          };
        }
      );
      data.guarderList = (data.guarderList || []).map(item => {
        return {
          value: item.workerId || "",
          label: item.workerName || "",
          id: item.workerId || "",
          type: item.orgType || item.type
        };
      });
      data.workerList = (data.workerList || []).map(item => {
        return {
          value: item.workerId || "",
          label: item.workerName || "",
          id: item.workerId || "",
          type: item.orgType || item.type
        };
      });
      data.generalWorkerList = (data.generalWorkerList || []).map(item => {
        return {
          value: item.workerId || "",
          label: item.workerName || "",
          id: item.workerId || "",
          type: item.orgType || item.type
        };
      });
      data.hoistingCommandList = (data.hoistingCommandList || []).map(item => {
        return {
          value: item.workerId || "",
          label: item.workerName || "",
          nameShowLicense: `${item.workerName}（${item.no}）`,
          id: item.workerId || "",
          type: item.orgType || item.type
        };
      });
      data.hoistingPersonList = (data.hoistingPersonList || []).map(item => {
        return {
          value: item.workerId || "",
          label: item.workerName || "",
          nameShowLicense: `${item.workerName}（${item.no}）`,
          id: item.workerId || "",
          type: item.orgType || item.type
        };
      });
      data.riggerPersonList = (data.riggerPersonList || []).map(item => {
        return {
          value: item.workerId || "",
          label: item.workerName || "",
          nameShowLicense: `${item.workerName}（${item.no}）`,
          id: item.workerId || "",
          type: item.orgType || item.type
        };
      });
      data.productionUnitCommander = {
        value: data.productionUnitCommander
          ? data.productionUnitCommander.workerId || ""
          : "",
        label: data.productionUnitCommander
          ? data.productionUnitCommander.workerName || ""
          : "",
        type: data.productionUnitCommander
          ? data.productionUnitCommander.orgType ||
            data.productionUnitCommander.type
          : ""
      };
      // if (this.projectName === "RT") {
      //   data.specialRequirement = data.specialRequirement
      //     ? data.specialRequirement.split(",")
      //     : [];
      //   data.deptSafetyEducationList = (data.deptSafetyEducationList || []).map(
      //     i => {
      //       return {
      //         ...i,
      //         id: i.workerId,
      //         name: i.workerName,
      //         value: i.workerId,
      //         label: i.workerName,
      //         type: i.orgType
      //       };
      //     }
      //   );
      //   data.safetyEducationList = (data.safetyEducationList || []).map(i => {
      //     return {
      //       ...i,
      //       id: i.workerId,
      //       name: i.workerName,
      //       value: i.workerId,
      //       label: i.workerName,
      //       type: i.orgType
      //     };
      //   });
      //   data.leaderSafetyEducationList = (
      //     data.leaderSafetyEducationList || []
      //   ).map(i => {
      //     return {
      //       ...i,
      //       id: i.workerId,
      //       name: i.workerName,
      //       value: i.workerId,
      //       label: i.workerName,
      //       type: i.orgType
      //     };
      //   });
      //   data.contactsList = (data.contactsList || []).map(i => {
      //     return {
      //       ...i,
      //       id: i.workerId,
      //       name: i.workerName,
      //       value: i.workerId,
      //       label: i.workerName,
      //       workMobile: i.workerTel,
      //       type: i.orgType
      //     };
      //   });
      // }
      data.gasMonitor = data.gasMonitor || 0;
      data.safetyMeasureListObjList = data.safetyMeasureList;
      data.safetyMeasureListStrs = data.safetyMeasureList.map(a => a.name);
      data.type = data.type ? data.type.split(",") : [];
      // 后端没返回属性 加上的
      data.workElevation = data.workElevation || "";
      data.spaceName = data.spaceName || "";
      data.mediumName = data.mediumName || "";
      data.typeSpecial = data.typeSpecial ? data.typeSpecial.split(",") : [];
      data.typeOther = data.typeOther ? data.typeOther.split(",") : [];
      if (!data.elevationLevelType) data.elevationLevelType = "1";
      data.elevationLevelId =
        data.elevationLevelId && data.elevationRange
          ? `${data.elevationLevelId}-${data.elevationRange}`
          : "";
      data.level =
        data.levelEditStatus === 0
          ? this.originalData.hoistingLevel
          : data.level || "";
      data.videoCaptureFlag = data.videoCaptureFlag ?? "";
      data.workVideoRelation = data.workVideoRelation ?? {
        videoName: "",
        videoId: "",
        videoType: ""
      };
      data.workHazardIdList = data.workHazardList
        .filter(o => o.workDicHazardId)
        .map(o => o.workDicHazardId);
      if (
        this.extendSafetyMeasureRequired &&
        (!data.extendSafetyMeasure ||
          (Array.isArray(data.extendSafetyMeasure) &&
            !data.extendSafetyMeasure.length))
      ) {
        data.extendSafetyMeasure = [
          {
            name: "无"
          }
        ];
      }
      return data;
    },
    async methodLevelSltDataSwitch() {
      const resArr = await Promise.all([
        getElevationLevel(),
        getElevationRange()
      ]);
      const level = resArr[0];
      const label = resArr[1];
      this.workElevationLevelSltData = level.reduce(
        (acc, cur, idx, source) => {
          acc["1"].push({
            value: `${cur.id}-${label[idx].id}`,
            label: `${cur.name}(${label[idx].name})`
          });
          let i = idx;
          if (i < 3) i++;
          cur = source[i];
          acc["2"].push({
            value: `${cur.id}-${label[idx].id}`,
            label: `${cur.name}(${label[idx].name})`
          });
          return acc;
        },
        { 1: [], 2: [] }
      );
    },
    async handlerEdit() {
      this.$dispatch?.("WorkticketDetail", "clickSaveBtn", this.name);
      if (!this.value.forceMeasureList?.length) {
        const { workTypeId } = this.value;
        let res = await getCoerciveMeasures({ workTypeId });
        res.forEach(item => {
          item.name = item.content;
        });
        if (this.specialJobType === WORKTICKET_TYPE["equipment-safety"]) {
          res = res.filter(
            item =>
              item.safetyRiskCode ===
              this.codeMap_equipmentSafety[this.ticketStatus]
          );
        }
        this.$set(this.value, "forceMeasureList", res);
      }
    },
    handSave(e) {
      this.$emit("save", e);
      // 如果没有安全措施, 则不可提交
      if (
        !(
          this.value.safetyMeasureListStrs &&
          this.value.safetyMeasureListStrs.length
        ) &&
        this.value.extendSafetyMeasure.every(i => !i.name)
      ) {
        // 如果也没有强制措施
        if (!this.value.forceMeasureList?.length) {
          this.$toast("安全措施和补充措施至少填写一个");
          return;
        }
      }
      this.$refs.form
        .validate()
        .then(res => {
          this.$emit("editable-change", false);
          const safetyMeasureList = this.value.safetyMeasureListStrs;
          const value = cloneDeep({ ...this.value, safetyMeasureList });
          // 格式化补充措施
          if (
            value.extendSafetyMeasure &&
            Array.isArray(value.extendSafetyMeasure)
          ) {
            value.extendSafetyMeasure = value.extendSafetyMeasure
              .filter(item => {
                return item.name.trim();
              })
              .map(a => {
                return {
                  name: a.name
                };
              });
          }

          if (!Array.isArray(value.attachmentList) && value.attachmentList)
            value.attachmentList = [value.attachmentList];
          value.type = value.type.toString();
          // value.guarderListInfo = value.guarderList
          //   .filter(i => i._mechanismType)
          //   .map(item => {
          //     return {
          //       id: item.value,
          //       type: item._mechanismType
          //     };
          //   });
          value.typeSpecial = value.typeSpecial.toString();
          value.typeOther = value.typeOther.toString();
          value.elevationRange = value.elevationLevelId.split("-")[1];
          value.elevationLevelId = value.elevationLevelId.split("-")[0];
          // if (this.projectName === "RT") {
          //   value.specialRequirement = value.specialRequirement.toString();
          //   if (!value.deptSafetyEducationList[0]?.id) {
          //     value.deptSafetyEducationList = null;
          //   }
          //   if (!value.safetyEducationList[0]?.id) {
          //     value.safetyEducationList = null;
          //   }
          //   if (!value.leaderSafetyEducationList[0]?.id) {
          //     value.leaderSafetyEducationList = null;
          //   }
          //   if (!value.contactsList[0]?.id) {
          //     value.contactsList = null;
          //   }
          //   // value.contactNumber = value.contact.workMobile;
          //   // value.contactsList = value.contactsList.value;
          // }

          // 改value为id，删除label，新增type
          value.leader = value.leader?.value
            ? {
                id: value.leader.value,
                type: value.leader.type
              }
            : null;
          value.analyst = value.analyst?.value
            ? {
                id: value.analyst.value,
                type: value.analyst.type
              }
            : null;
          // value.powerWorker = value.powerWorker?.value
          //   ? {
          //       id: value.powerWorker.value,
          //       type: value.powerWorker.type
          //     }
          //   : null;
          value.productionUnitCommander = value.productionUnitCommander?.value
            ? {
                id: value.productionUnitCommander.value,
                type: value.productionUnitCommander.type
              }
            : null;

          value.leaderSafetyEducationList = value.leaderSafetyEducationList
            ?.length
            ? [
                {
                  id:
                    value.leaderSafetyEducationList[0].workerId ||
                    value.leaderSafetyEducationList[0].personId ||
                    value.leaderSafetyEducationList[0].id,
                  type:
                    value.leaderSafetyEducationList[0].orgType ||
                    value.leaderSafetyEducationList[0].type
                }
              ]
            : null;
          value.workHazardList = value.workHazardIdList.map(id => {
            const obj = this.workHazardOptionts.find(o => o.value === id);
            return {
              workDicHazardId: obj.value,
              name: obj.label
            };
          });

          updateSpecialWorks(this.specialJobType, value.id, value)
            .then(res => {
              this.editing = false;
              this.$toast.success("更新成功");
              this.loadData();
            })
            .catch(error => {
              this.$toast.fail("更新失败，请重试");
            });
        })
        .catch(error => {
          console.log("handSave--error", error);
        });
    },
    handlerCancel(e) {
      this.$refs.form && this.$refs.form.resetValidation();
      this.$emit("cancel", e);
    },
    btnChange(editable) {
      this.$emit("editable-change", editable);
    },
    getPersonLabel(persons) {
      if (!persons) return "";
      if (Array.isArray(persons)) {
        return persons.map(item => item.label).join(",");
      }
      return persons.label;
    },
    getWorkSiteTypeName(workSiteType) {
      if (!workSiteType) return "";
      const find = this.workSiteTypes.find(item => item.value === workSiteType);
      return find ? find.label : "";
    },
    hoistingGetPersonLabel(persons) {
      if (!persons) return "";
      if (Array.isArray(persons)) {
        return persons.map(item => item.label).join(",");
      }
      return persons.label;
    },
    // 点击了转审
    chickReferral() {
      this.$emit("changeCurStatus", WORKTICKET_STATUS.ReferralApproving, {
        specialId: this.specialId,
        specialJobType: this.specialJobType,
        btnType: "referral-approving"
      });
    },
    //点击气体检测
    clickGasCheck() {
      //只有[待气体检测/待安全交底/审批中/作业中/暂停/待作业]的检测人能够新建，其他情况只能查看
      this.$router.push({
        path: "/workticket/checkgasAdd/",
        query: {
          workTypeId: this.id,
          specialId: this.specialId,
          ticketId: this.ticketId
        }
      });

      // const statusArr = [
      //   WORKTICKET_STATUS.Gas_monitor,
      //   WORKTICKET_STATUS.Safety_disclosure,
      //   WORKTICKET_STATUS.Safety_disclosureing,
      //   WORKTICKET_STATUS.Approving,
      //   WORKTICKET_STATUS.Working,
      //   WORKTICKET_STATUS.PendingJob,
      //   WORKTICKET_STATUS.Paused
      // ];
      // if (statusArr.includes(this.ticketStatus) && this.gasMonitorWorker == 1) {
      //   this.$router.push({
      //     path: "/workticket/checkgasAdd/",
      //     query: {
      //       workTypeId: this.id,
      //       specialId: this.specialId,
      //       ticketId: this.ticketId
      //     }
      //   });
      // }
      // else {
      //   getWorkLatestGasMonitor(this.specialId)
      //     .then(res => {
      //       if (res) {
      //         this.$router.push(`/workticket/checkgas/${res.id}?simple=1`);
      //       } else {
      //         this.$toast.fail("暂无检测信息");
      //       }
      //     })
      //     .catch(error => {
      //       console.log(error);
      //     });
      // }
    },
    gasHistoryClick() {
      this.$router.push({
        name: "workticketCheckGasRecord",
        params: {
          id: this.specialId
        }
      });
    },
    //审批
    async clickShenPi() {
      try {
        const { riskAreaId } = this.workInfo;
        const isIn = await workIsInFenceByPoiInfo(riskAreaId, 1, this.id);
        if (!isIn) {
          return await Dialog.alert({
            message: "您不在作业区域内，请至区域内签名",
            confirmButtonText: "知道了"
          });
        }
        // 检查气体检测是否超时
        const checked = await checkedGasExpires(this.specialId);
        if (checked !== true) return;
        // 获取当前审批节点信息
        const node = await getCurrentWorkNode(this.specialId, {
          approveType: this.approveType
        });
        // 修改状态为通过/退回
        this.$emit(
          "changeCurStatus",
          WORKTICKET_STATUS.ApprovingPassOrBack,
          {
            ticketId: this.ticketId,
            specialId: this.specialId,
            title: this.title,
            approveType: this.approveType,
            nodeName: node.name,
            approveWay: this.originalData.approveWay
          },
          isIn
        );
      } catch (e) {
        console.log(e);
      }
    },
    workerClick() {
      if (this.editing && this.specialJobType !== "5") {
        this.workerPickerVisible = true;
      }
    },
    normalWorkerClick() {
      if (this.editing) {
        this.normalPersonPickerVisible = true;
      }
    },
    hositingPersonConcat(v) {
      const HCommand = this.value.hoistingCommandList || [];
      const HPerson = this.value.hoistingPersonList || [];
      const riggerPerson = this.value.riggerPersonList || [];
      // const allHoistingPerson = HPerson.concat(
      //   HCommand.filter(
      //     item => !HPerson.find(itemH => itemH.value === item.value)
      //   )
      // );
      const allHoistingPerson = uniqBy(
        [...HCommand, ...HPerson, ...riggerPerson],
        "id"
      );
      this.value.workerList = allHoistingPerson;
    },
    tagTypeSwitch(item) {
      if (item.fileId) {
        return item.involved ? "purple" : "green";
      }
      return "white";
    },
    dictionaryAdd(specialJobType) {
      this.InputDialogFun(
        this.dictionaryMap[specialJobType].title,
        () =>
          new Promise(rlv => {
            rlv();
          }),
        [],
        (res, arg) => {
          this.dictionaryMap[specialJobType]
            .addApi({
              name: arg[0]
            })
            .then(res => {
              const key = this.dictionaryMap[specialJobType].listName;
              if (res) {
                this[key].push({
                  id: res,
                  name: arg[0],
                  delete: true
                });
                this.$toast("添加成功");
                return;
              }
              this.$toast("添加失败, 请重试");
            })
            .catch(e => {
              this.$toast("添加失败, 请重试");
            });
        },
        false,
        null,
        null,
        true,
        true,
        32
      );
    },
    async dictionaryDelete(id, specialJobType) {
      await dictionaryDelete(id);
      this.$toast("删除成功");
      const job = this.dictionaryMap[specialJobType];
      const idx = this[job.listName].findIndex(i => i.id === id);
      this[job.listName].splice(idx, 1);
      this.formBase[job.formAttr] = this.formBase[job.formAttr].filter(
        i => i !== id
      );
    },
    InputDialogFun(
      title,
      api,
      apiParamsList = [],
      cb,
      hasCheckPerson,
      ticketId,
      department,
      hasInput = true,
      isRequired = true,
      maxlength = 500
    ) {
      const successCb = function() {
        api(...apiParamsList, ...arguments)
          .then(res => {
            InputDialog.close();
            this.$toast.success("操作成功");
            cb(res, arguments);
          })
          .catch(error => {
            InputDialog.removeLoading();
            this.$toast.fail(error.msg || "请求失败,请重试");
          });
      };
      InputDialog.show({
        title,
        successCb,
        hasCheckPerson,
        ticketId,
        department,
        hasInput,
        isRequired,
        maxlength
      });
    },
    hoistingMethodChange(val) {
      // 选中“非吊车”
      if (val === "0") {
        const newData = cloneDeep(this.value);
        newData.hoistingCommandList = [];
        newData.hoistingPersonList = [];
        newData.riggerPersonList = [];
        newData.workerList = [];
        this.value = newData;
      }
    },
    licenseTypeFormatter(val) {
      return val ? val.replace(/,/g, "-") : "";
    }
  }
};
</script>

<style lang="scss">
.WorkticketTypeDetailItem {
  .custom-input-person {
    .van-field__control--right {
      flex-wrap: nowrap;
      flex-direction: column;
      align-items: flex-end;
    }
  }
  .van-cell {
    display: flex;
    .van-cell__title {
      flex: 1;
      white-space: nowrap;
    }
    .van-cell__value {
      flex: 1;
      &.isnowrap {
        white-space: nowrap;
      }
    }
    .ticket-title {
      // max-width: 50%;
      overflow: hidden;
    }
    .ticket-value {
      // max-width: 50%;
      // overflow-x: auto;
      flex: none;
      white-space: nowrap;
    }
  }
}
</style>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.WorkticketTypeDetailItem {
  .ticket-name {
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 96%;
    vertical-align: middle;
  }
  .ticket-name.export-w {
    max-width: calc(100% - 30px);
  }
  .daochu {
    font-size: 22px;
    opacity: 0.5;
    color: #1676ff;
    vertical-align: middle;
    margin: 0 0 0 4px;
  }
  .form-box {
    padding-bottom: 20px;
  }

  .extend-safety-measure {
    padding: 10px 15px;
    &-title {
      color: #323233;
      margin-bottom: 5px;
    }
    .extend-safety-item {
      position: relative;
      // display: flex;
      // align-items: center;
      padding: 0 15px 0 0;
      .safety-item {
        width: 100%;
        display: flex;
        justify-content: space-between;
        padding: 5px 0;
        margin: 0 15px 0 0;
        .dec {
          font-size: 14px;
          line-height: 20px;
          padding: 0 8px 0 0;
          white-space: break-spaces;
          word-break: break-all;
          max-width: 68%;
        }
        .safety-item-name {
          position: relative;
          display: inline-block;
          height: 32px;
          max-width: 120px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          line-height: 32px;
          border-radius: 16px;
          color: #64ae08;
          border: 1px solid #75c70e;
          background-color: #dcffdb;
          padding: 0 30px 0 10px;
          box-sizing: border-box;
          .van-icon {
            position: absolute;
            right: 10px;
            top: 0;
            line-height: 32px;
            margin-left: 6px;
            font-size: 18px;
            vertical-align: top;
          }
        }
        .upload-image-box {
          position: absolute;
          width: 60px;
          height: 30px;
          color: #1678ff;
          font-size: 12px;
          background-color: #eceef2;
          border-radius: 0 0 30px 30px;
          top: 5px;
          right: -45px;
          .content {
            display: flex;
            flex-direction: column;
            margin: 2px 0 0 12px;
            .num {
              margin: 0 0 0 5px;
              line-height: 9px;
            }
          }
        }
      }
      .remove-safety {
        display: inline-block;
        width: 40px;
        margin-left: 5px;
        color: rgb(238, 92, 92);
      }
    }
    .add-extend-safety-box {
      text-align: center;
      color: $--color-primary;
      .add-extend-safety {
        padding: 10px 0;
        .van-icon {
          position: relative;
          top: 2px;
          margin-right: 5px;
        }
      }
    }
  }

  ::v-deep.van-field__label {
    color: $--color-black-title;
  }

  ::v-deep.van-field__control {
    color: $--color-gray-item-text;
  }

  &.editing {
    .input {
      ::v-deep.van-field__body {
        border-radius: 2px;
        height: 36px;
        line-height: 36px;
        padding-right: 10px;
        border: 1px solid #c7c9d0;
        border-radius: 2px;
      }
    }
    .work-elevation {
      ::v-deep.van-field__body {
        border: 0;
      }
    }
  }

  .custom-input-person {
    .person-box {
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      color: #2e2e4d;
      margin: 0 0 0 12px;
    }
    .replace-color {
      color: #8c8f97;
    }
  }
  .wem {
    .wem-icon {
      vertical-align: text-top;
    }
    .wem-icon::before {
      color: #aeb3c0;
    }
    ::v-deep {
      .van-field__value {
        flex: none;
      }
    }
  }
  .hoisting-level-field {
    ::v-deep {
      .van-field__value {
        flex: auto;
      }
      .van-radio-group--horizontal {
        flex-direction: column;
      }
    }
  }
  .desc {
    line-height: 20px;
    font-size: 14px;
    font-weight: 400;
    color: #8c8f97;
    word-break: break-all;
  }

  .btn-box {
    .gas-history-btn {
      margin: 0 0 0 8px;
    }
  }

  // .safe-education {
  //   font-size: 14px;
  //   color: #000;
  //   line-height: 40px;
  //   padding: 0 20px 0;
  // }
  // .safe-education-field::after {
  //   border-bottom: 0;
  // }

  .pp-tag-content {
    // position: relative;
    .pp-option-close {
      margin: 0px 0 0 4px;
      vertical-align: text-top;
      // position: absolute;
      // top: 4px;
      // right: 4px;
    }
  }
}
.work-level-desc-table {
  width: 100%;
  border: 1px solid #c7c9d0;
  td {
    font-size: 12px;
    border: 1px solid #c7c9d0;
    text-align: center;
    vertical-align: middle;
    color: #2e2e4d;
    height: 30px;
  }
  .tit td {
    color: #99a3af;
  }
}
</style>
