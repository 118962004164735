<template>
  <router-link class="WorkticketItem" :to="routerLinkSwitch">
    <div
      v-if="item.videoTags != null"
      class="livestream-playback"
      :style="{
        'background-image': `url(${require(`./images/${
          videoTagsImgMap[item.videoTags]
        }`)})`
      }"
    ></div>
    <div class="name-line">
      <span class="name-text">
        <span>【{{ status }}】</span>
        <span>{{ title }}</span>
        <span>（{{ types }}）</span>
      </span>
      <span
        v-if="showWaringIcon"
        :class="[alarmNum > 0 ? 'icon-warning' : 'icon-watching', 'icon']"
      >
      </span>
    </div>
    <div class="number">许可编号：{{ number }}</div>

    <div class="marginBottom">
      <span class="icon-item"
        ><i
          :style="
            `background-image: url(${require('@/assets/images/workticket-item-1.png')})`
          "
        ></i
        >{{ text1 }}</span
      >
      <span class="icon-item"
        ><i
          :style="
            `background-image: url(${require('@/assets/images/workticket-item-4.png')})`
          "
        ></i
        >{{ text2 }}</span
      >
    </div>

    <div class="marginBottom">
      <span class="icon-item"
        ><i
          :style="
            `background-image: url(${require('@/assets/images/workticket-item-2.png')})`
          "
        ></i
        >{{ (text3 || []).map(item => item.name).toString() }}</span
      >
      <span class="icon-item"
        ><i
          :style="
            `background-image: url(${require('@/assets/images/workticket-item-5.png')})`
          "
        ></i
        >{{ (text4 || []).map(item => item.name).toString() }}</span
      >
    </div>

    <div>
      <span class="icon-item-timerange"
        ><i
          :style="
            `background-image: url(${require('@/assets/images/workticket-item-3.png')})`
          "
        ></i
        >{{ startTime }} 至 {{ endTime }}</span
      >
    </div>
    <div
      v-if="inspectStatus"
      class="inspect-status"
      :style="{ color: iconConf[inspectStatus].color }"
    >
      <van-icon :name="iconConf[inspectStatus].name" class-prefix="iconfont" />
    </div>
    <div v-if="forcedFinish" class="status-wrap">
      <p class="status-tag red">强制完成</p>
    </div>
    <div v-else-if="!inspectStatus && displayTerminalTime" class="countdown">
      <p class="label">倒计时</p>
      <van-count-down :time="terminalTime" format="HH:mm:ss">
        <template #default="timeData">
          <span class="block" :class="{ 'time-up': timeUpSwitch(timeData) }">{{
            numberSwitch(timeData.days * 24 + timeData.hours)
          }}</span>
          <span class="colon">:</span>
          <span class="block" :class="{ 'time-up': timeUpSwitch(timeData) }">{{
            numberSwitch(timeData.minutes)
          }}</span>
          <span class="colon">:</span>
          <span class="block" :class="{ 'time-up': timeUpSwitch(timeData) }">{{
            numberSwitch(timeData.seconds)
          }}</span>
        </template>
      </van-count-down>
    </div>
  </router-link>
</template>

<script>
import { deepFreeze } from "@/utils";
const iconConf = deepFreeze({
  1: { name: "yijiancha", color: "#8EB433" },
  0: { name: "weijiancha", color: "#5C7BA2" }
});
export default {
  name: "WorkticketItem",
  components: {},
  props: {
    id: { type: [String, Number], default: "" },
    status: { type: String, default: "作业状态" },
    forcedFinish: { type: Number, default: 0 },
    title: { type: String, default: "作业名称" },
    types: { type: String, default: "作业类别" },
    number: { type: String, default: "xxx-20-0927-0001" }, //许可编号
    startTime: { type: String, default: "作业状态" },
    endTime: { type: String, default: "作业状态" },
    text1: { type: String, default: "" },
    text2: { type: String, default: "" },
    text3: { type: Array, default: () => [] },
    text4: { type: Array, default: () => [] },
    routerLink: { type: Boolean, default: true },
    checkStatus: { type: [String, Number], default: "" },
    displayTerminalTime: { type: Number, default: 0 },
    terminalTime: { type: Number, default: 0 },
    alarmNum: { type: Number },
    warningFlag: { type: Boolean },
    item: {
      type: Object,
      default: function() {
        return {};
      }
    }
  },
  data() {
    return {
      iconConf,
      videoTagsImgMap: {
        1: "livestream.gif",
        2: "playback.svg"
      }
    };
  },
  computed: {
    showWaringIcon() {
      if (
        (this.status === "作业中" || this.status === "暂停中") &&
        this.warningFlag
      ) {
        return true;
      } else {
        return false;
      }
    },
    showWarningButton() {
      if (
        (this.status === "作业中" || this.status === "暂停中") &&
        this.warningFlag &&
        this.alarmNum > 0
      ) {
        return true;
      } else {
        return false;
      }
    },
    routerLinkSwitch() {
      if (!this.routerLink) return "";
      if (!!this.inspectStatus) {
        return `/workticket/${this.id}?mode=inspect`;
      }
      if (this.status === "待提交") return `/workticket/add/${this.id}`;
      return `/workticket/${this.id}?showWarningButton=${this.showWarningButton}`;
    },
    inspectStatus() {
      if (this.checkStatus === "") {
        return false;
      }
      return String(this.checkStatus);
    }
  },
  created: function() {},
  mounted: function() {},
  methods: {
    timeUpSwitch({ days, hours, minutes, seconds }) {
      return !(days + hours + minutes + seconds);
    },
    numberSwitch(num) {
      let str = String(num);
      if (str.length < 2) {
        str = `0${str}`;
      }
      return str;
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.WorkticketItem {
  .name-text {
    flex: 1;
    margin-right: 5px;
  }
  .icon {
    display: inline-block;
    width: 22px;
    height: 20px;
    align-self: start;
  }
  .icon-warning {
    background: url("../../assets/images/ico-warning.svg") no-repeat;
  }
  .icon-watching {
    background: url("../../assets/images/ico-watching.svg") no-repeat;
  }
  position: relative;
  display: block;
  padding: 18px 16px 10px;
  background-color: #fff;
  font-size: 12px;
  color: #3b4664;
  line-height: 17px;
  .livestream-playback {
    position: absolute;
    top: 0;
    left: 0;
    width: 64px;
    height: 18px;
    background-size: cover;
  }
  .name-line {
    font-size: 14px;
    line-height: 20px;
    font-weight: bold;
    word-break: break-all;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .number {
    color: #8c8f97;
    margin: 5px 0px;
  }

  .marginBottom {
    margin-bottom: 5px;
  }

  .icon-item {
    display: inline-block;
    margin-right: 16px;
    width: 24vw;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    i {
      display: inline-block;
      width: 15px;
      height: 15px;
      vertical-align: top;
      margin-right: 5px;
      background-size: cover;
    }
  }
  .icon-item-timerange {
    display: inline-block;
    margin-right: 16px;
    i {
      display: inline-block;
      width: 15px;
      height: 15px;
      vertical-align: top;
      margin-right: 5px;
      background-size: cover;
    }
  }

  .inspect-status {
    position: absolute;
    right: 32px;
    bottom: 15%;
    width: 60px;
    height: 60px;
    i {
      display: block;
      line-height: 60px;
      font-size: 60px;
    }
  }
  .countdown {
    position: absolute;
    right: 32px;
    top: 45%;
    .label {
      font-size: 12px;
      font-weight: 400;
      color: #8c8f97;
      margin: 0 0 2px;
      text-align: right;
    }
    .colon {
      display: inline-block;
      margin: 0 2px;
      font-size: 12px;
      font-weight: 400;
      color: #8c8f97;
    }
    .block {
      display: inline-block;
      min-width: 16px;
      height: 16px;
      line-height: 16px;
      padding: 0 2px;
      box-sizing: border-box;
      border-radius: 2px;
      color: #fff;
      font-size: 12px;
      font-weight: 600;
      text-align: center;
      background-color: #ffae13;
    }
    .block.time-up {
      background-color: #fa5555;
    }
  }
  .status-wrap {
    position: absolute;
    right: 32px;
    top: 59%;
    .status-tag {
      padding: 0 4px;
      font-size: 11px;
      border-radius: 2px;
      height: 16px;
      line-height: 16px;
      &.red {
        color: #f53f3f;
        background: rgba(245, 63, 63, 0.2);
      }
    }
  }
}
</style>
