<template>
  <van-field
    :value="value"
    :rules="[{ validator, message: '请选择' }, ...($attrs.rules || [])]"
    v-bind="$attrs"
  >
    <template #input>
      <span v-show="!editing">{{ label }}</span>
      <van-radio-group
        v-show="editing"
        v-model="modelValue"
        direction="horizontal"
        v-bind="$attrs"
      >
        <van-radio
          v-for="(item, index) in options"
          :key="index"
          :name="item.value"
          >{{ item.label }}</van-radio
        >
      </van-radio-group>
    </template>
  </van-field>
</template>

<script>
import EditFieldMixin from "@/components/edit/mixins/EditFieldMixin";

export default {
  name: "RadioEditField", //可编辑，带有单选项的cell
  components: {},
  mixins: [EditFieldMixin],
  inheritAttrs: true,
  props: {
    options: {
      type: Array,
      default: () => [
        { label: "是", value: 1 },
        { label: "否", value: 0 }
      ]
    }
  },
  data() {
    return {};
  },
  computed: {},
  watch: {},
  created: function() {},
  mounted: function() {},
  methods: {
    validator(val) {
      let flag = val !== null || val !== undefined;
      return flag;
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.RadioEditField {
  .right-box {
    position: absolute;
    right: 16px;
  }

  .label {
    color: $--color-gray-item-text;
  }
}
.van-radio__label {
  // white-space: nowrap;
  text-align: left;
}
</style>
