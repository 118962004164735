<template>
  <div class="render-form">
    <van-form ref="vanForm" class="form-box" label-width="7.8em">
      <div
        v-for="(value, key) in renderForm"
        :key="key"
        style="width: 100%"
        class="form-item-box"
      >
        <component
          :is="getFormItem(key)['renderCom']"
          :key="key"
          v-model="renderForm[key]"
          v-bind="$attrs"
          :item-data="getFormItem(key)"
          :editable="editable"
        ></component>
      </div>
    </van-form>
  </div>
</template>

<script>
import { multipleImportVue } from "./util/index";

// 引入所有需要的动态组件
const requireComponent = require.context(
  "./render", //组件所在目录的相对路径
  true, //是否查询其子目录
  /\w+\.vue$/ //匹配基础组件文件名的正则表达式
);
let comObj = multipleImportVue(requireComponent);

export default {
  name: "RenderForm",
  components: {
    ...comObj
  },
  props: {
    renderFormData: Object,
    renderList: Array,
    editable: Boolean
  },
  data() {
    return {
      formList: [],
      renderForm: {}
    };
  },
  watch: {
    renderFormData: {
      handler: function(val) {
        this.renderForm = val;
      },
      deep: true
    },
    renderList: {
      handler: function(val) {
        this.formList = val || [];
      },
      deep: true
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.renderForm = this.renderFormData;
      this.formList = this.renderList;
    });
  },
  methods: {
    getFormItem(key) {
      return this.formList.find(v => v.code === key) || { options: {} };
    },
    validate() {
      return this.$refs.vanForm.validate();
    }
  }
};
</script>

<style lang="scss" scoped>
.render-form {
  width: 100%;
  overflow-y: auto;
  .form-item-box {
    float: left;
    box-sizing: border-box;
    ::v-deep {
      .van-cell {
        background: transparent;
        padding-top: 5px;
        padding-bottom: 5px;
        &::after {
          border-bottom-color: transparent;
        }
      }
    }
  }
}
</style>
