<template>
  <div>
    <div v-if="type === 'file'" class="file-field">
      <span class="file-field-label">{{ label }}：</span>
      <p v-for="item in files" :key="item.id" @click="onPreview(item)">
        <i class="iconfont iconfont-fujian"></i>
        {{ item.name }}
      </p>
    </div>
    <div v-else class="label-value-field">
      <span
        class="label-value-field-label"
        :style="{ width: `${labelWidth}px` }"
        >{{ label }}：</span
      >
      <span class="label-value-field-value">{{ value }}</span>
    </div>
  </div>
</template>

<script>
import { getFileUrl } from "@/components/fileuploader/util";

export default {
  name: "LabelValueField",
  props: {
    label: {
      type: String,
      default: ""
    },
    labelWidth: {
      type: Number,
      default: 80
    },
    value: {
      type: [String, Array],
      default: ""
    },
    type: {
      type: String,
      default: ""
    }
  },
  computed: {
    files() {
      if (!this.value) {
        return [];
      }
      let files = [];
      if (!Array.isArray(this.value)) {
        files = [this.value];
      } else {
        files = this.value;
      }
      return files;
    }
  },
  methods: {
    onPreview(item) {
      const url = getFileUrl(item.id);
      window.open(url);
    }
  }
};
</script>

<style lang="scss">
.label-value-field {
  display: flex;
  min-height: 20px;
  font-size: 14px;
  align-items: flex-start;
  line-height: 20px;
  margin-bottom: 8px;
  &-label {
    min-width: 70px;
    color: #8c8f97;
  }
  &-value {
    flex: 1;
    color: #2e2e4d;
  }
}
.file-field {
  font-size: 14px;
  &-label {
    color: #8c8f97;
  }
  p {
    position: relative;
    text-indent: 30px;
    color: #1676ff;
    line-height: 20px;
    &:nth-of-type(1) {
      margin-top: 6px;
    }
    i.iconfont {
      position: absolute;
      top: 1px;
      left: -24px;
      width: 44px;
    }
  }
}
</style>
