<template>
  <van-popup
    v-model="visible"
    :close-on-click-overlay="false"
    position="bottom"
    :style="popupStyle"
    get-container="body"
    @open="onOpen"
    @click-overlay="clickOverlay"
  >
    <section class="main">
      <div class="header">
        <h3 class="popup-title">{{ title }}</h3>
        <van-search
          v-model="filterStr"
          autofocus
          placeholder="请输入"
          class="popup-search"
          @input="filter"
        >
          <template #left-icon>
            <van-icon
              name="sousuo"
              class-prefix="iconfont"
              class="search-icon"
            />
          </template>
        </van-search>
      </div>
      <div class="content">
        <van-checkbox-group v-show="filterData.length" v-model="value">
          <van-cell-group>
            <van-cell
              v-for="(item, idx) in filterData"
              :key="idx"
              :title="item[labelKey]"
              clickable
              @click.prevent="handlerClick(item)"
            >
              <template #right-icon>
                <van-checkbox
                  :disabled="item.disabled"
                  :name="item[valueKey]"
                />
              </template>
            </van-cell>
          </van-cell-group>
        </van-checkbox-group>
        <p v-show="!filterData.length" align="center" class="no-data">
          暂无数据
        </p>
      </div>
      <div class="footer block-space-left-right">
        <van-button
          plain
          round
          class="confirm-btn"
          @click.prevent="close(undefined)"
          >取消</van-button
        >
        <van-button
          type="info"
          round
          class="confirm-btn"
          @click.prevent="confirm(value)"
          >确定</van-button
        >
      </div>
    </section>
  </van-popup>
</template>

<script>
import {
  Popup,
  CellGroup,
  Cell,
  Search,
  Button,
  Checkbox,
  CheckboxGroup
} from "vant";
import { generatorComponents } from "@/utils";
const components = [
  Popup,
  CellGroup,
  Cell,
  Search,
  Button,
  Checkbox,
  CheckboxGroup
];
export default {
  name: "MultipleSelect",
  components: { ...generatorComponents(components) },
  props: {
    choosedVal: {
      type: Array,
      default: () => []
    },
    title: {
      type: String,
      default: "所属企业"
    },
    // 选中的值的属性名，必传
    valueKey: {
      type: String,
      default: "value"
    },
    // 在页面要展示的选项属性名，必传
    labelKey: {
      type: String,
      default: "label"
    },
    // popupStyle: {
    //   type: Object,
    //   default: () => ({ height: '75%' }),
    // },
    visible: {
      type: Boolean,
      default: false
    },
    data: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      popupStyle: {
        height: "75%"
      },
      value: [],
      filterStr: "",
      filterData: [],
      choosedRow: null
    };
  },
  methods: {
    onOpen() {
      this.filterStr = "";
      this.filterData = [...this.data];
      const choosedVal = [...this.choosedVal];
      this.value = choosedVal.map(i => i[this.valueKey]);
      // this.value = this.choosedVal;
      this.choosedRow = choosedVal;
      console.log("onOpen -> this.choosedVal", this.choosedVal);
    },
    filter(queryString) {
      const data = [...this.data];
      this.filterData = queryString
        ? data.filter(this.createStateFilter(queryString))
        : data;
    },
    createStateFilter(queryString) {
      return state => {
        return (
          state.label.toLowerCase().indexOf(queryString.toLowerCase()) !== -1
        );
      };
    },
    handlerClick(row) {
      if (row.disabled) return;
      const index = this.value.indexOf(row.value);
      if (index === -1) {
        this.value.push(row.value);
        this.choosedRow.push(row);
      } else {
        this.value.splice(index, 1);
        this.choosedRow.splice(index, 1);
      }
    },
    close(val) {
      console.log("close -> val", val);
      this.$emit("close", val ? this.choosedRow : undefined);
    },
    confirm() {
      this.$emit("confirm", this.choosedRow);
    },
    clickOverlay() {
      this.$emit("close", undefined);
    }
  }
};
</script>

<style lang="scss" scoped>
.van-hairline--top-bottom::after,
.van-hairline-unset--top-bottom::after {
  border: none;
}
.popup-title {
  font-weight: bold;
  font-size: 17px;
  padding: 20px;
}
.popup-search {
  margin-bottom: 2vh;
  height: 4.6vh;
  padding-left: 20px;
  padding-right: 20px;
}
.van-checkbox-group {
  margin: 0 20px;
}
.content {
  height: 45vh;
  overflow-y: auto;
  .no-data {
    padding-top: 80px;
    color: #aeb3c0;
    font-size: 14px;
  }
}
.footer {
  margin-top: 20px;
  display: flex;
  justify-content: space-around;
  .van-button {
    width: 148px;
  }
}
</style>
