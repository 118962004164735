<template>
  <div
    v-if="showDom"
    class="WorkticketSafetyItem"
    :class="{ 'block-space_8_notLast': bottomBorder }"
  >
    <p class="title"><slot name="otherPersonTitle"></slot></p>
    <div class="title">{{ title }}</div>
    <van-checkbox v-if="info" v-model="checked" shape="square">{{
      info
    }}</van-checkbox>
    <template
      v-if="
        ticketStatus === WORKTICKET_STATUS.Safety_disclosureing &&
          value.personType === '7'
      "
    >
      <van-button
        class="btn-select-person"
        round
        @click="selectPersonVisible = true"
        >选择人员</van-button
      >
      <!-- <select-person
        title="受影响相关方"
        :visible="selectPersonVisible"
        :choose-value="choosePersonSelect"
        department-disabled-key="--"
        :show-department-name="false"
        :show-phone-number="true"
        position="bottom"
        @close="selectPersonVisible = false"
        @confirm="onPersonConfirm"
      /> -->
      <select-person-by-tabs
        title="受影响相关方(多选)"
        :visible="selectPersonVisible"
        :show-department-name="false"
        :show-phone-number="true"
        department-disabled-key="--"
        position="bottom"
        :include-children="true"
        :multiple="true"
        :choose-value="choosePersonSelect"
        @close="selectPersonVisible = false"
        @confirm="onPersonConfirm"
      />
    </template>
    <template v-if="value.workerList && value.workerList.length > 0">
      <div v-for="(item, index) in value.workerList" :key="index">
        <div v-if="item.typeName" class="title1">{{ item.typeName }}</div>
        <!-- TODO: 安全交底人得选择按钮移到上面，并把人数限制去掉 -->
        <template
          v-if="
            ticketStatus === WORKTICKET_STATUS.Safety_disclosureing &&
              value.personType === activePersonType
          "
        >
          <div>
            <van-button
              class="btn-select-person"
              round
              @click="selectAnalysisVisible = true"
              >选择人员</van-button
            >
          </div>
        </template>
        <div
          v-if="item.workerList && item.workerList.length > 0"
          class="tag-box"
        >
          <!-- :type="item1.fileId && item1.current ? 'green' : 'white'" -->
          <Tag
            v-for="(item1, index1) in item.workerList"
            :key="index1"
            :type="typeSwitch(item1)"
            :tick="!!item1.fileId"
            @click.native="
              handleClickTag(
                item1,
                item1.fileId,
                item1.personId,
                item1.personType,
                ticketId,
                item.specialId
              )
            "
          >
            {{ item1.personName }}
          </Tag>
        </div>
      </div>
      <select-person
        :title="`${title}(多选)`"
        :visible="selectAnalysisVisible"
        :show-department-name="false"
        :show-phone-number="true"
        position="bottom"
        :include-children="true"
        :multiple="true"
        department-disabled-key="-"
        :choose-value="chooseAnalysisSelect"
        @close="selectAnalysisVisible = false"
        @confirm="onAnalysisConfirm"
      />
      <safe-inventory
        :visible="safeInventoryVb"
        position="bottom"
        title="安全交底清单"
        type="textarea"
        :autosize="{
          // minHeight: 26,
          maxHeight: 126
        }"
        mode-type="1"
        :rows="1"
        :ticket-id="ticketId"
        @confirm="safeInventoryConfirm"
        @close="safeInventoryVb = false"
      />
    </template>
  </div>
</template>

<script>
import Tag from "@/components/Tag";
import esign from "@/components/esign";
// import SelectPerson from "@/components/SelectPerson";
import { fileUrl } from "@/utils/constant";
import {
  addWorkSignature,
  addAffectedPerson,
  addSafetyPerson,
  getSafetyRegulation
} from "@/api/workticket";
import { WORKTICKET_STATUS } from "@/views/workticket/utils/constant";
import safeInventory from "@/views/workticket/components/safeInventory";

export default {
  name: "WorkticketSafetyItem",
  components: { Tag, safeInventory },
  props: {
    value: { type: Object, default: null },
    ticketId: { type: String, default: "" }, //作业id
    ticketStatus: { type: [String, Number], default: "" }, //作业状态
    bottomBorder: { type: Boolean, default: true },
    safeInventoryConfirmPersonIds: { type: Array, default: () => [] },
    originStatus: { type: Number }
  },
  data() {
    return {
      checked: true,
      selectPersonVisible: false,
      WORKTICKET_STATUS,
      selectAnalysisVisible: false,
      otherShowBlackList: [
        "10",
        "20",
        "21",
        "22",
        "23",
        "13",
        "11",
        "12",
        "1201",
        "2601"
      ],
      safeInventoryVb: false,
      cacheSignData: {}
    };
  },
  computed: {
    showDom() {
      if (this.value) {
        if (this.value.personType) {
          if (this.otherShowBlackList.includes(this.value.personType)) {
            return this.value.workerList.length > 0;
          }
          return true;
        }
      }
      return false;
    },
    chooseAnalysisSelect() {
      const choosePersonSelect = [];
      (this.value.workerList || []).forEach(tItem => {
        (tItem.workerList || []).forEach(pItem => {
          choosePersonSelect.push({
            label: pItem.personName,
            value: pItem.personId,
            type: pItem.type
          });
        });
      });
      return choosePersonSelect;
    },
    choosePersonSelect() {
      // 如果有问题则改为watch去赋值choosePersonSelect
      const choosePersonSelect = [];
      (this.value.workerList || []).forEach(tItem => {
        (tItem.workerList || []).forEach(pItem => {
          choosePersonSelect.push({
            label: pItem.personName,
            value: pItem.personId,
            type: pItem.type
          });
        });
      });
      return choosePersonSelect;
    },
    /*
     *
     * 1负责人 2监护人 3特种作业人 4安全分析负责人 5分析人*/
    title() {
      const { personType } = this.value;
      let titles = {
        1: "作业单位负责人",
        2: "监护人",
        3: "特种作业人员",
        15: "一般作业人员",
        19: "安全交底人",
        5: "分析人",
        6: "审批人",
        7: "受影响相关方",
        10: "用电人",
        20: "实施安全教育人",
        // 21: "实施安全教育人",
        // 22: "实施安全教育人",
        23: "联系人",
        13: this.TEXT_PRODUCTION_UNIT_WORK_COMMANDER,
        11: "指挥人员",
        12: "吊装作业人",
        1201: "司索人",
        2601: "用电单位负责人",
        38: "工艺安全交底人",
        39: "检修安全交底人"
      };
      return titles[personType] || "";
    },
    info() {
      const { personType } = this.value;
      let titles = {
        1: "本人已同作业人员讨论了该工作及安全工作方案，并对工作内容进行了现场检查，安全措施已落实。",
        2: "本人已掌握本次作业的内容及安全工作方案，并对作业内容及安全措施进行了现场检查，在作业过程中执行安全管理规定。",
        3: "本人已知晓工作中的危害、控制措施和安全要求，在作业中遵守执行。",
        19: "本人确认已传达交底内容，已向工作负责人和作业人员告知区域风险及预防措施。",
        15: "本人已知晓工作中的危害、控制措施和安全要求，在作业中遵守执行。",
        5: "本人已同作业人员讨论了该工作及安全工作方案，并对工作内容进行了现场检查，安全措施已落实。",
        7: "本人已确认工作内容，了解作业对本单位的影响，将安排人员对此项工作给予关注，并和相关各方保持联系。",
        38: "本人确认已传达交底内容，已向工作负责人和作业人员告知区域风险及预防措施。",
        39: "本人确认已传达交底内容，已向工作负责人和作业人员告知区域风险及预防措施。"
      };
      return titles[personType] || "";
    },
    activePersonType() {
      switch (this.originStatus) {
        case WORKTICKET_STATUS.Craft_safety_disclosure: // 待工艺安全交底（北元）
          return "38";
        case WORKTICKET_STATUS.Overhaul_safety_disclosure: // 待检修安全交底（北元）
          return "39";
        default:
          return "19";
      }
    }
  },
  methods: {
    safeInventoryConfirm() {
      const {
        item,
        fileId,
        personId,
        personType,
        ticketId,
        specialId
      } = this.cacheSignData;
      this.handleClickTag(
        item,
        fileId,
        personId,
        personType,
        ticketId,
        specialId,
        true
      );
    },
    onAnalysisConfirm(ids, list) {
      // type 人员类型，0代表企业，2代表承包商
      if (!list.length) {
        this.$toast.fail("安全交底人至少选择一个");
        return;
      }
      const params = list.map(item => {
        return {
          id: item.value,
          name: item.label,
          type: "0"
        };
      });
      addSafetyPerson(this.ticketId, params)
        .then(res => {
          const checkPersonList = [];
          const workerList =
            (this.value.workerList[0] && this.value.workerList[0].workerList) ||
            [];
          list.forEach(item => {
            const find = workerList.find(f => f.personId === item.value);
            let obj = {
              ...find,
              ticketId: this.ticketId,
              personType: this.activePersonType,
              specialId: ""
            };
            if (!find) {
              obj = {
                current: 1,
                fileId: "",
                personId: item.value,
                personName: item.label,
                ticketId: this.ticketId,
                personType: this.activePersonType,
                specialId: "",
                type: item.type
              };
            }
            checkPersonList.push(obj);
          });
          if (
            Array.isArray(this.value.workerList) &&
            this.value.workerList.length
          ) {
            this.value.workerList[0].workerList = checkPersonList;
          } else {
            this.value.workerList = [
              {
                specialId: "",
                specialName: "",
                typeName: "",
                workerList: checkPersonList
              }
            ];
          }
        })
        .catch(err => {
          this.$toast.fail(err.msg || "请求失败,请重试");
        });
    },
    onPersonConfirm(ids, list) {
      const params = list.map(item => {
        return {
          id: item.value,
          name: item.label,
          type: item.type
        };
      });
      addAffectedPerson(this.ticketId, params)
        .then(res => {
          const checkPersonList = [];
          const workerList =
            (this.value.workerList[0] && this.value.workerList[0].workerList) ||
            [];
          list.forEach(item => {
            const find = workerList.find(f => f.personId === item.value);
            let obj = {
              ...find,
              ticketId: this.ticketId,
              personType: "7",
              specialId: ""
            };
            if (!find) {
              obj = {
                current: 1,
                fileId: "",
                personId: item.value,
                personName: item.label,
                ticketId: this.ticketId,
                personType: "7",
                specialId: "",
                type: item.type
              };
            }
            checkPersonList.push(obj);
          });
          if (
            Array.isArray(this.value.workerList) &&
            this.value.workerList.length
          ) {
            this.value.workerList[0].workerList = checkPersonList;
          } else {
            this.value.workerList = [
              {
                specialId: "",
                specialName: "",
                typeName: "",
                workerList: checkPersonList
              }
            ];
          }
        })
        .catch(err => {
          this.$toast.fail(err.msg || "请求失败,请重试");
        });
    },
    typeSwitch({ fileId, current }) {
      if (current) {
        if (fileId) {
          return "green";
        }
        return "white";
      }
      return "gray";
    },
    async handleClickTag(
      item,
      fileId,
      personId,
      personType,
      ticketId,
      specialId,
      read
    ) {
      // 安全交底待签名
      if (personType === this.activePersonType && !fileId) {
        const params = {
          ticketId: this.ticketId
        };
        const safetyRegulation = await getSafetyRegulation(params);
        if (!safetyRegulation.contentList.length) {
          this.$toast("请编辑安全交底清单");
          return;
        }
      }
      if (fileId) {
        //已上传过，直接预览
        esign.showImage(fileUrl + fileId);
        return;
      }
      if (
        this.ticketStatus !== WORKTICKET_STATUS.Safety_disclosureing ||
        !item.current
      ) {
        return;
      }
      // ["3", "15"].includes(personType) && !read
      if (this.safeInventoryConfirmPersonIds.includes(personId) && !read) {
        this.cacheSignData = {
          item,
          fileId,
          personId,
          personType,
          ticketId,
          specialId
        };
        this.safeInventoryVb = true;
        return;
      }
      esign.show(imageRes => {
        fileId = imageRes.id;
        addWorkSignature({
          fileId,
          personId,
          personType,
          ticketId,
          specialId
        })
          .then(res => {
            item.fileId = fileId;
            this.$toast.success("提交成功");
            this.$emit("signature-success");
          })
          .catch(error => {
            this.$toast.fail("签名失败，请重试");
          });
      });
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.WorkticketSafetyItem {
  padding: 16px;

  .title {
    color: $--color-black-title;
    font-size: 16px;
    margin-bottom: 10px;
  }

  .title1 {
    color: $--color-black-title;
    font-size: 14px;
    margin-bottom: 10px;

    &:before {
      display: inline-block;
      content: " ";
      width: 3px;
      height: 12px;
      background: $--color-primary;
      border-radius: 2px;
      margin-right: 3px;
    }
  }

  .van-checkbox {
    align-items: flex-start;
    margin-bottom: 10px;
    pointer-events: none;
    ::v-deep .van-checkbox__label {
      font-size: 12px;
      text-align: justify;
      color: #8c8f97;
    }
  }
  .btn-select-person {
    display: block;
    width: 86px;
    height: 32px;
    line-height: 30px;
    font-size: 14px;
    font-weight: 400;
    text-align: center;
    background-color: #1676ff;
    color: #ffffff;
    padding: 0;
    margin: 0 0 16px;
  }

  .tag-box {
    margin-bottom: 16px;
  }
}
</style>
