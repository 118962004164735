<template>
  <van-popup
    v-model="visible"
    class="popup-work-ticket-select"
    :close-on-click-overlay="false"
    :overlay="false"
    get-container="body"
    position="bottom"
    v-bind="$attrs"
    @opened="onOpen"
    @close="onClose"
    @click-overlay="onClose"
    v-on="$listeners"
  >
    <div class="container">
      <div class="header">
        <p class="title">{{ title }}</p>
        <van-icon
          class="close-icon"
          name="mengcengguanbi"
          class-prefix="iconfont"
          @click.native="$emit('close')"
        />
      </div>
      <div>
        <div>
          <van-search
            v-model="query.ticketInfo"
            class="search-bar__workticket"
            placeholder="请输入关键词"
            @search="getList(true)"
            @clear="getList(true)"
          />
          <date-time
            v-model="dateTime"
            :time-close="false"
            type="date"
            format="{y}-{m}-{d}"
            @input="getList(true)"
          />
          <work-query :query="query" @refresh="onQueryChange"></work-query>
        </div>
        <LoadListView
          v-model="loadStatus"
          class="work-ticket-load-list-view"
          :list="list"
          :total="total"
          @load="getList"
        >
          <div
            v-for="(item, index) in list"
            :key="index"
            class="work-ticket-item-box"
          >
            <WorkticketItem
              :id="item.id"
              :item="item"
              :class="{ 'block-space_8': index < list.length - 1 }"
              :title="item.name"
              :status="item.statusName"
              :types="item.workType"
              :number="item.code"
              :start-time="item.planStart"
              :end-time="item.planEnd"
              :text1="item.departmentName"
              :text2="item.facilityName"
              :text3="item.supervisorList"
              :text4="item.approverList"
              :router-link="false"
            />
            <van-checkbox
              v-model="item.checked"
              class="wti-checkbox"
              icon-size="18px"
            ></van-checkbox>
          </div>
        </LoadListView>
      </div>
      <div class="bottom-box">
        <span class="btn cancel" @click="onClose">取消</span>
        <span class="btn confirm" @click="onConfirm">确定</span>
      </div>
    </div>
  </van-popup>
</template>

<script>
import { mapState } from "vuex";
import WorkticketItem from "@/components/workticket/WorkticketItem";
import LoadListView from "@/components/LoadListView";
import WorkQuery from "@/views/workticket/components/WorkQuery";
import { getWorkTicketByFilterRelation } from "@/api/workticket";
import { getTimeParseDate } from "@/utils";

export default {
  name: "WorkTicketSelect",
  components: {
    WorkticketItem,
    LoadListView,
    WorkQuery
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: "作业票"
    }
  },
  data() {
    return {
      dateTime: {
        start: "",
        end: ""
      },
      loadStatus: "loading",
      list: [],
      total: 0,
      query: {
        size: 10,
        page: 1,
        ticketInfo: "",
        queryTimeStart: "",
        queryTimeEnd: "",
        workType: "",
        workStatus: "",
        department: "",
        poiId: "" //作业区域
      }
    };
  },
  computed: {
    ...mapState({
      userInfo: state => state.login.userInfo
    })
  },
  watch: {},
  // 生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  methods: {
    onOpen() {
      this.getList(true);
    },
    onClose() {
      this.query = {
        size: 10,
        page: 1,
        ticketInfo: "",
        queryTimeStart: "",
        queryTimeEnd: "",
        workType: "",
        workStatus: "",
        department: "",
        poiId: "" //作业区域
      };
      this.dateTime = {
        start: "",
        end: ""
      };
      this.$emit("close");
    },
    onConfirm() {
      const checkedList = this.list.filter(item => item.checked);
      this.$emit("onConfirm", checkedList);
    },
    onQueryChange(query) {
      this.query = { ...this.query, ...query };
      this.getList(true);
    },
    /**
     * 是否是刷新,重新加载第一页
     * @param refresh
     */
    getList(refresh = false) {
      if (refresh) {
        this.query.page = 1;
        this.list = [];
        this.loadStatus = "loading";
      }

      getWorkTicketByFilterRelation({
        ...this.query,
        orgCode: this.userInfo.orgCode,
        queryTimeStart: getTimeParseDate(
          this.dateTime.start && this.dateTime.start + " 00:00:00"
        ),
        queryTimeEnd: getTimeParseDate(
          this.dateTime.end && this.dateTime.end + " 23:59:59"
        )
      })
        .then(({ total, list }) => {
          this.list = this.list.concat(
            list.map(item => {
              item.checked = false;
              return item;
            })
          );
          this.total = total;
          this.loadStatus = "success";
          if (list && list.length > 0) {
            this.query.page++;
          }
        })
        .catch(error => {
          console.log(error);
          this.loadStatus = "error";
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.popup-work-ticket-select {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  .container {
    height: 100%;
    position: relative;
    .header {
      position: relative;
      text-align: center;
      font-size: 18px;
      font-weight: bold;
      color: #2e2e4d;
      padding-bottom: 20px;
      padding-top: 30px;
      .title {
        font-size: 18px;
        font-weight: 400;
        text-align: center;
        color: #2e2e4d;
        line-height: 17px;
      }
      .close-icon {
        position: absolute;
        top: 30px;
        left: 16px;
        color: #9496a3;
        font-size: 18px;
      }
    }
    .work-ticket-load-list-view {
      height: calc(100vh - 220px);
      overflow-y: auto;
      .work-ticket-item-box {
        position: relative;
        .wti-checkbox {
          position: absolute;
          top: 54px;
          right: 20px;
        }
      }
    }
    .bottom-box {
      display: flex;
      width: calc(100% - 32px);
      position: absolute;
      left: 0;
      bottom: 0;
      padding: 0 16px 16px;
      .btn {
        // display: inline-block;
        flex: 1;
        width: 165px;
        height: 44px;
        line-height: 44px;
        border-radius: 28px;
        font-size: 16px;
        font-weight: 500;
        text-align: center;
        box-sizing: border-box;
      }
      .cancel {
        color: #8c8f97;
        background: #ffffff;
        border: 1px solid #c7c9d0;
        margin: 0 13px 0 0;
      }
      .confirm {
        background: #1676ff;
        color: #fff;
      }
    }
  }
}
</style>
