<template>
  <van-popup
    v-model="visible"
    :close-on-click-overlay="false"
    :overlay="false"
    get-container="body"
    v-bind="$attrs"
    class="select-person-tabs"
    @opened="onOpen"
    @close="onClose"
    @click-overlay="onClose"
    v-on="$listeners"
  >
    <section class="select-person__container">
      <div class="select-person__header">
        <h3 class="select-person__title">{{ title }}</h3>
        <van-icon
          class="close-icon"
          name="mengcengguanbi"
          class-prefix="iconfont"
          @click.native="$emit('close')"
        />
      </div>
      <van-tabs
        v-model="tabsValue"
        type="card"
        color="#1676ff"
        @change="tabsChange"
      >
        <van-tab title="企业">
          <div class="select-person__search">
            <van-field
              v-model="queryInfo.departmentName"
              left-icon="shouqi"
              readonly
              icon-prefix="iconfont"
              placeholder="所属部门"
              class="select-person__department"
              :disabled="disabledDepartmentQuery"
              @click="!disabledDepartmentQuery && (departmentVisible = true)"
            />
            <van-search
              v-model="queryInfo.keyInfo"
              autofocus
              placeholder="请输入关键词"
              @search="onSearch"
            >
              <template #left-icon>
                <van-icon
                  name="sousuo"
                  class-prefix="iconfont"
                  class="search-icon"
                />
              </template>
            </van-search>
          </div>
          <div class="select-person__list">
            <van-pull-refresh
              v-model="refreshing"
              class="select-person__list-wrapper--choose"
              :class="{
                'select-person__list-wrapper': true
              }"
              @refresh="onSearch"
            >
              <van-checkbox-group v-model="chooseIds" :border="false">
                <van-list
                  v-model="loading"
                  :error.sync="isError"
                  :finished="finished"
                  :finished-text="isError ? '' : '没有更多了'"
                  error-text="加载失败，请稍后再试！"
                  @load="getPersonList"
                >
                  <van-cell
                    v-for="(item, index) in personList"
                    :key="index"
                    :border="false"
                    clickable
                    @click="selectPerson(item)"
                  >
                    <van-checkbox :name="item.value" label-position="left">
                      <template #icon>
                        <van-icon
                          :name="
                            item.checked ? 'danxuan-yixuan' : 'danxuan-weixuan'
                          "
                          class-prefix="iconfont"
                          :color="item.checked ? ' #1676FF' : '#D4DBE3'"
                          class="choose-icon"
                        />
                      </template>
                      <div class="checkbox-list">
                        <span class="custom-title">{{ item.label }}</span>
                        <span v-if="showDepartmentName" class="additional"
                          >（{{ item.departmentName || "" }}）</span
                        >
                        <span v-if="showPhoneNumber" class="additional">{{
                          item.workMobile || ""
                        }}</span>
                      </div>
                    </van-checkbox>
                  </van-cell>
                </van-list>
              </van-checkbox-group>
            </van-pull-refresh>
          </div>
        </van-tab>
        <van-tab v-if="showContractorPerson" title="承包商">
          <div class="select-person__search">
            <van-field
              :value="departmentNameSwitch"
              left-icon="shouqi"
              readonly
              icon-prefix="iconfont"
              placeholder="所属承包商"
              class="select-person__department"
              :disabled="disabledContractorQuery"
              @click="
                !disabledContractorQuery && (popupContractorVisible = true)
              "
            />
            <van-search
              v-model="queryInfoC.name"
              autofocus
              placeholder="请输入关键词"
              @search="onSearchC"
            >
              <template #left-icon>
                <van-icon
                  name="sousuo"
                  class-prefix="iconfont"
                  class="search-icon"
                />
              </template>
            </van-search>
          </div>
          <div class="select-person__list">
            <van-pull-refresh
              v-model="refreshingC"
              class="select-person__list-wrapper--choose"
              :class="{
                'select-person__list-wrapper': true
              }"
              @refresh="onSearchC"
            >
              <van-checkbox-group v-model="chooseIds" :border="false">
                <van-list
                  v-model="loadingC"
                  :error.sync="isErrorC"
                  :finished="finishedC"
                  :finished-text="isErrorC ? '' : '没有更多了'"
                  error-text="加载失败，请稍后再试！"
                  @load="getPersonListC"
                >
                  <van-cell
                    v-for="(item, index) in personListC"
                    :key="index"
                    :border="false"
                    clickable
                    @click="selectPersonC(item)"
                  >
                    <van-checkbox :name="item.value" label-position="left">
                      <template #icon>
                        <van-icon
                          :name="
                            item.checked ? 'danxuan-yixuan' : 'danxuan-weixuan'
                          "
                          class-prefix="iconfont"
                          :color="item.checked ? ' #1676FF' : '#D4DBE3'"
                          class="choose-icon"
                        />
                      </template>
                      <div class="checkbox-list">
                        <span class="custom-title">{{ item.label }}</span>
                        <span v-if="showDepartmentName" class="additional"
                          >（{{ item.departmentName || "" }}）</span
                        >
                        <span v-if="showPhoneNumber" class="additional">{{
                          item.workMobile || ""
                        }}</span>
                      </div>
                    </van-checkbox>
                  </van-cell>
                </van-list>
              </van-checkbox-group>
            </van-pull-refresh>
          </div>
        </van-tab>
        <!-- <van-tab v-if="showRecentUsePerson" title="最近使用">
          <div class="select-person__search">
            <van-search
              v-model="queryInfoRecent.name"
              autofocus
              placeholder="请输入关键词"
              @search="onSearchRecent"
            >
              <template #left-icon>
                <van-icon
                  name="sousuo"
                  class-prefix="iconfont"
                  class="search-icon"
                />
              </template>
            </van-search>
          </div>
          <div class="select-person__list">
            <van-pull-refresh
              v-model="refreshingRecent"
              class="select-person__list-wrapper--choose"
              :class="{
                'select-person__list-wrapper': true
              }"
              @refresh="getPersonListRecent"
            >
              <van-checkbox-group v-model="chooseIds" :border="false">
                <van-list
                  v-model="loadingRecent"
                  :error.sync="isErrorRecent"
                  :finished="finishedRecent"
                  :finished-text="isErrorRecent ? '' : '没有更多了'"
                  error-text="加载失败，请稍后再试！"
                  @load="getPersonListRecent"
                >
                  <van-cell
                    v-for="(item, index) in personListRecentFilterData"
                    :key="index"
                    :border="false"
                    clickable
                    @click="selectPersonRecent(item)"
                  >
                    <van-checkbox :name="item.value" label-position="left">
                      <template #icon>
                        <van-icon
                          :name="
                            item.checked ? 'danxuan-yixuan' : 'danxuan-weixuan'
                          "
                          class-prefix="iconfont"
                          :color="item.checked ? ' #1676FF' : '#D4DBE3'"
                          class="choose-icon"
                        />
                      </template>
                      <div class="checkbox-list">
                        <span class="custom-title">{{ item.label }}</span>
                        <span class="additional"
                          >（{{ item.departmentName || "" }}）</span
                        >
                      </div>
                    </van-checkbox>
                  </van-cell>
                </van-list>
              </van-checkbox-group>
            </van-pull-refresh>
          </div>
        </van-tab> -->
      </van-tabs>
    </section>
    <section class="select-person__footer">
      <div class="select-person__footer-left">
        <span
          v-for="(item, idx) in chooseList"
          :key="idx"
          @click="handleDelete(idx, item)"
          >{{ item.label }}</span
        >
      </div>
      <!-- chooseIds.length -->
      <van-button
        type="info"
        class="select-person__footer-right"
        @click.prevent="onConfirm"
        >确定({{ chooseList.length }})
      </van-button>
    </section>
    <cascade-department
      ref="cascadeDepartment"
      v-model="departmentCur"
      :is-auth="isDepartmentAuth"
      :include-children="includeChildren"
      :visible="departmentVisible"
      :disabled-key="departmentDisabledKey"
      @close="departmentVisible = false"
      @confirm="onTreeConfirm"
    />
    <SelectPopupGrade
      ref="selectPopupGrade"
      v-model="queryInfoC.department"
      :immediately="false"
      list-key="value"
      title="所属承包商"
      :list="popupContractorList"
      :visible.sync="popupContractorVisible"
      popup-height="70%"
      :is-show-confrim-icon="false"
      is-show-footer
      @sure="contractorChange"
    />
  </van-popup>
</template>

<script>
import uniqBy from "lodash/uniqBy";
import { mapState } from "vuex";
import { deepClone } from "@/utils/index";
import CascadeDepartment from "@/components/CascadeDepartment";
import SelectPopupGrade from "@/components/SelectPopupGrade";
import { getPagePerson } from "@/api/psm";
import { getSelectContractorsNames } from "@/api/base";
import {
  getPagePersonsLicense,
  getContractorsPersonByLicense
} from "@/api/workticket";
import { getRecentUsePerson } from "@/api/psm/jsa";

// 可能出现多个人员选择组件，所以不设计双向绑定
export default {
  name: "SelectPersonByTabs",
  components: {
    CascadeDepartment,
    SelectPopupGrade
  },
  props: {
    // 选中的值，Array<Select> [{ label: '姓名', value: 1 }]，兼容选中回显值
    chooseValue: { type: [Array, String], default: () => [] },
    visible: Boolean,
    departmentDisabledKey: {
      type: String,
      default: "disabled"
    },
    isDepartmentAuth: {
      type: Boolean,
      default: true
    },
    title: {
      type: String,
      default: "选择人员"
    },
    multiple: { type: Boolean, default: true },
    showDepartmentName: {
      type: Boolean,
      default: true
    },
    showPhoneNumber: {
      type: Boolean,
      default: false
    },
    // 不包含的用户id
    excludePersonIds: {
      type: Array,
      default: () => []
    },
    licenseTypes: {
      type: String,
      default: ""
    },
    defaultDepartment: {
      type: [String, Number],
      default: ""
    },
    defaultContractor: {
      type: String,
      default: ""
    },
    disabledDepartmentQuery: {
      type: Boolean,
      default: false
    },
    disabledContractorQuery: {
      type: Boolean,
      default: false
    },
    workTypeId: {
      type: String,
      default: ""
    },
    contractorWorkerType: {
      // 不过滤资质需传空字符串！
      // 1 作业人、作业负责人；2 监护人、检测分析人
      type: String,
      default: "2"
    },
    // 最近选择人员类型,1:JSA执行人,2:JSA监护人,3:JSA组长,4:JSA审核,5:JSA其他涉及人员,,6.作业票安全分析监护人,7:作业票安全交底确认人,8:作业票待验收验收人,9:作业票待关闭关闭人
    recentType: {
      type: String
    },
    showContractorPerson: {
      type: Boolean,
      default: true
    },
    showRecentUsePerson: {
      type: Boolean,
      default: false
    },
    defaultTabName: {
      // tab索引
      type: Number
    },
    includeChildren: {
      type: Boolean,
      default: false
    },
    contractorLicenseTypes: {
      type: String,
      default: ""
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.login.userInfo
    }),
    departmentNameSwitch() {
      const find = this.popupContractorList.find(item => {
        return item.value === this.queryInfoC.department;
      });
      return find ? find.label || "" : "";
    }
  },
  data() {
    return {
      queryInfo: {
        department: this.defaultDepartment || "",
        departmentName: "",
        subDepartment: this.includeChildren,
        orgCode: "",
        keyInfo: "",
        positionArr: [],
        positions: "",
        page: 1,
        size: 50
      },
      departmentCur: this.defaultDepartment || "",
      departmentVisible: false,
      personList: [],
      loading: false,
      refreshing: false,
      finished: false,
      isError: false,
      cachePersonList: [],
      chooseIds: [],
      chooseList: [],

      queryInfoC: {
        name: "",
        mobile: "",
        orgCode: this.$store.state.login.userInfo.orgCode,
        department: this.defaultContractor || "",
        workTypeId: this.workTypeId || "",
        type: this.contractorWorkerType || "",
        licenseType: this.contractorLicenseTypes || "",
        accountStates: 0,
        page: 1,
        size: 50
      },
      popupContractorVisible: false,
      popupContractorList: [],
      personListC: [],
      loadingC: false,
      refreshingC: false,
      finishedC: false,
      isErrorC: false,
      cachePersonListC: [],
      tabsValue:
        Number(this.defaultTabName) === 2
          ? 0
          : Number(this.defaultTabName) || 0, // “最近使用”列表没有过滤资质，先做隐藏处理
      queryInfoRecent: {
        name: "",
        // 最近选择人员类型,1:JSA执行人,2:JSA监护人,3:JSA组长,4:JSA审核,5:JSA其他涉及人员,,6.作业票安全分析监护人,7:作业票安全交底确认人,8:作业票待验收验收人,9:作业票待关闭关闭人
        workerType: this.recentType || "",
        orgType: "", // 人员类型,0:部门人员，2:承包商人员
        orgCode: this.$store.state.login.userInfo.orgCode
      },
      personListRecent: [],
      loadingRecent: false,
      refreshingRecent: false,
      finishedRecent: false,
      isErrorRecent: false,
      cachePersonListRecent: [],
      personListRecentFilterData: []
    };
  },
  watch: {
    "queryInfo.positionArr": {
      handler(val) {
        if (val) {
          this.queryInfo.positions = val.join();
        }
      },
      deep: true
    }
    // defaultDepartment: {
    //   handler(newVal) {
    //     console.log("111", newVal);
    //     this.queryInfo.department = newVal;
    //   }
    // }
  },
  methods: {
    async getPersonList() {
      this.finished = false;
      this.loading = true;
      if (this.refreshing) {
        this.personList = [];
        this.refreshing = false;
      }
      try {
        this.queryInfo.orgCode = this.userInfo.orgCode;
        this.queryInfo.workerIds = this.excludePersonIds.join(",");
        let list = [];
        let total = 0;
        if (this.licenseTypes) {
          const params = {
            ...this.queryInfo,
            licenseTypes: this.licenseTypes
          };
          const res = await getPagePersonsLicense(params);
          list = (res.list || []).map(item => {
            item.label = item.name;
            item.value = item.id;
            item._mechanismType = item.type;
            return item;
          });
          total = res.total;
        } else {
          const res = await getPagePerson(this.queryInfo, true);
          list = (res.list || []).map(item => {
            item._mechanismType = item.type;
            return item;
          });
          total = res.total;
        }
        this.loading = false;
        // 当前列表数量等于总数 暂无内容
        if (list && list.length) {
          this.personList =
            this.queryInfo.page === 1
              ? list
              : uniqBy(this.personList.concat(list), "value");
          // this.cachePersonList = uniqBy(
          //   this.cachePersonList.concat(this.personList),
          //   "value"
          // );
          // 回显之前设置的状态
          this.setChooseList();
          if (list.length < this.queryInfo.size) {
            this.finished = true;
          } else {
            // 加载成功下次页码加1
            this.queryInfo.page++;
          }
        } else {
          this.finished = true;
        }
      } catch (error) {
        this.loading = false;
        this.finished = true;
        this.isError = true;
      }
    },
    selectPersonSingle(item) {
      const obj = this.personList.find(
        a => a.checked && a.value !== item.value
      );
      if (obj) {
        obj.checked = false;
      }
      item.checked = !item.checked;
      if (item.checked) {
        this.chooseList = [item];
      } else {
        const index = this.chooseList.findIndex(a => a.value === item.value);
        if (index === -1) {
          return;
        }
        this.chooseList.splice(index, 1);
      }
      this.cachePersonList = deepClone(this.chooseList);
      this.chooseIds = this.chooseList.map(a => a.value);
      this.recentPersonDiff(item);
    },
    selectPerson(item) {
      if (!this.multiple) {
        this.selectPersonSingle(item);
        return;
      }
      item.checked = !item.checked;
      if (item.checked) {
        this.chooseList.push(item);
        // this.personCheckedFilter(item);
      } else {
        const index = this.chooseList.findIndex(a => a.value === item.value);
        if (index === -1) {
          return;
        }
        this.chooseList.splice(index, 1);
      }
      this.cachePersonList = deepClone(this.chooseList);
      this.chooseIds = this.chooseList.map(a => a.value);
      // this.emptyListChecked("personListC");
      this.recentPersonDiff(item);
      console.log(
        "this.chooseList",
        this.chooseList,
        this.cachePersonList,
        this.chooseIds
      );
    },
    async onOpen() {
      // const filterId = this.chooseValue.filter(i => i.id || i.value);
      // const chooseValue = [...filterId];
      const chooseValue = [...this.chooseValue];
      this.chooseList = chooseValue;
      this.chooseIds = chooseValue.filter(a => a.value).map(x => x.value);
      this.cachePersonList = chooseValue;
      this.cachePersonListC = chooseValue;
      this.cachePersonListRecent = chooseValue;
      // this.onSearch();
      // this.setChooseList();
      // 如果有默认部门, 则需要获取部门默认值
      if (this.defaultDepartment && this.tabsValue === 0) {
        this.queryInfo.department = this.defaultDepartment;
        // departmentCur用于包含子部门时department为多个时使用
        this.departmentCur = this.defaultDepartment;
        this.$nextTick(() => {
          const v = this.$refs.cascadeDepartment.getSelectValue();
          this.queryInfo.departmentName = v.label;
          if (this.includeChildren) {
            this.$refs.cascadeDepartment.onConfirm();
          }
        });
      } else {
        // 如果重置有问题则用多个组件
        this.queryInfo.department = "";
        this.queryInfo.departmentName = "";
      }
      // this.tabsValue === 0 ? this.onSearch() : this.onSearchC();
      this.onSearch();
      this.onSearchC();
      if (this.showRecentUsePerson) {
        this.getPersonListRecent();
      }
    },
    onClose() {
      // 重置查询
      // this.queryInfo.department = "";
      // this.queryInfo.departmentName = "";
      this.queryInfo.keyInfo = "";
      this.queryInfo.page = 1;
      this.queryInfoC.name = "";
      this.queryInfoC.page = 1;
      this.$emit("close");
    },
    onConfirm() {
      this.$emit("confirm", this.chooseIds, this.chooseList);
      this.$emit("close");
    },
    onTreeConfirm(val, { include }) {
      this.departmentCur = val?.id || "";
      this.queryInfo.department = val?.id || "";
      this.queryInfo.subDepartment = include;
      this.queryInfo.departmentName = val?.label;
      this.onSearch();
    },
    onSearch() {
      this.queryInfo.page = 1;
      this.personList = [];
      this.getPersonList();
    },
    setChooseList(ids) {
      const _ids = ids || this.chooseIds;
      // this.chooseList = this.cachePersonList.filter(person => {
      //   const b = _ids.includes(person.value);
      //   if (b) {
      //     person.checked = true;
      //   }
      //   return b;
      // });
      // 回显列表选中态
      this.personList.map(a => {
        a.checked = _ids.includes(a.value);
      });
    },
    handleDelete(idx, item) {
      const person = this.personList.find(a => a.value === item.value);
      person && (person.checked = false);
      const personC = this.personListC.find(a => a.value === item.value);
      personC && (personC.checked = false);
      const recent = this.personListRecent.find(a => a.value === item.value);
      recent && (recent.checked = false);
      this.chooseList.splice(idx, 1);
      this.chooseIds = this.chooseList.map(a => a.value);
    },
    contractorChange(val) {
      this.queryInfoC.department = val[0] ? val[0].value : "";
      this.onSearchC();
    },
    async tabsChange(name, title) {
      // switch (name) {
      //   case 0:
      //     this.getPersonList();
      //     break;
      //   case 1:
      //     await this.getSelectContractorsNames();
      //     this.getPersonListC();
      //     break;
      // }
      switch (name) {
        case 0:
          this.onSearch();
          break;
        case 1:
          await this.getSelectContractorsNames();
          this.onSearchC();
          break;
      }
    },
    onSearchC() {
      this.queryInfoC.page = 1;
      this.personListC = [];
      this.getPersonListC();
    },
    onSearchRecent() {
      if (!this.queryInfoRecent.name) {
        this.personListRecentFilterData = this.personListRecent;
      }
      this.personListRecentFilterData = this.personListRecent.filter(i =>
        i.name.includes(this.queryInfoRecent.name)
      );
    },
    async getSelectContractorsNames() {
      const params = {
        orgCodeInList: this.userInfo.orgCode
      };
      this.popupContractorList = await getSelectContractorsNames(params);
    },
    async getPersonListC() {
      this.finishedC = false;
      this.loadingC = true;
      if (this.refreshingC) {
        this.personListC = [];
        this.refreshingC = false;
      }
      try {
        let list = [];
        let res = await getContractorsPersonByLicense({
          ...this.queryInfoC,
          personIdNotIn: this.excludePersonIds.join(","),
          type: this.contractorWorkerType
        });
        res = res || {};
        list = (res.list || []).map(item => {
          item.label = item.personName;
          item.value = item.personId;
          item._mechanismType = item.type;
          // item._chooseType = "c";
          return item;
        });

        this.loadingC = false;
        // 当前列表数量等于总数 暂无内容
        if (list && list.length) {
          this.personListC =
            this.queryInfoC.page === 1
              ? list
              : uniqBy(this.personListC.concat(list), "value");
          // this.cachePersonListC = uniqBy(
          //   this.cachePersonListC.concat(this.personListC),
          //   "value"
          // );
          // 回显之前设置的状态
          this.setChooseListC();
          if (list.length < this.queryInfoC.size) {
            this.finishedC = true;
          } else {
            // 加载成功下次页码加1
            this.queryInfoC.page++;
          }
        } else {
          this.finishedC = true;
        }
      } catch (error) {
        this.loadingC = false;
        this.finishedC = true;
        this.isErrorC = true;
      }
    },
    async getPersonListRecent() {
      this.finishedRecent = false;
      this.loadingRecent = true;
      if (this.refreshingRecent) {
        this.personListRecent = [];
        this.refreshingRecent = false;
      }
      try {
        let list = [];
        const res = await getRecentUsePerson(this.queryInfoRecent);
        list = (res || []).map(item => {
          item.label = item.name;
          item.value = item.personId;
          item.departmentName = item.deptName;
          item._mechanismType = String(item.type);
          return item;
        });
        // this.totalRecent = list.length || 0;

        this.loadingRecent = false;
        // 当前列表数量等于总数 暂无内容
        if (list && list.length) {
          this.personListRecent = list;
          // this.cachePersonListC = uniqBy(
          //   this.cachePersonListC.concat(this.personListC),
          //   "value"
          // );
          // 回显之前设置的状态
          this.setChooseListRecent();
        }
        this.finishedRecent = true;
        this.onSearchRecent();
      } catch (error) {
        this.loadingRecent = false;
        this.finishedRecent = true;
        this.isErrorRecent = true;
      }
    },
    setChooseListC(ids) {
      const _ids = ids || this.chooseIds;
      // this.chooseList = this.cachePersonListC.filter(person => {
      //   const b = _ids.includes(person.value);
      //   if (b) {
      //     person.checked = true;
      //   }
      //   return b;
      // });
      // 回显列表选中态
      this.personListC.map(a => {
        a.checked = _ids.includes(a.value);
      });
    },
    setChooseListRecent(ids) {
      const _ids = ids || this.chooseIds;
      // this.chooseList = this.cachePersonListC.filter(person => {
      //   const b = _ids.includes(person.value);
      //   if (b) {
      //     person.checked = true;
      //   }
      //   return b;
      // });
      // 回显列表选中态
      this.personListRecent.map(a => {
        a.checked = _ids.includes(a.value);
      });
    },
    selectPersonSingleC(item) {
      const obj = this.personListC.find(
        a => a.checked && a.value !== item.value
      );
      if (obj) {
        obj.checked = false;
      }
      item.checked = !item.checked;
      if (item.checked) {
        this.chooseList = [item];
      } else {
        const index = this.chooseList.findIndex(a => a.value === item.value);
        if (index === -1) {
          return;
        }
        this.chooseList.splice(index, 1);
      }
      this.cachePersonListC = deepClone(this.chooseList);
      this.chooseIds = this.chooseList.map(a => a.value);
      this.recentPersonDiff(item);
    },
    selectPersonC(item) {
      if (!this.multiple) {
        this.selectPersonSingleC(item);
        return;
      }
      item.checked = !item.checked;
      if (item.checked) {
        this.chooseList.push(item);
        // this.personCheckedFilter(item);
      } else {
        const index = this.chooseList.findIndex(a => a.value === item.value);
        if (index === -1) {
          return;
        }
        this.chooseList.splice(index, 1);
      }
      this.cachePersonListC = deepClone(this.chooseList);
      this.chooseIds = this.chooseList.map(a => a.value);
      // this.emptyListChecked("personList");
      this.recentPersonDiff(item);
    },
    selectPersonSingleRecent(item) {
      const obj = this.personListRecent.find(
        a => a.checked && a.value !== item.value
      );
      if (obj) {
        obj.checked = false;
      }
      item.checked = !item.checked;
      if (item.checked) {
        this.chooseList = [item];
      } else {
        const index = this.chooseList.findIndex(a => a.value === item.value);
        if (index === -1) {
          return;
        }
        this.chooseList.splice(index, 1);
      }
      this.cachePersonListRecent = deepClone(this.chooseList);
      this.chooseIds = this.chooseList.map(a => a.value);
    },
    selectPersonRecent(item) {
      if (!this.multiple) {
        this.selectPersonSingleRecent(item);
        return;
      }
      item.checked = !item.checked;
      if (item.checked) {
        this.chooseList.push(item);
      } else {
        const index = this.chooseList.findIndex(a => a.value === item.value);
        if (index === -1) {
          return;
        }
        this.chooseList.splice(index, 1);
      }
      this.cachePersonListRecent = deepClone(this.chooseList);
      this.chooseIds = this.chooseList.map(a => a.value);
    },
    recentPersonDiff(otherPersonData) {
      console.log("this.personListRecent", this.personListRecent);
      const findRecent = this.personListRecent.find(
        i => i.value === otherPersonData.value
      );
      // findRecent && this.selectPersonRecent(findRecent);
      if (!findRecent) return;
      findRecent.checked = !findRecent.checked;
    },
    personCheckedFilter(item) {
      // NOTE: 需要知道上次选择的人员是企业还是承包商，来内部增加_chooseType使选择正常，也会导致选择有问题。
      // NOTE: 暂时注释了cachePersonList、cachePersonListC等在数据获取的赋值，因为在open方法里面
      // 被赋值了chooseList的值，请求里的再合并值没有感觉到有什么作用。
      let list = deepClone(this.chooseList);
      const findOri = list.find(item => item._chooseType === "c");
      const findNew = item._chooseType === "c";
      if ((findOri && findNew) || (!findOri && !findNew)) {
        list.push({ ...item });
      } else {
        list = [{ ...item }];
      }
      this.chooseList = list;
    },
    emptyListChecked(prop) {
      this[prop] = this[prop].map(item => {
        item.checked = false;
        return item;
      });
    }
  }
};
</script>

<style lang="scss">
.select-person-tabs {
  /*position: relative;*/
  height: 100vh;
  overflow: hidden;
  &__container {
    margin: 0 16px;
  }
  &__header {
    position: relative;
    text-align: center;
    font-size: 18px;
    font-weight: bold;
    color: #2e2e4d;
    padding-bottom: 20px;
    padding-top: 30px;
  }
  .choose-icon {
    font-size: 18px;
  }
  .close-icon {
    position: absolute;
    top: 16px;
    left: 0;
    color: #9496a3;
    font-size: 16px;
  }
  &__search {
    margin-bottom: 20px;
    .van-search {
      padding: 0;
    }
  }
  &__department {
    border: 1px solid #c7c9d0;
    border-radius: 2px;
    padding-top: 7px;
    padding-left: 12px;
    padding-bottom: 7px;
    font-size: 14px;
    line-height: 1;
    margin-bottom: 12px;
    .van-field__value {
      line-height: 20px;
    }
    ::-webkit-input-placeholder {
      color: #2e2e4d;
    }
    :-moz-placeholder {
      /* Firefox 18- */
      color: #2e2e4d;
    }
    ::-moz-placeholder {
      /* Firefox 19+ */
      color: #2e2e4d;
    }
    :-ms-input-placeholder {
      color: #2e2e4d;
    }
    .van-field__left-icon {
      line-height: 20px;
      .iconfont {
        color: #aeb3c0;
        font-size: 12px;
      }
    }
  }
  &__list {
    height: calc(100vh - 68px - 84px);
    overflow: auto;
    &-wrapper {
      padding-bottom: 20px;
    }
    &-wrapper--choose {
      padding-bottom: 70px;
    }
    .van-checkbox {
      display: flex;
      justify-content: space-between;
    }
    .checkbox-list {
      .custom-title {
        vertical-align: middle;
      }
      .additional {
        vertical-align: middle;
        font-size: 12px;
        color: #cfd2d9;
        margin: 0 0 0 8px;
      }
    }
  }
  &__footer {
    position: absolute;
    bottom: 0;
    height: 50px;
    width: 100vw;
    box-sizing: border-box;
    padding-left: 8px;
    line-height: 50px;
    border-top: 1px solid #c7c9d0;
    background-color: #fff;
    z-index: 2;
    &-left {
      width: calc(100vw - 120px - 10px);
      overflow-x: auto;
      white-space: nowrap;
      font-size: 12px;
      color: #3b4664;
      span {
        padding: 3px 10px;
        background-color: #e1e3e8;
        border-radius: 4px;
      }
      span:not(:last-child) {
        margin-right: 8px;
      }
    }
    &-right {
      position: absolute;
      right: 8px;
      top: 5px;
    }
    .van-button {
      height: 40px;
      line-height: 40px;
      border-radius: $--button-height;
    }
    .van-button--normal {
      padding: 0 24px;
    }
  }
  .van-tabs__wrap {
    border-bottom: none;
    margin: 0 0 16px;
    .van-tabs__nav {
      margin: 0;
      width: 200px;
    }
  }
}
</style>
