<template>
  <el-form-item class="render-item" :prop="itemData.code" label-width="0">
    <div class="line"></div>
  </el-form-item>
</template>

<script>
export default {
  name: "RenderLine",
  props: {
    value: [String, Array],
    itemData: Object
  }
};
</script>

<style lang="scss" scoped>
.render-item {
  position: relative;
  width: 100%;
  height: 10px;
  .line {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    height: 1px;
    background: #ccc;
  }
}
</style>
