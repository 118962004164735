<template>
  <van-overlay
    :show="visiable"
    class="VideoPreview"
    z-index="2001"
    :custom-style="{ backgroundColor: 'rgba(0,0,0,.9)' }"
  >
    <van-icon class="close" name="clear" @click="close" />

    <div class="wrapper">
      <audio
        ref="mAudio"
        class="audio"
        :src="src"
        autoplay
        controls="controls"
        @pause="onPause"
        @play="onPlay"
        @timeupdate="onTimeupdate"
        @loadedmetadata="onLoadedmetadata"
      ></audio>
    </div>
  </van-overlay>
</template>

<script>
export default {
  name: "VideoPreview",
  components: {},
  data() {
    return {
      visiable: true,
      src: "",
      playing: false,
      maxTime: 0, // 音频最大播放时长
      currentTime: 0 // 音频当前播放时长
    };
  },
  created: function() {},
  mounted: function() {},
  methods: {
    show(src) {
      this.src = src;
      this.visiable = true;
      this.play();
    },

    close() {
      this.pause();
      this.visiable = false;
    },

    onPlay() {
      this.playing = true;
    },
    // 当音频暂停
    onPause() {
      this.playing = false;
    },
    // 当加载语音流元数据完成后，会触发该事件的回调函数
    // 语音元数据主要是语音的长度之类的数据
    onLoadedmetadata(res) {
      this.maxTime = parseInt(res.target.duration);
    },
    // 当timeupdate事件大概每秒一次，用来更新音频流的当前播放时间
    // 当音频当前时间改变后，进度条也要改变
    onTimeupdate(res) {
      this.currentTime = res.target.currentTime;
    },
    // 播放音频
    play() {
      setTimeout(() => {
        this.$refs.mAudio.play();
      }, 200);
    },
    // 暂停音频
    pause() {
      this.$refs.mAudio.pause();
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.VideoPreview {
  .close {
    position: absolute;
    top: 16px;
    right: 16px;
    font-size: 20px;
    color: $--color-gray-item-text;
  }

  .wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  .audio {
  }
}
</style>
