<template>
  <div></div>
</template>

<script>
export default {
  name: "WorkticketTag",
  components: {},
  data() {
    return {};
  },
  created: function() {},
  mounted: function() {},
  methods: {}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
