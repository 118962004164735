import Vue from "vue";
import store from "@/store/index";
import InputDialog from "./InputDialog";

const component = Vue.extend(InputDialog);

let instance;

function init() {
  if (!instance) {
    instance = new component({
      store,
      el: document.createElement("div")
    });
    document.body.appendChild(instance.$el);
  }
}

export default {
  show({
    title = "标题",
    successCb,
    failCb,
    ticketId,

    list = [
      {
        message: "",
        hasInput: true,
        isRequired: true,
        hasCheckPerson: false,
        department: "",
        isPersonByTab: true,
        choosePersonLabel: "验收",
        personByTabTitle: "验收人(多选)",
        personByTabMultiple: true,
        showRecentUsePerson: false,
        recentType: "",
        maxlength: 500,
        otherPerson: [],
        otherPersonLabel: "",
        isPowerCut: false,
        powerCutConfig: {},
        defaultCheckPerson: [],
        personType: "",
        specialId: "",
        specialJobType: "",
        startWorkSpaceWorker: false
      }
    ]
    // message = "",
    // hasInput = true,
    // isRequired = true,
    // hasCheckPerson,
    // department,
    // isPersonByTab = true,
    // choosePersonLabel = "验收",
    // personByTabTitle = "验收人(多选)",
    // personByTabMultiple = true,
    // showRecentUsePerson = false,
    // recentType = "",
    // maxlength = 500,
    // otherPerson = [],
    // otherPersonLabel = "",
    // isPowerCut = false,
    // powerCutConfig = {},
    // defaultCheckPerson = [],
    // personType = ""
  } = {}) {
    init();
    if (instance.visible) return;
    const params = {
      title,
      successCb,
      failCb,
      ticketId,
      list
    };
    return instance.show(params);
  },
  close() {
    if (!instance || !instance.visible) return;
    return instance.close();
  },
  removeLoading() {
    if (!instance || !instance.visible) return;
    return instance.removeLoading();
  }
};
