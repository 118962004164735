<template>
  <van-field
    class="render-input-box"
    :label="itemData.label + ':'"
    :required="itemData.options.required"
    :rules="[
      {
        validator: validator
      }
    ]"
  >
    <template #input>
      <template v-if="$attrs.editable">
        <van-radio-group v-model="data">
          <van-radio
            v-for="(item, index) in options"
            :key="index"
            :name="item"
            >{{ item }}</van-radio
          >
        </van-radio-group>
      </template>
      <template v-else>
        <span>{{ Array.isArray(data) ? data.join(",") : data }}</span>
      </template>
    </template>
  </van-field>
</template>

<script>
export default {
  name: "RenderRadio",
  props: {
    value: [String, Array],
    itemData: Object
  },
  data() {
    return {
      data: "",
      options: []
    };
  },
  watch: {
    data: {
      handler: function(val) {
        this.$emit("input", val);
      },
      deep: true
    }
  },
  created() {
    this.data = this.value;
    if (this.itemData.options.isCustomOptions) {
      this.options = this.itemData.options.options;
    } else {
      this.options = [];
    }
  },
  methods: {
    validator(rule, value, callback) {
      if (this.itemData.options) {
        if (
          this.itemData.options.required &&
          (!this.data || !this.data.length)
        ) {
          callback(new Error(`${this.itemData.label}不能为空`));
        }
      }
      callback();
    }
  }
};
</script>

<style scoped lang="scss">
.render-input-box {
  position: relative;
}
</style>
