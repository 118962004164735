<template>
  <div v-if="pwd.length" class="pwd-strong-box">
    <span
      v-for="item in colors"
      :key="item.label"
      class="color-item"
      :style="{
        backgroundColor: item.index <= pwdLevel ? item.activeColor : item.color
      }"
    ></span>
    <span class="pwd-strong-text">{{ curLabel }}</span>
  </div>
</template>

<script>
import {
  REG_PASSWORD,
  REG_UPPER_EN,
  REG_LOWER_EN,
  REG_EN,
  REG_REPEAT,
  REG_REPEAT_NUM,
  REG_REPEAT_EN,
  REG_REPEAT_NUM3,
  REG_REPEAT_EN3
} from "@/utils/constant";
export default {
  name: "PwdStrong",
  props: {
    pwd: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      colors: [
        {
          label: "弱",
          index: 0,
          activeColor: "#EC5F12",
          color: "#ccc"
        },
        {
          label: "中",
          index: 1,
          activeColor: "#EDAE00",
          color: "#ccc"
        },
        {
          label: "强",
          index: 2,
          activeColor: "#3F9F11",
          color: "#ccc"
        }
      ]
    };
  },
  computed: {
    pwdSource() {
      const pwd = this.pwd;
      const len = pwd.length;
      let source = 0;
      // 如果不符合规则,则返回0分
      if (!REG_PASSWORD.test(pwd)) {
        return source;
      }
      source = 70;
      console.log("初始分:", source);
      // + (总字元数 - 8)*4
      source = source + (len - 8) * 4;
      console.log("1:", source);
      const enLen = pwd.match(REG_EN) ? pwd.match(REG_EN).length : 0;
      const enUpperLen = pwd.match(REG_UPPER_EN)
        ? pwd.match(REG_UPPER_EN).length
        : 0;
      const enLowerLen = pwd.match(REG_LOWER_EN)
        ? pwd.match(REG_LOWER_EN).length
        : 0;
      // + (字母字元数 - 大写字母字元数) * (字母字元数 - 小写字母字元数) * 2
      source = source + (enLen - enUpperLen) * (enLen - enLowerLen) * 2;
      console.log("2:", source);
      // - 接连重复字元数 * 2
      const repeatStr = pwd.match(REG_REPEAT);
      console.log("接连重复字元数:", repeatStr);
      if (repeatStr) {
        source = source - repeatStr.length * 4;
        console.log("3:", source);
      }
      // - 接连数字字元数 - 3
      const repeatNumStr = pwd.match(REG_REPEAT_NUM);
      console.log("repeatNumStr:", repeatNumStr);
      if (repeatNumStr) {
        source =
          source - (repeatNumStr.length > 3 ? repeatNumStr.length - 3 : 0);
        console.log("4:", source);
      }
      // - 接连字母字元数 - 3
      const repeatEnStr = pwd.match(REG_REPEAT_EN);
      console.log("repeatEnStr:", repeatEnStr);
      if (repeatEnStr) {
        source = source - (repeatEnStr.length > 3 ? repeatEnStr.length - 3 : 0);
        console.log("5:", source);
      }
      // - 3码以上的连续数字 * 3
      const repeatNumStr3 = pwd.match(REG_REPEAT_NUM3);
      console.log("repeatNumStr3:", repeatNumStr3);
      if (repeatNumStr3) {
        source = source - repeatNumStr3.length * 5;
        console.log("6:", source);
      }
      // - 3码以上的连续字母 * 3
      const repeatEnStr3 = pwd.match(REG_REPEAT_EN3);
      if (repeatEnStr3) {
        source = source - repeatEnStr3.length * 5;
        console.log("7:", source);
      }
      console.log("最终得分:", source);
      return source;
    },
    pwdLevel() {
      if (this.pwdSource < 60) {
        return 0;
      } else if (this.pwdSource > 60 && this.pwdSource < 80) {
        return 1;
      } else {
        return 2;
      }
    },
    curLabel() {
      const obj = this.colors.find(a => a.index === this.pwdLevel);
      return obj.label;
    }
  }
};
</script>

<style lang="scss" scoped>
.pwd-strong-box {
  font-size: 0;
  .color-item {
    display: inline-block;
    width: 60px;
    height: 2px;
  }
  .pwd-strong-text {
    position: relative;
    top: 2px;
    font-size: 12px;
    margin-left: 3px;
  }
}
</style>
