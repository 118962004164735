<template>
  <h3 class="render-title">{{ itemData.label }}</h3>
</template>

<script>
export default {
  name: "RenderTitle",
  props: {
    value: [String, Array],
    itemData: Object
  }
};
</script>

<style lang="scss" scoped>
.render-title {
  width: 100%;
  height: 24px;
  color: #2e2e4d;
  text-indent: 16px;
  background: rgba(22, 118, 255, 0.1);
  line-height: 24px;
  font-weight: normal;
  margin-top: 12px;
  margin-bottom: 16px;
}
</style>
