<template>
  <div class="LoadListView">
    <van-list
      v-show="!isEmpty"
      v-model="isLoading"
      class="van-list"
      :finished="finished"
      :error.sync="isError"
      offset="100"
      :loading-text="loadingText"
      :finished-text="finishedText"
      :error-text="errorText"
      @load="load"
    >
      <div v-show="showTotalView && total > 0" class="total">
        {{ `共${total}条结果` }}
      </div>
      <slot></slot>
    </van-list>

    <slot name="empty">
      <van-empty v-show="isEmpty" :description="emptyText" />
    </slot>
  </div>
</template>

<script>
export default {
  name: "LoadListView",
  components: {},
  model: {
    prop: "status",
    event: "change"
  },
  props: {
    /**
     * 组件通过status变量控制加载状态，
     * 有三个状态：loading=加载中；success=加载成功；error=加载失败。
     * 没有更多和数据为空的情况，组件自己能判断
     */
    status: {
      type: String,
      default: "loading",
      required: true,
      validator: function(value) {
        // 这个值必须匹配下列字符串中的一个
        return ["loading", "success", "error"].indexOf(value) !== -1;
      }
    },
    total: { type: Number, default: 0, required: true },
    list: { type: Array, default: () => [], required: true }, //list数据
    loadingText: { type: String, default: "正在加载..." },
    finishedText: { type: String, default: "没有更多了" },
    errorText: { type: String, default: "加载失败，点击重试" },
    emptyText: { type: String, default: "暂无数据" },
    showTotalView: { type: Boolean, default: true } //显示总数布局
  },
  data() {
    return {
      isLoading: false,
      finished: false,
      isEmpty: false, //列表是否是空数据，默认false数据更新完设置
      isError: false
    };
  },
  watch: {
    isLoading(val) {
      if (val) {
        this.$emit("change", "loading");
      }
    },
    status: {
      handler(val) {
        if (val == "loading") {
          this.isLoading = true;
          this.finished = false;
          this.isEmpty = false;
          this.isError = false;
        } else if (val == "error") {
          this.isLoading = false;
          this.finished = false;
          this.isEmpty = false;
          this.isError = true;
        } else if (val == "success") {
          this.isLoading = false;
          this.finished = this.list.length >= this.total;
          this.isEmpty = this.total == 0;
          this.isError = false;
        }
      },
      immediate: true
    }
  },
  created: function() {},
  mounted: function() {},
  methods: {
    load() {
      this.$emit("load");
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.LoadListView {
  /*height: calc(100vh - 58px - 138px - 40px - 16px);*/
  /*overflow: auto;*/
  .total {
    height: 40px;
    line-height: 40px;
    font-size: 12px;
    text-align: center;
    color: #8c8f97;
  }
}
</style>
