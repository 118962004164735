<template>
  <div class="WorkAreaPicker">
    <van-popup v-model="showPopup" :title="title" position="bottom" round>
      <div class="title">
        {{ title }}
        <!-- <van-icon class="close" name="cross" @click="close" /> -->
        <!-- <van-icon class="confirm" name="success" @click="confirm" /> -->
        <i class="close iconfont iconfont-danchuangguanbi" @click="close"></i>
        <i
          class="confirm iconfont iconfont-danchuangqueren"
          @click="confirm"
        ></i>
      </div>
      <van-tabs
        v-model="active"
        swipeable
        animated
        :lazy-render="false"
        @change="onTabsChange"
      >
        <van-tab
          v-for="(item, index) in options"
          :key="index"
          :name="index"
          :title="item.label"
        >
          <div class="list-box">
            <LoadListView
              v-model="item.status"
              :list="item.list"
              :total="item.total"
              :finished-text="''"
              :show-total-view="false"
              @load="loadMore(index)"
            >
              <ul class="checkbox-group">
                <li
                  v-for="(item1, index1) in item.list"
                  :key="index1"
                  class="item"
                  :class="{ active: isItemChecked(item1) }"
                  @click="clickItem(index1, item1, item)"
                >
                  <span>{{ item1.label }}</span>
                </li>
              </ul>
            </LoadListView>
          </div>
        </van-tab>
      </van-tabs>
    </van-popup>
  </div>
</template>

<script>
import LoadListView from "@/components/LoadListView";
import {
  getCheckedArrayValues,
  getOtpionLabel,
  isChecked,
  toArray,
  toValue
} from "@/components/edit/utils/options";

export default {
  name: "WorkAreaPicker",
  components: { LoadListView },
  model: {
    prop: "value",
    event: "change"
  },
  props: {
    title: { type: String, default: "作业区域" },
    value: { type: [String, Number, Array], default: undefined },
    label: { type: String, default: "" }, //sync,选择项的label
    multiple: { type: Boolean, default: false } //是否多选,多选value是数组
  },
  data() {
    return {
      showPopup: false,
      active: 0,
      copyValue: [],
      valueAllDataList: []
    };
  },
  computed: {
    options() {
      return this.$store.state.workticket.areaOptions;
    }
  },
  watch: {},
  created: function() {},
  mounted: function() {
    this.onTabsChange(0);
  },
  methods: {
    close() {
      this.showPopup = false;
      this.copyValue = this.value;
    },
    confirm() {
      let label = this.options
        .map(item => {
          return getOtpionLabel({
            options: item.list,
            value: this.copyValue
          });
        })
        .filter(item => !!item)
        .join(",");
      this.$emit(
        "change",
        toValue(this.copyValue, this.multiple),
        label,
        this.valueAllDataList
      );
      this.$emit("update:label", label);
      this.showPopup = false;
    },
    convertValue(value) {
      if (!Array.isArray(value)) {
        value = [value];
      }
      const idx = this.active;
      const idList = [];
      value.forEach(v => {
        const id = this.options[idx].list?.find(o => o.label === v)?.value;
        if (id) {
          idList.push(id);
        }
      });
      return idList;
    },
    show() {
      this.showPopup = true;
      this.copyValue = toArray(this.convertValue(this.value));

      if (!this.multiple) {
        //判断显示哪个tab
        const isItemChecked = this.isItemChecked;
        let active = this.options.findIndex(option => {
          return option.list.findIndex(item => isItemChecked(item)) != -1;
        });
        if (active != -1) {
          this.active = active;
        }
      }
    },
    isItemChecked(item) {
      let { copyValue } = this;
      return isChecked({
        checked: copyValue,
        value: item.value
      });
    },
    clickItem(idx, listItem, categoryItem) {
      const listItemValue = listItem.value;
      this.copyValue = getCheckedArrayValues({
        checked: this.copyValue,
        value: listItemValue,
        multiple: this.multiple,
        inverse: true
      });
      const isChecked = this.isItemChecked(listItem);
      if (isChecked) {
        const obj = {
          value: listItemValue,
          label: listItem.label,
          type: categoryItem.type
        };
        if (this.multiple) {
          this.valueAllDataList.push(obj);
          return;
        }
        this.valueAllDataList = [obj];
      } else {
        this.valueAllDataList = this.valueAllDataList.filter(
          item => item.value !== listItemValue
        );
      }
    },
    getList(optionIndex) {
      this.$store.dispatch("getAreaOptionsList", this.options[optionIndex].key);
    },
    onTabsChange(index) {
      this.getList(index);
    },
    loadMore(index) {
      this.getList(index);
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.WorkAreaPicker {
  .title {
    position: relative;
    font-size: 16px;
    text-align: center;
    color: $--color-black-title;
    line-height: 22px;
    padding: 15px 0px 5px 0px;
    font-weight: 500;

    .close {
      position: absolute;
      left: 16px;
      top: 16px;
      font-size: 18px;
      color: $--color-gray-item-text;
    }

    .confirm {
      position: absolute;
      right: 16px;
      top: 16px;
      font-size: 18px;
      color: $--color-primary;
    }
  }

  .list-box {
    height: 275px;
    overflow-y: scroll;
  }

  .checkbox-group {
    .item {
      position: relative;
      height: 38px;
      line-height: 38px;
      font-size: 14px;
      text-align: left;
      color: $--color-black-list-page;
      padding: 0px 17px;

      span {
        display: inline-block;
        line-height: 20px;
      }

      &.active {
        color: $--color-primary;
        // color: #1676ff;
        background-color: rgba(22, 118, 255, 0.1);
      }

      .icon {
        position: absolute;
        right: 17px;
        top: 50%;
        transform: translateY(-50%);
        font-size: 18px;
      }
    }
  }

  ::v-deep .van-hairline--top-bottom::after,
  .van-hairline-unset--top-bottom::after {
    border: none;
  }
}
</style>
