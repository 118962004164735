<template>
  <el-form-item
    class="render-input-box"
    :label="itemData.label"
    :prop="itemData.code"
    :rules="[
      {
        required: itemData.options.required,
        validator: validator,
        trigger: ['blur', 'change']
      }
    ]"
  >
    <template v-if="$attrs.editable">
      <select-indicator
        v-model="data"
        :is-multiple="itemData.options.isMultiple"
        :placeholder="itemData.options.placeholder"
        :clearable="itemData.options.clearable"
      ></select-indicator>
    </template>
    <template v-else>
      <span>{{ getData() }}</span>
    </template>
  </el-form-item>
</template>

<script>
export default {
  name: "RenderSelectIndicator",
  props: {
    value: [String, Array],
    itemData: Object
  },
  data() {
    return {
      data: "",
      options: []
    };
  },
  watch: {
    data: {
      handler: function(val) {
        this.$emit("input", val);
      },
      deep: true
    }
  },
  created() {
    this.data = this.itemData.options.isMultiple
      ? Array.isArray(this.value)
        ? this.value
        : this.value && this.value.split(",")
      : this.value;
    if (this.itemData.options.isCustomOptions) {
      this.options = this.itemData.options.options;
    } else {
      this.options = [];
    }
  },
  methods: {
    validator(rule, value, callback) {
      if (this.itemData.options) {
        if (
          this.itemData.options.required &&
          (!this.data || !this.data.length)
        ) {
          callback(new Error(`${this.itemData.label}不能为空`));
        }
      }
      callback();
    },
    getData() {
      let arr = this.itemData.options.isMultiple ? this.value : [this.value];
      return this.$attrs["indicator-data"]
        ?.filter(s => arr.includes(s.value))
        .map(s => s.label)
        .join(",");
    }
  }
};
</script>

<style scoped lang="scss">
.render-input-box {
  position: relative;
  .el-select {
    width: 100%;
  }
}
</style>
