<template>
  <div class="CheckboxPicker">
    <van-popup v-model="showPopup" position="bottom" round>
      <!--      <div class="title">-->
      <!--        {{ title }}-->
      <!--        <i class="close" @click="close">取消</i>-->
      <!--        <i class="confirm" @click="confirm">确认</i>-->
      <!--      </div>-->
      <div class="header">
        <i
          class="iconclose iconfont iconfont-danchuangguanbi"
          @click="close"
        ></i>
        <h3 class="popup-title">{{ title }}</h3>
        <i
          class="iconsure iconfont iconfont-danchuangqueren"
          @click="confirm"
        ></i>
      </div>
      <ul class="checkbox-group">
        <li
          v-for="(item, index) in options"
          :key="index"
          class="item"
          :class="{ active: isItemChecked(item) }"
          @click="clickItem(index, item)"
        >
          <span>{{ item[optionLabelKey] }}</span>
          <van-icon v-show="isItemChecked(item)" class="icon" name="success" />
        </li>
      </ul>
    </van-popup>
  </div>
</template>

<script>
import {
  copyValue,
  getCheckedValues,
  getOtpionLabel,
  isChecked
} from "@/components/edit/utils/options";

export default {
  name: "CheckboxPicker", //多选
  components: {},
  model: {
    prop: "value",
    event: "change"
  },
  props: {
    title: { type: String, default: "标题" },
    value: { type: Array, default: () => [] },
    label: { type: String, default: "" }, //sync,选择项的label
    options: { type: Array, default: () => [] },
    optionLabelKey: { type: String, default: "label" },
    optionValueKey: { type: String, default: "value" }
  },
  data() {
    return {
      showPopup: false,
      copyValue: "",
      copyLabel: ""
    };
  },
  watch: {
    value: {
      immediate: true,
      handler(val) {
        this.$emit("update:label", this.getLabel());
      }
    },
    options() {
      this.$emit("update:label", this.getLabel());
    }
  },
  created: function() {},
  mounted: function() {},
  methods: {
    close() {
      this.showPopup = false;
      this.copyValue = copyValue(this.value);
      this.copyLabel = copyValue(this.label);
    },
    confirm() {
      this.showPopup = false;
      this.$emit("change", this.copyValue, this.copyLabel);
      this.$emit("update:label", this.copyLabel);
    },
    show() {
      this.showPopup = true;
      this.copyValue = copyValue(this.value);
      this.copyLabel = copyValue(this.label);
    },
    isItemChecked(item) {
      return isChecked({
        checked: this.copyValue,
        value: item[this.optionValueKey],
        optionValueKey: this.optionValueKey
      });
    },
    clickItem(index, item) {
      this.copyValue = getCheckedValues({
        checked: this.copyValue,
        value: item[this.optionValueKey],
        multiple: true
      });
    },
    getLabel() {
      let { value, options, optionValueKey, optionLabelKey } = this;
      return getOtpionLabel({
        options,
        value,
        optionValueKey,
        optionLabelKey
      });
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.CheckboxPicker {
  .header {
    text-align: center;
    position: relative;
    display: flex;
    justify-content: center;
    height: 54px;
    line-height: 54px;
    .iconclose,
    .iconsure {
      position: absolute;
      top: 0;
      width: 44px;
      height: 100%;
      text-align: center;
    }
    .iconclose {
      left: 0;
      color: #9496a3;
    }
    .iconsure {
      right: 0;
      color: #1676ff;
    }
    .popup-title {
      font-weight: bold;
      font-size: 16px;
      color: #1b1b4e;
    }
  }
  .checkbox-group {
    min-height: 200px;

    .item {
      position: relative;
      height: 38px;
      line-height: 38px;
      font-size: 14px;
      text-align: left;
      color: $--color-black-list-page;
      padding: 0px 17px;

      &.active {
        color: $--color-primary;
      }

      .icon {
        position: absolute;
        right: 17px;
        top: 50%;
        transform: translateY(-50%);
        font-size: 18px;
      }
    }
  }
}
</style>
