import axios from "@/utils/axios";
import apiPath from "@/utils/apiPath";

const { basics, psm, authorization, warning } = apiPath;
// 获取设施下拉
export const getSelectFacilities = params =>
  axios.get(`${basics}/select/facilities/org`, { params });

// 获取 jsa 下拉列表
export const getSelectJsas = params =>
  axios.get(`${psm}/select/jsas`, { params });

// 岗位名称下拉 需传入orgCode
export const selectPositions = params => {
  return axios
    .get(`${authorization}/select/positions`, { params })
    .then(res => {
      return res || [];
    });
};

// 获取传感器
export const getSelectSensorNum = params =>
  axios.get(`${warning}/select/sensor/num`, { params });

// 获取部门
export const getSelectUserDept = params =>
  axios.get(`${authorization}/select/user/dept`, { params });

/**
 * 原料数据查询接口【可分页，模糊查询】分页参数不传获取全部  模糊查询 materialName
 * @param params
 * @returns {*}
 */
export const getRaws = params => {
  return axios.get(`${psm}/page/raws`, { params });
};

/**
 * 查询产品数据 可分页，模糊查询】分页参数不传获取全部  模糊查询 materialName
 * @param params
 * @returns {*}
 */
export const getProducts = params => {
  return axios.get(`${psm}/page/products`, { params });
};

/**
 * 查询中间品数据 可分页，模糊查询】分页参数不传获取全部  模糊查询 materialName
 * @param params
 * @returns {*}
 */
export const getMedias = params => {
  return axios.get(`${psm}/page/medias`, { params });
};

/**
 * 物料库查询接口 可分页，模糊查询】分页参数不传获取全部  模糊查询 name
 * @param params
 * @returns {*}
 */
export const getMaterials = params => {
  return axios.get(`${psm}/page/materials`, { params });
};

/**
 * 通过参数获取人员
 * @param params orgCode department
 * @returns {*}
 */
export const getUsers = params => {
  return axios.get(`${authorization}/open/select/users`, { params });
};
