<template>
  <van-popup
    v-model="visible"
    class="WorkticketPersonPicker"
    :close-on-click-overlay="false"
    :overlay="false"
    get-container="body"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <section class="WorkticketPersonPicker__container">
      <div class="WorkticketPersonPicker__header">
        <h3 class="WorkticketPersonPicker__title">
          {{ title }}<span>({{ multiple ? "多选" : "单选" }})</span>
        </h3>
        <van-icon
          class="close-icon"
          name="mengcengguanbi"
          class-prefix="iconfont"
          @click="close"
        />
      </div>

      <div class="WorkticketPersonPicker__search">
        <van-search v-model="keyword" autofocus placeholder="请输入关键词">
          <template #left-icon>
            <van-icon
              name="sousuo"
              class-prefix="iconfont"
              class="search-icon"
            />
          </template>
        </van-search>
      </div>
      <div class="WorkticketPersonPicker__list">
        <van-checkbox-group
          class="WorkticketPersonPicker__list_group"
          :border="false"
        >
          <van-cell
            v-for="(item, index) in options"
            v-show="keyword ? item.label.indexOf(keyword) != -1 : true"
            :key="index"
            :border="false"
            clickable
            @click.stop="clickItem(index)"
          >
            <div class="checkbox-list">
              <span class="custom-title" style="vertical-align: middle;">{{
                item.label
              }}</span>
              <i
                v-if="showLicenseTag && item.licenseNum !== '0'"
                class="iconfont iconfont-zizhibiaoji license-tag"
              ></i>
              <span class="mobile">{{ item.mobile }}</span>
            </div>

            <template #right-icon>
              <van-icon
                :name="
                  isItemChecked(item) ? 'danxuan-yixuan' : 'danxuan-weixuan'
                "
                class-prefix="iconfont"
                :color="isItemChecked(item) ? ' #1676FF' : '#D4DBE3'"
                class="choose-icon"
              />
            </template>
          </van-cell>
        </van-checkbox-group>
      </div>
    </section>

    <section class="WorkticketPersonPicker__footer">
      <div class="WorkticketPersonPicker__footer-left">
        <span
          v-for="(item, idx) in chooseList"
          :key="idx"
          @click="handleDelete(idx)"
          >{{ item.label }}</span
        >
      </div>
      <van-button
        type="info"
        class="WorkticketPersonPicker__footer-right"
        @click.prevent="onConfirm"
        >确定({{ chooseList.length }})
      </van-button>
    </section>
  </van-popup>
</template>

<script>
import {
  copyValue,
  getCheckedArrayValues,
  isChecked
} from "@/components/edit/utils/options";
import {
  getWorkListPersons,
  getWorkListPersonsWorker,
  getWorkerByLicenseType,
  getUsableWorkers,
  getEnterWorkers
} from "@/api/workticket";
import projectTextConfig from "@/utils/projectTextConfig";

export default {
  name: "WorkticketPersonPicker",
  components: {},
  model: {
    prop: "value",
    event: "change"
  },
  props: {
    title: { type: String, default: "作业人" },
    value: { type: [Object, Array], default: null },
    label: { type: String, default: "" }, //sync,选择项的label
    multiple: { type: Boolean, default: false }, //是否多选
    workTypeId: { type: [String, Number], default: "" }, //特殊作业类型id,需要资质才传
    department: { type: [String, Number], default: "" }, //基本信息里面的所属部门id
    contractorStatus: { type: [String, Number], default: "" }, //作业类别item中是否承包商0：否，1：是
    deptCode: { type: [String, Number], default: "" }, //作业类别item中，所属部门code
    specialId: { type: String, default: "" }, // 小票id
    isCheckPerson: { type: Boolean, default: false }, //验收人员选择
    isWorker: { type: Boolean, default: false }, // 是否是作业人选择
    isHoisting: { type: Boolean, default: false }, // 是否是吊装选择
    licenseTypes: { type: String, default: "" }, // 资质号
    showLicenseTag: { type: Boolean, default: false }, // 是否显示资质图标
    ticketId: { type: String, default: "" }, // 大票id
    startWorkSpaceWorker: { type: Boolean, default: false }, // 开始作业受限空间小票所有已选特殊+普通作业人数据
    checkedWorker: { type: Boolean, default: false } // 已进入/未进入的人员
  },
  data() {
    return {
      visible: false,
      keyword: "",
      chooseIds: [],
      chooseList: [],
      options: [],
      // NOTE: 负责人是在人员变更时的作业负责人的名称
      workerWhileList: ["作业负责人", "负责人", "作业人"]
      // testerWhileList: ["监护人", "检测分析人"]
    };
  },
  computed: {},
  watch: {
    value: {
      immediate: true,
      handler(val) {}
    }
  },
  created: function() {},
  mounted: function() {
    // this.getPersonData();
  },
  methods: {
    async getPersonData() {
      // NOTE: 需要精简接口请求和参数配置
      let {
        workTypeId,
        department,
        contractorStatus,
        deptCode,
        workerWhileList,
        title,
        specialId,
        ticketId,
        startWorkSpaceWorker
      } = this;
      const isWorker = workerWhileList.includes(title);
      let params = {
        type: 2,
        department
      };
      if (!this.isCheckPerson) {
        const obj = {
          作业负责人: 1,
          负责人: 1,
          作业人: 1,
          [projectTextConfig.TEXT_WORK_ANALYST]: 5
        };
        const extraWorkTypeId = [
          projectTextConfig.TEXT_WORK_ANALYST,
          "监护人"
        ].includes(title);
        // 如果是承包商，小票部门就是承包商id
        // 作业人必传workTypeId
        // 是承包商contractorId必传
        // department:
        // 1：作业人/作业负责人 承包商 ？ 承包商id ：小票部门
        // 2: 监护人/检测分析人 承包商 ？大票部门 ： 大票部门,小票部门
        params = {
          type: obj[title] || 2,
          workTypeId: isWorker || extraWorkTypeId ? workTypeId : "",
          contractorId: contractorStatus ? deptCode : "",
          department: isWorker
            ? deptCode
            : contractorStatus
            ? department
            : `${department},${deptCode}`,
          accountStates: 20
        };
      }
      if (startWorkSpaceWorker) {
        params = {
          ticketId,
          specialTicketId: specialId
        };
        if (this.checkedWorker) {
          this.options = await getEnterWorkers(params);
        } else {
          this.options = await getUsableWorkers(params);
        }
        return;
      }
      if (this.isWorker) {
        await getWorkListPersonsWorker({
          contractorStatus,
          deptCode,
          specialId: this.specialId
        }).then(res => {
          this.options = res.map(item => {
            item.value = item.personId;
            item.label = item.personName;
            item.id = item.personId;
            item.type = item.type;
            return item;
          });
        });
        return;
      }
      if (this.isHoisting) {
        await getWorkerByLicenseType({
          contractorStatus,
          deptCode,
          specialId: this.specialId,
          licenseType: this.licenseTypes
        }).then(res => {
          this.options = res.map(item => {
            item.value = item.personId;
            item.label = item.personName;
            item.nameShowLicense = `${item.personName}（${item.no}）`;
            return item;
          });
        });
        return;
      }
      await getWorkListPersons(params).then(list => {
        this.options = list.map(item => {
          item.value = item.personId;
          item.label = item.personName;
          return item;
        });
      });
    },
    isItemChecked(item) {
      return isChecked({
        checked: this.chooseIds,
        value: item.value
      });
    },
    // 查询所有承包商
    showAllContractor(contractorId) {
      this.options = [];
      this.chooseIds = [];
      this.chooseList = [];
      this.visible = true;
      const params = {
        type: 1,
        contractorId
      };
      getWorkListPersons(params).then(list => {
        this.options = list.map(item => {
          item.value = item.personId;
          item.id = item.personId;
          item.label = item.personName;
          return item;
        });
      });
    },
    async show() {
      this.options = [];
      this.visible = true;
      await this.$nextTick();
      await this.getPersonData();
      let value = [];
      if (this.value) {
        value = this.multiple ? copyValue(this.value) : [copyValue(this.value)];
      }
      this.chooseIds = value.map(item => item.value);
      this.chooseList = this.getChooseList();
    },
    close() {
      this.visible = false;
      // this.$emit("close");
    },
    onConfirm() {
      let value = this.multiple ? this.chooseList : this.chooseList[0];
      this.$emit("change", value);
      let v = [];
      if (this.multiple) {
        v = this.options.filter(a => value.find(b => b.value === a.value));
      } else {
        v = this.options.find(a => value && a.value === value.value);
        v = [v];
      }
      this.$emit("confirm", v);
      this.close();
    },
    handleDelete(idx) {
      this.chooseIds.splice(idx, 1);
      this.chooseList = this.getChooseList();
    },
    onCheckboxChange(ids) {},
    clickItem(index) {
      let { chooseIds, multiple } = this;
      if (!this.multiple && chooseIds.length > 1) {
        //单选，大于1个，显示最新的一个
        this.chooseIds = [chooseIds[chooseIds.length - 1]];
      }

      this.chooseIds = getCheckedArrayValues({
        checked: chooseIds,
        value: this.options[index].value,
        multiple,
        inverse: true
      });

      this.chooseList = this.getChooseList();
    },
    getChooseList() {
      let { chooseIds, options } = this;
      if (!options || !chooseIds || chooseIds.length == 0) return [];
      return options
        .filter(item => chooseIds.includes(item.value))
        .map(item => {
          // return {
          //   value: item.value,
          //   label: item.label,
          //   nameShowLicense: item.nameShowLicense
          // };
          return item;
        });
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.WorkticketPersonPicker {
  /*position: relative;*/
  width: 100%;
  height: 100vh;
  overflow: hidden;

  &__container {
    margin: 0 16px;
  }

  &__header {
    position: relative;
    text-align: center;
    font-size: 18px;
    font-weight: bold;
    color: #2e2e4d;
    padding-bottom: 20px;
    padding-top: 30px;
  }

  .choose-icon {
    font-size: 18px;
  }

  .close-icon {
    position: absolute;
    top: 16px;
    left: 0;
    color: #9496a3;
    font-size: 16px;
  }

  &__search {
    margin-bottom: 20px;

    .van-search {
      padding: 0;
    }
  }

  &__department {
    border: 1px solid #c7c9d0;
    border-radius: 2px;
    padding-top: 7px;
    padding-left: 12px;
    padding-bottom: 7px;
    font-size: 14px;
    line-height: 1;
    margin-bottom: 12px;

    .van-field__value {
      line-height: 20px;
    }

    ::-webkit-input-placeholder {
      color: #2e2e4d;
    }

    :-moz-placeholder {
      /* Firefox 18- */
      color: #2e2e4d;
    }

    ::-moz-placeholder {
      /* Firefox 19+ */
      color: #2e2e4d;
    }

    :-ms-input-placeholder {
      color: #2e2e4d;
    }

    .van-field__left-icon {
      line-height: 20px;

      .iconfont {
        color: #aeb3c0;
        font-size: 12px;
      }
    }
  }

  &__list {
    height: calc(100vh - 68px - 84px);
    overflow: auto;

    &-wrapper {
      padding-bottom: 20px;
    }

    &-wrapper--choose {
      padding-bottom: 70px;
    }

    .van-checkbox {
      display: flex;
      justify-content: space-between;
    }

    &_group {
      padding-bottom: 50px;
      .checkbox-list {
        .license-tag {
          font-size: 14px;
          color: #3b4664;
          vertical-align: middle;
          margin: 0 0 0 5px;
        }
        .mobile {
          font-size: 12px;
          font-weight: 400;
          color: #8c8f97;
          vertical-align: middle;
          margin: 0 8px 0;
        }
      }
    }
  }

  &__footer {
    position: absolute;
    bottom: 0;
    height: 50px;
    width: 100vw;
    box-sizing: border-box;
    padding-left: 8px;
    line-height: 50px;
    border-top: 1px solid #c7c9d0;
    background-color: #fff;
    z-index: 2;

    &-left {
      width: calc(100vw - 120px - 10px);
      overflow-x: auto;
      white-space: nowrap;
      font-size: 12px;
      color: #3b4664;

      span {
        padding: 3px 10px;
        background-color: #e1e3e8;
        border-radius: 4px;
      }

      span:not(:last-child) {
        margin-right: 8px;
      }
    }

    &-right {
      position: absolute;
      right: 8px;
      top: 5px;
    }

    .van-button {
      height: 40px;
      line-height: 40px;
    }

    .van-button--normal {
      padding: 0 24px;
    }
  }
}
</style>
