<template>
  <div class="WorkticketReferralView">
    <div class="referral-approving">
      <div class="referral-title">转审-{{ referralInfo.name }}</div>
    </div>
    <div class="old-approving">
      <div class="approving-title">{{ countersignText }}</div>
      <div class="approving-names">
        <span
          v-for="item in referralInfo.approvePersonList"
          :key="item.id"
          class="sign-name"
          :class="{ active: item.signatureStatus == 1 }"
        >
          <i class="approve-name">{{ item.name }}</i>
          <van-icon v-if="item.signatureStatus == 1" name="success" />
        </span>
      </div>
    </div>
    <div>
      <van-form
        ref="vanForm"
        :show-error-message="true"
        error-message-align="right"
        @submit="onSubmit"
      >
        <van-field
          v-model="approverTransferNames"
          label="转审审批人"
          :required="true"
          readonly
          placeholder="请选择审批人"
          :is-link="true"
          input-align="right"
          :rules="[
            {
              required: true,
              message: '请选择审批人',
              trigger: 'onChange'
            }
          ]"
          @click="selectApproverPerson"
        />
        <van-field
          v-model="approveInfo.countersign"
          readonly
          :label-width="100"
          input-align="right"
          label="审批方式"
          :required="true"
          :rules="[
            {
              required: true,
              message: '请选择审批方式'
            }
          ]"
        >
          <div slot="input">
            <van-radio-group
              v-model="approveInfo.countersign"
              direction="horizontal"
            >
              <van-radio
                v-for="item in countersignOptions"
                :key="item.value"
                :name="item.value"
                >{{ item.label }}</van-radio
              >
            </van-radio-group>
          </div>
        </van-field>
        <van-field
          v-model="approveInfo.remark"
          input-align="right"
          name="remark"
          label="转审原因"
          placeholder="请输入转审原因"
          :required="true"
          :rules="[
            {
              required: true,
              message: '请输入转审原因'
            }
          ]"
        />
      </van-form>
    </div>
    <select-person
      title="选择人员(多选)"
      :visible="selectPersonVisible"
      :show-department-name="false"
      :show-phone-number="true"
      department-disabled-key="--"
      position="bottom"
      :multiple="true"
      :choose-value="approverTransferObjs"
      :exclude-person-ids="excludePersonIds"
      @close="selectPersonVisible = false"
      @confirm="onPersonConfirm"
    />
    <div class="sure-btns">
      <van-button round block @click="cancel">取 消</van-button>
      <van-button round type="info" block @click="sure">确 认</van-button>
    </div>
  </div>
</template>

<script>
import { transferApprove, transferApprovePost } from "@/api/workticket";
import SelectPerson from "@/components/SelectPerson";
import { WORKTICKET_STATUS } from "@/views/workticket/utils/constant";
import { Toast } from "vant";
export default {
  name: "WorkticketReferralView",
  components: {
    SelectPerson
  },
  props: {
    id: {
      type: String,
      default: ""
    },
    specialInfo: {
      type: Object,
      default: () => ({})
    },
    ticketId: {
      type: String,
      default: ""
    },
    referralInfo: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      selectPersonVisible: false,
      excludePersonIds: [],
      // referralInfo: {
      //   name: "",
      //   countersign: 0,
      //   approvePersonList: [
      //     {
      //       id: "",
      //       name: "",
      //       signatureStatus: 0
      //     }
      //   ]
      // },
      approveInfo: {
        countersign: "", // 1: 会签, 0: 或签
        remark: "",
        approverTransferList: [],
        approverList: [], // 已经签过的人的id
        approveType: "", // 审批级别
        ticketId: "", // 大票id
        name: this.referralInfo.name || ""
      },
      // 用于选择人员后回显
      approverTransferNames: "",
      // 用于选择人员后回显人员选择组件
      approverTransferObjs: [],
      countersignOptions: [
        {
          label: "会签",
          value: "1"
        },
        {
          label: "或签",
          value: "0"
        }
      ]
    };
  },
  inject: ["reload"],
  computed: {
    countersignText() {
      const obj = {
        0: "或签",
        1: "会签"
      };
      return obj[this.referralInfo.countersign];
    }
  },
  watch: {
    referralInfo: {
      handler(newV) {
        this.approveInfo.approverList = newV.approvePersonList
          .filter(a => a.signatureStatus == 1)
          .map(a => a.id);
        this.approveInfo.approveType = newV.approveType;
        this.approveInfo.name = newV.name || "";
        this.excludePersonIds = this.approveInfo.approverList;
      },
      immediate: true
    }
  },
  created() {
    this.approveInfo.ticketId = this.ticketId;
  },
  methods: {
    selectApproverPerson() {
      this.selectPersonVisible = true;
    },
    cancel() {
      this.$emit("changeCurStatus", WORKTICKET_STATUS.Approving);
    },
    sure() {
      this.$refs.vanForm.submit();
    },
    // 提交转审
    onSubmit() {
      transferApprovePost(this.id, this.approveInfo)
        .then(res => {
          if (res) {
            Toast.success({
              message: "转审成功",
              duration: 1500
            });

            setTimeout(() => {
              Toast.clear();
              this.cancel();
              this.reload();
            }, 1500);
          } else {
            this.$toast("转审失败, 请重试");
          }
        })
        .catch(e => {
          this.$toast("转审失败, 请重试");
        });
    },
    onPersonConfirm(ids, list) {
      this.approveInfo.approverTransferList = ids;
      this.approverTransferObjs = list;
      this.approverTransferNames = list.map(a => a.label).join(" ");
    }
  }
};
</script>

<style lang="scss" scoped>
.WorkticketReferralView {
  .referral-approving {
    .referral-title {
      text-align: center;
      padding: 16px 0;
      font-weight: 700;
    }
  }
  .old-approving {
    background-color: rgba(22, 118, 255, 0.05);
    padding: 6px 16px 0 16px;
    .approving-title {
      padding: 6px 0;
    }
    .approving-names {
      padding: 6px 0 0 0;
      .sign-name {
        display: inline-flex;
        height: 32px;
        line-height: 32px;
        padding: 0 10px 0 12px;
        border-radius: 16px;
        margin-right: 12px;
        background-color: #ffffff;
        border: 1px solid #e1e3e8;
        color: #8c8f97;
        box-sizing: border-box;
        font-size: 14px;
        align-items: center;
        margin-bottom: 16px;
        &.active {
          background-color: #dcffdb;
          border: 1px solid #75c70e;
          color: #64ae08;
          .van-icon {
            font-size: 18px;
            margin-left: 5px;
          }
        }
      }
    }
  }
  .sure-btns {
    position: fixed;
    width: 100%;
    padding: 16px 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: space-around;
    .van-button {
      width: 167px;
      // border-radius: ;
    }
  }
}
</style>
