<template>
  <div v-if="node.length" class="org-tree">
    <div
      v-for="(item, i) in node"
      v-show="expandFlag"
      :key="i"
      class="org-tree__item"
    >
      <van-cell-group :border="false">
        <van-cell
          v-if="item.filtered"
          :border="false"
          clickable
          @click="selectItem(item)"
        >
          <template #title>
            <van-icon
              v-show="item.children && item.children.length"
              :name="expandArr.includes(i) ? 'zhankai' : 'shouqi'"
              class-prefix="iconfont"
              class="expand-icon"
              @click.stop="expandItem(item, i)"
            />
            <span
              :class="{
                'label-title': true,
                'label-title-color': false
              }"
              >{{ item[labelKey] }}</span
            >
          </template>
          <template #right-icon>
            <van-icon
              v-if="item.checked"
              class-prefix="iconfont"
              :class="{
                'choose-icon': true,
                'disabled-icon': item[disabledKey]
              }"
              name="danxuan-yixuan"
            />
            <!-- 处理不可选，权限逻辑 -->
            <van-icon
              v-else
              class-prefix="iconfont"
              :class="{
                'un-choose-icon': true,
                'disabled-icon': item[disabledKey]
              }"
              name="danxuan-weixuan"
            />
          </template>
        </van-cell>
      </van-cell-group>
      <tree-node
        v-if="item.children"
        :node="item.children"
        :disabled-key="disabledKey"
        :expand-flag="expandArr.includes(i)"
        :label-key="labelKey"
        :disabled-toast="disabledToast"
        :checked-value="checkedValue"
        :multiple="multiple"
        :value-key="valueKey"
        @change="onChange"
        @reset-tree="resetTreeChecked"
      />
    </div>
  </div>
  <!-- <p v-else align="center" class="no-data">暂无数据</p> -->
</template>
<script>
import { Toast } from "vant";
export default {
  name: "TreeNode",
  props: {
    multiple: {
      type: Boolean,
      default: false
    },
    // 选中的值的属性名，必传
    valueKey: {
      type: String,
      default: "id"
    },
    // 在页面要展示的选项属性名，必传
    labelKey: {
      type: String,
      default: "label"
    },
    // 选中的值，必传，数组
    checkedValue: Array,
    // 控制展开，不需要传
    expandFlag: {
      type: Boolean,
      default: true
    },
    // 总数据，必传
    node: Array,
    // 不可选的唯一标识，如item[disabledKey]未true则不可选择，非必传
    disabledKey: {
      type: String,
      default: "disabled"
    },
    // 不可选提示文字，非必传
    disabledToast: String
  },
  data() {
    return {
      // 当前级组件已展开的项
      expandArr: [],
      checkedMap: {}
    };
  },
  watch: {
    checkedValue: {
      handler(val) {
        if (val) val.forEach(this.updateCheckedMap);
      }
      // deep: true
    }
  },
  methods: {
    onChange(list, currentNode) {
      this.$emit("change", list || [], currentNode);
    },
    updateCheckedMap(selectItem) {
      // console.log(`%c 开始更新 map --------------->>> `, "color: green");
      const innerKey = selectItem[this.valueKey];
      // 缓存所有选中过的节点
      this.checkedMap[innerKey] = selectItem;
      // console.log(this.checkedMap);
    },
    // 选择
    selectItem(item) {
      if (this.disabledKey && item[this.disabledKey]) {
        if (this.disabledToast) {
          Toast(this.disabledToast);
        }
        return;
      }
      if (!this.multiple) {
        this.resetTreeChecked();
      }
      this.$set(item, "checked", !item.checked);
      this.updateCheckedMap(item);
      // 返回选中的值
      const checkedList = Object.values(this.checkedMap).filter(x => x.checked);
      this.$emit("change", checkedList || [], item);
    },
    // 展开
    expandItem(item, i) {
      if (item.children && item.children.length) {
        let index = this.expandArr.indexOf(i);
        if (index > -1) {
          this.expandArr.splice(index, 1);
        } else {
          this.expandArr.push(i);
        }
      }
    },
    resetTreeChecked() {
      this.$emit("reset-tree");
    }
  }
};
</script>
<style lang="scss" scoped>
$--icon-color: #d4dbe3;
.org-tree {
  padding: 0;
  width: 100%;
  background: #fff;
  overflow: hidden;
  .van-cell-group {
    margin-bottom: 0;
  }
  .van-cell {
    align-items: center;
    padding-left: 0;
    padding-right: 4px;
  }
  .van-cell__title {
    vertical-align: middle;
  }
  &__item {
    box-sizing: border-box;
    position: relative;
    width: 100%;
    color: #3b4664;
    font-size: 14px;
    .iconfont {
      height: 18px;
      font-size: 18px;
      border-radius: 50%;
      line-height: 1;
    }
    .expand-icon {
      color: $--icon-color;
    }
    .choose-icon {
      color: #1676ff;
    }
    .un-choose-icon {
      color: $--icon-color;
    }
    .disabled-icon {
      background-color: #ebedf0;
      border-color: #c8c9cc;
      color: #c8c9cc;
      cursor: not-allowed;
    }
    .label-title {
      padding-left: 2vw;
    }
    .label-title-color {
      color: #3278ff;
    }
    > .org-tree {
      border-bottom: 0;
      padding-left: 5vw;
      width: calc(100% - 5vw);
    }
  }
}
.no-data {
  padding-top: 80px;
  color: #aeb3c0;
  font-size: 14px;
}
</style>
