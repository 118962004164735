var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.showDom)?_c('div',{staticClass:"WorkticketSafetyItem",class:{ 'block-space_8_notLast': _vm.bottomBorder }},[_c('p',{staticClass:"title"},[_vm._t("otherPersonTitle")],2),_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.title))]),(_vm.info)?_c('van-checkbox',{attrs:{"shape":"square"},model:{value:(_vm.checked),callback:function ($$v) {_vm.checked=$$v},expression:"checked"}},[_vm._v(_vm._s(_vm.info))]):_vm._e(),(
      _vm.ticketStatus === _vm.WORKTICKET_STATUS.Safety_disclosureing &&
        _vm.value.personType === '7'
    )?[_c('van-button',{staticClass:"btn-select-person",attrs:{"round":""},on:{"click":function($event){_vm.selectPersonVisible = true}}},[_vm._v("选择人员")]),_c('select-person-by-tabs',{attrs:{"title":"受影响相关方(多选)","visible":_vm.selectPersonVisible,"show-department-name":false,"show-phone-number":true,"department-disabled-key":"--","position":"bottom","include-children":true,"multiple":true,"choose-value":_vm.choosePersonSelect},on:{"close":function($event){_vm.selectPersonVisible = false},"confirm":_vm.onPersonConfirm}})]:_vm._e(),(_vm.value.workerList && _vm.value.workerList.length > 0)?[_vm._l((_vm.value.workerList),function(item,index){return _c('div',{key:index},[(item.typeName)?_c('div',{staticClass:"title1"},[_vm._v(_vm._s(item.typeName))]):_vm._e(),(
          _vm.ticketStatus === _vm.WORKTICKET_STATUS.Safety_disclosureing &&
            _vm.value.personType === _vm.activePersonType
        )?[_c('div',[_c('van-button',{staticClass:"btn-select-person",attrs:{"round":""},on:{"click":function($event){_vm.selectAnalysisVisible = true}}},[_vm._v("选择人员")])],1)]:_vm._e(),(item.workerList && item.workerList.length > 0)?_c('div',{staticClass:"tag-box"},_vm._l((item.workerList),function(item1,index1){return _c('Tag',{key:index1,attrs:{"type":_vm.typeSwitch(item1),"tick":!!item1.fileId},nativeOn:{"click":function($event){return _vm.handleClickTag(
              item1,
              item1.fileId,
              item1.personId,
              item1.personType,
              _vm.ticketId,
              item.specialId
            )}}},[_vm._v(" "+_vm._s(item1.personName)+" ")])}),1):_vm._e()],2)}),_c('select-person',{attrs:{"title":(_vm.title + "(多选)"),"visible":_vm.selectAnalysisVisible,"show-department-name":false,"show-phone-number":true,"position":"bottom","include-children":true,"multiple":true,"department-disabled-key":"-","choose-value":_vm.chooseAnalysisSelect},on:{"close":function($event){_vm.selectAnalysisVisible = false},"confirm":_vm.onAnalysisConfirm}}),_c('safe-inventory',{attrs:{"visible":_vm.safeInventoryVb,"position":"bottom","title":"安全交底清单","type":"textarea","autosize":{
        // minHeight: 26,
        maxHeight: 126
      },"mode-type":"1","rows":1,"ticket-id":_vm.ticketId},on:{"confirm":_vm.safeInventoryConfirm,"close":function($event){_vm.safeInventoryVb = false}}})]:_vm._e()],2):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }