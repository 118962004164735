<template>
  <div>
    <van-field
      clickable
      :value="getData()"
      class="render-input-box"
      :readonly="!$attrs.editable"
      :label="itemData.label + ':'"
      :required="itemData.options.required"
      :placeholder="$attrs.editable ? '请选择JSA' : ''"
      :error-message="errorMessage"
      :rules="[
        {
          validator: validator
        }
      ]"
      @click="onClick"
    >
    </van-field>
    <select-popup
      v-model="data"
      :show-filter="true"
      :data="$attrs['jsa-data']"
      :multiple="itemData.options.isMultiple"
      title="选择岗位"
      :visible="popupVisible"
      :popup-style="{
        height: '50vh'
      }"
      @close="onPopupClose"
      @confirm="onPopupConfirm"
    />
  </div>
</template>

<script>
import SelectPopup from "@/components/SelectPopup";

export default {
  name: "RenderSelectJSA",
  components: {
    SelectPopup
  },
  props: {
    value: [String, Array],
    itemData: Object
  },
  data() {
    return {
      data: "",
      errorMessage: "",
      popupVisible: false
    };
  },
  watch: {
    data: {
      handler: function(val) {
        this.$emit("input", val);
      },
      deep: true
    }
  },
  created() {
    this.data = this.itemData.options.isMultiple
      ? Array.isArray(this.value)
        ? this.value
        : this.value && this.value.split(",")
      : this.value;
  },
  methods: {
    onClick() {
      if (this.$attrs.editable) {
        this.popupVisible = true;
      }
    },
    onPopupClose() {
      this.validator();
      this.popupVisible = false;
    },
    onPopupConfirm() {
      this.validator();
      this.popupVisible = false;
    },
    validator() {
      const required = this.itemData.options?.required;
      if (!required) {
        return true;
      }
      const multiple = this.itemData.options.isMultiple;
      if (multiple) {
        if (!this.data?.length || this.data.some(v => v === "")) {
          this.errorMessage = `${this.itemData.label}不能为空`;
          return false;
        }
      } else {
        if (!this.data) {
          this.errorMessage = `${this.itemData.label}不能为空`;
          return false;
        }
      }
      this.errorMessage = "";
      return true;
    },
    getData() {
      let arr = this.itemData.options.isMultiple ? this.data : [this.data];
      return this.$attrs["jsa-data"]
        ?.filter(s => arr.includes(s.value))
        .map(s => s.label)
        .join(",");
    }
  }
};
</script>

<style scoped lang="scss">
.render-input-box {
  position: relative;
}
</style>
