/**
 * 选项列表根据value，获取显示的label
 * @param options 数组
 * @param value 可以是String/Number/Array
 * @param optionValueKey
 * @param optionLabelKey
 * @returns {string|*}
 */
export const getOtpionLabel = ({
  options,
  value,
  optionValueKey = "value",
  optionLabelKey = "label"
}) => {
  let checkedOptions = getCheckedOptions({ value, options, optionValueKey });
  return checkedOptions.map(item => item[optionLabelKey]).join(",");
};

/**
 * 获取已选择的options
 * @param options
 * @param value
 * @param optionValueKey
 * @param optionLabelKey
 * @returns {string|*}
 */
export const getCheckedOptions = ({
  options,
  value,
  optionValueKey = "value"
}) => {
  if (!options || options.length == 0 || value === undefined) {
    return [];
  }
  return options.filter(item =>
    isChecked({
      checked: value,
      value: item[optionValueKey],
      optionValueKey
    })
  );
};

/**
 * 点击单选多选item
 * @param checked 已选项
 * @param value 当前点击项value
 * @param multiple 是否多选
 * @returns {*[]|*}
 */
export const getCheckedValues = ({ checked, value, multiple = true }) => {
  if (multiple) {
    //多选
    let checkedCopy;
    if (Array.isArray(checked)) {
      checkedCopy = [...checked];
    } else {
      checkedCopy = [];
    }

    let findIndex = checkedCopy.findIndex(ite => ite == value);
    if (findIndex == -1) {
      checkedCopy.push(value);
    } else {
      checkedCopy.splice(findIndex, 1);
    }

    return checkedCopy;
  }

  //单选
  if (checked == value) {
    return null;
  } else {
    return value;
  }
};

/**
 *
 * @param checked
 * @param value
 * @param multiple 是否多选
 * @param inverse 单选时，是否可以反选，点击取消
 * @returns {*|[]|[*]|*[]}
 */
export const getCheckedArrayValues = ({
  checked = [],
  value,
  multiple = true,
  inverse = false
}) => {
  let checkedCopy = toArray(checked);

  if (!multiple) {
    if (inverse && checkedCopy[0] == value) {
      return [];
    }
    return [value];
  }

  //多选
  let findIndex = checkedCopy.findIndex(ite => ite == value);
  if (findIndex == -1) {
    checkedCopy.push(value);
  } else {
    checkedCopy.splice(findIndex, 1);
  }

  return checkedCopy;
};

/**
 * 判断选项是否选择
 * @param checked 已选项
 * @param value 当前value
 */
export const isChecked = ({ checked, value, optionValueKey = "value" }) => {
  if (Array.isArray(checked)) {
    let index = checked.findIndex(item => {
      return item instanceof Object && optionValueKey
        ? item[optionValueKey] == value
        : item == value;
    });
    return index != -1;
  } else {
    return checked instanceof Object && optionValueKey
      ? checked[optionValueKey] == value
      : checked == value;
  }
};

export const copyValue = value => {
  if (Array.isArray(value)) {
    return [...value];
  } else if (value instanceof Object) {
    return { ...value };
  } else {
    return value;
  }
};

export const toArray = value => {
  if (Array.isArray(value)) {
    return [...value];
  } else if (value === "" || value === undefined) {
    return [];
  } else {
    return [value];
  }
};

/**
 * 将value数组转换成对应的格式，多选返回数组，单选返回value[0]
 * @param value 选择的数组
 * @param multiple 是否多选
 * @returns {*}
 */
export const toValue = (value = [], multiple = true) => {
  return multiple ? [...value] : value[0];
};

/**
 * 过滤value，去除没有在options中的value
 * @param value
 * @param options
 */
export const filterValue = ({ value, options, optionValueKey = "value" }) => {
  if (Array.isArray(value)) {
    return value.filter(val =>
      options.some(opt =>
        optionValueKey ? opt[optionValueKey] == val : opt == val.name
      )
    );
  } else {
    return options.some(opt =>
      optionValueKey ? opt[optionValueKey] == value : opt == value
    )
      ? value
      : "";
  }
};
