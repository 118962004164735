<template>
  <van-popup
    v-model="visible"
    class="custom-popup"
    :close-on-click-overlay="false"
    :overlay="false"
    get-container="body"
    v-bind="$attrs"
    @opened="onOpen"
    @close="onClose"
    @click-overlay="onClose"
    v-on="$listeners"
  >
    <section class="header">
      <van-icon
        class="close-icon"
        name="mengcengguanbi"
        class-prefix="iconfont"
        @click.native="iconClose()"
      />
      <span class="title">{{ title }}</span>
      <span class="save" @click="onConfirm()">保存</span>
    </section>
    <section class="content">
      <div class="input-box">
        <van-field
          v-model="value"
          class="input"
          placeholder="请输入"
          input-align="left"
          clearable
          v-bind="$attrs"
          v-on="$listeners"
          @input="inputEvent"
          @clear="inputClear"
        />
      </div>
      <p
        v-for="(item, idx) of filterSelectData"
        :key="idx"
        class="text-item"
        @click="selectClick(item)"
        v-html="item.showText"
      ></p>
    </section>
    <!-- <section class="footer">
      <van-button @click="onConfirm" type="info" class="confirm-btn"
        >确定
      </van-button>
    </section> -->
  </van-popup>
</template>

<script>
export default {
  name: "InputAndSelect",
  components: {},
  props: {
    visible: Boolean,
    title: String,
    inputValue: {
      type: String,
      default: ""
    },
    selectData: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      clicked: false,
      value: ""
    };
  },
  computed: {
    filterSelectData() {
      // 还需要配置labelKey
      if (!this.clicked) {
        if (this.value) {
          const arr = [];
          const reg = new RegExp(this.value, "g");
          const repText = `<label class="keyword">${this.value}</label>`;
          this.selectData.forEach(item => {
            if (item.includes(this.value)) {
              arr.push({
                showText: item.replace(reg, repText),
                originalText: item
              });
            }
          });
          return arr;
        }
        return this.selectData.map(item => {
          return {
            showText: item,
            originalText: item
          };
        });
      }
      return [];
    }
  },
  watch: {
    // inputValue: {
    //   handler(newVal, oldVal) {
    //     console.log("newVal", newVal, oldVal);
    //     this.value = newVal || oldVal;
    //   },
    //   immediate: true,
    // },
    visible: {
      handler(newVal) {
        if (newVal) {
          this.value = this.inputValue || "";
          this.clicked = this.value ? true : false;
        }
      }
    }
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  methods: {
    inputEvent(val) {
      if (!val) {
        this.inputClear();
      }
      // this.$emit("input");
    },
    inputClear() {
      this.clicked = false;
    },
    selectClick(item) {
      this.value = item.originalText;
      this.clicked = true;
    },
    onOpen() {
      this.$emit("opened");
    },
    onClose() {
      this.$emit("close");
    },
    onConfirm() {
      this.$emit("confirm", this.value);
      this.$emit("close");
    },
    iconClose() {
      this.$dialog
        .confirm({
          message: "未保存的更改，确定要退出吗？",
          className: "custom-confirm-dialog",
          confirmButtonText: "放弃",
          confirmButtonColor: "#F13030",
          cancelButtonText: "再看看",
          cancelButtonColor: "#6E6E7D"
        })
        .then(() => {
          this.$emit("close");
        });
    }
  }
};
</script>

<style lang="scss">
.custom-confirm-dialog {
  .van-dialog__message {
    font-size: 16px;
    font-weight: 500;
    color: #242f3b;
    line-height: 22px;
  }
  .van-dialog__footer {
    border-top: 1px solid #eaecf1;
  }
  .van-dialog__cancel {
    border-right: 1px solid #eaecf1;
  }
}
</style>

<style lang="scss" scoped>
.custom-popup {
  height: 100vh;
  overflow: hidden;
  .header {
    // position: relative;
    display: flex;
    justify-content: space-between;
    padding: 14px 16px 13px;
    // padding-bottom: 20px;
    // padding-top: 30px;
    .close-icon {
      // position: absolute;
      // top: 16px;
      // left: 0;
      color: #9496a3;
      font-size: 16px;
    }
    .title {
      font-size: 18px;
      font-weight: 400;
      color: #2e2e4d;
      line-height: 17px;
    }
    .save {
      font-size: 14px;
      font-weight: 400;
      color: #1676ff;
      line-height: 20px;
    }
  }
  .content {
    padding: 16px;
    .input-box {
      .input {
        padding: 8px 12px;
        border: 1px solid #c7c9d0;
        font-size: 14px;
        font-weight: 400;
        color: #2e2e4d;
        line-height: 20px;
        min-height: 22px;
      }
    }
    .text-item {
      margin: 10px 0 0;
      padding: 10px 12px;
      background: #f5f7fa;
      border-radius: 2px;
      font-size: 12px;
      font-weight: 400;
      color: #8c8f97;
      line-height: 17px;
      ::v-deep.keyword {
        color: #1676ff;
      }
    }
  }
  .footer {
    position: absolute;
    bottom: 0;
    height: 50px;
    width: 100vw;
    box-sizing: border-box;
    padding-left: 8px;
    line-height: 50px;
    border-top: 1px solid #c7c9d0;
    background-color: #fff;
    z-index: 2;
    .confirm-btn {
      position: absolute;
      right: 8px;
      top: 5px;
    }
    .van-button {
      height: 40px;
      line-height: 40px;
      border-radius: $--button-height;
    }
    .van-button--normal {
      padding: 0 24px;
    }
  }
}
</style>
