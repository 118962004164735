var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('van-dialog',{staticClass:"InputDialog",class:{ loading: _vm.loading },attrs:{"title":_vm.title,"show-cancel-button":"","before-close":_vm.beforeClose,"disabled":_vm.loading},model:{value:(_vm.visible),callback:function ($$v) {_vm.visible=$$v},expression:"visible"}},[(_vm.hasInput)?_c('van-field',{staticClass:"remark-box",attrs:{"rows":"2","autosize":{ maxHeight: 250 },"type":"textarea","maxlength":_vm.maxlength,"placeholder":_vm.placeholder,"show-word-limit":"","error":_vm.messageError,"disabled":_vm.loading},model:{value:(_vm.message),callback:function ($$v) {_vm.message=$$v},expression:"message"}}):_vm._e(),(_vm.title === '延期申请')?[_c('van-cell',{attrs:{"title":("从" + _vm.startTime + "延期至"),"label":_vm.endTime},on:{"click":_vm.ERClick},scopedSlots:_vm._u([{key:"right-icon",fn:function(){return [_c('van-icon',{attrs:{"class-prefix":"iconfont","name":"riqi","size":"16"}})]},proxy:true},{key:"label",fn:function(){return [_c('span',{staticClass:"date-range-cell-label"},[_vm._v(_vm._s(_vm.endTime))])]},proxy:true}],null,false,2368466574)})]:_vm._e(),(_vm.otherPerson.length)?_c('div',{staticClass:"person-signature-box"},[_c('div',{staticStyle:{"line-height":"44px"}},[_vm._v(_vm._s(_vm.otherPersonLabel)+"人：")]),_c('div',{staticClass:"tag-box"},_vm._l((_vm.otherPerson),function(item){return _c('Tag',{key:item.personId,attrs:{"type":item.fileId ? 'green' : 'white',"tick":!!item.fileId},nativeOn:{"click":function($event){return _vm.handleClickTag(
              item,
              item.fileId,
              item.personId,
              item.personType,
              _vm.ticketId,
              item.specialId
            )}}},[_vm._v(" "+_vm._s(item.personName)+" ")])}),1)]):_vm._e(),(_vm.hasCheckPerson)?_c('div',{staticClass:"person-signature-box"},[_c('div',[_vm._v(" "+_vm._s(_vm.choosePersonLabel)+"人： "),_c('van-button',{staticClass:"btn-select-person",attrs:{"round":""},on:{"click":_vm.choosePerson}},[_vm._v("选择人员")])],1),_c('div',{staticClass:"tag-box"},_vm._l((_vm.checkPersonList),function(item){return _c('Tag',{key:item.personId,attrs:{"type":item.fileId ? 'green' : 'white',"tick":!!item.fileId},nativeOn:{"click":function($event){return _vm.handleClickTag(
              item,
              item.fileId,
              item.personId,
              item.personType,
              _vm.ticketId,
              item.specialId
            )}}},[_vm._v(" "+_vm._s(item.personName)+" ")])}),1)]):_vm._e(),_c('WorkticketPersonPicker',{ref:"personPicker",attrs:{"title":"验收人","multiple":true,"is-check-person":true,"department":_vm.department},on:{"change":_vm.checkPersonChange},model:{value:(_vm.checkPersonList),callback:function ($$v) {_vm.checkPersonList=$$v},expression:"checkPersonList"}}),_c('select-person-by-tabs',{key:_vm.recentType,attrs:{"title":_vm.personByTabTitle,"visible":_vm.SPBTPicker,"show-department-name":false,"show-phone-number":true,"position":"bottom","include-children":_vm.includeChildrenSwitch,"multiple":_vm.personByTabMultiple,"is-department-auth":false,"default-department":_vm.department,"recent-type":_vm.recentType,"show-recent-use-person":_vm.showRecentUsePerson,"default-tab-name":_vm.showRecentUsePerson ? 2 : 0},on:{"close":function($event){_vm.SPBTPicker = false},"confirm":_vm.onPersonConfirm}}),_c('select-person',{attrs:{"title":"停电人(单选)","visible":_vm.powerPersonPincker,"choose-value":_vm.checkPersonList,"show-department-name":false,"show-phone-number":true,"position":"bottom","multiple":false,"license-types":_vm.powerCutConfig.types,"department-disabled-key":"--"},on:{"close":function($event){_vm.powerPersonPincker = false},"confirm":_vm.powerPersonConfirm}})],2),_c('van-popup',{attrs:{"position":"bottom"},model:{value:(_vm.showTimePicker),callback:function ($$v) {_vm.showTimePicker=$$v},expression:"showTimePicker"}},[_c('van-datetime-picker',{attrs:{"type":"datetime","min-date":_vm.minDate,"max-date":_vm.maxDate},on:{"confirm":_vm.onDateimeConfirm,"cancel":function($event){_vm.showTimePicker = false}},model:{value:(_vm.timePickerVal),callback:function ($$v) {_vm.timePickerVal=$$v},expression:"timePickerVal"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }