<template>
  <el-form-item
    class="ck"
    :label="itemData.label"
    :prop="itemData.code"
    :rules="[]"
  >
    <template v-if="editable">
      <span v-html="data"></span>
    </template>
    <template v-else>
      <div
        class="ck-content"
        style="min-height: auto!important;"
        v-html="data"
      ></div>
    </template>
  </el-form-item>
</template>

<script>
import login from "@/utils/login";
window.globalConfig = window.globalConfig || process.env;
export default {
  name: "RenderCkEditor",
  props: {
    value: [String],
    itemData: Object,
    editable: Boolean
  },
  data() {
    return {
      isReady: false,
      editorConfig: {
        simpleUpload: {
          uploadUrl: `${window.globalConfig.VUE_APP_FILE_URL}/file/upload`,
          headers: {
            Authorization: "Bearer " + login.getToken()
          }
        }
      },
      data: ""
    };
  },
  watch: {
    data: {
      handler: function(val) {
        this.$emit("input", val);
      },
      deep: true
    }
  },
  mounted() {
    this.$nextTick(() => {
      if (!this.editable) {
        this.data = Array.isArray(this.value) ? this.value[0] : this.value;
      }
    });
  },
  methods: {
    readyHandle() {
      this.isReady = true;
      this.data = Array.isArray(this.value) ? this.value[0] : this.value;
    }
  }
};
</script>
