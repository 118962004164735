var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"WorkticketReferralView"},[_c('div',{staticClass:"referral-approving"},[_c('div',{staticClass:"referral-title"},[_vm._v("转审-"+_vm._s(_vm.referralInfo.name))])]),_c('div',{staticClass:"old-approving"},[_c('div',{staticClass:"approving-title"},[_vm._v(_vm._s(_vm.countersignText))]),_c('div',{staticClass:"approving-names"},_vm._l((_vm.referralInfo.approvePersonList),function(item){return _c('span',{key:item.id,staticClass:"sign-name",class:{ active: item.signatureStatus == 1 }},[_c('i',{staticClass:"approve-name"},[_vm._v(_vm._s(item.name))]),(item.signatureStatus == 1)?_c('van-icon',{attrs:{"name":"success"}}):_vm._e()],1)}),0)]),_c('div',[_c('van-form',{ref:"vanForm",attrs:{"show-error-message":true,"error-message-align":"right"},on:{"submit":_vm.onSubmit}},[_c('van-field',{attrs:{"label":"转审审批人","required":true,"readonly":"","placeholder":"请选择审批人","is-link":true,"input-align":"right","rules":[
          {
            required: true,
            message: '请选择审批人',
            trigger: 'onChange'
          }
        ]},on:{"click":_vm.selectApproverPerson},model:{value:(_vm.approverTransferNames),callback:function ($$v) {_vm.approverTransferNames=$$v},expression:"approverTransferNames"}}),_c('van-field',{attrs:{"readonly":"","label-width":100,"input-align":"right","label":"审批方式","required":true,"rules":[
          {
            required: true,
            message: '请选择审批方式'
          }
        ]},model:{value:(_vm.approveInfo.countersign),callback:function ($$v) {_vm.$set(_vm.approveInfo, "countersign", $$v)},expression:"approveInfo.countersign"}},[_c('div',{attrs:{"slot":"input"},slot:"input"},[_c('van-radio-group',{attrs:{"direction":"horizontal"},model:{value:(_vm.approveInfo.countersign),callback:function ($$v) {_vm.$set(_vm.approveInfo, "countersign", $$v)},expression:"approveInfo.countersign"}},_vm._l((_vm.countersignOptions),function(item){return _c('van-radio',{key:item.value,attrs:{"name":item.value}},[_vm._v(_vm._s(item.label))])}),1)],1)]),_c('van-field',{attrs:{"input-align":"right","name":"remark","label":"转审原因","placeholder":"请输入转审原因","required":true,"rules":[
          {
            required: true,
            message: '请输入转审原因'
          }
        ]},model:{value:(_vm.approveInfo.remark),callback:function ($$v) {_vm.$set(_vm.approveInfo, "remark", $$v)},expression:"approveInfo.remark"}})],1)],1),_c('select-person',{attrs:{"title":"选择人员(多选)","visible":_vm.selectPersonVisible,"show-department-name":false,"show-phone-number":true,"department-disabled-key":"--","position":"bottom","multiple":true,"choose-value":_vm.approverTransferObjs,"exclude-person-ids":_vm.excludePersonIds},on:{"close":function($event){_vm.selectPersonVisible = false},"confirm":_vm.onPersonConfirm}}),_c('div',{staticClass:"sure-btns"},[_c('van-button',{attrs:{"round":"","block":""},on:{"click":_vm.cancel}},[_vm._v("取 消")]),_c('van-button',{attrs:{"round":"","type":"info","block":""},on:{"click":_vm.sure}},[_vm._v("确 认")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }