<template>
  <svg
    aria-hidden="true"
    style="position: absolute; width: 0px; height: 0px; overflow: hidden;"
  >
    <symbol id="iconfont-wendang" viewBox="0 0 1024 1024">
      <path
        d="M204.989421 0.10239h520.139986L972.707849 250.445355v716.728328c0 15.307269-12.440356 27.69643-27.747625 27.69643H204.989421a27.747625 27.747625 0 0 1-27.79882-27.747625V27.850015c0-15.358464 12.440356-27.747625 27.79882-27.747625z"
        fill="#FFFFFF"
      ></path>
      <path
        d="M725.129407 0.10239v222.59534c0 15.358464 12.440356 27.747625 27.747625 27.747625H972.707849L725.129407 0.10239z"
        fill="#E5E5E5"
      ></path>
      <path
        d="M68.964624 143.345665h318.636936c11.826017 0 17.764624 5.887411 17.764623 17.764624v318.636936c0 11.826017-5.938606 17.764624-17.764623 17.764624H68.964624C57.138606 497.511849 51.2 491.573243 51.2 479.747225V161.110289c0-11.877212 5.938606-17.764624 17.764624-17.764624z"
        fill="#4297FC"
      ></path>
      <path
        d="M271.030817 562.990101h617.307869c7.372063 0 11.109289 3.686031 11.109289 11.109289 0 7.423258-3.737226 11.109289-11.109289 11.109289H271.030817c-7.423258 0-11.109289-3.686031-11.109289-11.109289 0-7.423258 3.686031-11.109289 11.109289-11.109289zM271.030817 714.014999h617.307869c7.372063 0 11.109289 3.686031 11.109289 11.058094 0 7.423258-3.737226 11.109289-11.109289 11.109289H271.030817c-7.423258 0-11.109289-3.686031-11.109289-11.109289 0-7.372063 3.686031-11.058094 11.109289-11.058094zM271.030817 638.50255h617.307869c7.372063 0 11.109289 3.686031 11.109289 11.109289 0 7.372063-3.737226 11.109289-11.109289 11.109289H271.030817c-7.423258 0-11.109289-3.737226-11.109289-11.109289 0-7.423258 3.686031-11.109289 11.109289-11.109289zM271.030817 941.985801h617.307869c7.372063 0 11.109289 3.686031 11.109289 11.058095 0 7.423258-3.737226 11.109289-11.109289 11.109289H271.030817c-7.423258 0-11.109289-3.686031-11.109289-11.109289 0-7.372063 3.686031-11.058094 11.109289-11.058095zM271.030817 863.862414h617.307869c7.372063 0 11.109289 3.737226 11.109289 11.109289 0 7.423258-3.737226 11.109289-11.109289 11.109289H271.030817c-7.423258 0-11.109289-3.686031-11.109289-11.109289 0-7.372063 3.686031-11.109289 11.109289-11.109289z"
        fill="#D8D8D8"
      ></path>
      <path
        d="M326.833237 430.139386a12.645135 12.645135 0 0 0 21.911409-8.549545V229.916208h-25.239077v159.369663l-85.39306-92.662733a12.645135 12.645135 0 0 0-18.532547 0L134.135706 389.337066V229.916208h-25.239076v191.673633c0 11.467653 14.078592 16.9967 21.911409 8.549545L228.846235 323.807619 326.833237 430.139386z"
        fill="#FFFFFF"
      ></path>
    </symbol>
    <symbol id="iconfont-jianxiujihua" viewBox="0 0 1024 1024">
      <path
        d="M237.211826 693.737739a37.442783 37.442783 0 1 0 0 74.930087h74.930087a37.442783 37.442783 0 0 0 0-74.930087H237.211826z m0-199.769043a37.442783 37.442783 0 0 0 0 74.930087h224.745739a37.442783 37.442783 0 0 0 0-74.930087H237.211826z m0-249.677913a37.442783 37.442783 0 1 0 0 74.930087h374.739478a37.442783 37.442783 0 1 0 0-74.930087H237.211826z"
        fill="#FFC709"
      ></path>
      <path
        d="M807.757913 11.130435a33.391304 33.391304 0 0 1 33.079652 28.850087l0.311652 4.541217v393.97287a33.391304 33.391304 0 0 1-66.470956 4.496695l-0.311652-4.496695-0.044522-360.581566H133.565217a55.652174 55.652174 0 0 0-55.385043 49.953392L77.913043 133.565217v767.910957h415.165218a33.391304 33.391304 0 0 1 33.079652 28.850087l0.311652 4.541217a33.391304 33.391304 0 0 1-28.850087 33.079652l-4.541217 0.311653H44.521739a33.391304 33.391304 0 0 1-33.079652-28.805566l-0.311652-4.541217V133.565217A122.434783 122.434783 0 0 1 126.085565 11.353043L133.565217 11.130435h674.192696z"
        fill="#1676FF"
      ></path>
      <path
        d="M631.140174 995.550609a16.873739 16.873739 0 0 1-11.219478-4.363131l-0.712348-0.756869-38.199652-39.980522a17.096348 17.096348 0 0 1-0.712348-23.329391l0.756869-0.890435 28.13774-28.315826a37.977043 37.977043 0 0 1 43.586782-7.568696l2.893913 1.380174 85.481739-85.837913-17.497043-17.942261-4.363131 0.75687a123.013565 123.013565 0 0 1-103.334956-31.209739l-1.958957-1.825392-1.914434-1.914435a124.972522 124.972522 0 0 1-32.144696-120.253217 9.794783 9.794783 0 0 1 9.349565-7.346087 9.349565 9.349565 0 0 1 6.233044 2.359652l0.667826 0.623305 24.486956 24.531478a47.326609 47.326609 0 0 0 69.142261-64.556522l-1.113043-1.335652-1.068522-1.29113-24.486957-24.620522a9.883826 9.883826 0 0 1 4.407653-16.473044 123.325217 123.325217 0 0 1 119.585391 32.189218c21.593043 21.682087 34.459826 50.576696 36.062609 81.118608l1.602782 15.894261 112.684522-114.109217c9.839304-9.839304 23.151304-15.404522 37.13113-15.449044a43.052522 43.052522 0 0 1 34.014609 12.243479c20.48 20.74713 20.48 54.093913 0 74.796521l-119.941565 120.698435 107.742608 100.173913c30.72 28.538435 33.747478 76.132174 6.989914 108.321392l-1.335653 1.55826-1.335652 1.51374a78.669913 78.669913 0 0 1-55.963826 25.199304h-1.424696a78.669913 78.669913 0 0 1-55.385043-23.062261l-1.113044-1.068522-1.068521-1.157565-123.592348-134.144-84.858435 85.303652 1.335652 2.849392a38.511304 38.511304 0 0 1-6.322087 42.607304l-1.068521 1.113043-28.13774 28.40487a16.65113 16.65113 0 0 1-12.020869 5.164522z m289.569391-107.341913a29.295304 29.295304 0 0 0 20.613565 49.997913 28.93913 28.93913 0 0 0 20.836174-49.062957 29.250783 29.250783 0 0 0-40.247652-2.226087l-1.202087 1.291131z"
        fill="#1676FF"
      ></path>
    </symbol>
    <symbol id="iconfont-gongdanguanli" viewBox="0 0 1024 1024">
      <path
        d="M962.470957 756.914087c0-26.045217 16.339478-48.083478 39.268173-56.898783a228.307478 228.307478 0 0 0-24.932173-60.282434 60.861217 60.861217 0 0 1-80.450783-80.495305 229.286957 229.286957 0 0 0-60.326957-24.932174 61.039304 61.039304 0 0 1-56.898782 39.268174A60.994783 60.994783 0 0 1 722.231652 534.26087a228.307478 228.307478 0 0 0-60.282435 24.932173 60.950261 60.950261 0 0 1-12.466087 67.984696 61.083826 61.083826 0 0 1-67.984695 12.510609A226.793739 226.793739 0 0 0 556.521739 700.015304c22.928696 8.859826 39.268174 30.898087 39.268174 56.898783A60.994783 60.994783 0 0 1 556.521739 813.857391c5.476174 21.459478 13.935304 41.672348 24.932174 60.282435a60.861217 60.861217 0 0 1 80.450783 80.495304 229.286957 229.286957 0 0 0 60.326956 24.932174A61.039304 61.039304 0 0 1 779.130435 940.29913c26.045217 0 48.083478 16.384 56.898782 39.268174a228.307478 228.307478 0 0 0 60.282435-24.932174 60.950261 60.950261 0 0 1 12.466087-67.984695 61.083826 61.083826 0 0 1 67.984696-12.510609c11.041391-18.610087 19.500522-38.822957 24.976695-60.282435a60.994783 60.994783 0 0 1-39.268173-56.898782zM779.130435 818.042435a61.128348 61.128348 0 1 1 0-122.256696 61.128348 61.128348 0 0 1 0 122.256696z"
        fill="#1676FF"
      ></path>
      <path
        d="M259.472696 693.782261a37.442783 37.442783 0 0 0 0 74.930087h74.930087a37.442783 37.442783 0 1 0 0-74.930087H259.472696z m0-199.769044a37.442783 37.442783 0 1 0 0 74.930087h224.745739a37.442783 37.442783 0 1 0 0-74.930087H259.472696z m0-249.677913a37.442783 37.442783 0 0 0 0 74.930087h374.739478a37.442783 37.442783 0 0 0 0-74.930087H259.472696z"
        fill="#FFC709"
      ></path>
      <path
        d="M830.018783 11.130435a33.391304 33.391304 0 0 1 33.079652 28.850087l0.311652 4.541217v413.651478a33.391304 33.391304 0 0 1-66.470957 4.541218l-0.311652-4.541218-0.044521-380.260174H155.826087c-28.493913 0-52.49113 23.329391-55.340522 53.871305l-0.311652 6.233043v808.069566h415.165217a33.391304 33.391304 0 0 1 33.079653 28.805565l0.311652 4.541217a33.391304 33.391304 0 0 1-28.850087 33.079652l-4.541218 0.311652L66.782609 1012.869565a33.391304 33.391304 0 0 1-33.079652-28.850087L33.391304 979.478261V138.017391C33.391304 70.878609 83.878957 15.36 148.346435 11.353043L155.826087 11.130435h674.192696z"
        fill="#1676FF"
      ></path>
    </symbol>
    <symbol id="iconfont-guanlianfujian1" viewBox="0 0 1121 1024">
      <path
        d="M1006.592 0c50.566095 0 91.526095 42.569143 91.526095 95.085714V316.952381H0V95.085714C0 42.569143 40.96 0 91.526095 0h915.065905z m0 73.142857H91.526095c-8.289524 0-16.14019 7.070476-17.993143 17.359238L73.142857 95.085714V243.809524h951.832381V95.085714c0-11.117714-6.826667-19.602286-14.872381-21.504l-3.510857-0.438857z"
        fill="#1676FF"
      ></path>
      <path
        d="M1098.118095 243.809524v682.666666c0 53.881905-41.74019 97.52381-93.184 97.52381H415.451429V243.809524h682.666666z m-73.191619 73.142857h-536.380952v633.904762h516.388571c9.167238 0 17.846857-8.045714 19.69981-19.797333L1024.975238 926.47619l-0.048762-609.523809z"
        fill="#1676FF"
      ></path>
      <path
        d="M487.619048 243.809524v780.190476H91.428571C40.911238 1024 0 980.358095 0 926.47619V243.809524h487.619048zM414.47619 950.857143v-633.904762h-341.333333V926.47619c0 12.873143 7.314286 22.137905 15.262476 24.03962l3.023238 0.341333H414.47619z"
        fill="#FFC709"
      ></path>
    </symbol>
    <symbol id="iconfont-jiandingjihua1" viewBox="0 0 1024 1024">
      <path
        d="M247.994182 725.271273a39.144727 39.144727 0 1 0 0 78.336h78.336a39.144727 39.144727 0 0 0 0-78.336H247.994182z m0-208.849455a39.144727 39.144727 0 0 0 0 78.336h234.961454a39.144727 39.144727 0 0 0 0-78.336H247.994182z m0-261.026909a39.144727 39.144727 0 1 0 0 78.336h391.773091a39.144727 39.144727 0 1 0 0-78.336H247.994182z"
        fill="#FFC709"
      ></path>
      <path
        d="M844.474182 11.636364a34.909091 34.909091 0 0 1 34.583273 30.161454l0.325818 4.747637v411.880727a34.909091 34.909091 0 0 1-69.492364 4.701091l-0.325818-4.701091-0.046546-376.971637H139.636364a58.181818 58.181818 0 0 0-57.902546 52.224L81.454545 139.636364v802.816h434.036364a34.909091 34.909091 0 0 1 34.583273 30.161454l0.325818 4.747637a34.909091 34.909091 0 0 1-30.161455 34.583272l-4.747636 0.325818H46.545455a34.909091 34.909091 0 0 1-34.583273-30.114909l-0.325818-4.747636V139.636364A128 128 0 0 1 131.816727 11.869091L139.636364 11.636364h704.837818z"
        fill="#1676FF"
      ></path>
      <path
        d="M791.272727 558.545455c128.372364 0 232.727273 104.354909 232.727273 232.727272s-104.354909 232.727273-232.727273 232.727273-232.727273-104.354909-232.727272-232.727273 104.354909-232.727273 232.727272-232.727272z m-8.750545 116.363636a29.696 29.696 0 0 0-29.696 29.696v78.336h-0.325818v59.392h0.325818v0.372364h59.392v-0.372364h95.325091a29.696 29.696 0 0 0 0-59.392H812.218182v-78.336a29.696 29.696 0 0 0-29.696-29.696z"
        fill="#1676FF"
      ></path>
    </symbol>
    <symbol id="iconfont-baoyangjihua1" viewBox="0 0 1024 1024">
      <path
        d="M813.860919 185.768688L638.145682 232.953759l76.184229 283.560976 175.633318-47.185071 6.225808-2.007004c35.347844-13.967109 53.86143-50.584689 44.399841-85.809655l-38.993218-145.118668a71.67871 71.67871 0 0 0-87.816659-50.625649z m15.892194 59.349972a10.239816 10.239816 0 0 1 12.574494 7.20883l38.952259 145.118668a10.239816 10.239816 0 0 1-6.061971 12.164901l-2.867149 0.901104-114.604017 30.719447-44.276963-164.942951 116.324306-31.169999z"
        fill="#FFC709"
      ></path>
      <path
        d="M711.790436 214.440172l-24.493639-91.339156L491.511521 10.012492a75.487921 75.487921 0 0 0-102.889668 27.565584L30.515019 657.660354a75.487921 75.487921 0 0 0 27.565584 102.889668l391.570552 226.013212a75.487921 75.487921 0 0 0 102.848709-27.524625l244.854472-424.174125-17.530564-65.698657-68.033336-254.766614zM487.415595 921.356088l-391.570552-226.013212 357.983957-620.041319 162.485395 93.796711 96.786738 361.424535-225.685538 390.833285z m440.721667-347.416467s-75.324084 66.517843-75.324084 109.975621c0 43.416818 33.791392 78.518907 75.365043 78.518906 41.65557 0 75.324084-35.224966 75.324085-78.518906 0-43.3349-75.365043-109.97562-75.365044-109.975621z"
        fill="#1676FF"
      ></path>
    </symbol>
    <symbol id="iconfont-shebeiwendang" viewBox="0 0 1068 1024">
      <path
        d="M983.930435 957.217391H84.591304a17.808696 17.808696 0 0 1-17.808695-17.808695v-186.991305c0-9.794783 8.013913-17.808696 17.808695-17.808695h899.339131c9.794783 0 17.808696 8.013913 17.808695 17.808695v186.991305a17.808696 17.808696 0 0 1-17.808695 17.808695z m21.993739-278.617043H62.597565c-34.54887 0-62.597565 28.048696-62.597565 62.597565v218.112c0 34.54887 28.048696 62.597565 62.597565 62.597565h943.326609c34.54887 0 62.597565-28.048696 62.597565-62.597565v-218.112c0-34.504348-28.048696-62.597565-62.597565-62.597565z"
        fill="#1676FF"
      ></path>
      <path
        d="M712.347826 690.086957H178.086957v-623.304348h534.260869v623.304348zM716.889043 0H174.258087c-34.54887 0-62.597565 28.048696-62.597565 62.597565v635.814957c0 34.54887 667.826087 34.54887 667.826087 0V62.686609C779.486609 28.048696 751.437913 0 716.889043 0z"
        fill="#1676FF"
      ></path>
      <path
        d="M868.173913 677.576348h-89.043478V250.078609h89.043478v427.497739z m7.123478-510.976h-103.290434c-32.946087 0-59.65913 28.048696-59.659131 62.597565v469.25913c0 34.504348 222.608696 34.504348 222.608696 0V229.197913c0-34.504348-26.713043-62.597565-59.659131-62.597565z"
        fill="#1676FF"
      ></path>
      <path
        d="M600.687304 263.657739h-289.391304a33.391304 33.391304 0 0 1 0-66.782609h289.391304a33.391304 33.391304 0 1 1 0 66.782609zM600.687304 419.483826h-289.391304a33.391304 33.391304 0 0 1 0-66.782609h289.391304a33.391304 33.391304 0 1 1 0 66.782609zM467.122087 575.309913h-155.826087a33.391304 33.391304 0 1 1 0-66.782609h155.826087a33.391304 33.391304 0 1 1 0 66.782609z"
        fill="#FFC709"
      ></path>
      <path
        d="M567.652174 894.619826h-66.782609a41.71687 41.71687 0 1 1 0-83.478261h66.782609a41.71687 41.71687 0 1 1 0 83.478261z"
        fill="#1676FF"
      ></path>
    </symbol>
    <symbol id="iconfont-guzhangjilu" viewBox="0 0 1117 1024">
      <path
        d="M1080.593262 1003.707169h-250.914342a36.443318 36.443318 0 0 1-36.443318-36.443319v-8.098515c0-20.106658 16.290117-36.443318 36.443318-36.443318h250.867799c20.153202 0 36.489862 16.290117 36.489861 36.443318v8.098515c0 20.106658-16.290117 36.443318-36.443318 36.443319z m0-121.431185h-250.914342a36.443318 36.443318 0 0 1-36.443318-36.443319v-8.098515c0-20.106658 16.290117-36.443318 36.443318-36.443318h250.867799c20.153202 0 36.489862 16.33666 36.489861 36.443318v8.098515c0 20.153202-16.290117 36.443318-36.443318 36.443319z m0-121.384642h-250.914342a36.443318 36.443318 0 0 1-36.443318-36.443318v-8.098516c0-20.106658 16.290117-36.443318 36.443318-36.443318h250.867799c20.153202 0 36.489862 16.290117 36.489861 36.443318v8.098516c0 20.106658-16.290117 36.443318-36.443318 36.443318z m-429.733281-560.84545l-0.465432 0.605062-54.54862-94.94811a46.310475 46.310475 0 0 0-4.561232-7.912342l-0.418889-0.698148h-0.186173a40.306403 40.306403 0 0 0-64.369233 0h-0.232716l-0.418888 0.698148a41.749242 41.749242 0 0 0-4.468147 7.912342l-54.502076 94.94811-0.558519-0.558518-323.475176 562.800263 0.837777 0.232716-54.548619 94.94811 0.046543 0.139629a40.492576 40.492576 0 0 0 32.347518 64.648492h590.958894v32.394061c0 17.174437 3.397653 33.557641 9.494811 48.591091H109.190326a36.676034 36.676034 0 0 1-14.428389-3.025307 121.617358 121.617358 0 0 1-88.850952-156.105862 51.662942 51.662942 0 0 1 6.050615-16.47629L452.306729 62.045331c0.605061-1.117037 1.396296-2.094444 2.140986-3.118393a121.198469 121.198469 0 0 1 207.954977 0.046543c0.744691 0.977407 1.535925 1.954814 2.140987 3.07185l308.581355 536.92225h-93.086381l-229.225215-398.875145z"
        fill="#1676FF"
      ></path>
      <path
        d="M558.51829 728.450738a56.689606 56.689606 0 1 1 0 113.425756 56.689606 56.689606 0 0 1 0-113.379213zM493.683625 339.908181a64.788122 64.788122 0 1 1 128.924639 9.169008l-23.643941 298.481483a40.492576 40.492576 0 1 1-80.985152 0l-23.504311-298.388396a66.649849 66.649849 0 0 1-0.791235-9.262095z"
        fill="#FFC709"
      ></path>
    </symbol>
    <symbol id="iconfont-anquanfujian" viewBox="0 0 1024 1024">
      <path
        d="M931.285333 262.528H406.186667a20.181333 20.181333 0 0 0-20.181334 20.224v363.52c0 11.136 9.045333 20.181333 20.181334 20.181333h525.098666a20.181333 20.181333 0 0 0 20.181334-20.181333v-363.52a20.181333 20.181333 0 0 0-20.181334-20.224z m-50.517333 333.226667h-424.106667v-121.173334h424.106667v121.173334z m0-191.829334h-424.106667V333.226667h424.106667v70.698666z"
        fill="#FFC709"
      ></path>
      <path
        d="M721.322667 737.152a20.309333 20.309333 0 0 0-13.952 5.632c-9.045333 8.533333-18.773333 16.384-29.013334 23.381333l-231.765333 158.122667-231.850667-158.037333a232.106667 232.106667 0 0 1-101.376-191.957334V178.261333l333.226667-104.106666 333.226667 104.106666v13.610667a10.112 10.112 0 0 0 10.069333 10.069333h50.517333c5.546667 0 10.069333-4.522667 10.069334-10.069333v-28.544c0-22.058667-14.336-41.6-35.413334-48.170667L446.592 0 78.08 115.2C57.045333 121.813333 42.666667 141.312 42.666667 163.413333v410.88a302.933333 302.933333 0 0 0 132.266666 250.325334l271.658667 185.173333 271.573333-185.173333a302.634667 302.634667 0 0 0 73.813334-71.424 10.112 10.112 0 0 0 0.853333-10.538667c-1.109333-2.261333-24.96-4.096-71.509333-5.546667z"
        fill="#1676FF"
      ></path>
    </symbol>
  </svg>
</template>

<script>
export default {
  name: "ColorIcon"
};
</script>

<style></style>
