<template>
  <div class="render-area">
    <render-title :item-data="itemData"></render-title>
    <div v-for="item in flows" :key="item.id" class="render-area-box">
      <RenderForm
        :key="`render-${item.id}`"
        ref="renderForm"
        class="dialog-render-from"
        :render-list="item.formList"
        :editable="editable"
        :render-form-data="item.renderFormData"
        v-bind="$attrs"
        v-on="$listeners"
      ></RenderForm>
      <div class="operate-box">
        <van-field label="操作人:" readonly :value="item.userName"></van-field>
        <van-field
          label="操作时间:"
          readonly
          :value="item.createTime"
        ></van-field>
      </div>
    </div>
  </div>
</template>

<script>
import { getValues } from "../api/index";
import axios from "@/utils/axios";
import RenderTitle from "./renderTitle";

export default {
  name: "RenderArea",
  props: {
    value: [String, Array],
    itemData: Object,
    editable: Boolean
  },
  components: {
    RenderForm: () => import("../RenderForm"),
    RenderTitle
  },
  data() {
    return {
      data: {},
      flows: [],
      api: axios
    };
  },
  created() {
    this.data = JSON.parse(this.value);
    this.getValues();
  },
  methods: {
    initRenderFormData(formList, layoutValues = {}) {
      let renderFormData = {};
      formList.map(v => {
        if (v.type === "title") {
          renderFormData[v.code] = v.label || "";
        } else if (v.type === "group") {
          let obj = {};
          v.children.map(k => {
            if (Array.isArray(k.value)) {
              if (k.value[0]) {
                obj[k.code] =
                  k.type === "input"
                    ? Array.isArray(k.value)
                      ? k.value
                      : [k.value]
                    : k.value;
              } else {
                obj[k.code] = { label: k.label, value: "", type: k.type };
              }
            } else {
              if (k.value) {
                obj[k.code] =
                  k.type === "input"
                    ? Array.isArray(k.value)
                      ? k.value
                      : [k.value]
                    : k.value;
              } else {
                obj[k.code] = { label: k.label, value: "", type: k.type };
              }
            }
          });
          renderFormData[v.code] = [obj];
        } else if (["docUpload", "imageUpload"].includes(v.type)) {
          renderFormData[v.code] = [];
        } else if (["input", "textarea"].includes(v.type)) {
          renderFormData[v.code] = [""];
        } else if (
          [
            "ckeditor",
            "number",
            "user",
            "position",
            "department",
            "facility",
            "indicator",
            "jsa",
            "phone",
            "material",
            "product",
            "intermediate",
            "feedstock"
          ].includes(v.type)
        ) {
          renderFormData[v.code] = v.value || "";
        } else if (["area"].includes(v.type)) {
          let change = {
            changeId: this.id
          };
          renderFormData[v.code] = JSON.stringify({ ...v, ...change });
        } else {
          renderFormData[v.code] = v.value || [];
        }
      });
      renderFormData = Object.assign({}, renderFormData, layoutValues);
      return renderFormData;
    },
    isJSON(str) {
      if (typeof str == "string") {
        try {
          const obj = JSON.parse(str);
          return !!(typeof obj == "object" && obj);
        } catch (e) {
          return false;
        }
      }
    },
    getValues() {
      let params = {
        changeId: this.data.changeId,
        flowId: this.data.flowId
      };
      getValues(this.api, params).then(res => {
        this.flows = res.map(v => {
          let obj = {};
          if (v.value) {
            let arr = JSON.parse(v.value);
            arr.map(k => {
              if (k.type === "docUpload") {
                obj[k.name] = JSON.parse(k.value);
              } else if (
                [
                  "group",
                  "date",
                  "dateTime",
                  "textarea",
                  "input",
                  "ckeditor"
                ].includes(k.type)
              ) {
                obj[k.name] = k.value || "";
              } else {
                if (
                  k.value &&
                  typeof k.value === "string" &&
                  k.value.indexOf(",") > 0 &&
                  !this.isJSON(k.value)
                ) {
                  obj[k.name] = k.value.split(",") || [];
                } else {
                  obj[k.name] = k.value || "";
                }
              }
            });
          }
          v.formList = v.settings ? JSON.parse(v.settings) : [];
          v.renderFormData = this.initRenderFormData(v.formList, obj);
          return v;
        });
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.render-area {
  width: 100%;
  height: 30px;
  ::v-deep.render-title {
    margin-top: 10px;
  }
}
.render-area-box {
  float: left;
  width: 100%;
  height: auto;
  overflow-y: scroll;
  border-bottom: 1px dashed #e0e0e0;
}
.render-area-box:last-of-type {
  border: 0;
}
.operate-box {
  ::v-deep {
    .van-cell::after {
      border-bottom-color: transparent;
    }
  }
}
</style>
