export const getFileUrl = (id, downloadFlag = true) => {
  return `${window.globalConfig.VUE_APP_FILE_URL}/file/download?downloadFlag=${downloadFlag}&fileName=${id}`;
};

export const FILE_ACCEPT_TYPES = {
  IMAGE: "image/*", //图片：拍照+相册
  AUDIO: "audio/*", //音频
  VIDEO: "video/*", //视频：录像+相册
  FILE: "*/*", //附件：打开文件管理
  IMAGE_AND_AUDIO: "image/*,video/*", //图片和视频：拍照 + 相册 + 录像
  ONLY_PHOTO: ".only_photo", //只能拍照（自定义格式）
  ONLY_VIDEO: ".only_video", //只能录像（自定义格式）
  ONLY_PHOTO_AND_VIDEO: ".only_photo_video" //只能录像+拍照（自定义格式）
};

export const getFileType = fileName => {
  // 后缀获取
  let suffix = "";
  // 获取类型结果
  let result = "";
  try {
    const flieArr = fileName.split(".");
    suffix = flieArr[flieArr.length - 1];
  } catch (err) {
    suffix = "";
  }
  // fileName无后缀返回 false
  if (!suffix) {
    return "";
  }
  suffix = suffix.toLocaleLowerCase();
  // 图片格式
  const imglist = ["png", "jpg", "jpeg", "bmp", "gif"];
  // 进行图片匹配
  result = imglist.find(item => item === suffix);
  if (result) {
    return "image";
  }
  // 匹配txt
  const txtlist = ["txt"];
  result = txtlist.find(item => item === suffix);
  if (result) {
    return "txt";
  }
  // 匹配 excel
  const excelist = ["xls", "xlsx"];
  result = excelist.find(item => item === suffix);
  if (result) {
    return "excel";
  }
  // 匹配 word
  const wordlist = ["doc", "docx"];
  result = wordlist.find(item => item === suffix);
  if (result) {
    return "word";
  }
  // 匹配 pdf
  const pdflist = ["pdf"];
  result = pdflist.find(item => item === suffix);
  if (result) {
    return "pdf";
  }
  // 匹配 ppt
  const pptlist = ["ppt", "pptx"];
  result = pptlist.find(item => item === suffix);
  if (result) {
    return "ppt";
  }
  // 匹配 视频
  const videolist = [
    "mp4",
    "m2v",
    "mkv",
    "rmvb",
    "wmv",
    "avi",
    "flv",
    "mov",
    "m4v"
  ];
  result = videolist.find(item => item === suffix);
  if (result) {
    return "video";
  }
  // 匹配 音频
  const audiolist = ["mp3", "wav", "wmv", "ogg"];

  result = audiolist.find(item => item === suffix);
  if (result) {
    return "audio";
  }
  // 其他 文件类型
  return "other";
};

/**
 * 获取视频第一帧
 * @param url
 * @returns {Promise<unknown>}
 */
export const getVideoImageBase64 = url => {
  return new Promise(function(resolve, reject) {
    let dataURL = "";
    let video = document.createElement("video");
    video.setAttribute("crossOrigin", "anonymous"); //处理跨域
    video.setAttribute("src", url);
    video.setAttribute("width", 500);
    video.setAttribute("height", 500);
    video.setAttribute("style", "object-fit:cover;");
    video.addEventListener("loadeddata", function() {
      let canvas = document.createElement("canvas"),
        width = video.width, //canvas的尺寸和图片一样
        height = video.height;
      canvas.width = width;
      canvas.height = height;
      canvas.getContext("2d").drawImage(video, 0, 0, width, height); //绘制canvas
      dataURL = canvas.toDataURL("image/jpeg"); //转换为base64
      resolve(dataURL);
    });
  });
};
