<template>
  <van-popup
    v-model="visible"
    :close-on-click-overlay="false"
    :style="treeStyle"
    get-container="body"
    position="bottom"
    round
    v-bind="$attrs"
    @click-overlay="onClose"
    @close="onClose"
    @open="onOpen"
    v-on="$listeners"
  >
    <section class="department-container">
      <div class="header">
        <h3 class="popup-title">{{ title }}</h3>
        <van-icon
          name="danchuangguanbi"
          class-prefix="iconfont"
          class="close-icon"
          @click.native="$emit('close')"
        />
      </div>
      <div :class="['content']">
        <div
          :class="{
            'org-tree-container': true,
            'container-height--nto-btn': !featureButtonVisible
          }"
        >
          <org-tree
            v-model="selectValue"
            :data="treeData"
            :label-key="labelKey"
            :value-key="valueKey"
            :disabled-key="disableKeyCom"
            :multiple="multiple"
            disabled-toast="没有对应部门的数据权限"
          />
        </div>
      </div>
      <div :class="['footer', includeChildren && 'include-children']">
        <div v-show="featureButtonVisible" class="btn-group">
          <!--占位-->
          <div style="width: 85px"></div>
          <van-button :disabled="!innerValue" @click.prevent="onReset"
            >重置</van-button
          >
          <van-button size="large" type="info" @click.prevent="onConfirm"
            >确定
          </van-button>
        </div>
      </div>
    </section>
  </van-popup>
</template>

<script>
import { getOrgNameByOrgCode } from "@/utils";

export default {
  name: "CascadeTree",
  model: {
    prop: "value", //绑定的值，通过父组件传递
    event: "update" //自定义时间名
  },
  props: {
    value: [String, Number, Array],
    includeChildren: {
      type: Boolean,
      default: false
    },
    treeStyle: {
      type: Object,
      default: () => ({ height: "80vh" })
    },
    title: {
      type: String,
      default: "级联"
    },
    visible: {
      type: Boolean,
      default: false
    },
    valueKey: {
      type: String,
      default: "id"
    },
    labelKey: {
      type: String,
      default: "label"
    },
    disabledKey: {
      type: String,
      default: "disabled"
    },
    isAuth: {
      type: Boolean,
      default: true
    },
    treeData: {
      type: Array,
      default() {
        return [];
      }
    },
    // 是否支持多选
    multiple: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      innerValue: "",
      selectValue: {},
      flatTreeData: [],
      featureButtonVisible: true
    };
  },
  watch: {
    selectValue: {
      handler(val) {
        if (this.multiple) {
          this.innerValue = val.map(o => o[this.valueKey]).join();
        } else {
          this.innerValue = val[this.valueKey] || "";
        }
      }
    }
  },
  created() {
    if (this.multiple) {
      this.selectValue = [];
    }
    this.setTreeData();
  },
  computed: {
    disableKeyCom() {
      if (this.isAuth) {
        return this.disabledKey;
      } else {
        return "";
      }
    }
  },
  methods: {
    async setTreeData() {
      try {
        this.flatTreeData = this.flatTree();
        this.initFilterTree();
      } catch (e) {
        console.log("setTreeData -> e", e);
      }
    },
    initFilterTree() {
      // 初始化需要响应式
      this.flatTreeData.forEach((item, idx) => {
        this.$set(item, "filtered", true);
        this.$set(this.flatTreeData, idx, item);
      });
    },
    resetFilterTree() {
      this.flatTreeData.forEach(item => (item.filtered = false));
    },
    flatTree() {
      function flatTree(tree, treeMap = [], depth = 0) {
        if (!(tree && tree.length)) return;
        depth++;
        return tree.reduce((acc, cur) => {
          cur.depth = depth;
          acc.push(cur);
          if (cur.children && cur.children.length) {
            flatTree(cur.children, treeMap, depth);
          }
          return acc;
        }, treeMap);
      }

      return flatTree(this.treeData);
    },

    filterTree(val) {
      if (!val) return this.initFilterTree();
      const labelKey = this.labelKey;
      const valueKey = this.valueKey;
      const treeData = this.treeData;

      function setOwn(tree, name, parent = treeData) {
        for (let i = 0; i < tree.length; i++) {
          const currentNode = tree[i];
          if (currentNode[labelKey].includes(val)) {
            currentNode.filtered = true;
            const parentNode = setParent(parent, currentNode[valueKey]);
            if (parentNode && !parentNode._root) {
              treeData.forEach(tree => setParent(tree, parentNode[valueKey]));
            }
          }
          if (currentNode.children) {
            setOwn(currentNode.children, name, currentNode);
          }
        }
      }

      function setParent(parent, childId) {
        if (!parent) return;
        for (let i = 0; i < (parent?.children?.length || 0); i++) {
          const node = parent.children[i];
          if (node[valueKey] === childId) {
            parent.filtered = true;
            return parent;
          }
          if (node.children && node.children > 0) {
            return setParent(node, name);
          }
        }
      }
      setOwn(treeData, val);
    },
    onOpen() {
      // 回显
      this.setSelectValue();
    },
    onClose() {
      this.$emit("close");
    },
    setSelectValue() {
      if (this.multiple) {
        this.selectValue = this.value.map(id => {
          return {
            [this.valueKey]: id,
            [this.labelKey]: getOrgNameByOrgCode(this.treeData, id)
          };
        });
      } else {
        this.selectValue = {
          [this.valueKey]: this.value,
          [this.labelKey]: getOrgNameByOrgCode(this.treeData, this.value)
        };
      }
    },
    getSelectValue() {
      this.setSelectValue();
      return this.selectValue;
    },
    getChildrenById(id) {
      if (!id || !this.treeData || !this.treeData.length) return [];
      const list = [...this.treeData];
      let current = {};
      while (current.id !== id) {
        current = list.shift() || {};
        current.children && list.push(...current.children);
      }
      return current.children;
    },
    async onConfirm() {
      this.$emit(
        "update",
        this.multiple
          ? this.innerValue.split(",").filter(o => o)
          : this.innerValue
      );
      this.$emit("confirm", this.selectValue, {
        tree: [...this.treeData]
      });
      this.onClose();
    },
    onReset() {
      this.innerValue = "";
      if (this.multiple) {
        this.selectValue = [];
      } else {
        this.selectValue = {
          [this.valueKey]: "",
          [this.labelKey]: ""
        };
      }
      this.$emit("update", "");
      this.$emit("reset");
    }
  }
};
</script>

<style lang="scss" scoped>
input.van-field__control {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.van-search {
  padding-left: 0;
  padding-right: 0;
}
.van-hairline--top-bottom::after,
.van-hairline-unset--top-bottom::after {
  border: none;
}

.department-container {
  position: relative;
  height: 100%;
}

.header {
  text-align: center;
  position: relative;
  .close-icon {
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
    color: #9496a3;
    z-index: 1;
    font-size: 18px;
  }
  .popup-title {
    font-weight: bold;
    font-size: 16px;
    padding: 20px 0 16px;
    color: #1b1b4e;
  }
}

.content {
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 30px;
  bottom: 0;
  left: 20px;
  right: 20px;
  padding-top: 16px;
  .org-tree-container {
    position: absolute;
    top: 30px;
    bottom: 52px;
    left: 0;
    right: 0;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  }
  .container-height--nto-btn {
    bottom: 0;
  }
}

.footer {
  position: fixed;
  left: 0;
  right: 0;
  padding-left: 16px;
  padding-right: 6px;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 50px;
  line-height: 50px;
  box-shadow: 0 1px 0 0 #c7c9d0 inset;
  .include {
    width: 85px;
    color: #3b4664;
    font-size: 12px;
    margin-right: 30px;
    visibility: hidden;
    ::v-deep {
      .van-checkbox__icon {
        height: initial;
      }
      .van-checkbox__icon .van-icon {
        width: 16px;
        height: 16px;
        font-size: 16px;
        line-height: 16px;
        border-radius: 2px;
        vertical-align: middle;
      }
      .van-checkbox__label {
        vertical-align: middle;
      }
    }
  }
  .btn-group {
    flex: auto;
    display: flex;
    justify-content: space-between;
  }
  .van-button {
    width: 110px;
    border-radius: 38px;
    height: 38px;
    line-height: 38px;
  }
  .cascade-tree__footer-button--confirm {
    background-color: $--button-color-save;
    border-color: $--button-color-save;
    box-shadow: $--button-color-save-shadow;
  }
}
.include-children {
  .include {
    visibility: visible;
  }
}
</style>
