<template>
  <van-field
    v-model="data[0]"
    class="render-input-box"
    :readonly="!$attrs.editable"
    :label="itemData.label + ':'"
    :required="itemData.options.required"
    :placeholder="$attrs.editable ? '请输入' : ''"
    :error-message="errorMessage"
    :rules="[
      {
        validator: validator
      }
    ]"
  >
  </van-field>
</template>

<script>
export default {
  name: "RenderInput",
  props: {
    value: [String, Array],
    itemData: Object
  },
  data() {
    return {
      data: [""],
      errorMessage: ""
    };
  },
  watch: {
    data: {
      handler: function(val) {
        this.$emit("input", val);
      },
      deep: true
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.data = Array.isArray(this.value) ? this.value : [this.value];
    });
  },
  methods: {
    validator(value) {
      if (this.itemData.options) {
        if (this.itemData.options.required && !value) {
          this.errorMessage = `${this.itemData.label}不能为空`;
          return false;
        }
        if (this.data.some(v => v.length > this.itemData.options.maxlength)) {
          this.errorMessage = `不超过${this.itemData.options.maxlength}个字符`;
          return false;
        }
      }
      this.errorMessage = "";
      return true;
    }
  }
};
</script>

<style lang="scss" scoped>
.render-input-box {
  position: relative;
}
</style>
