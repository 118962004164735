import Vue from "vue";
import EsignView from "./EsignView";

const component = Vue.extend(EsignView);
let instance;

function init() {
  if (!instance) {
    instance = new component({
      el: document.createElement("div")
    });
    document.body.appendChild(instance.$el);
  }
}

export default {
  /**
   * 显示电子签名弹窗
   * @param callback 签名成功后会回调callback(resultImg)
   */
  show(callback) {
    init();
    if (instance.visible) return;

    Vue.nextTick(() => {
      instance.show(callback);
    });
  },
  showImage(imgUrl) {
    init();
    if (instance.visible) return;

    Vue.nextTick(() => {
      instance.showImage(imgUrl);
    });
  }
};
