<template>
  <van-field
    v-model="data"
    class="render-input-box"
    :label="itemData.label + ':'"
    :prop="itemData.code"
    :readonly="!$attrs.editable"
    type="number"
    :required="itemData.options.required"
    :placeholder="$attrs.editable ? '请输入' : ''"
    :rules="[
      {
        validator: validator
      }
    ]"
  >
    <template>
      <span
        v-if="itemData.options.hasUnit && itemData.options.unit"
        slot="button"
        class="unit"
        >{{ itemData.options.unit }}</span
      >
    </template>
  </van-field>
</template>

<script>
export default {
  name: "RenderPhone",
  props: {
    value: String,
    itemData: Object
  },
  data() {
    return {
      data: "",
      errorMessage: ""
    };
  },
  watch: {
    data: {
      handler: function(val) {
        this.$emit("input", val);
      },
      deep: true
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.data = this.value;
    });
  },
  methods: {
    validator() {
      let isPhone = /^1\d{10}/.test(this.data);
      if (this.itemData.options) {
        if (this.itemData.options.required && !this.data) {
          this.errorMessage = `${this.itemData.label}不能为空`;
          return false;
        }
        if (this.data && !isPhone) {
          this.errorMessage = `请输入正确的${this.itemData.label}`;
          return false;
        }
      }
      this.errorMessage = "";
      return true;
    }
  }
};
</script>

<style lang="scss" scoped>
.render-input-box {
  position: relative;
}
</style>
