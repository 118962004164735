<template>
  <van-popup
    v-model="visible"
    :close-on-click-overlay="false"
    :style="popupStyle"
    get-container="body"
    position="bottom"
    round
    v-bind="$attrs"
    class="add-facility"
    @click-overlay="onClose"
    @close="onClose"
    @open="onOpen"
    v-on="$listeners"
  >
    <section class="add-facility__container">
      <div class="add-facility__header">
        <van-icon
          class="header-icon"
          name="danchuangguanbi"
          class-prefix="iconfont"
          @click.native="$emit('close')"
        />
        <h3 class="add-facility__title">{{ $attrs.title || "生产装置" }}</h3>
        <van-icon
          class="header-icon confirm-icon"
          name="danchuangqueren"
          class-prefix="iconfont"
          @click.native="onConfirm"
        />
      </div>
      <van-tabs v-model="activeName" animated swipeable @change="onTabsChange">
        <van-tab
          v-for="(item, index) in facilityArr"
          :key="index"
          :title="item.label"
          :name="String(item.value)"
        >
        </van-tab>
      </van-tabs>
      <div class="add-facility__list">
        <van-search
          v-model="facilityInput"
          autofocus
          class="search-bar"
          placeholder="请输入关键字"
          @search="onSearch"
        >
          <template #left-icon>
            <van-icon
              name="sousuo"
              class-prefix="iconfont"
              class="search-icon"
            />
          </template>
        </van-search>
        <!--          v-if="showFilterDepartment"-->
        <van-search
          v-if="false"
          v-model="filterDepartmentName"
          class="search-bar"
          placeholder="请选择部门"
          readonly
          @focus="departmentVisible = true"
        >
          <template #left-icon>
            <van-icon
              name="sousuo"
              class-prefix="iconfont"
              class="search-icon"
            />
          </template>
        </van-search>
        <cascade-department
          v-model="filterDepartment"
          :visible="departmentVisible"
          @close="departmentVisible = false"
          @confirm="filterDepartmentConfirm"
        />
        <van-pull-refresh
          v-model="isPullRefreshing"
          class="add-facility__refresh"
          @refresh="onSearch"
        >
          <!--          :class="showFilterDepartment ? 'show-dept' : ''"-->
          <van-checkbox-group
            v-model="checkedList"
            :border="false"
            @change="onCheckChange"
          >
            <van-list
              v-model="isLoading"
              :error.sync="isError"
              :finished="isFinished"
              :finished-text="isError ? '' : '没有更多了'"
              error-text="加载失败，请稍后再试！"
              @load="getList"
            >
              <van-cell
                v-for="(item, index) in equipmentList"
                :key="index"
                :border="false"
                clickable
                :class="[
                  'add-facility__item',
                  checkedList.includes(activeName + '-' + item.value) &&
                    'activated-item'
                ]"
                @click="onSetChecked(item, index)"
              >
                <van-checkbox
                  ref="checkboxes"
                  :name="activeName + '-' + item.value"
                  label-position="left"
                  shape="square"
                >
                  <template #icon>
                    <i></i>
                  </template>
                  <span>{{ item.label }}</span>
                </van-checkbox>
              </van-cell>
            </van-list>
          </van-checkbox-group>
        </van-pull-refresh>
      </div>
    </section>
  </van-popup>
</template>

<script>
import { getPageEquipmentSimple } from "@/api/basics/equipment";
import {
  getPageSelectWarehouses,
  getPageSelectWarehousesForOrg
} from "@/api/basics/warehouses";
import {
  getPageSelectTanks,
  getPageSelectTanksForOrg
} from "@/api/basics/tanks";
import {
  getPageSelectDevices,
  getPageSelectDevicesForOrg
} from "@/api/basics/devices";
import {
  getPageSelectTankAreas,
  getPageSelectTankAreasForOrg
} from "@/api/basics/tanksAreas";
import { getPageSelectPois, getPageSelectPoisForOrg } from "@/api/basics/pois";
import {
  getPageSelectWarehouseAreas,
  getPageSelectWarehouseAreasForOrg
} from "@/api/basics/warehouseAreas";
import { mapState } from "vuex";
import authApi from "@/api/authorization";

export default {
  // 用法与 web 端基本保持一直，不一样的参数有：多选属性（multiple）
  name: "AddFacility",
  model: {
    prop: "value", // 绑定的值，通过父组件传递
    event: "update" // 自定义时间名
  },
  props: {
    value: {
      // 要求格式，{label: string, value: string, type: string}或数组
      // { label: '生产装置', value: '188060718199734272', type: 13 }
      type: [Object, Array]
    },
    multiple: {
      type: Boolean,
      default: false
    },
    popupStyle: {
      type: Object,
      default: () => ({ height: "80vh" })
    },
    visible: {
      type: Boolean,
      default: false
    },
    // 要展示的东东
    facility: {
      type: Array,
      default() {
        return [13, 14, 15, 31, 16, 17, 26];
      }
    },
    // 可不传，默认为登陆用户的 orgCode
    orgCode: String,
    // 激活的tab页签
    activated: {
      type: [String, Number],
      default: "13"
    },
    auth: {
      type: String,
      default: "department",
      validator: function(value) {
        // 目前有企业和部门权限，如有新增，这里新增规则
        return ["department", "org"].indexOf(value) !== -1;
      }
    },
    department: {
      type: [String, Number],
      default: ""
    },
    includesDepartment: {
      type: Boolean,
      default: false
    },
    // NOTE: 现在没有showFilterDepartment这个相关得搜索，包含子部门没有影响，如果再次放开这个属性则可能会有问题
    showFilterDepartment: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      activeName: "1",
      facilityInput: "",
      filterDepartment: "",
      filterDepartmentName: "",
      departmentVisible: false,
      equipmentList: [],
      total: 0,
      innerValue: {},
      innerValueMultiple: [],
      query: {
        orgCode: "",
        department: "",
        page: 1,
        size: 10
      },
      isPullRefreshing: false,
      isLoading: false,
      isFinished: true,
      isError: false,
      checkedList: [],
      equipmentMap: {},
      cacheMap: {},
      currentChecked: ""
    };
  },
  computed: {
    ...mapState({
      userInfo: state => state.login.userInfo
    }),
    facilityArr() {
      const facilityMap = {
        "13": "储罐",
        "14": "罐区",
        "15": "仓库",
        "31": "库区",
        "16": "生产单元",
        "17": "其他设施",
        "26": "设备"
      };
      return this.facility.map(value => ({
        value,
        label: facilityMap[value]
      }));
    }
  },
  methods: {
    filterDepartmentConfirm(value) {
      this.filterDepartmentName = value.label || "";
      this.onSearch();
    },
    onClose() {
      this.$emit("close");
    },
    onOpen() {
      this.activeName = String(this.activated);
      this.processInputParams();
      this.onTabsChange();
    },
    onTabsChange(name) {
      // this.facilityInput = "";
      // this.filterDepartmentName = "";
      // this.filterDepartment = "";
      this.equipmentList = [];
      this.getData(1);
    },
    resetQuery(page = 1) {
      delete this.query.tankName;
      delete this.query.worksiteName;
      delete this.query.poiName;
      delete this.query.tankAreaName;
      delete this.query.warehouseName;
      delete this.query.name;
      this.query.page = page;
      this.query.orgCode = this.orgCode || this.userInfo.orgCode;
    },
    async getList() {
      if (this.auth === "department") {
        await this.getDataForDepartment();
      } else {
        await this.getDataForOrg();
      }
    },
    async getData(page = 1) {
      this.resetQuery(page);
      await this.getList();
      await this.renderChecked();
    },
    async getDataForOrg() {
      this.isFinished = false;
      this.isLoading = true;
      if (this.isPullRefreshing) {
        this.equipmentList = [];
        this.isPullRefreshing = false;
      }
      try {
        let equipmentData = {};
        const activeName = this.activeName;
        // this.query.department = this.filterDepartment;
        //设备
        if (activeName === "26") {
          this.query.name = this.facilityInput;
          equipmentData = await getPageEquipmentSimple(this.query);
          equipmentData?.list?.forEach(x => {
            x.label = x.name;
            x.value = x.id;
          });
        }
        //仓库
        if (activeName === "15") {
          this.query.warehouseName = this.facilityInput;
          equipmentData = await getPageSelectWarehousesForOrg(this.query);
        }
        //储罐
        else if (activeName === "13") {
          this.query.tankName = this.facilityInput;
          equipmentData = await getPageSelectTanksForOrg(this.query);
        }
        //生产单元
        else if (activeName === "16") {
          this.query.name = this.facilityInput;
          equipmentData = await getPageSelectDevicesForOrg(this.query);
        }
        //罐区
        else if (activeName === "14") {
          this.query.tankAreaName = this.facilityInput;
          equipmentData = await getPageSelectTankAreasForOrg(this.query);
        }
        //其他设施
        else if (activeName === "17") {
          this.query.poiName = this.facilityInput;
          equipmentData = await getPageSelectPoisForOrg(this.query);
        }
        //库区
        else if (activeName === "31") {
          this.query.name = this.facilityInput;
          equipmentData = await getPageSelectWarehouseAreasForOrg(this.query);
        }
        const { list, total } = equipmentData;
        this.isError = false;
        this.isLoading = false;
        this.total = total;
        if (list && list.length) {
          this.equipmentList = this.equipmentList.concat(list);
          this.updateEquipmentMap();
          // 当前列表数量等于总数 暂无内容
          if (list.length >= this.total) {
            this.isFinished = true;
          } else {
            // 加载成功下次页码加1
            this.query.page++;
          }
        } else {
          this.isFinished = true;
        }
      } catch (e) {
        console.log(e);
        this.isLoading = false;
        this.isFinished = true;
        this.isError = true;
      }
    },
    async getDataForDepartment() {
      this.isFinished = false;
      this.isLoading = true;
      if (this.isPullRefreshing) {
        this.equipmentList = [];
        this.isPullRefreshing = false;
      }
      try {
        let equipmentData = {};
        const activeName = this.activeName;
        // this.query.department = this.filterDepartment;
        //设备
        if (activeName === "26") {
          this.query.name = this.facilityInput;
          equipmentData = await getPageEquipmentSimple(this.query);
        }
        //仓库
        if (activeName === "15") {
          this.query.warehouseName = this.facilityInput;
          equipmentData = await getPageSelectWarehouses(this.query);
        }
        //储罐
        else if (activeName === "13") {
          this.query.tankName = this.facilityInput;
          equipmentData = await getPageSelectTanks(this.query);
        }
        //生产单元
        else if (activeName === "16") {
          this.query.name = this.facilityInput;
          equipmentData = await getPageSelectDevices(this.query);
        }
        //罐区
        else if (activeName === "14") {
          this.query.tankAreaName = this.facilityInput;
          equipmentData = await getPageSelectTankAreas(this.query);
        }
        //其他设施
        else if (activeName === "17") {
          this.query.poiName = this.facilityInput;
          equipmentData = await getPageSelectPois(this.query);
        }
        //库区
        else if (activeName === "31") {
          this.query.name = this.facilityInput;
          equipmentData = await getPageSelectWarehouseAreas(this.query);
        }
        const { list, total } = equipmentData;
        this.isError = false;
        this.isLoading = false;
        this.total = total;
        if (list && list.length) {
          this.equipmentList = this.equipmentList.concat(list);
          this.updateEquipmentMap();
          // 当前列表数量等于总数 暂无内容
          if (list.length >= this.total) {
            this.isFinished = true;
          } else {
            // 加载成功下次页码加1
            this.query.page++;
          }
        } else {
          this.isFinished = true;
        }
      } catch (e) {
        console.log(e);
        this.isLoading = false;
        this.isFinished = true;
        this.isError = true;
      }
    },
    onSearch() {
      this.equipmentList = [];
      this.getData(1);
    },
    processUniqueKey(value, activeName) {
      // 默认值为当前的 activeName，若传递了取传递的
      activeName = activeName || this.activeName;
      // 唯一 key 由当前tab + 选中的value组成
      return activeName + "-" + value;
    },
    updateEquipmentMap() {
      this.equipmentList.forEach(item => {
        // 自己定的规则，于多选保存的key保持一直，[type + value]
        this.equipmentMap[this.processUniqueKey(item.value)] = item;
      });
    },
    processInputParams() {
      const params = this.value;
      if (!params) return;
      if (this.multiple) {
        params.forEach(item => {
          const key = this.processUniqueKey(item.value, item.type);
          this.cacheMap[key] = item;
        });
      } else {
        const { value, type } = params;
        this.cacheMap[this.processUniqueKey(value, type)] = params;
      }
    },
    async renderChecked() {
      await this.$nextTick();
      Object.keys(this.cacheMap).forEach(cacheKey => {
        const index = this.equipmentList.findIndex(eq => {
          return this.processUniqueKey(eq.value) === cacheKey;
        });
        if (!this.multiple) {
          // 单选先情空
          this.equipmentList.forEach((x, y) => {
            this.$refs.checkboxes[y].toggle(false);
          });
        }
        if (index !== -1) {
          this.$refs.checkboxes[index].toggle(true);
        }
      });
    },
    toResult() {
      if (!Object.keys(this.cacheMap).length) {
        return [{ label: "", value: "", type: "" }];
      }
      return Object.entries(this.cacheMap).map(([key, value]) => {
        const type = key.split("-")[0];
        return { ...value, type };
      });
    },
    async onSetChecked(row, index) {
      await this.$nextTick();
      if (!this.multiple) {
        const lastChecked = this.$refs.checkboxes[index].checked;
        this.equipmentList.forEach((x, y) => {
          this.$refs.checkboxes[y].toggle(false);
        });
        this.$refs.checkboxes[index].toggle();
        if (lastChecked) {
          this.currentChecked = "";
        } else {
          this.currentChecked = this.processUniqueKey(row.value);
        }
      } else {
        this.currentChecked = this.processUniqueKey(row.value);
      }
    },
    onCheckChange(list) {
      const currentChecked = this.currentChecked;
      if (this.multiple) {
        if (list.includes(currentChecked)) {
          this.cacheMap[currentChecked] = this.equipmentMap[currentChecked];
        } else {
          delete this.cacheMap[currentChecked];
        }
      } else {
        this.cacheMap = {};
        if (currentChecked) {
          this.cacheMap[currentChecked] = this.equipmentMap[currentChecked];
        }
      }
    },
    onConfirm() {
      const result = this.toResult();
      let r;
      if (this.multiple) {
        r = result;
      } else {
        r = result.at(-1);
      }
      this.$emit("update", r);
      this.$emit("confirm", r, result);
      this.$emit("close");
    }
  },
  watch: {
    department: {
      async handler(val) {
        let allDept = [val];
        if (this.includesDepartment) {
          allDept = await authApi.getSubDepartmentByDepartment(val);
        }
        this.query.department = allDept.toString() ?? "";
        this.getData();
      },
      immediate: true
    }
  }
};
</script>

<style scoped lang="scss">
/* 公共组件样式推荐写 scoped */
.add-facility {
  overflow: auto;
  &__container {
    position: relative;
    height: 100%;
    & ::v-deep {
      .van-tabs .van-tabs__wrap {
        border-bottom: 1px solid rgba(241, 241, 241, 0.8);
      }
    }
  }
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    font-size: 16px;
    font-weight: bold;
    color: #2e2e4d;
    padding: 16px 18px;
  }
  .header-icon {
    color: #9496a3;
    font-size: 18px;
  }
  .confirm-icon {
    color: $--color-primary;
  }
  .search-bar {
    padding: 16px 20px 0;
    .van-cell {
      height: 32px;
    }
  }
  &__list {
    height: calc(100% - 50px - 45px);
    & ::v-deep {
      .van-checkbox {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }
  }
  &__refresh {
    height: calc(100% - 66px);
    overflow-y: auto;
    padding: 16px 0 0;
  }
  &__refresh.show-dept {
    height: calc(100% - 116px);
  }
  &__item {
    padding: 12px 28px;
  }
  .activated-item {
    color: $--color-primary;
    background-color: rgba(22, 118, 255, 0.1);
    & ::v-deep {
      .van-cell__value--alone,
      .van-cell__value,
      .van-checkbox__label {
        color: inherit;
      }
    }
  }
}
</style>
