<template>
  <van-field
    class="CheckboxEditField"
    error-message-align="right"
    :value="validatorValue"
    :rules="$attrs.rules || [{ required: true, message: '请选择' + title }]"
    v-bind="$attrs"
  >
    <template #input>
      <div class="content-box">
        <div class="title">{{ title }}</div>
        <Tag
          v-for="(item, index) in options"
          v-show="editing ? true : isChecked(item)"
          :key="index"
          :type="isChecked(item) ? tagType : 'white'"
          @click.native="clickItem(index, item)"
        >
          <slot name="tagContent" :item="item" :index="index">{{
            item[optionLabelKey]
          }}</slot>
        </Tag>
        <Tag v-show="extra && !editing" :type="tagType">{{ extra }}</Tag>
        <slot></slot>
      </div>
    </template>
  </van-field>
</template>

<script>
import Tag from "@/components/Tag";
import EditFieldMixin from "@/components/edit/mixins/EditFieldMixin";
import {
  getCheckedOptions,
  getCheckedValues,
  isChecked
} from "@/components/edit/utils/options";

export default {
  name: "CheckboxEditField",
  components: { Tag },
  mixins: [EditFieldMixin],
  props: {
    tagType: {
      type: String,
      default: "blue"
    },
    extra: null,
    multiple: { type: Boolean, default: true },
    valueItemIsObject: { type: Boolean, default: false } //传进来的value的item是否是对象
  },
  data() {
    return {};
  },
  computed: {
    //van-field恶心的bug：必须给value设置响应式数据才有正确的校验功能，value只能是String，Number
    validatorValue() {
      let { value } = this;
      if (Array.isArray(value)) {
        return value.join(",");
      }
      return value;
    }
  },
  watch: {
    value: {
      immediate: true,
      handler(val) {}
    }
  },
  created: function() {},
  mounted: function() {},
  methods: {
    clickItem(index, item) {
      if (!this.editing) return;

      let {
        value,
        options,
        optionValueKey,
        multiple,
        valueItemIsObject
      } = this;

      let values = getCheckedValues({
        checked: value,
        value: item[optionValueKey],
        multiple
      });

      if (valueItemIsObject) {
        this.$emit(
          "change",
          getCheckedOptions({
            options,
            value: values,
            optionValueKey
          })
        );
      } else {
        this.$emit("change", values);
      }
    },
    isChecked(item) {
      return isChecked({
        checked: this.value,
        value: item[this.optionValueKey],
        optionValueKey: this.optionValueKey
      });
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.CheckboxEditField {
  padding-bottom: 15px;

  .content-box {
    width: 100%;
  }

  .title {
    color: $--color-black-title;
    margin-bottom: 8px;
  }

  .tags-box {
    padding: 0px 16px;
  }

  &::v-deep {
    .Tag {
      width: auto;
      padding-left: 15px;
      padding-right: 15px;
    }
  }
}
</style>
