<template>
  <van-popup
    v-model="visible"
    :close-on-click-overlay="false"
    position="bottom"
    :style="popupStyle"
    get-container="body"
    @open="onOpen"
    @close="close"
    @click-overlay="clickOverlay"
  >
    <section class="main">
      <div class="header block-space-left-right">
        <h3 class="popup-title">{{ title }}</h3>
        <van-field
          v-model="value"
          class="show-choose-result"
          readonly
          :label="
            selectVal[valueKey] && selectVal[labelKey]
              ? '目前选中：'
              : '您还未选择'
          "
        />
      </div>
      <div class="content">
        <org-tree
          v-model="selectVal"
          :data="data"
          :value-key="valueKey"
          :label-key="labelKey"
        />
      </div>
      <div class="footer block-space-left-right">
        <van-button
          plain
          size="large"
          class="cascader-tree__footer-button--cancel"
          @click.prevent="close(undefined)"
          >取消</van-button
        >
        <van-button
          size="large"
          type="info"
          class="cascader-tree__footer-button--confirm"
          :disabled="!value"
          @click.prevent="onConfirm(selectVal)"
          >确定</van-button
        >
      </div>
    </section>
  </van-popup>
</template>

<script>
import { Popup, Search, Button, Field } from "vant";
import OrgTree from "./OrgTree";
import { generatorComponents, getOrgNameByOrgCode } from "@/utils";
const components = [Popup, Search, Button, Field];
export default {
  name: "CascaderTree",
  components: { ...generatorComponents(components), OrgTree },
  props: {
    choosedVal: [String || Number],
    title: {
      type: String,
      default: "所属企业"
    },
    visible: {
      type: Boolean,
      default: false
    },
    data: {
      type: Array,
      required: true
    },
    valueKey: {
      type: String,
      default: "id"
    },
    labelKey: {
      type: String,
      default: "label"
    }
  },
  data() {
    return {
      popupStyle: {
        height: "75%"
      },
      value: "",
      choosedRow: null,
      selectVal: {}
    };
  },
  watch: {
    selectVal(val) {
      this.value = val.label;
    }
  },
  methods: {
    onOpen() {
      // 回显
      this.selectVal = {
        [this.valueKey]: this.choosedVal,
        [this.labelKey]: getOrgNameByOrgCode(this.data, this.choosedVal)
      };
    },
    close(val) {
      this.$emit("close", val ? val : undefined);
    },
    clickOverlay() {
      this.$emit("close", undefined);
    },
    onConfirm(val) {
      this.$emit("confirm", val);
      this.$emit("close", undefined);
    }
  }
};
</script>

<style lang="scss" scoped>
input.van-field__control {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.van-hairline--top-bottom::after,
.van-hairline-unset--top-bottom::after {
  border: none;
}
.show-choose-result {
  display: flex;
  justify-content: space-between;
}
.popup-title {
  font-weight: bold;
  font-size: 17px;
  margin-top: 3vh;
  padding-bottom: 3vh;
}
.content {
  height: 45vh;
  overflow-y: auto;
  margin: 0 9vw;
}
.footer {
  margin-top: 20px;
  display: flex;
  justify-content: space-around;
  .van-button {
    width: 148px;
  }
  .cascader-tree__footer-button--cancel {
    color: #1b0f49;
    background-color: $--button-color-cancel;
    box-shadow: $--button-border-color-cancel-shadow;
  }
  .cascader-tree__footer-button--confirm {
    background-color: $--button-color-save;
    border-color: $--button-color-save;
    box-shadow: $--button-color-save-shadow;
  }
}
</style>
