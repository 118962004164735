<template>
  <van-dialog
    v-model="visible"
    class="InputDialog"
    :class="{ loading }"
    :title="title"
    show-cancel-button
    :before-close="beforeClose"
    :disabled="loading"
  >
    <p v-if="t1" class="t1">{{ t1 }}</p>
    <p v-if="t2" class="t2">{{ t2 }}：</p>
    <van-field
      v-if="hasInput"
      v-model="message"
      class="remark-box"
      rows="2"
      :autosize="{ maxHeight: 250 }"
      type="textarea"
      :maxlength="maxlength"
      :placeholder="placeholder"
      show-word-limit
      :error="messageError"
      :disabled="loading"
    />
    <div v-if="pauseTime > 2" class="person-signature-box">
      <div style="line-height: 44px;">{{ otherPersonLabel }}：</div>
      <van-button class="btn-select-person" round @click="chooseNodePerson"
        >选择人员</van-button
      >
      <div class="tag-box">
        <Tag
          v-for="item in selectPerson.checkPersonList"
          :key="item.personId"
          :type="item.fileId ? 'green' : 'white'"
          :tick="!!item.fileId"
          @click.native="
            handleClickTag(
              item,
              item.fileId,
              item.personId,
              item.personType,
              ticketId,
              item.specialId
            )
          "
        >
          {{ item.personName }}
        </Tag>
      </div>
    </div>
    <template v-else>
      <div v-if="otherPerson.length" class="person-signature-box">
        <div style="line-height: 44px;">{{ otherPersonLabel }}人：</div>
        <div class="tag-box">
          <Tag
            v-for="item in otherPerson"
            :key="item.personId"
            :type="item.fileId ? 'green' : 'white'"
            :tick="!!item.fileId"
            @click.native="
              handleClickTag(
                item,
                item.fileId,
                item.personId,
                item.personType,
                ticketId,
                item.specialId
              )
            "
          >
            {{ item.personName }}
          </Tag>
        </div>
      </div>
    </template>
    <div v-if="hasCheckPerson" class="person-signature-box">
      <div
        v-show="
          isYanShouHeGe &&
            ticketEvaluate.evaluateQuotaList &&
            ticketEvaluate.evaluateQuotaList.length
        "
        class="work-evaluation"
      >
        作业评价：
        <van-button
          class="btn-select-person"
          :class="{
            'not-active': !ticketEvaluate.evaluateResult
          }"
          round
          @click="$refs.workEvaluation.open()"
          >作业评价</van-button
        >
        <div v-show="ticketEvaluate.evaluateResult" class="result-box">
          综合评价：<span class="result">{{
            ticketEvaluate.evaluateResult
          }}</span>
        </div>
      </div>
      <div>
        {{ choosePersonLabel }}人：
        <van-button class="btn-select-person" round @click="choosePerson"
          >选择人员</van-button
        >
      </div>
      <div
        class="tag-box"
        :style="choosePersonLabel === '验收' ? 'padding-left: 74px' : ''"
      >
        <Tag
          v-for="item in checkPersonList"
          :key="item.personId"
          :type="item.fileId ? 'green' : 'white'"
          :tick="!!item.fileId"
          @click.native="
            handleClickTag(
              item,
              item.fileId,
              item.personId,
              item.personType,
              ticketId,
              item.specialId
            )
          "
        >
          {{ item.personName }}
        </Tag>
      </div>
    </div>
    <WorkticketPersonPicker
      ref="personPicker"
      v-model="checkPersonList"
      title="验收人"
      :multiple="true"
      :is-check-person="true"
      :department="department"
      @change="checkPersonChange"
    />
    <select-person-by-tabs
      :key="recentType"
      :title="personByTabTitle"
      :visible="SPBTPicker"
      :show-department-name="false"
      :show-phone-number="true"
      position="bottom"
      :include-children="includeChildrenSwitch"
      :multiple="personByTabMultiple"
      :is-department-auth="false"
      :default-department="department"
      :recent-type="recentType"
      :show-recent-use-person="showRecentUsePerson"
      :default-tab-name="showRecentUsePerson ? 2 : 0"
      :exclude-person-ids="excludePersonIds"
      @close="SPBTPicker = false"
      @confirm="onPersonConfirm"
    />
    <select-person
      title="停电人(单选)"
      :visible="powerPersonPincker"
      :choose-value="checkPersonList"
      :show-department-name="false"
      :show-phone-number="true"
      position="bottom"
      :include-children="true"
      :multiple="false"
      :license-types="powerCutConfig.licenseTypes"
      department-disabled-key="--"
      :exclude-person-ids="excludePersonIds"
      @close="powerPersonPincker = false"
      @confirm="powerPersonConfirm"
    />
    <WorkEvaluation
      v-show="
        isYanShouHeGe &&
          ticketEvaluate.evaluateQuotaList &&
          ticketEvaluate.evaluateQuotaList.length
      "
      ref="workEvaluation"
      :data="ticketEvaluate"
    />
    <select-person
      :title="selectPerson.title"
      :visible="selectPerson.visible"
      :choose-value="selectPerson.checkPersonList"
      :show-department-name="false"
      :show-phone-number="true"
      position="bottom"
      :include-children="true"
      :multiple="false"
      license-types=""
      department-disabled-key="--"
      :custom-list="selectPerson.customList"
      @close="selectPerson.visible = false"
      @confirm="selectPersonConfirm"
    />
  </van-dialog>
</template>

<script>
import Tag from "@/components/Tag";
import esign from "@/components/esign";
import WorkticketPersonPicker from "@/components/picker/WorkticketPersonPicker";
import { fileUrl } from "@/utils/constant";
import { addWorkSignature } from "@/api/workticket";
import SelectPersonByTabs from "@/components/SelectPersonByTabs";
import WorkEvaluation from "@/views/workticket/components/WorkEvaluation";
export default {
  name: "InputDialog",
  components: {
    Tag,
    WorkticketPersonPicker,
    SelectPersonByTabs,
    WorkEvaluation
  },
  props: {},
  data() {
    return {
      projectName: window.globalConfig.VUE_APP_NAME,
      title: "标题",
      placeholder: "请输入",
      loading: false, //loading状态下，对话框不可点击
      visible: false,
      message: "",
      messageError: false,
      doneFn: null,
      resolve: null,
      reject: null,
      hasInput: true, //是否显示input输入框
      isRequired: true, // 是否必填
      successCb: null,
      failCb: null,
      checkPersonList: [],
      excludePersonIds: [], // 列表除外人员ids
      hasCheckPerson: false,
      ticketId: "",
      department: "",
      isPersonByTab: true,
      choosePersonLabel: "",
      personByTabTitle: "",
      personByTabMultiple: true,
      SPBTPicker: false,
      recentType: "",
      showRecentUsePerson: false,
      maxlength: 500,
      otherPerson: [],
      otherPersonLabel: "",
      powerPersonPincker: false,
      isPowerCut: false,
      powerCutConfig: {
        licenseTypes: ""
      },
      defaultCheckPerson: [],
      personType: "",
      t1: "",
      t2: "",
      isYanShouHeGe: false, // 是否是“验收合格”弹窗
      ticketEvaluate: {
        ticketId: "",
        evaluateQuotaList: [],
        evaluateRemark: "",
        evaluateResult: "",
        workerList: []
      },
      pauseTime: null, // 【高处作业】暂停时间：作业小于1h，不弹框；作业暂停1-3h，需要监护人签字；暂停3h以上，需要审批流最后一个节点的人员审批
      selectPerson: {
        title: "",
        visible: false,
        checkPersonList: [],
        customList: []
      }
    };
  },
  computed: {
    //提交按钮判断是否可点击
    submitBtnDisabled() {
      const personList = this.checkPersonList;
      const isSg = personList.some(item => !item.fileId);
      return personList.length ? isSg : true;
    },
    // 其他人是否签名
    otherSubmitBtnDisabled() {
      const personList = this.otherPerson;
      const isSg = personList.some(item => !item.fileId);
      return personList.length ? isSg : true;
    },
    includeChildrenSwitch() {
      const inCh = ["验票", "验收", "关闭", "批准"];
      return inCh.some(i => i === this.choosePersonLabel);
    }
  },
  watch: {
    message(newValue, oldValue) {
      this.messageError = false;
    }
  },
  methods: {
    powerPersonConfirm(ids, list) {
      const data = list ? (Array.isArray(list) ? list : [list]) : [];
      if (!data.length) {
        this.checkPersonList = [];
        return;
      }
      const checkPersonList = [];
      data.forEach(item => {
        console.log("item", item);
        const find = this.checkPersonList.find(f => f.personId === item.value);
        let obj = { ...find, personType: "26" };
        if (!find) {
          obj = {
            fileId: "",
            personType: "26",
            personId: item.value,
            personName: item.label,
            id: item.value,
            name: item.label,
            ticketId: this.ticketId,
            specialId: "",
            type: item._mechanismType || item.personType || "0"
          };
        }
        checkPersonList.push(obj);
        console.log("");
      });
      this.checkPersonList = checkPersonList;
    },
    checkPersonChange(val) {
      const data = val ? (Array.isArray(val) ? val : [val]) : [];
      if (!data.length) {
        this.checkPersonList = [];
        return;
      }
      const checkPersonList = [];
      data.forEach(item => {
        const find = this.checkPersonList.find(f => f.personId === item.value);
        let obj = { ...find, personType: "8" };
        if (!find) {
          obj = {
            fileId: "",
            personType: "8",
            personId: item.value,
            personName: item.label,
            ticketId: this.ticketId,
            specialId: ""
          };
        }
        checkPersonList.push(obj);
      });
      this.checkPersonList = checkPersonList;
    },
    onPersonConfirm(ids, list) {
      const data = list || [];
      if (!data.length) {
        this.checkPersonList = [];
        return;
      }
      const checkPersonList = [];
      data.forEach(item => {
        const find = this.checkPersonList.find(f => f.personId === item.value);
        let obj = { ...find, personType: "14" };
        if (!find) {
          obj = {
            ...item,
            fileId: "",
            personType: this.personType
              ? this.personType
              : this.choosePersonLabel === "验收"
              ? "8"
              : "14",
            personId: item.value,
            personName: item.label,
            ticketId: this.ticketId,
            specialId: ""
          };
        }
        checkPersonList.push(obj);
      });
      this.checkPersonList = checkPersonList;
    },
    handleClickTag(item, fileId, personId, personType, ticketId, specialId) {
      if (fileId) {
        //已上传过，直接预览
        esign.showImage(fileUrl + fileId);
        return;
      }
      esign.show(imageRes => {
        fileId = imageRes.id;
        if (personType === "3301") {
          // 签名移到外部
          item.fileId = fileId;
          return;
        }
        addWorkSignature({
          fileId,
          personId,
          personType,
          ticketId,
          specialId
        })
          .then(res => {
            item.fileId = fileId;
            this.$toast.success("提交成功");
          })
          .catch(error => {
            this.$toast.fail("签名失败，请重试");
          });
      });
    },
    show({
      title,
      message = "",
      hasInput = true,
      isRequired = true,
      successCb,
      failCb,
      hasCheckPerson,
      ticketId,
      department,
      isPersonByTab = true,
      choosePersonLabel = "验收",
      personByTabTitle = "验收人(多选)",
      personByTabMultiple = true,
      showRecentUsePerson = false,
      recentType = "",
      maxlength = 500,
      otherPerson = [],
      otherPersonLabel = "",
      isPowerCut = false,
      powerCutConfig = {},
      defaultCheckPerson = [],
      personType = "",
      t1 = "",
      t2 = "",
      ticketEvaluate,
      excludePersonIds = [], // 列表种除外的人员ids
      pauseTime
    }) {
      this.hasInput = hasInput;
      this.isRequired = isRequired;
      this.title = title;
      this.message = message;
      this.messageError = false;
      this.successCb = successCb;
      this.failCb = failCb;
      this.hasCheckPerson = hasCheckPerson;
      this.ticketId = ticketId;
      this.department = department;
      this.checkPersonList = [...defaultCheckPerson];
      this.excludePersonIds = [...excludePersonIds];
      this.isPersonByTab = isPersonByTab;
      this.choosePersonLabel = choosePersonLabel;
      this.personByTabTitle = personByTabTitle;
      this.personByTabMultiple = personByTabMultiple;
      this.loading = false;
      this.visible = true;
      this.recentType = recentType;
      this.showRecentUsePerson = showRecentUsePerson;
      this.maxlength = maxlength;
      this.otherPerson = otherPerson;
      this.otherPersonLabel = otherPersonLabel;
      this.isPowerCut = isPowerCut;
      this.powerCutConfig = { ...this.powerCutConfig, ...powerCutConfig };
      // this.defaultCheckPerson = defaultCheckPerson;
      this.personType = personType;
      this.t1 = t1;
      this.t2 = t2;
      // 此处采用硬编码，其他改动方式无法确认影响范围
      this.isYanShouHeGe = title === "确认验收合格？" ? true : false;
      if (this.isYanShouHeGe) {
        this.ticketEvaluate = ticketEvaluate;
      }
      this.pauseTime = pauseTime;
      this.selectPerson.checkPersonList = []; // 清空已选人员，防止切换作业票时展示上一次记录
    },
    close() {
      this.visible = false;
      this.doneFn && this.doneFn();
      this.doneFn = null;
    },
    //移除loading状态，一般接口异步加载失败时候调用后，dialog可点击
    removeLoading() {
      this.loading = false;
      this.doneFn && this.doneFn(false);
      this.doneFn = null;
    },
    choosePerson() {
      if (this.isPowerCut) {
        this.powerPersonPincker = true;
        return;
      }
      if (this.isPersonByTab) {
        this.SPBTPicker = true;
        return;
      }
      this.$refs.personPicker.show();
    },
    beforeClose(action, done) {
      // if (action == "cancel") {
      //   done();
      //   this.reject && this.reject();
      //   return;
      // }

      // if (action === "confirm") {
      //   let { message, hasInput } = this;

      //   if (!hasInput) {
      //     //没有输入框
      //     this.doneFn = done;
      //     this.loading = true;
      //     this.resolve && this.resolve(message.trim());
      //     return;
      //   }

      //   if (!message || message.trim().length == 0) {
      //     //没有输入内容
      //     this.messageError = true;
      //     done(false);
      //     return;
      //   }
      //   //有输入内容
      //   //回调方法中需要手动调用show()方法关闭
      //   this.doneFn = done;
      //   this.loading = true;
      //   this.resolve && this.resolve(message.trim());
      // }

      if (action == "cancel") {
        done();
        this.failCb && this.failCb();
        return;
      }

      if (action === "confirm") {
        let {
          message,
          hasInput,
          hasCheckPerson,
          checkPersonList,
          otherPerson,
          isYanShouHeGe,
          ticketEvaluate
        } = this;
        if (
          (!message || message.trim().length == 0) &&
          hasInput &&
          this.isRequired
        ) {
          //没有输入内容
          this.messageError = true;
          done(false);
          return;
        }
        if (
          isYanShouHeGe &&
          ticketEvaluate.evaluateQuotaList?.length &&
          !ticketEvaluate.evaluateResult
        ) {
          this.$toast.fail("请填写作业评价");
          done(false);
          return;
        }
        // this.projectName === "RT" &&
        if (otherPerson.length) {
          if (this.pauseTime > 2) {
            if (this.selectPerson.checkPersonList.every(i => !i.fileId)) {
              this.$toast.fail(`${this.otherPersonLabel}签字未完成！`);
              done(false);
              return;
            }
          } else if (this.otherPersonLabel === "监护") {
            if (this.otherPerson.every(i => !i.fileId)) {
              this.$toast.fail(`${this.otherPersonLabel}人签字未完成！`);
              done(false);
              return;
            }
          } else if (this.otherSubmitBtnDisabled) {
            this.$toast.fail(`${this.otherPersonLabel}人签字未完成！`);
            done(false);
            return;
          }
        }
        if (hasCheckPerson && this.submitBtnDisabled) {
          this.$toast.fail(`${this.choosePersonLabel}人签字未完成！`);
          done(false);
          return;
        }
        //有输入内容
        //回调方法中需要手动调用show()方法关闭
        this.doneFn = done;
        this.loading = true;
        // let params = [];
        // if (this.showRecentUsePerson) {
        //   params = checkPersonList.map(item => {
        //     return {
        //       ...item,
        //       value: item.personId,
        //       label: item.personName
        //     };
        //   });
        // } else {
        //   params = checkPersonList.map(item => {
        //     return {
        //       value: item.personId,
        //       label: item.personName
        //     };
        //   });
        // }
        const params = checkPersonList.map(item => {
          return {
            ...item,
            value: item.personId,
            label: item.personName
          };
        });
        this.successCb && this.successCb(message.trim(), params);
        // if (this.successCb) {
        //   if (hasInput && hasCheckPerson) {
        //     this.successCb(message.trim(), checkPersonList);
        //     return;
        //   }
        //   if (hasInput) {
        //     this.successCb(message.trim());
        //     return;
        //   }
        //   if (hasCheckPerson) {
        //     this.successCb(message.trim(), checkPersonList);
        //     return;
        //   }
        //   this.successCb()
        // }
      }
    },
    chooseNodePerson() {
      this.selectPerson = {
        ...this.selectPerson,
        title: `${this.otherPersonLabel}(单选)`,
        visible: true,
        customList: this.otherPerson
      };
    },
    selectPersonConfirm(ids, list) {
      const data = list ? (Array.isArray(list) ? list : [list]) : [];
      if (!data.length) {
        this.checkPersonList = [];
        return;
      }
      const checkPersonList = [];
      data.forEach(item => {
        const find = this.checkPersonList.find(f => f.personId === item.value);
        let obj = { ...find, personType: "27" };
        if (!find) {
          obj = {
            fileId: "",
            personType: "27",
            personId: item.value,
            personName: item.label,
            id: item.value,
            name: item.label,
            ticketId: this.ticketId,
            specialId: "",
            type: item._mechanismType || item.personType || "0"
          };
        }
        checkPersonList.push(obj);
      });
      this.selectPerson.checkPersonList = checkPersonList;
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.InputDialog {
  ::v-deep .van-dialog__header {
    padding: 20px 16px 12px;
  }
  &.loading {
    //禁止取消按钮点击
    ::v-deep .van-dialog__cancel {
      pointer-events: none;
      cursor: default;
      opacity: 0.6;
    }
  }
  .t1 {
    font-size: 14px;
    font-weight: 500;
    color: #2e2e4d;
    line-height: 20px;
    margin: 24px 20px 0;
  }
  .t2 {
    font-size: 14px;
    font-weight: 400;
    color: #5d5f63;
    line-height: 20px;
    margin: 12px 20px 0;
  }
  .remark-box {
    // border-bottom: 1px solid #e1e3e8;
    padding: 8px 20px 16px;
    ::v-deep {
      .van-cell__value {
        border: 1px solid #e1e3e8;
        padding: 8px 10px 0;
        .van-field__word-limit {
          padding: 0 0 3px;
        }
      }
    }
  }
  .remark-box::after {
    display: none;
  }
  .person-signature-box {
    padding: 0 20px 20px;
    .btn-select-person {
      display: inline-block;
      width: 86px;
      height: 32px;
      line-height: 30px;
      font-size: 14px;
      font-weight: 400;
      text-align: center;
      background-color: #1676ff;
      color: #ffffff;
      padding: 0;
      margin: 0 0 16px;
      &.not-active {
        background: #fff;
        color: #1676ff;
        border-color: #1676ff;
      }
    }
    // .tag-box {
    //   margin: 16px 0 0;
    // }
  }
  .work-evaluation {
    margin: 30px 0 20px;
    .result-box {
      font-size: 14px;
      color: #2e2e4d;
      padding-left: 90px;
      .result {
        color: #f3961c;
      }
    }
  }
}
</style>
