<template>
  <van-popup
    v-model="visible"
    :close-on-click-overlay="false"
    position="bottom"
    :style="popupStyle"
    get-container="body"
    round
    v-bind="$attrs"
    @open="onOpen"
    @close="onClose"
    @click-overlay="onClose"
    v-on="$listeners"
  >
    <section class="select-popup-container">
      <div :class="['header', !showFilter && 'show-border']">
        <i
          class="iconclose iconfont iconfont-danchuangguanbi"
          @click="onClose"
        ></i>
        <h3 class="popup-title">{{ title }}</h3>
        <i
          class="iconsure iconfont iconfont-danchuangqueren"
          @click="onConfirm"
        ></i>
      </div>
      <div
        class="content"
        :style="{
          top: showFilter ? '54px' : '80px'
        }"
      >
        <van-search
          v-if="showFilter"
          v-model="filterStr"
          autofocus
          class="search-bar"
          placeholder="请输入"
          @input="filter"
        >
          <template #left-icon>
            <van-icon
              name="sousuo"
              class-prefix="iconfont"
              class="search-icon"
            />
          </template>
        </van-search>
        <div
          :class="{
            'select-popup-main': true,
            'no-filter-top': !showFilter
          }"
        >
          <div v-show="showList.length" class="select-popup-list">
            <div
              v-for="item in showList"
              :key="item.id"
              class="select-popup-list-item"
              :class="{ active: item[valueKey] === checkedValue }"
              @click="handlerClick(item)"
            >
              {{ item[labelKey] }}
            </div>
          </div>
          <p v-show="!showList.length" class="no-data">
            暂无数据
          </p>
        </div>
      </div>
    </section>
  </van-popup>
</template>

<script>
import uniqBy from "lodash/uniqBy";
export default {
  name: "AutoCompleteGeneral",
  props: {
    title: {
      type: String,
      default: "名称"
    },
    showFilter: {
      type: Boolean,
      default: true
    },
    popupStyle: {
      type: Object,
      default: () => ({ height: "80vh" })
    },
    visible: {
      type: Boolean,
      default: false
    },
    valueKey: {
      type: String,
      default: "id"
    },
    labelKey: {
      type: String,
      default: "name"
    },
    max: {
      type: Number,
      default: 50
    },
    api: {
      type: Function,
      default: function() {}
    },
    params: {
      type: Object,
      default: function() {
        return {};
      }
    },
    disabled: {
      type: Boolean,
      default: false
    },
    remote: {
      type: Boolean,
      default: false
    },
    remoteKey: {
      type: String,
      default: "name"
    },
    checked: String
  },
  data() {
    return {
      loading: false,
      checkedValue: "",
      checkedRow: "",
      filterStr: "",
      filterData: [],
      list: []
    };
  },
  computed: {
    showList() {
      let list;
      if (this.remote) {
        list = this.list.slice(0, this.max);
      } else {
        list = this.filterData.slice(0, this.max);
      }
      return uniqBy(list, this.valueKey);
    }
  },
  methods: {
    async onOpen() {
      this.resetInnerStatue();
      this.checkedValue = this.checked;
      await this.getList();
      this.filterData = [...this.list];
    },
    async getList(name) {
      try {
        if (this.loading) return;
        this.loading = true;
        const query = { ...this.params };
        if (this.remote) {
          query[this.remoteKey || this.valueKey] = this.filterStr;
        }
        let res = await this.api(query);
        this.loading = false;
        if (!Array.isArray(res)) {
          res = res.list;
        }
        this.list = res;
      } catch (e) {
        console.log(e);
      }
    },
    async filter(queryString) {
      const createFilter = item => {
        const value = item[this.valueKey].toLowerCase();
        return value.indexOf(queryString.toLowerCase()) >= 0;
      };
      await this.getList(queryString);
      this.filterData = queryString
        ? this.list.filter(createFilter)
        : this.list;
    },
    handlerClick(row) {
      if (row[this.valueKey] === this.checkedValue) {
        this.onReset();
      } else {
        this.checkedValue = row[this.valueKey];
        this.checkedRow = { ...row };
      }
    },
    onClose() {
      this.$emit("close");
    },
    onConfirm() {
      this.$emit("confirm", this.checkedValue, this.checkedRow);
      this.onClose();
    },
    resetInnerStatue() {
      this.checkedValue = "";
      this.filterStr = "";
      this.checkedRow = null;
    },
    onReset() {
      this.resetInnerStatue();
      this.$emit("reset");
    }
  }
};
</script>

<style lang="scss" scoped>
.choose-icon {
  font-size: 14px;
}
.choose-title {
  color: #2981ff;
}
.van-hairline--top-bottom::after,
.van-hairline-unset--top-bottom::after {
  border: none;
}

.select-popup-container {
  position: relative;
  height: 100%;
}

.header {
  text-align: center;
  position: relative;
  display: flex;
  justify-content: center;
  height: 54px;
  line-height: 54px;
  .iconclose,
  .iconsure {
    position: absolute;
    top: 0;
    width: 44px;
    height: 100%;
    text-align: center;
  }
  .iconclose {
    left: 0;
    color: #9496a3;
  }
  .iconsure {
    right: 0;
    color: #1676ff;
  }
  .popup-title {
    font-weight: bold;
    font-size: 16px;
    color: #1b1b4e;
  }
}
.show-border:after {
  position: absolute;
  bottom: 0;
  content: "";
  display: block;
  height: 1px;
  width: 100%;
  border-bottom: 0.5px solid #c7c9d0;
  transform: scaleY(0.5);
}

.content {
  position: absolute;
  /*top: 54px;*/
  /*top: 80px;*/
  bottom: 0;
  left: 0;
  right: 0;
  .search-bar {
    padding-top: 0;
  }
  .select-popup-main {
    position: absolute;
    top: 45px;
    bottom: 0;
    left: 0;
    right: 0;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    .select-popup-list {
      &-item {
        // height: 44px;
        line-height: 24px;
        padding: 8px 16px;
        color: #2e2e4d;
        &.active {
          color: $--color-primary;
          background-color: rgba(22, 118, 255, 0.1);
        }
      }
    }
  }
  .no-filter-top {
    top: 0;
  }
  .no-data {
    padding: 20px 0;
    color: #aeb3c0;
    font-size: 14px;
    text-align: center;
  }
}
</style>
