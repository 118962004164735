<template>
  <van-popup
    v-model="visible"
    :close-on-click-overlay="false"
    position="bottom"
    :style="popupStyle"
    get-container="body"
    @open="onOpen"
    @close="$emit('close')"
    @click-overlay="$emit('close')"
  >
    <section class="main">
      <div class="header block-space-left-right">
        <h3 class="popup-title">{{ title }}</h3>
        <van-field
          v-model="choosedText"
          class="show-choose-result"
          readonly
          :label="
            selectVal[valueKey] && selectVal[labelKey]
              ? '目前选中：'
              : '您还未选择'
          "
        />
      </div>
      <div class="content">
        <org-tree
          ref="org-tree"
          v-model="selectVal"
          :data="treeData"
          :value-key="valueKey"
          :label-key="labelKey"
        />
      </div>
      <div class="footer block-space-left-right">
        <van-button
          plain
          size="large"
          class="cascader-tree__footer-button--cancel"
          @click.prevent="$emit('cancel')"
          >取消</van-button
        >
        <van-button
          size="large"
          type="info"
          class="cascader-tree__footer-button--confirm"
          :disabled="!choosedText"
          @click.prevent="onConfirm"
          >确定</van-button
        >
      </div>
    </section>
  </van-popup>
</template>

<script>
import { Popup, Search, Button, Field } from "vant";
import OrgTree from "./OrgTree";
import { generatorComponents, getOrgNameByOrgCode } from "@/utils";
const components = [Popup, Search, Button, Field];
import authApi from "@/api/authorization";
import { mapState } from "vuex";
export default {
  name: "DepartmentSelect",
  components: { ...generatorComponents(components), OrgTree },
  props: {
    choosedVal: [String || Number],
    orgCode: [String || Number],
    title: {
      type: String,
      default: "所属部门"
    },
    visible: {
      type: Boolean,
      default: false
    },
    valueKey: {
      type: String,
      default: "id"
    },
    labelKey: {
      type: String,
      default: "label"
    },
    value: {
      type: Object,
      default() {
        return {
          [this.$attrs.valueKey || "id"]: "",
          [this.$attrs.labelKey || "label"]: ""
        };
      }
    }
  },
  data() {
    return {
      popupStyle: {
        height: "75%"
      },
      choosedText: "",
      departmentData: [],
      selectVal: {}
    };
  },
  watch: {
    selectVal(val) {
      this.choosedText = val.label;
    },
    orgCode: {
      handler: function(val) {
        if (this.userInfo.isEnterprise) {
          return false;
        }
        if (val) {
          this.getDepartmentByOrgCode(val);
        } else {
          this.departmentData = [];
          this.$emit("input", {
            [this.valueKey]: "",
            [this.labelKey]: ""
          });
        }
      },
      immediate: true,
      deep: true
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.login.userInfo
    }),
    treeData() {
      if (this.userInfo.isEnterprise) {
        return this.departmentData;
      }
      return this.orgCode ? this.departmentData : [];
    }
  },
  created() {
    if (this.userInfo.isEnterprise) {
      this.getDepartmentByOrgCode(this.userInfo.orgCode);
    }
  },
  methods: {
    async getDepartmentByOrgCode(orgCode) {
      try {
        this.departmentData = await authApi.getSelectTreeDept(orgCode);
      } catch (e) {
        console.log("getDepartmentByOrgCode -> e", e);
      }
    },
    onConfirm() {
      this.$emit("input", this.selectVal);
      this.$emit("confirm", this.selectVal);
    },
    onOpen() {
      if (!this.departmentData.length) {
        this.getDepartmentByOrgCode();
      }
      this.selectVal = { ...this.value };
    }
  }
};
</script>

<style lang="scss" scoped>
input.van-field__control {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.van-hairline--top-bottom::after,
.van-hairline-unset--top-bottom::after {
  border: none;
}
.show-choose-result {
  display: flex;
  justify-content: space-between;
}
.popup-title {
  font-weight: bold;
  font-size: 17px;
  margin-top: 3vh;
  padding-bottom: 3vh;
}
.content {
  height: 45vh;
  overflow-y: auto;
  margin: 0 9vw;
}
.footer {
  margin-top: 20px;
  display: flex;
  justify-content: space-around;
  .van-button {
    width: 148px;
  }
  .cascader-tree__footer-button--cancel {
    color: #1b0f49;
    background-color: $--button-color-cancel;
    box-shadow: $--button-border-color-cancel-shadow;
  }
  .cascader-tree__footer-button--confirm {
    background-color: $--button-color-save;
    border-color: $--button-color-save;
    box-shadow: $--button-color-save-shadow;
  }
}
</style>
