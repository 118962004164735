<template>
  <div class="render-cascade">
    <van-field
      clickable
      :value="getCascadeOptions()"
      class="render-input-box"
      :readonly="!$attrs.editable"
      :label="itemData.label + ':'"
      :required="itemData.options.required"
      :placeholder="$attrs.editable ? '请输入' : ''"
      :error-message="errorMessage"
      :rules="[
        {
          validator: validator
        }
      ]"
      @click="onClick"
    >
    </van-field>
    <cascade-tree
      v-model="data"
      :visible="popupVisible"
      :tree-data="treeData"
      :title="itemData.label"
      :multiple="itemData.options.isMultiple"
      @close="popupVisible = false"
      @confirm="onTreeConfirm"
      @reset="onRest"
    />
  </div>
</template>

<script>
import CascadeTree from "@/components/designForm/components/CascadeTree";
export default {
  name: "RenderCascade",
  components: {
    CascadeTree
  },
  props: {
    value: [String, Array, Number],
    itemData: Object
  },
  data() {
    return {
      data: [],
      keys: {
        labelKey: "label",
        valueKey: "id",
        children: "children"
      },
      errorMessage: "",
      popupVisible: false,
      treeData: []
    };
  },
  watch: {
    data: {
      handler: function(val) {
        this.$emit("input", val);
      },
      deep: true
    }
  },
  created() {
    this.data = this.itemData.options.isMultiple
      ? Array.isArray(this.value)
        ? this.value
        : this.value && this.value.split(",")
      : this.value;
    this.treeData = this.itemData.options?.tree || [];
  },
  methods: {
    onClick() {
      if (this.$attrs.editable) {
        this.popupVisible = true;
      }
    },
    onRest() {
      const multiple = this.itemData.options.isMultiple;
      this.data = multiple ? [] : "";
      this.validator();
    },
    onTreeConfirm() {
      this.validator();
    },
    getCascadeOptions() {
      let treeData = (this.itemData && this.itemData.options.tree) || [];
      let ids = String(this.value)
        .split(",")
        .map(v => Number(v));
      let arr1 = [];
      let fn = function(arr, ids) {
        arr.map(v => {
          if (ids.includes(v.id)) {
            arr1.push({ id: v.id, label: v.label });
          }
          if (v.children && v.children.length) {
            fn(v.children, ids);
          }
        });
      };
      fn(treeData, ids);
      return arr1.map(v => v.label).join(",");
    },
    validator() {
      const required = this.itemData.options?.required;
      if (!required) {
        return true;
      }
      const multiple = this.itemData.options.isMultiple;
      if (multiple) {
        if (!this.data?.length || this.data.some(v => v === "")) {
          this.errorMessage = `${this.itemData.label}不能为空`;
          return false;
        }
      } else {
        if (!this.data) {
          this.errorMessage = `${this.itemData.label}不能为空`;
          return false;
        }
      }
      this.errorMessage = "";
      return true;
    }
  }
};
</script>

<style lang="scss">
.render-cascade {
  .van-cascader__selected-icon {
    display: none;
  }
}
</style>
