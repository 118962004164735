<template>
  <el-form-item
    :label="itemData.label"
    :prop="itemData.code"
    :rules="[
      {
        required: itemData.options.required,
        message: `${itemData.label}不能为空`,
        validator: validator
      }
    ]"
  >
    <el-upload
      :disabled="!$attrs.editable"
      class="upload-demo"
      :action="fileUrl"
      :file-list="data"
      :on-success="onSuccess"
      :on-change="(file, fileList) => onChangeHandle(file, fileList)"
      :before-upload="beforeUpload"
      :on-remove="(file, fileList) => onRemove(file, fileList)"
      :on-preview="onPreview"
      multiple
      name="files"
    >
      <el-button size="small" type="primary" :disabled="!$attrs.editable"
        >点击上传</el-button
      >
      <div slot="tip" class="el-upload__tip">
        {{ `文件不超过${size}${unit}` }}
      </div>
    </el-upload>
  </el-form-item>
</template>

<script>
window.globalConfig = window.globalConfig || process.env;

export default {
  name: "RenderImageUpload",
  props: {
    value: [String, Array],
    itemData: Object,
    size: {
      type: Number,
      default: 10
    },
    unit: {
      type: String,
      default: "MB"
    }
  },
  data() {
    return {
      data: [],
      fileUrl: window.globalConfig.VUE_APP_FILE_URL + "/file/upload"
    };
  },
  watch: {
    data: {
      handler: function(val) {
        val = this.formatFileList(val);
        this.$parent.validateField(this.itemData.code);
        this.$emit("input", val);
      },
      deep: true
    }
  },
  created() {
    this.data = this.value;
  },
  methods: {
    formatFileList(fileList = []) {
      const fileUrl = `${window.globalConfig.VUE_APP_FILE_URL}/file/download?downloadFlag=false&fileName=`;
      return fileList
        .filter(v => v)
        .map(item => {
          if (item.id) {
            return { ...item, url: fileUrl + item.id };
          } else if (item.response && item.response.data) {
            const file = item.response.data[0];
            return { ...file, url: fileUrl + file.id };
          }
        });
    },
    validator(rule, value, callback) {
      if (this.itemData.options) {
        if (this.itemData.options.required && !this.data.length) {
          callback(new Error(`${this.itemData.label}不能为空`));
        }
      }
      callback();
    },
    onRemove(file, fileList) {
      this.data = fileList;
    },
    onSuccess(response, file, fileList) {
      this.data = fileList;
    },
    onPreview(file) {
      const url = `${window.globalConfig.VUE_APP_FILE_URL}/file/download?downloadFlag=true&fileName=${file.id}`;
      const eleLink = document.createElement("a");
      eleLink.setAttribute("download", file.name);
      eleLink.setAttribute("href", url);
      document.body.appendChild(eleLink);
      eleLink.click();
      document.body.removeChild(eleLink);
    },
    beforeUpload(file) {
      const unitSize = {
        KB: 1024,
        MB: 1024 * 1024
      };
      const result = file.size / unitSize[this.unit] < this.size;
      if (!result) {
        this.$message({
          message: "上传文件大小不能超过" + this.size + this.unit + "!",
          type: "warning"
        });
      }
      return result;
    },
    onChangeHandle(file, fileList) {
      this.data = fileList;
    }
  }
};
</script>

<style scoped></style>
