<template>
  <div>
    <van-field
      clickable
      :value="getData()"
      class="render-input-box"
      :readonly="!$attrs.editable"
      :label="itemData.label + ':'"
      :required="itemData.options.required"
      :placeholder="$attrs.editable ? '请选择部门' : ''"
      :error-message="errorMessage"
      :rules="[
        {
          validator: validator
        }
      ]"
      @click="onClick"
    >
    </van-field>
    <cascade-department
      v-model="data"
      :visible="departmentVisible"
      disabled-key="--"
      :multiple="itemData.options.isMultiple"
      @close="departmentVisible = false"
      @confirm="onTreeConfirm"
      @reset="onRest"
    />
  </div>
</template>

<script>
import CascadeDepartment from "@/components/CascadeDepartment";
export default {
  name: "RenderCascaderDepartment",
  components: {
    CascadeDepartment
  },
  props: {
    value: [String, Array],
    itemData: Object
  },
  data() {
    return {
      data: "",
      list: [],
      options: [],
      errorMessage: "",
      departmentVisible: false
    };
  },
  watch: {
    data: {
      handler: function(val) {
        this.$emit("input", val);
      },
      deep: true
    }
  },
  created() {
    this.data = this.itemData.options.isMultiple
      ? Array.isArray(this.value)
        ? this.value
        : this.value && this.value.split(",")
      : this.value;
    if (this.itemData.options.isCustomOptions) {
      this.options = this.itemData.options.options;
    } else {
      this.options = [];
    }
  },
  methods: {
    onClick() {
      if (this.$attrs.editable) {
        this.departmentVisible = true;
      }
    },
    onRest() {
      const multiple = this.itemData.options.isMultiple;
      this.data = multiple ? [] : "";
      this.validator();
    },
    onTreeConfirm() {
      this.validator();
    },
    validator() {
      const required = this.itemData.options?.required;
      if (!required) {
        return true;
      }
      const multiple = this.itemData.options.isMultiple;
      if (multiple) {
        if (!this.data?.length || this.data.some(v => v === "")) {
          this.errorMessage = `${this.itemData.label}不能为空`;
          return false;
        }
      } else {
        if (!this.data) {
          this.errorMessage = `${this.itemData.label}不能为空`;
          return false;
        }
      }
      this.errorMessage = "";
      return true;
    },
    getData() {
      let arr = this.itemData.options.isMultiple ? this.data : [this.data];
      return this.$attrs["dept-data"]
        ?.filter(s => arr.includes(s.value))
        .map(s => s.label)
        .join(",");
    }
  }
};
</script>

<style scoped lang="scss">
.render-input-box {
  position: relative;
}
</style>
