<template>
  <div class="InputEditCell">
    <!-- <input
      v-model="modelValue"
      class="input-reset"
      :disabled="!editing"
      v-bind="$attrs"
    /> -->
    <van-field
      v-model="modelValue"
      class="input-reset"
      :disabled="!editing"
      :rules="$attrs.rules || []"
      v-bind="$attrs"
    />
  </div>
</template>

<script>
import EditFieldMixin from "@/components/edit/mixins/EditFieldMixin";

export default {
  name: "InputEditCell",
  components: {},
  mixins: [EditFieldMixin],
  inheritAttrs: false,
  props: {},
  data() {
    return {};
  },
  created: function() {},
  mounted: function() {},
  methods: {}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.InputEditCell {
  width: 100%;
  box-sizing: border-box;
  margin: 5px 0px;
  .input-reset {
    padding: 0px;
  }
  ::v-deep input {
    width: 100%;
    height: 44px;
    line-height: 44px;
    background: #f5f7fa;
    font-size: 14px;
    color: #8c8f97;
    padding: 0 8px;
  }
}
</style>
