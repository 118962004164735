<template>
  <div class="box-empty">
    <slot v-if="$slots.default" />
    <img v-else src="~@/assets/images/no-data.png" alt="" />
    <span v-if="placeholder !== false">{{ placeholder }}</span>
  </div>
</template>

<script>
export default {
  name: "BoxEmpty",
  props: {
    type: String,
    placeholder: {
      type: [String, Boolean],
      default: "暂无相关数据"
    }
  },
  data() {
    return {};
  },
  created() {}
};
</script>
<style lang="scss">
.box-empty {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  img {
    margin-top: -15%;
    width: 196px;
    height: 160px;
    display: block;
    margin-bottom: 20px;
  }
  > span {
    color: rgba(192, 196, 204, 1);
    font-size: 14px;
    position: relative;
  }
}
</style>
