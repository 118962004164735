<template>
  <div v-if="data.length" class="org-tree">
    <div
      v-for="(item, i) in data"
      v-show="expandFlag"
      :key="i"
      class="org-tree__item"
    >
      <van-cell-group :border="false">
        <van-cell
          v-if="item.filtered"
          :border="false"
          clickable
          @click="selectItem(item)"
        >
          <template #title>
            <van-icon
              v-show="item.children && item.children.length"
              :name="expandArr.includes(i) ? 'zhankai' : 'shouqi'"
              class-prefix="iconfont"
              class="expand-icon"
              @click.stop="expandItem(item, i)"
            />
            <!--            'label-title-color': value[valueKey] === item[valueKey]-->
            <span
              :class="{
                'label-title': true,
                'label-title-color': false
              }"
              >{{ item[labelKey] }}</span
            >
          </template>
          <template v-if="chooseBoxSwitch(item)" #right-icon>
            <van-icon
              v-if="value[valueKey] == item[valueKey]"
              class-prefix="iconfont"
              :class="{
                'choose-icon': true,
                'disabled-icon': item[disabledKey]
              }"
              name="danxuan-yixuan"
            />
            <!-- 处理不可选，权限逻辑 -->
            <van-icon
              v-else
              class-prefix="iconfont"
              :class="{
                'unchoose-icon': true,
                'disabled-icon': item[disabledKey]
              }"
              name="danxuan-weixuan"
            />
          </template>
        </van-cell>
      </van-cell-group>
      <org-tree
        v-if="item.children"
        :data="item.children"
        :disabled-key="disabledKey"
        :choosable-key="choosableKey"
        :expand-flag="expandArr.includes(i)"
        :label-key="labelKey"
        :disabled-toast="disabledToast"
        :value="value"
        :value-key="valueKey"
        @input="input"
      />
    </div>
  </div>
  <!-- <p v-else align="center" class="no-data">暂无数据</p> -->
</template>
<script>
import { Toast } from "vant";
export default {
  // 组件名必写，自己点用自己
  name: "OrgTree",
  props: {
    // 选中的值的属性名，必传
    valueKey: {
      type: String,
      default: "id"
    },
    // 在页面要展示的选项属性名，必传
    labelKey: {
      type: String,
      default: "label"
    },
    // 不可选的唯一标识，如item[disabledKey]未true则不可选择，非必传
    disabledKey: {
      type: String,
      default: "disabled"
    },
    // 选中的值，必传
    value: Object,
    // 控制展开，不需要传
    expandFlag: {
      type: Boolean,
      default: true
    },
    // 总数据，必传
    data: Array,
    // 不可选提示文字，非必传
    disabledToast: String,
    // 是否展示选择
    choosableKey: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      // 当前级组件已展开的项
      expandArr: []
    };
  },
  methods: {
    chooseBoxSwitch(item) {
      if (!this.choosableKey) return true;
      return item[this.choosableKey];
    },
    // 子组件逐级传递选中项
    input(item) {
      this.$emit("input", item);
    },
    // 选择
    selectItem(item) {
      if (this.disabledKey && item[this.disabledKey]) {
        if (this.disabledToast) {
          Toast(this.disabledToast);
        }
        return;
      }
      if (this.chooseBoxSwitch(item)) {
        this.$emit("input", item);
      }
    },
    // 展开
    expandItem(item, i) {
      if (item.children && item.children.length) {
        let index = this.expandArr.indexOf(i);
        if (index > -1) {
          this.expandArr.splice(index, 1);
        } else {
          this.expandArr.push(i);
        }
      }
    }
  }
};
</script>
<style lang="scss" scoped>
$--icon-color: #d4dbe3;
.org-tree {
  padding: 0;
  width: 100%;
  background: #fff;
  overflow: hidden;
  .van-cell-group {
    margin-bottom: 0;
  }
  .van-cell {
    align-items: center;
    padding-left: 0;
    padding-right: 4px;
  }
  .van-cell__title {
    vertical-align: middle;
  }
  &__item {
    box-sizing: border-box;
    position: relative;
    width: 100%;
    color: #3b4664;
    font-size: 14px;
    .iconfont {
      height: 18px;
      font-size: 18px;
      border-radius: 50%;
      line-height: 1;
    }
    .expand-icon {
      color: $--icon-color;
    }
    .choose-icon {
      color: #1676ff;
    }
    .unchoose-icon {
      color: $--icon-color;
    }
    .disabled-icon {
      background-color: #ebedf0;
      border-color: #c8c9cc;
      color: #c8c9cc;
      cursor: not-allowed;
    }
    .label-title {
      padding-left: 2vw;
    }
    .label-title-color {
      color: #3278ff;
    }
    > .org-tree {
      border-bottom: 0;
      padding-left: 5vw;
      width: calc(100% - 5vw);
    }
  }
}
.no-data {
  padding-top: 80px;
  color: #aeb3c0;
  font-size: 14px;
}
</style>
