import Vue from "vue";
import AudioPreviewComp from "./AudioPreviewComp";

const component = Vue.extend(AudioPreviewComp);
let instance;

function init() {
  if (!instance) {
    instance = new component({
      el: document.createElement("div")
    });
    document.body.appendChild(instance.$el);
  }
}

export default {
  show(src) {
    init();
    if (instance.visible) return;

    Vue.nextTick(() => {
      instance.show(src);
    });
  }
};
