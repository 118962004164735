<template>
  <van-popup
    v-model="visible"
    :close-on-click-overlay="false"
    position="bottom"
    :style="popupStyle"
    class="device-popup"
    round
    v-bind="$attrs"
    @open="onOpen"
    @opened="onOpened"
    @click-overlay="onClose"
    v-on="$listeners"
  >
    <div class="popup-con-header">
      <i
        v-if="isShowConfrimIcon"
        class="iconclose iconfont iconfont-danchuangguanbi"
        @click="onClose"
      ></i>
      <div class="popup-con-header-title">{{ title }}</div>
      <i
        v-if="isShowConfrimIcon"
        class="iconsure iconfont iconfont-danchuangqueren"
        @click="selectSure"
      ></i>
      <i
        v-if="!isShowConfrimIcon"
        class="iconright iconfont iconfont-danchuangguanbi"
        @click="onClose"
      ></i>
    </div>
    <div class="popup-list">
      <van-tree-select
        height="100%"
        :items="category"
        :active-id.sync="activeId"
        :main-active-index.sync="activeIndex"
        @click-nav="onClickNav"
        @click-item="onClickItem"
      />
    </div>
  </van-popup>
</template>

<script>
import {
  getEquipmentCategory,
  getEquipmentListByCategory
} from "@/api/basics/equipment";
import { mapState } from "vuex";

export default {
  name: "DevicePopup",
  props: {
    popupHeight: {
      type: String,
      default: "50%"
    },
    title: {
      type: String,
      default: "请选择"
    },
    visible: {
      type: Boolean,
      default: false
    },
    isShowConfrimIcon: {
      // 是否显示右上角的确认对号, 如果不显示则会把关闭放到这个位置
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      popupStyle: {
        height: this.popupHeight
      },
      category: [],
      activeId: "",
      activeIndex: 0
    };
  },
  computed: {
    ...mapState({
      userInfo: state => state.login.userInfo
    })
  },
  methods: {
    onClickNav(index) {
      const item = this.category[index];
      this.getEquipmentListByCategory(item.value, index);
    },
    onClickItem(v) {
      this.$emit("change", v);
    },
    getEquipmentListByCategory(category, index) {
      if (this.category[index].length > 0) {
        return;
      }
      getEquipmentListByCategory({
        category,
        orgCode: this.userInfo.orgCode
      }).then(res => {
        res.map(a => {
          a.text = a.name;
        });
        this.$set(this.category[index], "children", res);
      });
    },
    selectSure() {
      this.$emit("update:visible", false);
      this.$emit("close");
    },
    onOpen() {
      if (this.category.length) {
        return;
      }
      getEquipmentCategory().then(res => {
        const arr = [];
        res.map(a => {
          const { label, value } = a;
          arr.push({
            text: label,
            label,
            value,
            children: []
          });
          if (a.children) {
            a.children.map(item => {
              const { label, value } = item;
              arr.push({
                text: label,
                label,
                value,
                children: []
              });
            });
          }
        });
        this.category = arr;
        if (this.category.length) {
          this.getEquipmentListByCategory(this.category[0].value, 0);
        }
      });
    },
    onOpened() {},
    onClose() {
      this.$emit("update:visible", false);
      this.$emit("close");
    }
  }
};
</script>

<style lang="scss" scoped>
.device-popup {
  .popup-con-header {
    height: 54px;
    line-height: 54px;
    .popup-con-header-title {
      color: #2e2e4d;
      margin: 0 44px;
      text-align: center;
    }
    .iconclose,
    .iconsure,
    .iconright {
      position: absolute;
      top: 0;
      width: 44px;
      height: 100%;
      text-align: center;
    }
    .iconclose {
      left: 0;
      color: #9496a3;
    }
    .iconsure {
      right: 0;
      color: #1676ff;
    }
    .iconright {
      right: 0;
    }
  }
  .popup-list {
    position: absolute;
    top: 56px;
    bottom: 0;
    width: 100%;
    overflow: auto;
    .popup-list-item {
      color: #2e2e4d;
      padding: 14px 16px;
      &.active {
        color: $--color-primary;
      }
      &.active-bgc {
        background-color: rgba(22, 118, 255, 0.1);
      }
    }
  }
}
</style>
