var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('van-dialog',{staticClass:"InputDialog",class:{ loading: _vm.loading },attrs:{"title":_vm.title,"show-cancel-button":"","before-close":_vm.beforeClose,"disabled":_vm.loading},model:{value:(_vm.visible),callback:function ($$v) {_vm.visible=$$v},expression:"visible"}},[(_vm.t1)?_c('p',{staticClass:"t1"},[_vm._v(_vm._s(_vm.t1))]):_vm._e(),(_vm.t2)?_c('p',{staticClass:"t2"},[_vm._v(_vm._s(_vm.t2)+"：")]):_vm._e(),(_vm.hasInput)?_c('van-field',{staticClass:"remark-box",attrs:{"rows":"2","autosize":{ maxHeight: 250 },"type":"textarea","maxlength":_vm.maxlength,"placeholder":_vm.placeholder,"show-word-limit":"","error":_vm.messageError,"disabled":_vm.loading},model:{value:(_vm.message),callback:function ($$v) {_vm.message=$$v},expression:"message"}}):_vm._e(),(_vm.pauseTime > 2)?_c('div',{staticClass:"person-signature-box"},[_c('div',{staticStyle:{"line-height":"44px"}},[_vm._v(_vm._s(_vm.otherPersonLabel)+"：")]),_c('van-button',{staticClass:"btn-select-person",attrs:{"round":""},on:{"click":_vm.chooseNodePerson}},[_vm._v("选择人员")]),_c('div',{staticClass:"tag-box"},_vm._l((_vm.selectPerson.checkPersonList),function(item){return _c('Tag',{key:item.personId,attrs:{"type":item.fileId ? 'green' : 'white',"tick":!!item.fileId},nativeOn:{"click":function($event){return _vm.handleClickTag(
            item,
            item.fileId,
            item.personId,
            item.personType,
            _vm.ticketId,
            item.specialId
          )}}},[_vm._v(" "+_vm._s(item.personName)+" ")])}),1)],1):[(_vm.otherPerson.length)?_c('div',{staticClass:"person-signature-box"},[_c('div',{staticStyle:{"line-height":"44px"}},[_vm._v(_vm._s(_vm.otherPersonLabel)+"人：")]),_c('div',{staticClass:"tag-box"},_vm._l((_vm.otherPerson),function(item){return _c('Tag',{key:item.personId,attrs:{"type":item.fileId ? 'green' : 'white',"tick":!!item.fileId},nativeOn:{"click":function($event){return _vm.handleClickTag(
              item,
              item.fileId,
              item.personId,
              item.personType,
              _vm.ticketId,
              item.specialId
            )}}},[_vm._v(" "+_vm._s(item.personName)+" ")])}),1)]):_vm._e()],(_vm.hasCheckPerson)?_c('div',{staticClass:"person-signature-box"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(
        _vm.isYanShouHeGe &&
          _vm.ticketEvaluate.evaluateQuotaList &&
          _vm.ticketEvaluate.evaluateQuotaList.length
      ),expression:"\n        isYanShouHeGe &&\n          ticketEvaluate.evaluateQuotaList &&\n          ticketEvaluate.evaluateQuotaList.length\n      "}],staticClass:"work-evaluation"},[_vm._v(" 作业评价： "),_c('van-button',{staticClass:"btn-select-person",class:{
          'not-active': !_vm.ticketEvaluate.evaluateResult
        },attrs:{"round":""},on:{"click":function($event){return _vm.$refs.workEvaluation.open()}}},[_vm._v("作业评价")]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.ticketEvaluate.evaluateResult),expression:"ticketEvaluate.evaluateResult"}],staticClass:"result-box"},[_vm._v(" 综合评价："),_c('span',{staticClass:"result"},[_vm._v(_vm._s(_vm.ticketEvaluate.evaluateResult))])])],1),_c('div',[_vm._v(" "+_vm._s(_vm.choosePersonLabel)+"人： "),_c('van-button',{staticClass:"btn-select-person",attrs:{"round":""},on:{"click":_vm.choosePerson}},[_vm._v("选择人员")])],1),_c('div',{staticClass:"tag-box",style:(_vm.choosePersonLabel === '验收' ? 'padding-left: 74px' : '')},_vm._l((_vm.checkPersonList),function(item){return _c('Tag',{key:item.personId,attrs:{"type":item.fileId ? 'green' : 'white',"tick":!!item.fileId},nativeOn:{"click":function($event){return _vm.handleClickTag(
            item,
            item.fileId,
            item.personId,
            item.personType,
            _vm.ticketId,
            item.specialId
          )}}},[_vm._v(" "+_vm._s(item.personName)+" ")])}),1)]):_vm._e(),_c('WorkticketPersonPicker',{ref:"personPicker",attrs:{"title":"验收人","multiple":true,"is-check-person":true,"department":_vm.department},on:{"change":_vm.checkPersonChange},model:{value:(_vm.checkPersonList),callback:function ($$v) {_vm.checkPersonList=$$v},expression:"checkPersonList"}}),_c('select-person-by-tabs',{key:_vm.recentType,attrs:{"title":_vm.personByTabTitle,"visible":_vm.SPBTPicker,"show-department-name":false,"show-phone-number":true,"position":"bottom","include-children":_vm.includeChildrenSwitch,"multiple":_vm.personByTabMultiple,"is-department-auth":false,"default-department":_vm.department,"recent-type":_vm.recentType,"show-recent-use-person":_vm.showRecentUsePerson,"default-tab-name":_vm.showRecentUsePerson ? 2 : 0,"exclude-person-ids":_vm.excludePersonIds},on:{"close":function($event){_vm.SPBTPicker = false},"confirm":_vm.onPersonConfirm}}),_c('select-person',{attrs:{"title":"停电人(单选)","visible":_vm.powerPersonPincker,"choose-value":_vm.checkPersonList,"show-department-name":false,"show-phone-number":true,"position":"bottom","include-children":true,"multiple":false,"license-types":_vm.powerCutConfig.licenseTypes,"department-disabled-key":"--","exclude-person-ids":_vm.excludePersonIds},on:{"close":function($event){_vm.powerPersonPincker = false},"confirm":_vm.powerPersonConfirm}}),_c('WorkEvaluation',{directives:[{name:"show",rawName:"v-show",value:(
      _vm.isYanShouHeGe &&
        _vm.ticketEvaluate.evaluateQuotaList &&
        _vm.ticketEvaluate.evaluateQuotaList.length
    ),expression:"\n      isYanShouHeGe &&\n        ticketEvaluate.evaluateQuotaList &&\n        ticketEvaluate.evaluateQuotaList.length\n    "}],ref:"workEvaluation",attrs:{"data":_vm.ticketEvaluate}}),_c('select-person',{attrs:{"title":_vm.selectPerson.title,"visible":_vm.selectPerson.visible,"choose-value":_vm.selectPerson.checkPersonList,"show-department-name":false,"show-phone-number":true,"position":"bottom","include-children":true,"multiple":false,"license-types":"","department-disabled-key":"--","custom-list":_vm.selectPerson.customList},on:{"close":function($event){_vm.selectPerson.visible = false},"confirm":_vm.selectPersonConfirm}})],2)}
var staticRenderFns = []

export { render, staticRenderFns }