import axios from "@/utils/axios";
import apiPath from "@/utils/apiPath";

const { psm } = apiPath;

/**
 * 获取最新的草稿
 * @param api
 * @param id
 * @returns {*}
 */
export const getFormSettings = function(api, id) {
  return axios.get(`${psm}/forms/${id}`);
};

/**
 * 建立表单和分类的关联关系
 * @param api
 * @param data
 * @returns {*}
 */
export const concatClassifiesForm = function(api, data) {
  return axios.put(`${psm}/safety-classifies/${data.id}/forms`, data);
};

/**
 * 分页字段列表
 * @param {*} params
 * @returns
 */
export function getPageFields(params) {
  return axios.get(psm + "/page/fields", { params });
}

/**
 * 字段列表
 * @param params
 * @returns {Promise<AxiosResponse<T>>}
 */
export const getFields = function(api, params) {
  return axios.get(`${psm}/fields`, { params });
};

/**
 * 新建字段
 * @param data
 * @returns {Promise<AxiosResponse<T>>}
 */
export const postFields = function(api, data) {
  return axios.post(`${psm}/fields`, data);
};

/**
 * 更新字段
 * @param data
 * @returns {Promise<AxiosResponse<T>>}
 */
export const updateFieldById = function(api, data) {
  return axios.put(`${psm}/fields/${data.id}`, data);
};

/**
 * 校验字段名称唯一
 * @param api
 * @returns {*}
 */
export const validateFieldName = function(api, params) {
  return axios.get(`${psm}/fields/validate-names`, { params });
};

/**
 * 获取字段详情
 * @param id
 * @returns {Promise<AxiosResponse<T>>}
 */
export function getFieldById(id) {
  return axios.get(`${psm}/fields/${id}`);
}

/**
 * 删除字段
 * @param id
 * @returns {Promise<AxiosResponse<T>>}
 */
export function deleteFieldById(id) {
  return axios.delete(`${psm}/fields/${id}`);
}

/**
 * 变更字段列表
 * @param params
 * @returns {Promise<AxiosResponse<T>>}
 */
export const getMoudleFields = function(api, params) {
  return axios.get(`${psm}/models/fields`, { params });
};

/**
 * 变更工作流节点下拉
 * @param params
 * @returns {Promise<AxiosResponse<T>>}
 */
export const getMoudleFlows = function(api, params) {
  return axios.get(`${psm}/models/${params.modelId}/flows`, { params });
};

/**
 * 变更工作流节点下拉表单
 * @param params
 * @returns {Promise<AxiosResponse<T>>}
 */
export const getMoudleForms = function(api, params) {
  return axios.get(`${psm}/models/forms`, { params });
};

/**
 * 新增、修改 变更类型布局 settings
 * @param params
 * @returns {Promise<AxiosResponse<T>>}
 */
export const postChangeTypeSettings = function(api, data) {
  return axios.post(`${psm}/change-types/${data.classifyId}/settings`, data);
};

// 获取当前节点配置和 value
export const getValues = function(api, params) {
  return axios.get(`${psm}/list/values`, { params });
};
