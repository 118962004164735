<template>
  <div class="BottomFixedBox">
    <div class="fixed">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "BottomFixedBox",
  components: {},
  data() {
    return {};
  },
  created: function() {},
  mounted: function() {},
  methods: {}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.BottomFixedBox {
  position: relative;
  width: 100%;
  height: 44px;
  padding: 10px 0px 16px 0px;

  .fixed {
    position: fixed;
    display: flex;
    width: 100%;
    bottom: 16px;
    z-index: 100;
    box-sizing: border-box;
    padding: 0px 16px;

    .van-button {
      flex-grow: 1;

      &:not(:last-child) {
        margin-right: 13px;
      }
    }
  }
}
</style>
