<template>
  <div class="empty-data">
    <div class="empty-img" @click="refresh"></div>
    <!-- <h1>无数据</h1> -->
    <!-- <p>暂无相关数据，请 <span @click="refresh">刷新</span> 尝试。</p> -->
    <p>暂无数据</p>
  </div>
</template>

<script>
export default {
  name: "EmptyData",
  methods: {
    refresh() {
      this.$emit("refresh");
    }
  }
};
</script>

<style scoped lang="scss">
.empty-data {
  text-align: center;
  color: #3b4664;
  .empty-refresh {
    padding: 5vw;
    text-align: right;
    border-bottom: 1px solid #eee;
  }
  .empty-img {
    width: 126px;
    height: 126px;
    margin: 5vw auto;
    margin-top: 140px;
    background: url("../assets/images/pic-nodata.png") no-repeat;
    background-size: 126px 126px;
  }
  h1 {
    font-size: 5vw;
    margin-bottom: 8vw;
  }
  p {
    font-size: 4vw;
    span {
      // color: #1890ff;
      color: $--color-link;
    }
  }
}
</style>
