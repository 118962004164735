<template>
  <div class="datetime-popup">
    <div class="dateinput" @click="handlerInputClick">
      <van-icon name="notes-o" class="datetime-sign" />
      <div class="input-style">
        <span v-show="datetime">{{ datetime }}</span>
        <span v-show="!datetime" class="disabled-color">请选择</span>
      </div>
      <van-icon
        v-if="timeClose"
        v-show="datetime"
        slot="button"
        name="close"
        class="clear-btn"
        @click.stop="handleClearClick"
      />
    </div>
    <van-popup
      v-model="datetimeVisible"
      position="bottom"
      :style="{ height: '40%' }"
    >
      <van-datetime-picker
        v-model="currentTime"
        v-bind="$attrs"
        @confirm="handleConfirm"
        @cancel="datetimeVisible = false"
        @change="handleChange"
      />
    </van-popup>
  </div>
</template>

<script>
import { generatorComponents, parseTime } from "@/utils";
import { DatetimePicker, Field, Popup, Icon, Toast } from "vant";
const components = [DatetimePicker, Field, Popup, Icon, Toast];
export default {
  name: "DateTimePickerPopup",
  components: { ...generatorComponents(components) },
  props: {
    value: {
      type: String
    },
    format: {
      type: String,
      default: "{y}-{m}-{d}"
    },
    timeClose: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      datetimeVisible: false,
      datetime: "",
      currentTime: new Date()
    };
  },
  created() {
    this.datetime = this.value;
  },
  methods: {
    handleConfirm(val) {
      this.datetimeVisible = false;
      this.datetime = parseTime(val, this.format);
      this.$emit("input", this.datetime);
      this.$emit("confirm", this.datetime);
    },
    handlerInputClick() {
      this.datetimeVisible = true;
    },
    handleClearClick() {
      this.datetime = "";
      this.$emit("input", this.datetime);
      this.$emit("clear", this.datetime);
    },
    handleChange(val) {
      this.$emit("change", val);
    }
  }
};
</script>

<style lang="scss" scoped>
.van-icon-notes-o::before {
  // 处理图标偏上的问题
  transform: translateY(-1px);
}
.datetime-popup {
  margin: 12px 15px;
  border: 1px solid #dcdfe6;
  border-radius: 4px;
  height: 40px;
  display: flex;
  align-items: center;
  .dateinput {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: inherit;
    line-height: 40px;
    flex: 1;
    padding: 0 8px;
    box-sizing: border-box;
    .clear-btn {
      position: relative;
      vertical-align: middle;
      z-index: 999;
      color: #606266;
      opacity: 0.3;
    }
  }
  .datetime-sign {
    vertical-align: middle;
    font-size: 16px;
  }
  .input-style {
    font-size: 13px;
    margin-left: 5px;
    outline: none;
    border: none;
    width: 100%;
    color: #606266;
    padding: 0;
  }
}
</style>
