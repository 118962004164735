<template>
  <router-link
    class="WorkticketGasCheckItem"
    :to="`/workticket/checkgas/${id}`"
  >
    <span class="result text-gas-check-result" :class="{ pass: result == 1 }">{{
      result ? "合格" : "不合格"
    }}</span>

    <div class="name-line">
      <span>{{ title }}</span>
      <span>（{{ types }}）</span>
    </div>
    <div class="number">许可编号：{{ number }}</div>

    <div class="marginBottom">
      <span class="icon-item ">
        <i
          :style="
            `background-image: url(${require('@/assets/images/workticket-item-1.png')})`
          "
        ></i>
        {{ text1 }}
      </span>
      <span class="icon-item">
        <i
          :style="
            `background-image: url(${require('@/assets/images/workticket-item-4.png')})`
          "
        ></i>
        {{ text2 }}
      </span>
    </div>

    <div>
      <span class="icon-item">
        <i
          :style="
            `background-image: url(${require('@/assets/images/workticket-item-6.png')})`
          "
        ></i>
        {{ text3 }}
      </span>
      <span class="icon-item">
        <i
          :style="
            `background-image: url(${require('@/assets/images/workticket-item-3.png')})`
          "
        ></i>
        {{ text4 }}
      </span>
    </div>
  </router-link>
</template>

<script>
import projectTextConfig from "@/utils/projectTextConfig";

export default {
  name: "WorkticketGasCheckItem",
  components: {},
  props: {
    id: { type: [String, Number], default: "" },
    title: { type: String, default: "作业名称" },
    types: { type: String, default: "作业类别" },
    number: { type: String, default: "xxx-20-0927-0001" }, //许可编号
    text1: { type: String, default: "氯化苯工段" },
    text2: { type: String, default: "作业区域" },
    text3: { type: String, default: projectTextConfig.TEXT_WORK_ANALYST },
    text4: { type: String, default: "2020-04-03 10:32" },
    result: { type: [String, Number], default: 0 } //作业结果
  },
  data() {
    return {};
  },
  created: function() {},
  mounted: function() {},
  methods: {}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.WorkticketGasCheckItem {
  position: relative;
  display: block;
  padding: 10px 16px;
  background-color: #fff;
  font-size: 12px;
  color: #3b4664;
  line-height: 17px;

  .result {
    position: absolute;
    top: 13px;
    right: 16px;
  }

  .name-line {
    font-size: 14px;
    line-height: 20px;
    font-weight: bold;
    padding-right: 75px;
  }

  .number {
    color: #8c8f97;
    margin: 5px 0px;
  }

  .marginBottom {
    margin-bottom: 5px;
  }

  .icon-item {
    display: inline-block;
    margin-right: 20px;

    i {
      display: inline-block;
      width: 15px;
      height: 15px;
      vertical-align: top;
      margin-right: 5px;
      background-size: cover;
    }
  }
}
</style>
