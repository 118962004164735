<template>
  <van-nav-bar :title="title" fixed left-arrow @click-left="backClick()">
    <template #right>
      <van-icon
        v-if="supervisedCheck"
        class="icon-jiandujiancha"
        class-prefix="iconfont"
        name="jiandujiancha1"
        @click="clickjiandujiancha"
      />
      <i
        v-if="showUserChangeSwitch"
        class="iconfont iconfont-renyuanbiangeng icon-user-change"
        @click="clickUserChange"
      ></i>
      <i
        v-if="showProcessIcon"
        class="iconfont iconfont-liucheng icon-process"
        @click="clickProcess"
      ></i>
      <van-icon
        v-if="showGasRecord"
        size="24"
        :name="require('@/assets/images/workticket-gas-record.png')"
        @click="clickGasRecord"
      />
    </template>
  </van-nav-bar>
</template>

<script>
import { WORKTICKET_STATUS } from "@/views/workticket/utils/constant";

export default {
  name: "WorkticketHeader",
  components: {},
  props: {
    title: {
      type: String,
      default: "安全作业许可管理"
    },
    showUserChange: { type: Boolean, default: false },
    showProcess: { type: Boolean, default: false },
    showGasRecord: { type: Boolean, default: false },
    supervisedCheck: { type: Boolean, default: false },
    ticketStatus: { type: [String, Number], default: "" },
    originStatus: { type: [String, Number], default: "" }
  },
  data() {
    return {};
  },
  computed: {
    showUserChangeSwitch() {
      if (this.showUserChange) {
        const userChangeWhiteList = [
          WORKTICKET_STATUS.Gas_monitor,
          WORKTICKET_STATUS.Safety_disclosure,
          WORKTICKET_STATUS.Safety_disclosureing,
          WORKTICKET_STATUS.Approving,
          WORKTICKET_STATUS.Working,
          WORKTICKET_STATUS.PendingJob,
          WORKTICKET_STATUS.Paused
        ];
        const status = Number(this.ticketStatus);
        if (userChangeWhiteList.includes(status)) return true;
      }
      return false;
    },
    showProcessIcon() {
      return (
        this.showProcess &&
        ![
          WORKTICKET_STATUS.Safety_disclosure_sign,
          WORKTICKET_STATUS.Safety_disclosureing
        ].includes(this.ticketStatus)
      );
    }
  },
  created: function() {},
  mounted: function() {},
  methods: {
    backClick() {
      if (
        [
          WORKTICKET_STATUS.Safety_disclosureing,
          WORKTICKET_STATUS.Safety_disclosure_sign
        ].includes(this.ticketStatus)
      ) {
        // 如果是安全交底, 需要提示文案
        if (
          [
            WORKTICKET_STATUS.Safety_disclosure,
            WORKTICKET_STATUS.Craft_safety_disclosure,
            WORKTICKET_STATUS.Overhaul_safety_disclosure
          ].includes(this.originStatus)
        ) {
          this.$dialog
            .confirm({
              title: "",
              message: "将返回安全交底页面, 可再次进行安全措施确认"
            })
            .then(() => {
              // on confirm
              this.$emit("update:ticketStatus", this.originStatus);
            })
            .catch(() => {
              // on cancel
            });
          return;
        }
        this.$emit("update:ticketStatus", this.originStatus);
        return;
      } else if (
        [
          WORKTICKET_STATUS.ReferralApproving, // 转审
          WORKTICKET_STATUS.ApprovingPassOrBack // 通过/退回
        ].includes(this.ticketStatus)
      ) {
        this.$emit("update:ticketStatus", WORKTICKET_STATUS.Approving);
        return;
      }
      this.$router.back();
    },
    clickUserChange() {
      this.$router.push({
        name: "workticketPersonChange",
        params: { id: this.$route.params.id }
      });
    },
    clickProcess() {
      this.$router.push("/workticket/process/" + this.$route.params.id);
    },
    clickGasRecord() {
      this.$emit("clickGasRecord");
    },
    clickjiandujiancha() {
      this.$router.push({
        name: "WorkInspectAdd",
        params: { id: this.$route.params.id }
      });
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.icon-process {
  font-size: 24px;
}
.icon-user-change {
  font-size: 19px;
  margin: 0 11px 0 0;
}
.icon-jiandujiancha {
  font-size: 19px;
  margin: 0 8px 0 0;
}
</style>
