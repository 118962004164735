var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.data.length)?_c('div',{staticClass:"org-tree"},_vm._l((_vm.data),function(item,i){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.expandFlag),expression:"expandFlag"}],key:i,staticClass:"org-tree__item"},[_c('van-cell-group',{attrs:{"border":false}},[(item.filtered)?_c('van-cell',{attrs:{"border":false,"clickable":""},on:{"click":function($event){return _vm.selectItem(item)}},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('van-icon',{directives:[{name:"show",rawName:"v-show",value:(item.children && item.children.length),expression:"item.children && item.children.length"}],staticClass:"expand-icon",attrs:{"name":_vm.expandArr.includes(i) ? 'zhankai' : 'shouqi',"class-prefix":"iconfont"},on:{"click":function($event){$event.stopPropagation();return _vm.expandItem(item, i)}}}),_c('span',{class:{
              'label-title': true,
              'label-title-color': false
            }},[_vm._v(_vm._s(item[_vm.labelKey]))])]},proxy:true},(_vm.chooseBoxSwitch(item))?{key:"right-icon",fn:function(){return [(_vm.value[_vm.valueKey] == item[_vm.valueKey])?_c('van-icon',{class:{
              'choose-icon': true,
              'disabled-icon': item[_vm.disabledKey]
            },attrs:{"class-prefix":"iconfont","name":"danxuan-yixuan"}}):_c('van-icon',{class:{
              'unchoose-icon': true,
              'disabled-icon': item[_vm.disabledKey]
            },attrs:{"class-prefix":"iconfont","name":"danxuan-weixuan"}})]},proxy:true}:null],null,true)}):_vm._e()],1),(item.children)?_c('org-tree',{attrs:{"data":item.children,"disabled-key":_vm.disabledKey,"choosable-key":_vm.choosableKey,"expand-flag":_vm.expandArr.includes(i),"label-key":_vm.labelKey,"disabled-toast":_vm.disabledToast,"value":_vm.value,"value-key":_vm.valueKey},on:{"input":_vm.input}}):_vm._e()],1)}),0):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }