<template>
  <div>
    <van-dialog
      v-model="visible"
      class="InputDialog"
      :class="{ loading }"
      :title="title"
      show-cancel-button
      :before-close="beforeClose"
      :disabled="loading"
    >
      <van-field
        v-if="hasInput"
        v-model="message"
        class="remark-box"
        rows="2"
        :autosize="{ maxHeight: 250 }"
        type="textarea"
        :maxlength="maxlength"
        :placeholder="placeholder"
        show-word-limit
        :error="messageError"
        :disabled="loading"
      />
      <template v-if="title === '延期申请'">
        <van-cell
          :title="`从${startTime}延期至`"
          :label="endTime"
          @click="ERClick"
        >
          <template #right-icon>
            <van-icon class-prefix="iconfont" name="riqi" size="16" />
          </template>
          <template #label>
            <span class="date-range-cell-label">{{ endTime }}</span>
          </template>
        </van-cell>
      </template>
      <div v-if="otherPerson.length" class="person-signature-box">
        <div style="line-height: 44px;">{{ otherPersonLabel }}人：</div>
        <div class="tag-box">
          <Tag
            v-for="item in otherPerson"
            :key="item.personId"
            :type="item.fileId ? 'green' : 'white'"
            :tick="!!item.fileId"
            @click.native="
              handleClickTag(
                item,
                item.fileId,
                item.personId,
                item.personType,
                ticketId,
                item.specialId
              )
            "
          >
            {{ item.personName }}
          </Tag>
        </div>
      </div>
      <div v-if="hasCheckPerson" class="person-signature-box">
        <div>
          {{ choosePersonLabel }}人：
          <van-button class="btn-select-person" round @click="choosePerson"
            >选择人员</van-button
          >
        </div>
        <div class="tag-box">
          <Tag
            v-for="item in checkPersonList"
            :key="item.personId"
            :type="item.fileId ? 'green' : 'white'"
            :tick="!!item.fileId"
            @click.native="
              handleClickTag(
                item,
                item.fileId,
                item.personId,
                item.personType,
                ticketId,
                item.specialId
              )
            "
          >
            {{ item.personName }}
          </Tag>
        </div>
      </div>
      <WorkticketPersonPicker
        ref="personPicker"
        v-model="checkPersonList"
        title="验收人"
        :multiple="true"
        :is-check-person="true"
        :department="department"
        @change="checkPersonChange"
      />
      <select-person-by-tabs
        :key="recentType"
        :title="personByTabTitle"
        :visible="SPBTPicker"
        :show-department-name="false"
        :show-phone-number="true"
        position="bottom"
        :include-children="includeChildrenSwitch"
        :multiple="personByTabMultiple"
        :is-department-auth="false"
        :default-department="department"
        :recent-type="recentType"
        :show-recent-use-person="showRecentUsePerson"
        :default-tab-name="showRecentUsePerson ? 2 : 0"
        @close="SPBTPicker = false"
        @confirm="onPersonConfirm"
      />
      <select-person
        title="停电人(单选)"
        :visible="powerPersonPincker"
        :choose-value="checkPersonList"
        :show-department-name="false"
        :show-phone-number="true"
        position="bottom"
        :multiple="false"
        :license-types="powerCutConfig.types"
        department-disabled-key="--"
        @close="powerPersonPincker = false"
        @confirm="powerPersonConfirm"
      />
    </van-dialog>
    <van-popup v-model="showTimePicker" position="bottom">
      <van-datetime-picker
        v-model="timePickerVal"
        type="datetime"
        :min-date="minDate"
        :max-date="maxDate"
        @confirm="onDateimeConfirm"
        @cancel="showTimePicker = false"
      />
    </van-popup>
  </div>
</template>

<script>
import Tag from "@/components/Tag";
import esign from "@/components/esign";
import WorkticketPersonPicker from "@/components/picker/WorkticketPersonPicker";
import { fileUrl } from "@/utils/constant";
import { addWorkSignature } from "@/api/workticket";
import SelectPersonByTabs from "@/components/SelectPersonByTabs";
import { parseTime } from "@/utils";

export default {
  name: "InputDialog",
  components: {
    Tag,
    WorkticketPersonPicker,
    SelectPersonByTabs
  },
  props: {},
  data() {
    return {
      projectName: window.globalConfig.VUE_APP_NAME,
      title: "标题",
      placeholder: "请输入",
      loading: false, //loading状态下，对话框不可点击
      visible: false,
      message: "",
      messageError: false,
      doneFn: null,
      resolve: null,
      reject: null,
      hasInput: true, //是否显示input输入框
      isRequired: true, // 是否必填
      successCb: null,
      failCb: null,
      checkPersonList: [],
      hasCheckPerson: false,
      ticketId: "",
      department: "",
      isPersonByTab: true,
      choosePersonLabel: "",
      personByTabTitle: "",
      personByTabMultiple: true,
      SPBTPicker: false,
      recentType: "",
      showRecentUsePerson: false,
      maxlength: 500,
      otherPerson: [],
      otherPersonLabel: "",
      powerPersonPincker: false,
      isPowerCut: false,
      powerCutConfig: {
        licenseTypes: ""
      },
      defaultCheckPerson: [],
      personType: "",
      startTime: "",
      endTime: "",
      dateRange: [],
      showTimePicker: false,
      timePickerVal: ""
    };
  },
  computed: {
    //提交按钮判断是否可点击
    submitBtnDisabled() {
      const personList = this.checkPersonList;
      const isSg = personList.some(item => !item.fileId);
      return personList.length ? isSg : true;
    },
    // 其他人是否签名
    otherSubmitBtnDisabled() {
      const personList = this.otherPerson;
      const isSg = personList.some(item => !item.fileId);
      return personList.length ? isSg : true;
    },
    minDate() {
      return this.dateRange.length ? new Date(this.dateRange[0]) : "";
    },
    maxDate() {
      return this.dateRange.length ? new Date(this.dateRange[1]) : "";
    },
    hasMaxDate() {
      if (!this.dateRange.length) return false;
      const year = new Date(this.dateRange[1]).getFullYear();
      return year > 4000;
    },
    includeChildrenSwitch() {
      const inCh = ["批准"];
      return inCh.some(i => i === this.choosePersonLabel);
    }
  },
  watch: {
    message(newValue, oldValue) {
      this.messageError = false;
    }
  },
  methods: {
    ERClick() {
      if (!this.timePickerVal) {
        const end = new Date(this.dateRange[1]);
        const year = end.getFullYear();
        this.timePickerVal = year > 4000 ? new Date() : end;
      }
      this.showTimePicker = true;
    },
    onDateimeConfirm(time) {
      this.endTime = parseTime(time, "{y}-{m}-{d} {h}:{i}:00");
      this.showTimePicker = false;
    },
    powerPersonConfirm(ids, list) {
      const data = list ? (Array.isArray(list) ? list : [list]) : [];
      if (!data.length) {
        this.checkPersonList = [];
        return;
      }
      const checkPersonList = [];
      data.forEach(item => {
        const find = this.checkPersonList.find(f => f.personId === item.value);
        let obj = { ...find, personType: "26" };
        if (!find) {
          obj = {
            fileId: "",
            personType: "26",
            personId: item.value,
            personName: item.label,
            id: item.value,
            name: item.label,
            ticketId: this.ticketId,
            specialId: "",
            type: item._mechanismType || item.personType || "0"
          };
        }
        checkPersonList.push(obj);
      });
      this.checkPersonList = checkPersonList;
    },
    checkPersonChange(val) {
      const data = val ? (Array.isArray(val) ? val : [val]) : [];
      if (!data.length) {
        this.checkPersonList = [];
        return;
      }
      const checkPersonList = [];
      data.forEach(item => {
        const find = this.checkPersonList.find(f => f.personId === item.value);
        let obj = { ...find, personType: "8" };
        if (!find) {
          obj = {
            fileId: "",
            personType: "8",
            personId: item.value,
            personName: item.label,
            ticketId: this.ticketId,
            specialId: ""
          };
        }
        checkPersonList.push(obj);
      });
      this.checkPersonList = checkPersonList;
    },
    onPersonConfirm(ids, list) {
      const data = list || [];
      if (!data.length) {
        this.checkPersonList = [];
        return;
      }
      const checkPersonList = [];
      data.forEach(item => {
        const find = this.checkPersonList.find(f => f.personId === item.value);
        let obj = { ...find, personType: "3202" };
        if (!find) {
          obj = {
            ...item,
            fileId: "",
            personType: this.personType
              ? this.personType
              : this.choosePersonLabel === "批准"
              ? "3202"
              : "14",
            personId: item.value,
            personName: item.label,
            ticketId: this.ticketId,
            specialId: ""
          };
        }
        checkPersonList.push(obj);
      });
      this.checkPersonList = checkPersonList;
    },
    handleClickTag(item, fileId, personId, personType, ticketId, specialId) {
      if (fileId) {
        //已上传过，直接预览
        esign.showImage(fileUrl + fileId);
        return;
      }
      esign.show(imageRes => {
        fileId = imageRes.id;
        if (this.title !== "取消原因") {
          item.fileId = fileId;
          return;
        }
        addWorkSignature({
          fileId,
          personId,
          personType,
          ticketId,
          specialId
        })
          .then(res => {
            item.fileId = fileId;
            this.$toast.success("提交成功");
          })
          .catch(error => {
            this.$toast.fail("签名失败，请重试");
          });
      });
    },
    show({
      title,
      message = "",
      hasInput = true,
      isRequired = true,
      successCb,
      failCb,
      hasCheckPerson,
      ticketId,
      department,
      isPersonByTab = true,
      choosePersonLabel = "验收",
      personByTabTitle = "验收人(多选)",
      personByTabMultiple = true,
      showRecentUsePerson = false,
      recentType = "",
      maxlength = 500,
      otherPerson = [],
      otherPersonLabel = "",
      isPowerCut = false,
      powerCutConfig = {},
      defaultCheckPerson = [],
      personType = "",
      dateRange = []
    }) {
      this.hasInput = hasInput;
      this.isRequired = isRequired;
      this.title = title;
      this.message = message;
      this.messageError = false;
      this.successCb = successCb;
      this.failCb = failCb;
      this.hasCheckPerson = hasCheckPerson;
      this.ticketId = ticketId;
      this.department = department;
      this.checkPersonList = [...defaultCheckPerson];
      this.isPersonByTab = isPersonByTab;
      this.choosePersonLabel = choosePersonLabel;
      this.personByTabTitle = personByTabTitle;
      this.personByTabMultiple = personByTabMultiple;
      this.loading = false;
      this.visible = true;
      this.recentType = recentType;
      this.showRecentUsePerson = showRecentUsePerson;
      this.maxlength = maxlength;
      this.otherPerson = otherPerson;
      this.otherPersonLabel = otherPersonLabel;
      this.isPowerCut = isPowerCut;
      this.powerCutConfig = { ...this.powerCutConfig, ...powerCutConfig };
      // this.defaultCheckPerson = defaultCheckPerson;
      this.personType = personType;
      if (dateRange.length) {
        this.dateRange = dateRange;
        this.startTime = dateRange[0];
        this.endTime = "";
        this.timePickerVal = "";
      }
    },
    close() {
      this.visible = false;
      this.doneFn && this.doneFn();
      this.doneFn = null;
    },
    //移除loading状态，一般接口异步加载失败时候调用后，dialog可点击
    removeLoading() {
      this.loading = false;
      this.doneFn && this.doneFn(false);
      this.doneFn = null;
    },
    choosePerson() {
      if (this.isPowerCut) {
        this.powerPersonPincker = true;
        return;
      }
      if (this.isPersonByTab) {
        this.SPBTPicker = true;
        return;
      }
      this.$refs.personPicker.show();
    },
    beforeClose(action, done) {
      // if (action == "cancel") {
      //   done();
      //   this.reject && this.reject();
      //   return;
      // }

      // if (action === "confirm") {
      //   let { message, hasInput } = this;

      //   if (!hasInput) {
      //     //没有输入框
      //     this.doneFn = done;
      //     this.loading = true;
      //     this.resolve && this.resolve(message.trim());
      //     return;
      //   }

      //   if (!message || message.trim().length == 0) {
      //     //没有输入内容
      //     this.messageError = true;
      //     done(false);
      //     return;
      //   }
      //   //有输入内容
      //   //回调方法中需要手动调用show()方法关闭
      //   this.doneFn = done;
      //   this.loading = true;
      //   this.resolve && this.resolve(message.trim());
      // }

      if (action == "cancel") {
        done();
        this.failCb && this.failCb();
        return;
      }

      if (action === "confirm") {
        let {
          message,
          hasInput,
          hasCheckPerson,
          checkPersonList,
          otherPerson,
          endTime
        } = this;
        if (
          (!message || message.trim().length == 0) &&
          hasInput &&
          this.isRequired
        ) {
          //没有输入内容
          this.messageError = true;
          done(false);
          return;
        }
        if (this.title === "延期申请" && !endTime) {
          this.$toast.fail("请选择延期日期");
          done(false);
          return;
        }
        if (otherPerson.length && this.otherSubmitBtnDisabled) {
          this.$toast.fail(`${this.otherPersonLabel}人签字未完成！`);
          done(false);
          return;
        }
        if (hasCheckPerson && this.submitBtnDisabled) {
          this.$toast.fail(`${this.choosePersonLabel}人签字未完成！`);
          done(false);
          return;
        }
        //有输入内容
        //回调方法中需要手动调用show()方法关闭
        this.doneFn = done;
        this.loading = true;
        // let params = [];
        // if (this.showRecentUsePerson) {
        //   params = checkPersonList.map(item => {
        //     return {
        //       ...item,
        //       value: item.personId,
        //       label: item.personName
        //     };
        //   });
        // } else {
        //   params = checkPersonList.map(item => {
        //     return {
        //       value: item.personId,
        //       label: item.personName
        //     };
        //   });
        // }
        const params = checkPersonList.map(item => {
          return {
            ...item,
            value: item.personId,
            label: item.personName
          };
        });
        if (this.successCb) {
          this.successCb({
            remark: message.trim(),
            choosePerson: params,
            delayEndTime: endTime
          });
        }
        // if (this.successCb) {
        //   if (hasInput && hasCheckPerson) {
        //     this.successCb(message.trim(), checkPersonList);
        //     return;
        //   }
        //   if (hasInput) {
        //     this.successCb(message.trim());
        //     return;
        //   }
        //   if (hasCheckPerson) {
        //     this.successCb(message.trim(), checkPersonList);
        //     return;
        //   }
        //   this.successCb()
        // }
      }
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.InputDialog {
  &.loading {
    //禁止取消按钮点击
    ::v-deep .van-dialog__cancel {
      pointer-events: none;
      cursor: default;
      opacity: 0.6;
    }
  }
  .remark-box {
    // border-bottom: 1px solid #e1e3e8;
    padding: 8px 20px 16px;
    ::v-deep {
      .van-cell__value {
        border: 1px solid #e1e3e8;
        padding: 8px 10px 0;
        .van-field__word-limit {
          padding: 0 0 3px;
        }
      }
    }
  }
  .date-range-cell-label {
    font-size: 16px;
    color: rgb(50, 50, 51);
  }
  .person-signature-box {
    padding: 16px 20px 20px;
    .btn-select-person {
      display: inline-block;
      width: 86px;
      height: 32px;
      line-height: 30px;
      font-size: 14px;
      font-weight: 400;
      text-align: center;
      background-color: #1676ff;
      color: #ffffff;
      padding: 0;
      margin: 0 0 16px;
    }
    // .tag-box {
    //   margin: 16px 0 0;
    // }
  }
}
</style>
