import { getOtpionLabel } from "@/components/edit/utils/options";

export default {
  model: {
    prop: "value",
    event: "change"
  },
  props: {
    editing: { type: Boolean, default: false },
    title: { type: String, default: "" },
    value: { type: [String, Number, Array], default: "" },
    options: { type: Array, default: () => [] },
    optionLabelKey: { type: String, default: "label" },
    optionValueKey: { type: String, default: "value" }
  },
  data() {
    return {};
  },
  computed: {
    modelValue: {
      //用来给v-model组件调用的
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("change", val);
      }
    },
    label() {
      let { options, value, optionValueKey, optionLabelKey } = this;
      return getOtpionLabel({ options, value, optionValueKey, optionLabelKey });
    }
  },
  watch: {},
  methods: {}
};
