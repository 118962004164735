<template>
  <van-popup
    v-model="visible"
    :close-on-click-overlay="false"
    :overlay="false"
    get-container="body"
    v-bind="$attrs"
    class="select-person"
    @opened="onOpen"
    @close="onClose"
    @click-overlay="onClose"
    v-on="$listeners"
  >
    <section class="select-person__container">
      <div class="select-person__header">
        <h3 class="select-person__title">{{ title }}</h3>
        <van-icon
          class="close-icon"
          name="mengcengguanbi"
          class-prefix="iconfont"
          @click.native="$emit('close')"
        />
      </div>
      <div class="select-person__search">
        <van-field
          v-if="showDepartmentSearch"
          v-model="queryInfo.departmentName"
          left-icon="shouqi"
          readonly
          icon-prefix="iconfont"
          placeholder="所属部门"
          class="select-person__department"
          @click="departmentVisible = true"
        />
        <van-search
          v-if="showKeywordSearch"
          v-model="queryInfo.keyInfo"
          autofocus
          placeholder="请输入关键词"
          @search="onSearch"
          @input="onSearch"
        >
          <template #left-icon>
            <van-icon
              name="sousuo"
              class-prefix="iconfont"
              class="search-icon"
            />
          </template>
        </van-search>
      </div>
      <div class="select-person__list">
        <van-pull-refresh
          v-model="refreshing"
          class="select-person__list-wrapper--choose"
          :class="{
            'select-person__list-wrapper': true
          }"
          @refresh="onSearch"
        >
          <van-checkbox-group v-model="chooseIds" :border="false">
            <van-list
              v-model="loading"
              :error.sync="isError"
              :finished="finished"
              :finished-text="isError ? '' : '没有更多了'"
              error-text="加载失败，请稍后再试！"
              @load="getPersonList"
            >
              <van-cell
                v-for="(item, index) in personList"
                :key="index"
                :border="false"
                clickable
                @click="selectPerson(item)"
              >
                <van-checkbox :name="item.value" label-position="left">
                  <template #icon>
                    <van-icon
                      :name="
                        item.checked ? 'danxuan-yixuan' : 'danxuan-weixuan'
                      "
                      class-prefix="iconfont"
                      :color="item.checked ? ' #1676FF' : '#D4DBE3'"
                      class="choose-icon"
                    />
                  </template>
                  <div class="checkbox-list">
                    <span class="custom-title">{{ item.label }}</span>
                    <span v-if="showDepartmentName" class="additional"
                      >（{{ item.departmentName || "" }}）</span
                    >
                    <span v-if="showPhoneNumber" class="additional">{{
                      item.workMobile || ""
                    }}</span>
                  </div>
                </van-checkbox>
              </van-cell>
            </van-list>
          </van-checkbox-group>
        </van-pull-refresh>
      </div>
    </section>
    <section class="select-person__footer">
      <div class="select-person__footer-left">
        <span
          v-for="(item, idx) in chooseList"
          :key="idx"
          @click="handleDelete(idx, item)"
          >{{ item.label }}</span
        >
      </div>
      <van-button
        type="info"
        class="select-person__footer-right"
        @click.prevent="onConfirm"
        >确定({{ chooseIds.length }})
      </van-button>
    </section>
    <cascade-department
      ref="cascadeDepartment"
      v-model="departmentCur"
      :include-children="includeChildren"
      :is-auth="isDepartmentAuth"
      :visible="departmentVisible"
      :disabled-key="departmentDisabledKey"
      @close="departmentVisible = false"
      @confirm="onTreeConfirm"
    />
  </van-popup>
</template>

<script>
import CascadeDepartment from "@/components/CascadeDepartment";
import { getPagePerson } from "@/api/psm";
import { getPagePersonsLicense } from "@/api/workticket";
import uniqBy from "lodash/uniqBy";
import { mapState } from "vuex";
// 可能出现多个人员选择组件，所以不设计双向绑定
export default {
  name: "SelectPerson",
  components: { CascadeDepartment },
  props: {
    // 选中的值，Array<Select> [{ label: '姓名', value: 1 }]，兼容选中回显值
    chooseValue: { type: [Array, String], default: () => [] },
    visible: Boolean,
    departmentDisabledKey: {
      type: String,
      default: "disabled"
    },
    isDepartmentAuth: {
      type: Boolean,
      default: true
    },
    showDepartmentSearch: {
      type: Boolean,
      default: true
    },
    title: {
      type: String,
      default: "选择人员"
    },
    multiple: { type: Boolean, default: true },
    showDepartmentName: {
      type: Boolean,
      default: true
    },
    showPhoneNumber: {
      type: Boolean,
      default: false
    },
    // 不包含的用户id
    excludePersonIds: {
      type: Array,
      default: () => []
    },
    licenseTypes: {
      type: String,
      default: ""
    },
    defaultDepartment: {
      type: [String, Number],
      default: ""
    },
    includeChildren: {
      type: Boolean,
      default: false
    },
    showKeywordSearch: {
      type: Boolean,
      default: true
    },
    // 外部传入人员列表，不从接口获取
    customList: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.login.userInfo
    })
  },
  data() {
    return {
      queryInfo: {
        department: this.defaultDepartment || "",
        departmentName: "",
        subDepartment: this.includeChildren,
        orgCode: "",
        keyInfo: "",
        positionArr: [],
        positions: "",
        page: 1,
        size: 10
      },
      departmentCur: this.defaultDepartment || "",
      departmentVisible: false,
      personList: [],
      loading: false,
      refreshing: false,
      finished: false,
      isError: false,
      cachePersonList: [],
      chooseIds: [],
      chooseList: []
    };
  },
  watch: {
    "queryInfo.positionArr": {
      handler(val) {
        if (val) {
          this.queryInfo.positions = val.join();
        }
      },
      deep: true
    }
    // defaultDepartment: {
    //   handler(newVal) {
    //     console.log("111", newVal);
    //     this.queryInfo.department = newVal;
    //   }
    // }
  },
  methods: {
    async getPersonList() {
      // 传入自定义列表
      if (this.customList?.length) {
        this.loading = false;
        this.personList = this.customList.map(item => ({
          ...item,
          label: item.label || item.name || item.personName,
          value: item.value || item.id
        }));
        return;
      }

      this.finished = false;
      this.loading = true;
      if (this.refreshing) {
        this.personList = [];
        this.refreshing = false;
      }
      try {
        this.queryInfo.orgCode = this.userInfo.orgCode;
        this.queryInfo.workerIds = this.excludePersonIds.join(",");
        let list = [];
        let total = 0;
        if (this.licenseTypes) {
          const params = {
            ...this.queryInfo,
            licenseTypes: this.licenseTypes
          };
          const res = await getPagePersonsLicense(params);
          list = (res.list || []).map(item => {
            item.label = item.name;
            item.value = item.id;
            return item;
          });
          total = res.total;
        } else {
          const res = await getPagePerson(this.queryInfo, true);
          list = res.list;
          total = res.total;
        }
        this.loading = false;
        this.total = total;
        // 当前列表数量等于总数 暂无内容
        if (list && list.length) {
          this.personList =
            this.queryInfo.page === 1
              ? list
              : uniqBy(this.personList.concat(list), "value");
          this.cachePersonList = uniqBy(
            this.cachePersonList.concat(this.personList),
            "value"
          );
          // 回显之前设置的状态
          this.setChooseList();
          if (list.length < this.queryInfo.size) {
            this.finished = true;
          } else {
            // 加载成功下次页码加1
            this.queryInfo.page++;
          }
        } else {
          this.finished = true;
        }
      } catch (error) {
        this.loading = false;
        this.finished = true;
        this.isError = true;
      }
    },
    selectPersonSingle(item) {
      const obj = this.personList.find(
        a => a.checked && a.value !== item.value
      );
      if (obj) {
        obj.checked = false;
      }
      item.checked = !item.checked;
      if (item.checked) {
        this.chooseList = [item];
      } else {
        const index = this.chooseList.findIndex(a => a.value === item.value);
        if (index === -1) {
          return;
        }
        this.chooseList.splice(index, 1);
      }
      this.chooseIds = this.chooseList.map(a => a.value);
    },
    selectPerson(item) {
      if (!this.multiple) {
        this.selectPersonSingle(item);
        return;
      }
      item.checked = !item.checked;
      if (item.checked) {
        this.chooseList.push(item);
      } else {
        const index = this.chooseList.findIndex(a => a.value === item.value);
        if (index === -1) {
          return;
        }
        this.chooseList.splice(index, 1);
      }
      this.chooseIds = this.chooseList.map(a => a.value);
    },
    onOpen() {
      const chooseValue = [...this.chooseValue];
      this.chooseList = chooseValue;
      this.chooseIds = chooseValue.filter(a => a.value).map(x => x.value);
      this.cachePersonList = chooseValue;
      // this.onSearch();
      this.setChooseList();
      // 如果有默认部门, 则需要获取部门默认值
      if (this.defaultDepartment) {
        this.queryInfo.department = this.defaultDepartment;
        // departmentCur用于包含子部门时department为多个时使用
        this.departmentCur = this.defaultDepartment;
        this.$nextTick(() => {
          const v = this.$refs.cascadeDepartment.getSelectValue();
          this.queryInfo.departmentName = v.label;
          if (this.includeChildren) {
            this.$refs.cascadeDepartment.onConfirm();
          }
        });
      } else {
        // 如果重置有问题则用多个组件
        this.queryInfo.department = "";
        this.queryInfo.departmentName = "";
      }
      this.onSearch();
    },
    onClose() {
      // 重置查询
      // this.queryInfo.department = "";
      // this.queryInfo.departmentName = "";
      this.queryInfo.keyInfo = "";
      this.queryInfo.page = 1;
      this.$emit("close");
    },
    onConfirm() {
      this.$emit("confirm", this.chooseIds, this.chooseList);
      this.$emit("close");
    },
    onTreeConfirm(val, { include }) {
      this.departmentCur = val?.id || "";
      this.queryInfo.department = val?.id || "";
      this.queryInfo.subDepartment = include;
      this.queryInfo.departmentName = val?.label;
      this.onSearch();
    },
    onSearch() {
      this.queryInfo.page = 1;
      this.personList = [];
      this.getPersonList();
    },
    setChooseList(ids) {
      const _ids = ids || this.chooseIds;
      this.chooseList = this.cachePersonList.filter(person => {
        const b = _ids.includes(person.value);
        if (b) {
          person.checked = true;
        }
        return b;
      });
      // 回显列表选中态
      this.personList.map(a => {
        a.checked = _ids.includes(a.value);
      });
    },
    handleDelete(idx, item) {
      const person = this.personList.find(a => a.value === item.value);
      person && (person.checked = false);
      this.chooseList.splice(idx, 1);
      this.chooseIds = this.chooseList.map(a => a.value);
    }
  }
};
</script>
