<template>
  <div class="enterprise-screen">
    <div class="enterprise-header">
      <div class="enterprise-info">
        <h3>所属企业</h3>
        <div>
          <van-icon name="arrow" @click="colosePopup" />
        </div>
      </div>
      <van-search
        v-model="searchEnterpriseValue"
        placeholder="请输入企业名称关键字"
        @input="handleFilter"
        @clear="clear"
      />
    </div>
    <div class="enterprise-body">
      <div
        v-for="item in options"
        :key="item.value"
        class="item"
        :class="item.value == activeId ? 'is-active' : ''"
        @click="clickEnterprise(item)"
      >
        <span>{{ item.label }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { Search, Icon } from "vant";
import { getAllEnterpriseList } from "@/api/base";
export default {
  name: "EnterpriseScreen",
  components: {
    [Search.name]: Search,
    [Icon.name]: Icon
  },
  data() {
    return {
      searchEnterpriseValue: "",
      activeId: 0,
      enterpriseList: [],
      options: []
    };
  },
  async mounted() {
    try {
      this.enterpriseList = await getAllEnterpriseList();
      this.enterpriseList.unshift({ label: "全部", value: "" });
      this.options = [...this.enterpriseList];
    } catch (e) {
      console.log("mounted -> e", e);
    }
  },
  methods: {
    clickEnterprise(item) {
      this.activeId = item.value;
      this.$emit("handleEnterprise", item);
      setTimeout(() => {
        this.searchEnterpriseValue = "";
        this.options = [...this.enterpriseList];
      }, 1000);
    },
    handleFilter(val) {
      this.options = this.enterpriseList.filter(
        item => item.label.indexOf(val) >= 0
      );
    },
    clear() {
      this.options = [...this.enterpriseList];
    },
    colosePopup() {
      this.$emit("closePopup");
    }
  }
};
</script>

<style scoped lang="scss">
.enterprise-screen {
  height: 100%;
  display: flex;
  flex-direction: column;
  .item {
    padding: 5vw 16vw;
    color: #0b0817;
    font-size: 4vw;
  }
}
.enterprise-header {
  height: 40vw;
  .enterprise-info {
    margin: 14vw 10vw 4vw 10vw;
    display: flex;
    justify-content: space-between;
    h3 {
      font-size: 5.6vw;
      color: $--color-link;
    }
  }
}
.enterprise-body {
  flex: 1;
  overflow-y: auto;
  padding-bottom: 10vw;
}
.is-active {
  background: #e1ebff;
  // span {
  // color: #fff;
  // }
}
</style>
